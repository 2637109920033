import { Container, Typography } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export const NoMatch = () => {
  const { pathname } = useLocation()
  return (
    <Container>
      <Typography variant="h3">
        No match for <code>{pathname}</code>
      </Typography>
      <Typography paragraph>
        <Link to="/">Go to start page</Link>
      </Typography>
    </Container>
  )
}
