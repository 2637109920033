import ContentCutIcon from '@mui/icons-material/ContentCut'
import KeyIcon from '@mui/icons-material/Key'
import LinkIcon from '@mui/icons-material/Link'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import {
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  getListingViaHemnetId,
  listingViaHemnetIdSelector,
  selectLoading,
} from './listingsSlice'

export default function OpenFromHemnetPage({ match }) {
  const { params } = match
  const dispatch = useDispatch()
  const history = useHistory()
  const hetmnetUrl = params.url && decodeURIComponent(params.url)
  const hemnetId = hetmnetUrl && getIdFromUrl(hetmnetUrl)
  const listingId = useSelector(listingViaHemnetIdSelector)
  const loading = useSelector(selectLoading)
  const listingUrl = listingId && `/listing/${listingId}`

  useEffect(() => {
    if (!hemnetId) return
    dispatch(getListingViaHemnetId({ hemnetId, index: 'listing' }))
  }, [dispatch, hemnetId])

  useEffect(() => {
    if (!listingUrl) return
    history.replace(listingUrl)
  }, [listingUrl, history])

  return (
    <>
      <Helmet>
        <title>Open from Hemnet ...</title>
      </Helmet>
      <Box display="flex" sx={{ maxWidth: 'lg' }} m="auto">
        <Box>
          <Typography variant="h2" gutterBottom>
            Finding listing{' '}
            {hemnetId && loading === 'pending' && <CircularProgress />}
          </Typography>
          <List sx={{ fontSize: '175%' }}>
            <ListItem>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <Link href={hetmnetUrl} target="_blank">
                {hetmnetUrl}
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ContentCutIcon />
              </ListItemIcon>
              {hemnetId || 'No Hemnet web ID found'}
            </ListItem>
            {listingId && (
              <ListItem>
                <ListItemIcon>
                  <KeyIcon />
                </ListItemIcon>
                <Link
                  href={listingUrl}
                >{`Movesta Listing ID: ${listingId}`}</Link>
              </ListItem>
            )}
            {loading === 'idle' && !listingId && (
              <ListItem>
                <ListItemIcon>
                  <ReportGmailerrorredIcon />
                </ListItemIcon>
                <Box>
                  <Typography>Listing not found.</Typography>
                  <Typography>
                    Is it possible that the item has been sold very recently,
                    within the last 24 hours? You can still try to find the
                    listing in <Link href="/search">Search</Link> by entering
                    the address.
                  </Typography>
                </Box>
              </ListItem>
            )}
            {loading === 'rejected' && (
              <ListItem>
                <ListItemIcon>
                  <ReportGmailerrorredIcon />
                </ListItemIcon>
                {JSON.stringify(loading)}
              </ListItem>
            )}
          </List>
        </Box>
      </Box>
    </>
  )
}

function getIdFromUrl(url) {
  let hemnetId = null
  const urlTypes = ['/salda/', '/bostad/']
  urlTypes.forEach((key) => {
    if (url.indexOf(`www.hemnet.se${key}`) !== -1) {
      const parts = url.split(key)
      if (parts.length === 2) {
        const pparts = parts[1].split('-')
        const last = pparts[pparts.length - 1]
        const lastWithoutHash = last.split('#')[0]
        if (parseInt(lastWithoutHash)) {
          hemnetId = `${key}${lastWithoutHash}`
        }
      }
    }
  })

  return hemnetId
}
