import { AccessTime, MoveToInbox } from '@mui/icons-material'
import { Container, LinearProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'

import { DebugJSONPopupButton } from '../../components/DebugJSONPopupButton'
import { utcIso } from '../../utils/dateUtils'
import { AddressCard } from './components/AddressCard'
import { AVMCard } from './components/AVMCard'
import { HomeFeaturesCard } from './components/HomeFeaturesCard'
import { LeadStatusCard } from './components/LeadStatusCard'
import { ManualValuationsCard } from './components/ManualValuationsCard'
import { OutboundListingCard } from './components/OutboundListingCard'
import { PersonCard } from './components/PersonCard'
import { SaleGuaranteeOffersCard } from './components/SaleGuaranteeOffersCard'
import { getStreetAddress } from './homeUtils'
import { leadByIdConnector, leadByIdSelector } from './leadsSelectors'
import { getContactName } from './leadUtils'
import { TENANTS } from './tenants'

export const LeadDetailPage = ({ match }) => {
  const leadId = match.params.id
  useFirestoreConnect(leadByIdConnector(leadId))
  const lead = useSelector((state) => leadByIdSelector(state, leadId))

  const streetAddress = getStreetAddress(
    lead?.home?.features_snapshot?.features
  )
  const createdAt = lead?.created_at && moment(utcIso(lead.created_at))

  const listingId = lead?.external_id?.split('lid_')?.[1]

  return !isLoaded(lead) || !isLoaded(lead.home) ? (
    <LinearProgress />
  ) : (
    <Container sx={{ pb: 5 }}>
      <Helmet>
        <title>
          Lead {streetAddress}, {getContactName(lead)}
        </title>
      </Helmet>
      <Typography variant="h1" gutterBottom>
        {streetAddress}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
        <AccessTime sx={{ flex: '0 0 20px' }} />
        <Typography>{createdAt.format('dddd, YYYY-MM-DD HH:mm')}</Typography>
        <Typography sx={{ color: '#bbb' }}>({createdAt.fromNow()})</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
        <MoveToInbox sx={{ flex: '0 0 20px' }} />
        <Typography>{TENANTS[lead?.tenant_id] ?? 'Unknown source'}</Typography>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 3 }}
      >
        <PersonCard lead={lead} sx={{ flex: '1 0 300px' }} />
        <AddressCard lead={lead} sx={{ flex: '1 0 300px' }} />
        <LeadStatusCard lead={lead} sx={{ flex: '1 0 300px' }} />
        {listingId && (
          <OutboundListingCard
            listingId={listingId}
            sx={{ flex: '1 0 300px' }}
          />
        )}
        <HomeFeaturesCard
          features={lead?.home?.features_snapshot?.features}
          sx={{ flex: '1 0 300px' }}
        />
        <AVMCard lead={lead} sx={{ flex: '1 0 300px' }} />
        <ManualValuationsCard lead={lead} sx={{ flex: '1 0 300px' }} />
        <SaleGuaranteeOffersCard leadId={leadId} sx={{ flex: '1 0 300px' }} />
        <Box flex="1 0 300px"></Box>
      </Box>
      <DebugJSONPopupButton
        data={lead}
        title="Lead"
        buttonText="Show raw lead"
      />
    </Container>
  )
}
