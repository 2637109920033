import { Container, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useQuery } from '../../clients/routerUseQuery'
import { isValidUUID } from '../../utils/uuidUtils'
import { requireOffer, selectLoading } from './offersSlice'

export default function RequireOfferPage(props) {
  const { uuid } = useQuery()
  const loading = useSelector(selectLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isValidUUID(uuid)) {
      dispatch(requireOffer({ uuid: uuid }))
    }
  }, [dispatch, uuid])

  const onChange = useCallback(
    (event) => {
      const uuidStr = event.target.value
      if (isValidUUID(uuidStr)) {
        dispatch(requireOffer({ uuid: uuidStr }))
      }
    },
    [dispatch]
  )

  return loading === 'idle' ? (
    <Container>
      <Typography variant="h3">Require Offer</Typography>
      <div>
        <p>To “create” an offer page for a HubSpot Deal:</p>
        <ol>
          <li>Set a value for the Presented offer amount property.</li>
          <li>Copy the deal uuid value.</li>
          <li>Paste it here.</li>
        </ol>
      </div>
      <form target="_blank" rel="noopener noreferrer">
        <input
          type="textarea"
          placeholder="lead uuid"
          name="uuid"
          onChange={onChange}
        />
      </form>
    </Container>
  ) : (
    <pre>{JSON.stringify(loading, null, 2)}</pre>
  )
}
