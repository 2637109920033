import { Add, Check, Error, PlayArrow, Save } from '@mui/icons-material'
import { Button } from '@mui/material'

import { AddSelectMenu } from './AddSelectMenu'

export function RenovationEstimateFormButtons({
  valid,
  dirty,
  submitting,
  fields,
  values,
  canAddDefaults,
  configNotDeprecated,
  handleSubmit,
  onAddAllDefaults,
  onAddItem,
}) {
  const configList = Object.values(configNotDeprecated)

  return (
    <>
      <AddSelectMenu
        buttonVariant="outlined"
        icon={<Add />}
        onSelectId={(itemId) => onAddItem(fields, itemId, values)}
        options={configList}
      />
      <Button
        variant="outlined"
        disabled={canAddDefaults}
        onClick={() => onAddAllDefaults(fields, values)}
      >
        <PlayArrow /> Add defaults
      </Button>
      <Button
        variant="outlined"
        disabled={!values.valuation_id}
        href={`/valuate/${values.valuation_id}`}
      >
        Open valuation
      </Button>
      <Button
        variant="contained"
        disabled={!valid || submitting || !dirty}
        onClick={handleSubmit}
        sx={{ marginLeft: 'auto', width: 170 }}
      >
        {!valid ? (
          <>
            <Error sx={{ mr: 1 }} />
            Errors
          </>
        ) : dirty ? (
          <>
            <Save sx={{ mr: 1 }} />
            Save
          </>
        ) : (
          <>
            <Check sx={{ mr: 1 }} />
            No changes
          </>
        )}
      </Button>
    </>
  )
}
