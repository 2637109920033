import { Alert, Box, Grid } from '@mui/material'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'
import _ from 'lodash'
import { makeValidate } from 'mui-rff'
import { Children, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useDispatch } from 'react-redux'

import FFSubmitError from '../../../components/FFSubmitErrors.js'
import { formErrorsAsList } from '../../../utils/formUtils.js'
import { generateUUID } from '../../../utils/uuidUtils.js'
import { formSchema } from '../formSchema.js'
import {
  addAllDefaults,
  addItem,
  addOption,
  calculateItemTotal,
  calculateOptionTotal,
} from '../formUtils.js'
import { createRenovationEstimate } from '../renovationEstimateSelectors.js'
import { RenovationEstimateFormButtons } from './RenovationEstimateFormButtons.js'
import { RenovationEstimateItem } from './RenovationEstimateItem.js'
import { RenovationEstimateProperties } from './RenovationEstimateProperties.js'

export function RenovationsEstimateForm({
  renovationEstimateConfig,
  configNotDeprecated,
  renovationEstimate,
}) {
  const dispatch = useDispatch()
  const validate = makeValidate(formSchema)
  const initialValues = {
    notes: '\n\nRequests: ',
    supplementary_area: 0,
    num_floors: 1,
    ...renovationEstimate,
  }

  const onAddItem = useCallback(
    (fields, itemId, values) =>
      addItem(renovationEstimateConfig, fields, itemId, values),
    [renovationEstimateConfig]
  )
  const onAddOption = useCallback(
    (fields, itemId, optionId, value) =>
      addOption(renovationEstimateConfig, fields, itemId, optionId, value),
    [renovationEstimateConfig]
  )

  const onAddAllDefaults = useCallback(
    (fields, values) => addAllDefaults(configNotDeprecated, fields, values),
    [configNotDeprecated]
  )

  const onSubmit = useCallback(
    (values, form) => {
      const id = generateUUID()
      const renovationEstimate = { id, ...values }
      form.reset(values)
      return dispatch(createRenovationEstimate({ renovationEstimate }))
        .unwrap()
        .then(() => {
          form.reset(values)
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error('submission error', rejectedValueOrSerializedError)
          return { [FORM_ERROR]: rejectedValueOrSerializedError }
        })
    },
    [dispatch]
  )

  const requiredFieldsForDefaults = [
    'living_area',
    'num_floors',
    'supplementary_area',
    'indoor_area',
  ]
  const canAddDefaults = (errors) =>
    Object.keys(errors).filter(
      (fieldName) => requiredFieldsForDefaults.indexOf(fieldName) !== -1
    ).length !== 0

  const decorator = useMemo(
    () =>
      createDecorator(
        {
          field: 'items',
          updates: {
            total: (items) =>
              items.map((item) => item.total).reduce((a, b) => a + b, 0),
          },
        },
        {
          field: /(living_area)|(supplementary_area)/,
          updates: (value_, name, allValues) => {
            return {
              indoor_area:
                parseFloat(allValues['living_area']) +
                  parseFloat(allValues['supplementary_area']) || 0,
            }
          },
        },
        {
          field: /items\[(.*)\]\.options/,
          updates: (__, name, allValues) => {
            const matcher = /items\[(.*)\]\.options/.exec(name)
            if (!matcher || matcher.length !== 2) return {}
            const itemIdx = matcher[1]
            const itemId = allValues.items[itemIdx].id

            const updates = {}

            allValues.items[itemIdx].options.forEach((option, optionIdx) => {
              const optionId = option.id
              const value = option.value
              const optionConfig =
                renovationEstimateConfig[itemId].options[optionId]
              const total = calculateOptionTotal(
                optionConfig.cost.type,
                optionConfig.cost.base,
                optionConfig.cost.per_unit,
                value
              )
              updates[`items[${itemIdx}].options[${optionIdx}].total`] =
                total || 0
            })

            const itemTotal = calculateItemTotal(allValues.items[itemIdx])
            if (itemTotal !== allValues.items[itemIdx].total) {
              updates[`items[${itemIdx}].total`] = itemTotal
            }
            return _.isEmpty(updates) ? null : updates
          },
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      decorators={[decorator]}
      mutators={{ ...arrayMutators }}
      render={({
        handleSubmit,
        submitting,
        valid,
        dirty,
        errors,
        values,
        submitErrors,
      }) => (
        <>
          {<FFSubmitError submitErrors={submitErrors} />}
          {Children.toArray(
            formErrorsAsList(errors).map((errorMessage) => (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            ))
          )}
          <form
            onSubmit={handleSubmit}
            noValidate={true}
            autoComplete="new-password"
          >
            <FieldArray name="items">
              {({ fields }) => (
                <>
                  <Grid container>
                    <Grid item md={3}>
                      <RenovationEstimateProperties {...renovationEstimate} />
                    </Grid>
                    <Grid item md={9}>
                      <Box sx={{ display: 'flex', gap: 3 }}>
                        <RenovationEstimateFormButtons
                          {...{
                            valid,
                            dirty,
                            submitting,
                            fields,
                            values,
                            configNotDeprecated,
                            handleSubmit,
                            onAddAllDefaults,
                            onAddItem,
                            canAddDefaults: canAddDefaults(errors),
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Box>
                          {Children.toArray(
                            fields.map((itemName, itemIndex) => (
                              <RenovationEstimateItem
                                itemName={itemName}
                                itemValue={fields.value[itemIndex]}
                                itemConfig={
                                  renovationEstimateConfig[
                                    fields.value[itemIndex].id
                                  ]
                                }
                                onClickRemove={() =>
                                  fields.value[itemIndex] &&
                                  fields.remove(itemIndex)
                                }
                                onAddOption={(fields, itemId, optionId) =>
                                  onAddOption(fields, itemId, optionId, values)
                                }
                              />
                            ))
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </FieldArray>
          </form>
        </>
      )}
    ></Form>
  )
}
