import { Add, Cancel, NoPhotography, Remove } from '@mui/icons-material'
import {
  Card,
  CardContent,
  Chip,
  IconButton,
  Pagination,
  Skeleton,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { capitalize } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import ThumbCarousel from '../../../components/ThumbCarousel'
import {
  formatDate,
  formatLivingAreaRoomsAndSupplementaryArea,
  formatSEK,
  formatSEKPerArea,
} from '../../../utils/formatUtils'
import {
  getListing,
  getListings,
  listingByIdSelector,
} from '../../listings/listingsSlice'
import { isSold } from '../../listings/listingUtils'
import {
  addComparableToManualValuation,
  removeComparableFromManualValuation,
} from '../../valuate/valuationActions'
import { isEditable } from '../../valuate/valuationUtils'

const THUMB_HEIGHT = 200
const MANUAL_VALUATIN_BUTTONS_HEIGHT = 40

export const MapPointsPreview = ({
  previewMapPoints,
  manualValuation,
  onClose,
}) => {
  const dispatch = useDispatch()

  const [activeIdx, setActiveIdx] = useState(0)

  const previewMapPointsSorted = useMemo(
    () => previewMapPoints.sort(sortPoints),
    [previewMapPoints]
  )

  const point = previewMapPointsSorted[activeIdx]
  const listingId = point?.properties?.sourceId

  const listing = useSelector((state) => listingByIdSelector(state, listingId))

  const manualValuationOn = manualValuation && isEditable(manualValuation)

  useEffect(() => {
    setActiveIdx(0)
  }, [previewMapPointsSorted, setActiveIdx])

  useEffect(() => {
    if (!listing && listingId) {
      dispatch(getListing({ listingId }))
      // prefetch
      setTimeout(() => {
        const listingIds = previewMapPointsSorted.map(
          (point) => point.properties.sourceId
        )
        dispatch(getListings({ listingIds }))
      }, 1000)
    }
  }, [previewMapPointsSorted, listingId, listing, dispatch])

  const label = point?.properties?.active ? (
    <Chip color="highlight" label="matched by filters" />
  ) : point?.properties?.searchResult ? (
    <Chip color="primary" label="matched by filters" />
  ) : (
    <Chip label="not matched by filters" />
  )

  return (
    <Box mb={2}>
      <Card>
        <CardContent sx={{ boxShadow: 4 }}>
          <Box
            container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {label}
            <IconButton
              color="primary"
              sx={{
                position: 'relative',
                transform: 'translate(25%, -25%)',
              }}
            >
              <Cancel sx={{ fontSize: 25 }} onClick={onClose} />
            </IconButton>
          </Box>
          <Box
            height={
              THUMB_HEIGHT +
              220 +
              (manualValuationOn ? MANUAL_VALUATIN_BUTTONS_HEIGHT : 0)
            }
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            {listing ? (
              <>
                {listing.thumbImages?.length ? (
                  <ThumbCarousel
                    images={listing.thumbImages}
                    imageHeight={THUMB_HEIGHT}
                  />
                ) : (
                  <Box
                    sx={{
                      height: THUMB_HEIGHT,
                      width: '100%',
                      backgroundColor: '#ddd',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <NoPhotography sx={{ fontSize: '40px', color: '#aaa' }} />
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="h5"
                    sx={{ flexGrow: 1 }}
                    component={Link}
                    to={listing ? `/listing/${listing.id}` : null}
                  >
                    {listing.streetAddress}
                  </Typography>
                </Box>
                <Box
                  container
                  sx={{
                    display: 'flex',
                    width: '100%',
                    mt: 2,
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>
                    {isSold(listing)
                      ? `Sold on ${formatDate(listing.soldDate)}`
                      : `Listed since ${
                          formatDate(listing.publicationDate) || 'N/A'
                        }`}
                  </Typography>

                  {listing.statusFormatted && (
                    <Typography
                      sx={{
                        background: '#ddd',
                        maxWidth: '90px',
                        overflow: 'hidden',
                        borderRadius: 2,
                        pt: 0.15,
                        px: 1,
                        fontSize: '1.0em',
                        fontWeight: 700,
                      }}
                    >
                      {listing.statusFormatted}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography color="#888">
                    {capitalize(listing.housingType)}
                  </Typography>
                  <Typography fontWeight={700}>
                    {isSold(listing) && formatSEK(listing.soldPrice)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography sx={{ flex: 1 }}>
                    {listing.livingArea
                      ? formatLivingAreaRoomsAndSupplementaryArea({
                          livingArea: listing.livingArea,
                          noRooms: listing.noRooms,
                          supplementaryArea: listing.supplementaryArea,
                        })
                      : null}
                  </Typography>
                  <Typography>
                    {isSold(listing) &&
                      listing.soldPrice &&
                      formatSEKPerArea(listing.soldPricePerLivingArea)}
                  </Typography>
                </Box>

                {manualValuationOn && (
                  <Box sx={{ height: '70px', mt: 3 }}>
                    {Object.keys(manualValuation.comparables).includes(
                      `hemnet_listing_${listing.id}`
                    ) ? (
                      <IconButton
                        color="error"
                        size="small"
                        onClick={() => {
                          dispatch(
                            removeComparableFromManualValuation({
                              comparableId: `hemnet_listing_${listing.id}`,
                              manualValuationId: manualValuation.id,
                            })
                          )
                        }}
                      >
                        <Remove />
                        Remove
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          dispatch(
                            addComparableToManualValuation({
                              type: 'hemnet_listing',
                              listingId: listing.id,
                              manualValuationId: manualValuation.id,
                            })
                          )
                        }}
                      >
                        <Add /> Add
                      </IconButton>
                    )}
                  </Box>
                )}
              </>
            ) : (
              <Box display="flex" flexDirection="column" gap={1}>
                <Skeleton variant="rectangular" height={THUMB_HEIGHT} />
                <Skeleton variant="rectangular" height={100} />
              </Box>
            )}
          </Box>
          {previewMapPointsSorted.length > 1 && (
            <Box display="flex" justifyContent="center">
              <Pagination
                sx={{ mb: -1, pt: 1, mt: 1, borderTop: '1px solid #ccc' }}
                count={previewMapPointsSorted.length}
                page={activeIdx + 1}
                size="small"
                onChange={(e, page) => setActiveIdx(page - 1)}
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

const sortPoints = (a, b) => {
  if (!a.properties.id.startsWith('hemnet')) {
    throw new Error('sourceId must start with "hemnet"')
  }

  // first if .properties.active true
  if (a.properties.active && !b.properties.active) {
    return -1
  }
  if (!a.properties.active && b.properties.active) {
    return 1
  }
  // then if .properties.searchResult true
  if (a.properties.searchResult && !b.properties.searchResult) {
    return -1
  }
  if (!a.properties.searchResult && b.properties.searchResult) {
    return 1
  }
  // then by .properties.sourceId (string) descending
  return parseInt(b.properties.sourceId) - parseInt(a.properties.sourceId)
}
