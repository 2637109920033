import { firebaseReducer } from 'react-redux-firebase'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'

import config from '../config'
import createConfigReducer from '../configSlice'
import authReducer from '../features/auth/authSlice'
import debugReducer from '../features/debug/debugSlice'
import dsAvmPredictionsReducer from '../features/ds_avm_predictions/dsAVMPredictionSlice'
import labellingReducer from '../features/labelling/labellingSlice'
import hubspotDealsReducer from '../features/leads/hubspotDealsSlice'
import listingsReducer from '../features/listings/listingsSlice'
import marketplaceReducer from '../features/marketplace/redux/marketplaceSlice'
import metricsReducer from '../features/metrics/metricsSlice'
import offersReducer from '../features/offers/offersSlice'
import mapSearchReducer from '../features/search/mapSearchSlice'
import settingsReducer from '../features/settings/settingsSlice'

export const createRootReducer = () =>
  combineReducers({
    auth: authReducer,
    debug: debugReducer,
    ds_avm_predictions: dsAvmPredictionsReducer,
    config: createConfigReducer(config),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    listings: listingsReducer,
    metrics: metricsReducer,
    offers: offersReducer,
    hubspotDeals: hubspotDealsReducer,
    labelling: labellingReducer,
    marketplace: marketplaceReducer,
    mapPoints: mapSearchReducer,
    settings: settingsReducer,
  })
