import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getMetricsAiAttribution,
  metricsTableSelector,
  selectLoading,
  STAGES,
  TAGS,
} from './metricsSlice'

export default function MetricsPage(props) {
  const data = useSelector(metricsTableSelector)
  const loading = useSelector(selectLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMetricsAiAttribution())
  }, [dispatch])

  return (
    <Container>
      {loading !== 'idle' ? (
        <pre>{JSON.stringify(loading, null, 2)}</pre>
      ) : (
        <div>
          {TAGS.map((tag) => (
            <div key={tag}>
              <Typography variant="h2">{tag}</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>date</TableCell>
                    <>
                      {STAGES.map((s) => (
                        <TableCell key={s}>{s}</TableCell>
                      ))}
                    </>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data[tag]).map(([date, row]) => (
                    <TableRow key={date}>
                      <TableCell>{date}</TableCell>
                      <>
                        {STAGES.map((s) => (
                          <TableCell key={s}>{row[s]}</TableCell>
                        ))}
                      </>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ))}
        </div>
      )}
    </Container>
  )
}
