import _ from 'lodash'

import {
  getDocumentUrl,
  getImageUrl,
  getThumbImageUrl,
} from '../features/listings/listingUtils'

export default class Listing {
  constructor(obj, config) {
    this.REACT_APP_IMAGE_PROXY_URL = config.REACT_APP_IMAGE_PROXY_URL
    this.FIREBASE_PROJECT_ID = config.FIREBASE_PROJECT_ID
    this.obj = obj
  }

  get id() {
    return this.obj._id || this.obj.id
  }
  get status() {
    if (this.obj.sold_at) {
      return 'SOLD'
    }
    return this.obj.sale_page_status
  }
  get statusFormatted() {
    return (
      {
        SOLD: 'Sold',
        OPEN: 'For sale',
        UPCOMING_SALE: 'Soon',
        REMOVED_UNKNOWN: 'Removed',
        BIDDING: 'Bidding',
        // use getter to check for sold_at
      }[this.status] || '?'
    )
  }
  get housingType() {
    return this.obj.housing_type
  }
  get constructionYear() {
    return this.obj.construction_year
  }
  get noRooms() {
    return this.obj.no_rooms
  }
  get hasBalcony() {
    return this.obj.has_balcony
  }
  get hasPatio() {
    return this.obj.has_patio
  }
  get plotSize() {
    return this.obj.plot_size
  }
  get tenure() {
    return this.obj.tenure
  }
  get supplementaryArea() {
    return this.obj.supplementary_area
  }
  get livingArea() {
    return this.obj.living_area
  }
  get description() {
    return this.obj.description
  }
  get listingPrice() {
    return this.obj.listing_price
  }
  get listingPricePerLivingArea() {
    return this.livingArea ? this.listingPrice / this.livingArea : 0
  }
  get soldPrice() {
    return this.obj.sold_price
  }
  get soldPricePerLivingArea() {
    return this.livingArea ? this.soldPrice / this.livingArea : 0
  }
  get housingCooperativeFeePerMonth() {
    return this.obj.housing_cooperative_fee_per_month
  }
  get operatingCostPerYear() {
    return this.obj.operating_cost_per_year
  }
  get streetAddress() {
    return this._gmaps && this._gmaps.route
      ? `${this._gmaps.route} ${this._gmaps.street_number}`
      : this.obj.postal_address
      ? this.obj.postal_address.streetAddress
      : null
  }
  get publicationDate() {
    return this.obj.publication_date && new Date(this.obj.publication_date)
  }
  get soldDate() {
    return this.obj.sold_at && new Date(this.obj.sold_at)
  }
  get coordinates() {
    if (this.obj.location) {
      return {
        lng: this.obj.location.lon,
        lat: this.obj.location.lat,
      }
    }
    if (this._gmaps) {
      return {
        lng: this._gmaps.location.lon,
        lat: this._gmaps.location.lat,
      }
    }
    return null
  }
  get sublocality() {
    return this._gmaps && this._gmaps.sublocality_level_1
      ? this._gmaps.sublocality_level_1
      : null
  }
  get city() {
    return this._gmaps
      ? this._gmaps.postal_town
      : this.obj.postal_address
      ? this.obj.postal_address.addressLocality
      : null
  }
  get images() {
    return (this.obj.image_paths || []).map((image) =>
      getImageUrl(this.REACT_APP_IMAGE_PROXY_URL, image)
    )
  }
  get documents() {
    return (this.obj.documents || []).map((document) => ({
      name: document.name,
      url: getDocumentUrl(this.REACT_APP_IMAGE_PROXY_URL, document.object_path),
    }))
  }
  get thumbImages() {
    return this.obj.image_paths.map((image) =>
      getThumbImageUrl(this.REACT_APP_IMAGE_PROXY_URL, image)
    )
  }
  get brokerFirm() {
    return this.obj.broker_firm
  }
  get brokerName() {
    return this.obj.broker_name
  }
  get brokerPhone() {
    return this.obj.broker_phone
  }

  get salePageURL() {
    return this.obj.sale_page_url
  }
  get soldPageURL() {
    return this.obj.sold_page_url
  }

  get bucketURL() {
    if (_.isEmpty(this.obj.image_paths)) {
      return null
    }
    const project = this.FIREBASE_PROJECT_ID
    const env = project.split('-')[0]
    const scraperBucketUrl = `https://console.cloud.google.com/storage/browser/hemvist-${env}/scraper/`
    const listingFolder = this.obj.image_paths[0].split('/image/')[0] // includes leading slash
    return `${scraperBucketUrl}${listingFolder}?project=${project}`
  }

  get _gmaps() {
    return this.obj.google_maps_address
  }

  get _score() {
    return this.obj._score
  }

  get _explanation() {
    return this.obj._explanation
  }
}
