import { Card, CardContent, Typography } from '@mui/material'

import GeoMap from '../../../components/GeoMap'
import { getStreetAddress } from '../homeUtils'

export const AddressCard = ({ lead, sx }) => {
  const features = lead?.home?.features_snapshot?.features
  const postalAddress = features?.postal_address
  const geoLocation = features?.geo_location
  const streetAddress = getStreetAddress(features)

  const mapItems = geoLocation && [
    {
      coordinates: { lat: geoLocation?.latitude, lng: geoLocation?.longitude },
      id: 'ID',
      images: [],
    },
  ]

  return (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">{streetAddress}</Typography>
        <Typography variant="body1">
          {postalAddress.postal_code} {postalAddress.postal_town}
        </Typography>
      </CardContent>
      {mapItems && <GeoMap mapItems={mapItems} height="300px" />}
    </Card>
  )
}
