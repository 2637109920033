import { createSelector } from '@reduxjs/toolkit'
import { populate } from 'react-redux-firebase'

const populatesLeadWithHome = [
  { child: 'home_id', root: 'home_root', childAlias: 'home' },
]

export const multipleLeadsConnector = (ids) => [
  {
    collection: 'lead_root',
    where: [['id', 'in', [...ids, 0]]], // add 0 to avoid error when ids is empty
  },
]

export const leadByIdConnector = (leadId) => () => {
  return [
    {
      collection: 'lead_root',
      doc: leadId || '0', // should not be undefined, otherwise we query AND populate all leads
      populates: populatesLeadWithHome,
    },
  ]
}

export const multipleLeadsSelector = createSelector(
  ({ firestore }, leadIds) => {
    return leadIds.map((leadId) => firestore.data.lead_root?.[leadId])
  },
  (leads) => leads || []
)

export const leadByIdSelector = createSelector(
  ({ firestore }, leadId) => {
    return populate(firestore, 'lead_root', populatesLeadWithHome)?.[leadId]
  },
  (lead) => lead
)
