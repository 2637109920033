import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import * as React from 'react'

const ITEMS = {
  sold: 'Sold',
  onmarket: 'For sale',
  other: 'Removed',
}

export default function SaleStatusToggle({ value, setValue, sx }) {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e) => setValue(e.target.value)}
      sx={{ ...sx }}
      fullWidth
      size="small"
    >
      {Object.entries(ITEMS).map(([v, label]) => (
        <ToggleButton value={v} key={v}>
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
