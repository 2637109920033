import { createSelector, createSlice } from '@reduxjs/toolkit'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    dsAvmPredictTask: {
      currentTableColumns: [],
      availableTableColumns: [],
      mapColumns: null,
      pointsOffset: 0,
    },
  },
  reducers: {
    setDSPredictCurrentTableColumns(state, action) {
      state.dsAvmPredictTask.currentTableColumns = action.payload
    },
    setDSPredictAvailableTableColumns(state, action) {
      state.dsAvmPredictTask.availableTableColumns = action.payload
    },
    setDSPredictMapColumns(state, action) {
      state.dsAvmPredictTask.mapColumns = action.payload
    },
    setDSPredictPointsOffset(state, action) {
      state.dsAvmPredictTask.pointsOffset = action.payload
    },
  },
})

export const {
  setDSPredictCurrentTableColumns,
  setDSPredictAvailableTableColumns,
  setDSPredictMapColumns,
  setDSPredictPointsOffset,
} = settingsSlice.actions

export const selectCurrentTableColumns = createSelector(
  (state) => state.settings.dsAvmPredictTask.currentTableColumns,
  (currentTableColumns) => currentTableColumns || []
)
export const selectAvailableTableColumns = createSelector(
  (state) => state.settings.dsAvmPredictTask.availableTableColumns,
  (availableTableColumns) => availableTableColumns || []
)

export const selectMapColumns = createSelector(
  (state) => state.settings.dsAvmPredictTask.mapColumns,
  (mapColumns) => mapColumns
)

export const selectPointsOffset = createSelector(
  (state) => state.settings.dsAvmPredictTask.pointsOffset,
  (pointsOffset) => pointsOffset
)

export default settingsSlice.reducer
