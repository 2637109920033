import axios from 'axios'

const DEFAULT_INDEX = 'listing'

const createSabiApi = (config, getAccessTokenSilently) => {
  const getAxiosConfig = async () => {
    const token = await getAccessTokenSilently()
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }

  return {
    importListingToHubspot: async function (listingId, index = DEFAULT_INDEX) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/import-listing`,
        {
          ...(await getAxiosConfig()),
          params: { id: listingId, index },
        }
      )
    },

    hubspotDealFromListing: async function (listingId, index = DEFAULT_INDEX) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/listing-deal`,
        {
          ...(await getAxiosConfig()),
          params: { id: listingId, index },
        }
      )
    },

    listOffers: async function () {
      return axios.get(`${config.REACT_APP_MOVESTA_API_URL}/offers/`, {
        ...(await getAxiosConfig()),
      })
    },

    getOffer: async function (offerId) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/offers/${offerId}`,
        {
          ...(await getAxiosConfig()),
        }
      )
    },

    patchOffer: async function (offerId, offerUpdate) {
      return axios.patch(
        `${config.REACT_APP_MOVESTA_API_URL}/offers/${offerId}`,
        offerUpdate,
        {
          ...(await getAxiosConfig()),
        }
      )
    },

    requireOffer: async function (dealUUID) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/require-offer`,
        {
          ...(await getAxiosConfig()),
          params: { deal_uuid: dealUUID },
        }
      )
    },

    elasticSearch: async function ({ request, index = DEFAULT_INDEX }) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/elasticsearch-search`,
        request,
        {
          ...(await getAxiosConfig()),
          params: { index },
        }
      )
    },

    getBrokerPagesForListing: async function ({ listingId }) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/listing/${listingId}/broker-pages`,
        {
          ...(await getAxiosConfig()),
        }
      )
    },

    wasabiRequest: async function ({ request }) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/wasabi-api`,
        request,
        {
          ...(await getAxiosConfig()),
        }
      )
    },

    readHubspotDeal: async function ({ uuid }) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/lead/${uuid}/hubspot`,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    searchHubspotDeals: async function ({ query, page = 0 }) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/search-hubspot-deals`,
        {
          ...(await getAxiosConfig()),
          params: { query, page },
        }
      )
    },

    getFirebaseToken: async function () {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/firebase_token`,
        {
          ...(await getAxiosConfig()),
          params: {},
        }
      )
    },
    syncMarketplaceHemnetListing: async function (homeId) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/home/${homeId}/sync-hemnet-listing`,
        null,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    syncWithSpreadsheet: async function (homeId) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/home/${homeId}/sync-with-spreadsheet`,
        null,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    listMarketplaceHomes: async function () {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/homes`,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    getMarketplaceHome: async function (home_id) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/home/${home_id}`,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    getMarketplaceHomeSuggestions: async function (home_id) {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/home/${home_id}/suggestions`,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    createNewMarketplaceHome: async function (number) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/home/import/${number}`,
        {},
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    saveMarketplaceHome: async function (homeId, data) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/home/${homeId}`,
        data,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    syncMarketplaceGDriveFiles: async function (homeId, fileIds) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/marketplace/home/${homeId}/sync-gdrive-files`,
        fileIds,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    addHemnetListingAsComparable: async function (valuationId, listingId) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/valuation/add-comparable`,
        {
          source_id: listingId,
          source_type: 'hemnet_listing',
          valuation_id: valuationId,
        },
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    createManualValuation: async function ({ homeFeatures, title, leadId }) {
      return axios.post(
        `${config.REACT_APP_MOVESTA_API_URL}/sabi/valuation/create`,
        {
          home_features: homeFeatures,
          title,
          lead_id: leadId,
        },
        {
          ...(await getAxiosConfig()),
        }
      )
    },
    avmRequestForHome: async function ({ homeId, force }) {
      return axios.post(
        `${
          config.REACT_APP_MOVESTA_API_URL
        }/sabi/home/${homeId}/avm-request/?force=${force ? 1 : 0}`,
        {},
        {
          ...(await getAxiosConfig()),
        }
      )
    },
  }
}

export default createSabiApi
