import {
  Button,
  Container,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { utcIso } from '../../../utils/dateUtils'
import { debugSelector } from '../../debug/debugSlice'
import { useTableSettings } from '../settingsHook'
import { getTableData } from '../tableData'
import ComparablesTableAVM from './ComparablesTableAVM'
import { ListingTagsDialog } from './ListingTagsDialog'
import { PredictionSampleMap } from './PredictionSampleMap'
import { SamplesQueueProgress } from './SamplesQueueProgress'
import { SettingsModal } from './SettingsModal'

export const PredictionSample = ({
  task,
  sample,
  comparables,
  listings,
  tags,
}) => {
  const isDebug = useSelector(debugSelector)
  const [listingIdsSelected, setListingIdsSelected] = useState([])

  const tableDataAndListings = useMemo(
    () => getTableData(sample, comparables, listings, tags),
    [sample, comparables, listings, tags]
  )

  const [tagsDialogListingId, setTagsDialogListingId] = useState(null)
  const [showSettingsModal, setShowSettingsModal] = useState(false)

  const verticalLayout = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  const { setAvailableTableColumns } = useTableSettings()

  useEffect(() => {
    if (tableDataAndListings) {
      const columns = tableDataAndListings
        .reduce((acc, row) => [...acc, ...Object.keys(row?.sample)], [])
        .filter((col, i, arr) => arr.indexOf(col) === i)

      setAvailableTableColumns(columns)
    }
  }, [tableDataAndListings, setAvailableTableColumns])

  return (
    <>
      <Container maxWidth="100%">
        <Stack
          sx={{ mb: 2 }}
          direction="row"
          spacing={6}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Tooltip
            title={
              task?.created_at && (
                <Typography variant="body1" align="center">
                  {moment(utcIso(task?.created_at)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                  <br />({moment(utcIso(task?.created_at)).fromNow()})
                </Typography>
              )
            }
          >
            <Typography variant="h1" align="center">
              {task?.task_id} · {sample?.listing_id}
            </Typography>
          </Tooltip>

          <SamplesQueueProgress
            predictTaskId={task?.task_id}
            currentSampleId={sample?.listing_id}
          />
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setShowSettingsModal(true)}
            >
              Settings
            </Button>
          </Box>
        </Stack>

        <SettingsModal
          open={showSettingsModal}
          onClose={() => setShowSettingsModal(false)}
        />
        <ListingTagsDialog
          open={!!tagsDialogListingId}
          onClose={() => setTagsDialogListingId(null)}
          listingId={tagsDialogListingId}
          tags={
            tableDataAndListings.find(
              (row) => row?.sample?.listing_id === tagsDialogListingId
            )?.sample.tags
          }
        />

        <Stack
          direction={verticalLayout ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          flex="1 1 auto"
          gap={2}
        >
          <ComparablesTableAVM
            tableDataAndListings={tableDataAndListings}
            setListingIdsSelected={setListingIdsSelected}
            onTagsColumnClick={(listingId) => setTagsDialogListingId(listingId)}
          />
          <PredictionSampleMap
            tableDataAndListings={tableDataAndListings}
            listingIdsSelected={listingIdsSelected}
          />
          {isDebug && (
            <Box sx={{ overflowY: 'auto', maxHeight: '50vh' }}>
              <pre>{JSON.stringify(tableDataAndListings, null, 2)}</pre>
            </Box>
          )}
        </Stack>
      </Container>
    </>
  )
}
