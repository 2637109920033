export const homesSelector = (state) =>
  Object.values(state.marketplace.entities)
export const homeSelector = (state, homeId) =>
  state.marketplace.entities[homeId]
export const homesLoadingSelector = (state) => state.marketplace.homesLoading
export const homeLoadingSelector = (state) => state.marketplace.homeLoading

export const suggestionsSelector = (state) => state.marketplace.suggestions
export const suggestionsLoadingSelector = (state) =>
  state.marketplace.suggestionsLoading

export const previewLoadingSelector = (state) =>
  state.marketplace.previewLoading
export const previewSelector = (state) => state.marketplace.preview

export const createLoadingSelector = (state) => state.marketplace.createLoading
export const createdHomeSelector = (state) => state.marketplace.createdHome

export const syncLoadingSelector = (state) => state.marketplace.syncLoading
export const syncResultSelector = (state) => state.marketplace.syncResult

export const saveLoadingSelector = (state) => state.marketplace.saveLoading
