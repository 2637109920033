import { Box, Chip } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { configSelector } from '../../../configSlice'
import {
  formatArea,
  formatDate,
  formatSEK,
  formatSEKPerArea,
} from '../../../utils/formatUtils'
import { getThumbImageUrl } from '../../listings/listingUtils'

const MAX_HEIGHT_FIRST_COLUMN = 100

const COLUMNS = [
  {
    name: 'image',
    label: ' ',
    options: {
      sort: false,
      customBodyRender: (v) =>
        v && (
          <img
            src={v}
            style={{ height: MAX_HEIGHT_FIRST_COLUMN, margin: 'auto' }}
            alt={v}
          />
        ),
    },
  },
  {
    name: 'sale_date',
    label: 'Sale',
    options: { customBodyRender: formatDate },
  },
  { name: 'address', label: 'Address' },
  {
    name: 'living_area',
    label: 'Living area',
    options: { customBodyRender: (v) => v && formatArea(v) },
  },
  {
    name: 'rooms',
    label: 'Rooms',
    options: { customBodyRender: (v) => v },
  },
  {
    name: 'plot_area',
    label: 'Plot (m²)',
    options: { customBodyRender: (v) => v && formatArea(v) },
  },
  {
    name: 'days_on_market',
    label: 'Days on market',
    options: { customBodyRender: (v) => v === 0 || (v && `${v} days`) },
  },
  {
    name: 'publication_date',
    label: 'Publication',
    options: {
      customBodyRender: formatDate,
    },
  },
  {
    name: 'housing_cooperative_fee_per_month',
    label: 'BRF fee',
    options: { customBodyRender: (v) => v && formatSEK(v) },
  },
  {
    name: 'sale_price',
    label: 'Sale price',
    options: { customBodyRender: (v) => v && formatSEK(v) },
  },
  {
    name: 'price_per_sqm',
    label: 'Price/m²',
    options: { customBodyRender: (v) => v && formatSEKPerArea(v) },
  },
  {
    name: 'listingId',
    label: 'Listing ID',
    options: {
      customBodyRender: (v) =>
        v === 'TARGET' ? <Chip label="Target" color="primary" /> : v,
    },
  },
]

export default function ComparablesTableV2({
  valuationFeatures,
  comparables,
  onRemoveComparable,
  width = '100%',
}) {
  const history = useHistory()
  const imageProxyUrl = useSelector(configSelector).REACT_APP_IMAGE_PROXY_URL

  const data = useMemo(
    () => [
      colsManualValuationTarget(valuationFeatures),
      ...Object.values(comparables)
        .sort((c) => c.id)
        .map((c) => colsComparable(c, imageProxyUrl)),
    ],
    [comparables, valuationFeatures, imageProxyUrl]
  )

  const onRowClick = useCallback(
    (_, { dataIndex }) => {
      const listingId = parseInt(data[dataIndex].listingId)
      if (listingId) {
        history.push(`/listing/${listingId}`)
      }
    },
    [data, history]
  )

  const onRowsDelete = useCallback(
    ({ lookup }) => {
      // datay array indices that were selected
      const listingIds = Object.keys(lookup)
        .map((idx) => data[idx].comparableId)
        .filter((x) => x)
      listingIds.forEach((listingId) => onRemoveComparable(listingId))
      return false // no need to update the table, done by redux
    },
    [data, onRemoveComparable]
  )

  if (_.isEmpty(valuationFeatures) && _.isEmpty(comparables)) return 'No data'

  const options = {
    download: false,
    print: false,
    search: false,
    filter: false,
    viewColumns: false,
    pagination: false,
    elevation: 2,
    selectableRows: onRemoveComparable ? true : false,
    onRowsDelete: onRemoveComparable ? onRowsDelete : null,
    onRowClick,
  }

  return (
    <Box sx={{ width }}>
      <MUIDataTable data={data} columns={COLUMNS} options={options} />
    </Box>
  )
}

const colsCommon = (features) =>
  features?.postal_address && {
    address: `${features.postal_address.route_name} ${
      features.postal_address.street_number || ''
    }`,
    living_area: features.living_area,
    rooms: features.num_rooms,
    plot_area: features.plot_area,
    housing_cooperative_fee_per_month:
      features.housing_cooperative_fee_per_month,
  }

const colsComparable = (comparable, imageProxyUrl) => {
  const daysOnMarket =
    comparable.listing_date &&
    comparable.sale_date &&
    moment(comparable.sale_date).diff(moment(comparable.listing_date), 'days')

  const imagePathRaw =
    comparable.type === 'hemnet_listing' &&
    comparable.raw_source_data.image_paths?.[0]
  const imagePath =
    imagePathRaw && getThumbImageUrl(imageProxyUrl, imagePathRaw)

  return {
    ...colsCommon(comparable.features),
    comparableId: comparable.id,
    listingId: comparable.type === 'hemnet_listing' && comparable.source_id,
    image: imagePath,
    sale_date: moment(comparable.sale_date),
    sale_price: comparable.sale_price,
    price_per_sqm:
      comparable.features.living_area > 0 && comparable.sale_price > 0
        ? comparable.sale_price / comparable.features.living_area
        : null,
    publication_date: moment(comparable.listing_date),
    days_on_market: daysOnMarket,
    status:
      comparable.type === 'hemnet_listing' &&
      comparable.raw_source_data.sale_page_status,
  }
}

const colsManualValuationTarget = (valuation) => {
  return {
    ...colsCommon(valuation),
    comparableId: 'TARGET',
  }
}
