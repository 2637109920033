import { Alert, LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import ListingView from './components/ListingView'
import {
  getListing,
  hubspotDealFromListing,
  listingByIdSelector,
  selectLoading,
} from './listingsSlice'

export default function ListingPage({ match }) {
  const { params } = match
  const dispatch = useDispatch()

  const listingId = params.listingId

  const loadingState = useSelector(selectLoading)
  const listing = useSelector((state) => listingByIdSelector(state, listingId))
  const index = 'listing'

  useEffect(() => {
    dispatch(getListing({ listingId, index }))
    dispatch(hubspotDealFromListing({ listingId, index }))
  }, [dispatch, listingId, index])

  // TODO: get actual loading state for the item
  if (loadingState === 'pending') {
    return (
      <Box sx={{ height: '5px' }}>
        {loadingState === 'pending' && <LinearProgress />}
      </Box>
    )
  } else if (loadingState !== 'idle') {
    return <Alert color="error">{JSON.stringify(loadingState.payload)}</Alert>
  } else if (loadingState === 'rejected' || !listing) {
    return <Alert color="error">Listing does not exist</Alert>
  }
  return (
    <>
      <Helmet>
        <title>{`${listing.streetAddress}, ${listing.city}`}</title>
      </Helmet>
      <ListingView listing={listing} index={index} />
    </>
  )
}
