import { AddCircleOutline } from '@mui/icons-material'
import { Box, Chip, Tooltip, Typography } from '@mui/material'
import _ from 'lodash'

export const TagsListV2 = ({
  tagsObj,
  onRemove,
  canModify,
  tagSuggestions,
}) => {
  const tagsObjEntries = Object.entries(tagsObj ?? {}).sort((a, b) =>
    a[1].label.localeCompare(b[1].label)
  )

  return (
    <>
      {tagsObjEntries?.map(([tagId, { label }]) => (
        <>
          <Chip
            key={tagId}
            onDelete={onRemove ? () => onRemove(tagId) : null}
            label={
              <Tooltip>
                <Typography fontSize="inherit">{label}</Typography>
              </Tooltip>
            }
            sx={{
              mr: 1,
              mb: 1,
              background: `#${tagSuggestions?.[tagId]?.color ?? 'bbb'}`,
              cursor: canModify ? 'pointer' : 'default',
            }}
          />
        </>
      ))}
    </>
  )
}

export const TagsListV2ReadOnly = ({ tags, max = 5 }) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {_.isEmpty(tags) ? (
        <Typography
          color="primary"
          fontStyle="italic"
          sx={{
            '&:hover': { color: 'primary.light' },
            minWidth: 75,
            textAlign: 'center',
          }}
        >
          <AddCircleOutline
            sx={{ verticalAlign: 'baseline', fontSize: '1.1em' }}
          />
        </Typography>
      ) : Object.keys(tags).length > max ? (
        <>
          <TagsListV2
            tagsObj={Object.fromEntries(Object.entries(tags).slice(0, max))}
            canModify={true}
          />
          ...
        </>
      ) : (
        <TagsListV2 tagsObj={tags} canModify={true} />
      )}
    </Box>
  )
}
