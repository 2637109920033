import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { useState } from 'react'

import { wizardSteps } from '../labellingInfo'

export function LabellingWizard({ onCompleted }) {
  const [activeStep, setActiveStep] = useState(0)

  return (
    <Box sx={{ maxWidth: 'md', margin: 'auto' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {wizardSteps.map((step) => (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={7} mb={7}>
        {wizardSteps[activeStep].contents}
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={() => setActiveStep(activeStep > 0 ? activeStep - 1 : 0)}
        >
          Back
        </Button>
        <Button
          onClick={() =>
            activeStep < wizardSteps.length - 1
              ? setActiveStep(activeStep + 1)
              : onCompleted()
          }
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}
