/** @jsxImportSource @emotion/react */
import { Box, Button, CircularProgress } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { theme } from '../../theme'
import { debugSelector } from '../debug/debugSlice'
import { allOffersSelector, listOffers, selectLoading } from './offersSlice'

const columns = [
  {
    name: 'Offer id',
    options: {
      display: false,
      filter: false,
    },
  },
  'Accepted at',
  'Valid until',
  'Address',
  'Contact',
  'Email',
  'Amount',
  'Sell With Guarantee Amount',
]

export default function OffersPage() {
  const offers = useSelector(allOffersSelector)
  const loading = useSelector(selectLoading)
  const dispatch = useDispatch()
  let history = useHistory()
  const debugMode = useSelector(debugSelector)

  useEffect(() => {
    dispatch(listOffers({}))
  }, [dispatch])

  const formatDate = (date) => {
    return !date ? '' : moment.utc(date).local().format('YYYY-MM-DD[,] HH:mm')
  }

  const formatAmount = (value) => {
    if (!value && value !== 0) {
      return '-'
    }
    if (typeof value === 'object') {
      return `${value['min']} - ${value['max']}`
    }
    return value.toString()
  }

  let data = offers.map((offer) => {
    return [
      offer.offer_id,
      formatDate(offer.accepted_at),
      formatDate(offer.valid_until),
      offer.address_slug,
      offer.contact_name,
      offer.email,
      formatAmount(offer.amount),
      offer.sell_with ? formatAmount(offer.sell_with.guarantee_amount) : '-',
    ]
  })

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    onRowClick: (rowData) => history.push(`/offer?offerId=${rowData[0]}`),
  }

  if (loading === 'pending') {
    return (
      <Box sx={{ minHeight: '100vh', display: 'grid', placeItems: 'center' }}>
        {debugMode ? JSON.stringify(loading, null, 2) : <CircularProgress />}
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Button
        component={Link}
        to="/requireOffer?"
        variant="contained"
        sx={{ m: '0px 20px 20px 20px' }}
      >
        Create
      </Button>
      <MUIDataTable
        title={'Offers'}
        data={data}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  )
}
