import { AddBox, Delete, Remove } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import _ from 'lodash'
import { TextField } from 'mui-rff'
import { Children } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import Hint from '../../../components/Hint'
import { formatSEK } from '../../../utils/formatUtils'
import { AddSelectMenu } from './AddSelectMenu'

export function RenovationEstimateItem({
  itemConfig,
  itemName,
  itemValue,
  onClickRemove,
  onAddOption,
}) {
  const deprecated = !!itemConfig.deprecated

  return (
    <Card sx={{ mb: 4, backgroundColor: deprecated ? grey[200] : null }}>
      <CardContent>
        {deprecated && (
          <Alert severity="warning" sx={{ mb: 3 }}>
            <AlertTitle>Outdated</AlertTitle>
            This item has been removed or updated since it was added to this
            renovation estimate, for example because new underlying price for
            the calculation for a square meter price. You can delete but not
            change it.
          </Alert>
        )}
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Button variant="outlined" onClick={onClickRemove} size="small">
            <Remove />
          </Button>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {itemConfig.display_name}
            <Hint text={itemConfig.description} />
          </Typography>
        </Box>
        <Box sx={{ margin: 0 }}>
          <FieldArray name={`${itemName}.options`}>
            {({ fields }) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {!deprecated && (
                    <AddSelectMenu
                      icon={<AddBox fontSize="small" />}
                      options={Object.values(itemConfig.options)}
                      onSelectId={(optionId) =>
                        onAddOption(fields, itemValue.id, optionId)
                      }
                    />
                  )}
                </Box>
                <Box>
                  {Children.toArray(
                    fields.map((optionName, optionIndex) => (
                      <RenovationItemOption
                        optionConfig={
                          itemConfig.options[itemValue.options[optionIndex].id]
                        }
                        optionName={optionName}
                        deprecated={deprecated}
                        onClickRemove={() => fields.remove(optionIndex)}
                      />
                    ))
                  )}
                  {fields.length > 1 && (
                    <Grid container alignItems="center" justify="center" mt={2}>
                      <Grid item md={9}></Grid>
                      <Grid
                        item
                        md={2}
                        sx={{
                          borderTop: '1px solid black',
                          textAlign: 'right',
                          pt: 3,
                        }}
                      >
                        <Field name={`${itemName}.total`}>
                          {(props) => (
                            <>
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {props.input.value
                                  ? formatSEK(props.input.value)
                                  : '--'}
                              </Typography>
                            </>
                          )}
                        </Field>
                      </Grid>
                      <Grid item md={1}></Grid>
                    </Grid>
                  )}
                </Box>
              </>
            )}
          </FieldArray>
        </Box>
      </CardContent>
    </Card>
  )
}

const RenovationItemOption = ({
  optionConfig,
  deprecated,
  optionName,
  onClickRemove,
}) => {
  let hintText =
    optionConfig.description && !_.isEmpty(optionConfig.description)
      ? optionConfig.description
      : ''
  hintText = optionConfig.cost.unit
    ? `${hintText} ••• Unit cost: ${formatSEK(
        optionConfig.cost.per_unit
      )} per ${optionConfig.cost.unit}, base: ${formatSEK(
        optionConfig.cost.base
      )}`
    : hintText

  const showDisplayNameColumn = !_.isEmpty(optionConfig.display_name)

  return (
    <Grid
      container
      key={optionName}
      alignItems="center"
      justify="center"
      mt={2}
    >
      {showDisplayNameColumn && (
        <Grid item md={2}>
          <Field name={`${optionName}.id`} component="input" type="hidden" />
          <Typography sx={{ fontWeight: 'bold' }}>
            {optionConfig.display_name} <Hint text={hintText} />
          </Typography>
        </Grid>
      )}
      <Grid item md={showDisplayNameColumn ? 4 : 6}>
        <TextField
          variant="standard"
          size="small"
          FormHelperTextProps={{ sx: { display: 'none' } }}
          sx={{ width: showDisplayNameColumn ? 300 : 600 }}
          name={`${optionName}.note`}
          label={' '}
          disabled={deprecated}
        />
      </Grid>
      <Grid item md={3} sx={{ textAlign: 'right' }}>
        {optionConfig.cost.type === 'per_unit_cost' && (
          <TextField
            size="small"
            sx={{ width: 100 }}
            showError={() => null}
            name={`${optionName}.value`}
            label={optionConfig.cost.unit}
            disabled={deprecated}
            FormHelperTextProps={{ sx: { display: 'none' } }}
          />
        )}
      </Grid>
      <Grid item md={2} sx={{ textAlign: 'right' }}>
        <Field name={`${optionName}.total`}>
          {(props) => (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>
                {props.input.value ? formatSEK(props.input.value) : '--'}
              </Typography>
            </>
          )}
        </Field>
      </Grid>
      <Grid item md={1} sx={{ display: 'flex' }}>
        {!deprecated && (
          <Button size="small" sx={{ marginLeft: 'auto' }}>
            <Delete onClick={onClickRemove} />
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
