import { Box, TableCell, TableRow } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import { useState } from 'react'

import { useTableSettings } from '../settingsHook'
import { getColumnsConfig } from '../tableColumns'
import { ExpandedTableRow } from './ExpandedTableRow'

export default function ComparablesTableAVM({
  tableDataAndListings,
  setListingIdsSelected,
  onTagsColumnClick = () => {},
}) {
  const theme = useTheme()

  const { availableTableColumns, currentTableColumns } = useTableSettings()
  const [dataIndicesSelected, setDataIndicesSelected] = useState(null)

  if (_.isEmpty(tableDataAndListings)) return 'No data'

  const tableData = tableDataAndListings.map((r) => r.sample)

  const options = {
    download: false,
    print: false,
    pagination: tableDataAndListings.length > 10,
    viewColumns: false,
    elevation: 0,
    selectableRows: 'multiple',
    rowsSelected: dataIndicesSelected,
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      setDataIndicesSelected(allRowsSelected.map((r) => r.dataIndex))
      setListingIdsSelected(
        allRowsSelected.map((r) => tableDataAndListings[r.dataIndex]?.id)
      )
    },
    setRowProps: (row, dataIndex) => {
      return {
        style: {
          backgroundColor: dataIndex === 0 ? theme.palette.grey[300] : '',
        },
      }
    },
    customSort: (data, colIndex, order, meta) => {
      // target sample is always the first row
      const [data0, ...dataRest] = data

      dataRest.sort((a, b) => {
        const aVal = a.data[colIndex]
        const bVal = b.data[colIndex]
        if (aVal === bVal) return 0
        if (order === 'asc') {
          return aVal < bVal ? -1 : 1
        }
        return aVal > bVal ? -1 : 1
      })

      return [data0, ...dataRest]
    },
    customToolbarSelect: () => {},
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandedTableRow data={tableDataAndListings[rowMeta.dataIndex]} />
          </TableCell>
        </TableRow>
      )
    },
    onCellClick: (colData, cellMeta) => {
      // MUI Datatables does not have a mapping between column index and column name
      // (as we allow hiding columns and reordering), so we need to
      // find the column name by a prop defined in the column definitions list
      if (colData?.props?.columnName === 'tags') {
        onTagsColumnClick(tableData[cellMeta.dataIndex].listing_id)
      }
      return false
    },
  }

  const tableConfig = getColumnsConfig(
    availableTableColumns,
    currentTableColumns
  )

  return (
    <Box sx={{ overflowX: 'scroll', maxWidth: 'calc(100vw-20px)' }}>
      <MUIDataTable data={tableData} columns={tableConfig} options={options} />
    </Box>
  )
}
