import { Circle, LocationOnSharp } from '@mui/icons-material'
import { Alert, Icon } from '@mui/material'
import { Box } from '@mui/system'
import GoogleMapReact from 'google-map-react'
import { useMemo } from 'react'

import { getColorForInteger } from '../../../utils/colors'
import { waitForMapsWrapper } from '../../../utils/mapUtils'
import { getMapPoints, MAP_COLUMNS } from '../mapPoints'
import { useMapSettings } from '../settingsHook'

export const PredictionSampleMap = ({
  tableDataAndListings,
  listingIdsSelected,
}) => {
  const { mapColumns, pointsOffset } = useMapSettings()

  const mapPoints = useMemo(
    () =>
      mapColumns && tableDataAndListings?.length
        ? getMapPoints(
            tableDataAndListings,
            MAP_COLUMNS[mapColumns].columns,
            MAP_COLUMNS[mapColumns].isSWEREF99
          )
        : [],
    [tableDataAndListings, mapColumns]
  )

  return (
    <Box sx={{ width: 900, height: 750 }}>
      {mapPoints?.length && mapPoints[0]?.lat && mapPoints[0]?.lng ? (
        <>
          <GoogleMapReact
            // no api keys, use existing global google maps object
            googleMapLoader={waitForMapsWrapper()}
            defaultZoom={18}
            center={{ lat: mapPoints[0]?.lat, lng: mapPoints[0]?.lng }}
          >
            {/* reverse-iters to get the target on top */}
            {mapPoints?.reverse().map((mp, index) => (
              <Icon
                key={mp?.id}
                component={!mp.sample.__isTarget ? Circle : LocationOnSharp}
                lat={
                  mp.lat +
                  pointsOffset * 0.000004 * (index - mapPoints.length / 2)
                }
                lng={
                  mp.lng +
                  pointsOffset * 0.000008 * (index - mapPoints.length / 2)
                }
                sx={{
                  color: getColorForInteger(mp?.id),
                  transform: mp.sample.__isTarget
                    ? 'translate(-50%, -100%)'
                    : 'translate(-50%, -50%)',
                  fontSize: mp.sample.__isTarget ? 35 : 25,
                }}
              />
            ))}
            {mapPoints
              .filter((mp) => listingIdsSelected?.includes(mp?.id))
              .map((mp) => (
                <Box
                  key={mp?.id}
                  lat={mp.lat}
                  lng={mp.lng}
                  sx={{
                    width: 'fit-content',
                    transform: 'translate(-50%, -150%)',
                    backgroundColor: 'white',
                    padding: 1,
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderWidth: 3,
                    borderColor: getColorForInteger(mp?.id),
                  }}
                  boxShadow={3}
                >
                  {mp?.listing?.streetAddress} • {mp?.listing?.id}
                </Box>
              ))}
          </GoogleMapReact>
        </>
      ) : (
        <Alert severity="warning">
          No map points to show. Try changing the map columns or check the
          sample and comparables.
        </Alert>
      )}
    </Box>
  )
}
