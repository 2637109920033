import { Card, CardContent, Typography } from '@mui/material'

import HomeAVMValuations from '../../avm/components/HomeAVMValuations'

export const AVMCard = ({ lead, sx }) => {
  return (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">Valuation Model</Typography>
        {lead?.home?.id && <HomeAVMValuations homeId={lead.home.id} />}
      </CardContent>
    </Card>
  )
}
