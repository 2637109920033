import { Cancel, Forward } from '@mui/icons-material'
import {
  Card,
  CardContent,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { capitalize } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { configSelector } from '../../../configSlice'
import Listing from '../../../data_models/Listing'
import { HOUSING_TYPES_MAPPING } from '../../../utils/dataUtils'
import {
  formatArea,
  formatLivingAreaRoomsAndSupplementaryArea,
  formatSEK,
  formatSEKPerArea,
} from '../../../utils/formatUtils'
import { estimateBasedOnComparables } from '../../compare/comparisonUtils'
import { ListingCard } from '../../search/components/ListingCard'
import { activeIdSelector, setActivePoint } from '../../search/mapSearchSlice'
import { removeComparableFromManualValuation } from '../valuationActions'
import { isEditable } from '../valuationUtils'
import { TooltipSimpleEstimate } from './TooltipSimpleEstimate'

export const ValuationPanel = ({ manualValuation, flex }) => {
  const dispatch = useDispatch()
  const config = useSelector(configSelector)
  const activeId = useSelector(activeIdSelector)

  const features = manualValuation?.features_snapshot?.features

  // averaged comparables
  const {
    averagePerLivingArea: averagePerLivingAreaSimple,
    estimatedPrice: estimatedPriceSimple,
  } = estimateBasedOnComparables({
    comparables: manualValuation.comparables,
    targetLivingArea: features.living_area,
  })

  // manually tuned estimate
  const estimatedPrice = manualValuation?.valuation_estimate?.expectation
  const estimatedPricePerLivingArea = features.living_area
    ? estimatedPrice / features.living_area
    : null

  const valuationFeaturesList = [
    {
      name: capitalize(
        HOUSING_TYPES_MAPPING[features.housing_type] || 'Unknown housing type'
      ),
      value: (
        <>
          {formatLivingAreaRoomsAndSupplementaryArea({
            livingArea: features.living_area,
            noRooms: features.num_rooms,
            supplementaryArea: features.supplementary_area,
          })}
          <br />
          {features.plot_area && `Plot ${formatArea(features.plot_area)}`}{' '}
          {features.construction_year ? `(${features.construction_year})` : ''}
        </>
      ),
    },
    {
      name: 'Manual estimate',
      value: estimatedPrice
        ? [
            formatSEK(estimatedPrice),
            formatSEKPerArea(estimatedPricePerLivingArea),
          ].join(' / ')
        : 'Not set',
    },
    {
      name: (
        <>
          Simple estimate <TooltipSimpleEstimate />
        </>
      ),
      value: [
        formatSEK(estimatedPriceSimple),
        formatSEKPerArea(averagePerLivingAreaSimple),
      ].join(' / '),
    },
  ]

  return (
    <Box
      flex={flex}
      height="100%"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Card sx={{ mb: 3, mr: '3px', flex: '0 0 auto' }}>
        <CardContent>
          <Typography variant="h5">{manualValuation.title}</Typography>
        </CardContent>

        <TableContainer>
          <Table sx={{ width: '100%' }} aria-label="home features">
            <TableBody>
              {valuationFeaturesList.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 700 }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ListItemButton
          component={Link}
          variant="outlined"
          to={`/valuate/${manualValuation.id}`}
        >
          <ListItemIcon>
            <Forward />
          </ListItemIcon>
          <ListItemText primary="Valuation details" />
        </ListItemButton>
      </Card>
      <Box sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
        {Object.values(manualValuation?.comparables || {})
          .filter((comparable) => comparable.id.startsWith('hemnet_listing_'))
          .map((comparable) => {
            const listing = new Listing(comparable.raw_source_data, config)

            return (
              <Box
                onMouseEnter={() =>
                  dispatch(
                    setActivePoint({
                      id: `hemnet_listing_${comparable.source_id}`,
                    })
                  )
                }
                sx={{
                  borderRightStyle: 'solid',
                  borderRightWidth: '3px',
                  borderRightColor:
                    activeId === `hemnet_listing_${comparable.source_id}`
                      ? 'highlight.main'
                      : 'transparent',
                  mb: 2,
                }}
              >
                <ListingCard
                  streetAddress={listing.streetAddress}
                  housingType={listing.housingType}
                  numRooms={listing.noRooms}
                  livingArea={listing.livingArea}
                  plotArea={listing.plotSize}
                  saleStatus={listing.statusFormatted}
                  saleDate={listing.soldDate}
                  salePrice={listing.soldPrice}
                  listingDate={listing.publicationDate}
                  listingPrice={listing.listingPrice}
                  thumbSrc={listing.thumbImages?.[0]}
                  sublocality={null}
                  link={`/listing/${comparable.source_id}`}
                >
                  {isEditable(manualValuation) && (
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() =>
                        dispatch(
                          removeComparableFromManualValuation({
                            comparableId: comparable.id,
                            manualValuationId: manualValuation.id,
                          })
                        )
                      }
                    >
                      <Cancel /> Remove
                    </IconButton>
                  )}
                </ListingCard>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}
