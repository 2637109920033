import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

export const listOffers = createAsyncThunk('offer/list', async (_, thunkAPI) =>
  thunkAPI
    .extra()
    .sabiApi.listOffers()
    .then((response) => response.data.map((o) => ({ ...o, id: o.offer_id })))
    .catch((error) => {
      console.error(error, error.response)
      return thunkAPI.rejectWithValue([error.message, error.response.data])
    })
)

export const getOffer = createAsyncThunk(
  'offer/get',
  async ({ offerId }, thunkAPI) =>
    thunkAPI
      .extra()
      .sabiApi.getOffer(offerId)
      .then((response) => ({ ...response.data, id: offerId }))
      .catch((error) => {
        console.error(error, error.response)
        return thunkAPI.rejectWithValue([error.message, error.response.data])
      })
)

export const requireOffer = createAsyncThunk(
  'offer/require',
  async ({ uuid }, thunkAPI) =>
    thunkAPI
      .extra()
      .sabiApi.requireOffer(uuid)
      .then((response) => {
        if (typeof document !== 'undefined') {
          document.location.replace(`/offer?offerId=${response.data.offer_id}`)
        }
        return response.data
      })
      .catch((error) => {
        console.error(error, error.response)
        return thunkAPI.rejectWithValue([error.message, error.response.data])
      })
)

export const updateOffer = createAsyncThunk(
  'offer/update',
  async ({ offerId, offer }, thunkAPI) =>
    thunkAPI
      .extra()
      .sabiApi.patchOffer(offerId, offer)
      .then((response) => ({ ...response.data, id: offerId }))
      .catch((error) => {
        console.error(error, error.response)
        return thunkAPI.rejectWithValue([error.message, error.response.data])
      })
)

const offersAdapter = createEntityAdapter({
  selectId: (offer) => offer['id'],
  sortComparer: (a, b) => a.created_at.localeCompare(b.created_at) * -1,
})

const offersSlice = createSlice({
  name: 'offers',
  initialState: offersAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listOffers.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(listOffers.fulfilled, (state, action) => {
        offersAdapter.setAll(state, action.payload)
        state.loading = 'idle'
      })
      .addCase(listOffers.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
      .addCase(getOffer.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(getOffer.fulfilled, (state, action) => {
        offersAdapter.upsertOne(state, action.payload)
        state.loading = 'idle'
      })
      .addCase(getOffer.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
      .addCase(requireOffer.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(requireOffer.fulfilled, (state, action) => ({
        loading: 'idle',
      }))
      .addCase(requireOffer.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
      .addCase(updateOffer.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(updateOffer.fulfilled, (state, action) => {
        offersAdapter.upsertOne(state, action.payload)
        state.loading = 'idle'
      })
      .addCase(updateOffer.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
  },
})

export const offersSelectors = offersAdapter.getSelectors(
  (state) => state.offers
)

export const selectLoading = (state) => state.offers.loading

export const getOfferByIdSelector = (offerId) => (state) =>
  offersSelectors.selectById(state, offerId)

export const allOffersSelector = (state) => offersSelectors.selectAll(state)

export default offersSlice.reducer
