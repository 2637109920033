import { OpenInNew, Photo } from '@mui/icons-material'
import {
  Card,
  CardContent,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { capitalize } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { configSelector } from '../../../configSlice'
import { hubspotLeadByIdSelector, readHubspotDeal } from '../hubspotDealsSlice'

export const LeadStatusCard = ({ lead, sx }) => {
  const config = useSelector(configSelector)

  const dispatch = useDispatch()
  const hubspotLead = useSelector((state) =>
    hubspotLeadByIdSelector(state, lead?.id)
  )

  console.log({ lead })

  useEffect(() => {
    lead?.id && dispatch(readHubspotDeal({ leadId: lead?.id }))
  }, [dispatch, lead?.id])

  const title = hubspotLead
    ? [capitalize(hubspotLead?.stage), capitalize(hubspotLead?.pipeline)].join(
        ' • '
      )
    : 'Lead status'

  const leadImagesUrl = `${config?.REACT_APP_MOVESTA_API_URL}/images/lead/${lead?.id}?width=900`

  return (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">{title}</Typography>
        <Typography fontWeight={700} sx={{ mt: 1 }}>
          Application comment
        </Typography>
        <Box>
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              p: 1,
              maxHeight: '150px',
              overflowY: 'auto',
              whiteSpace: 'pre',
              borderColor: '#efefef',
              borderStyle: 'solid',
              borderWidth: 0,
              borderLeftWidth: 2,
            }}
          >
            {lead?.applicant_comment || 'No comment'}
          </Typography>
        </Box>
      </CardContent>

      {hubspotLead && (
        <ListItemButton
          component={Link}
          href={hubspotLead?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText primary="Open deal in Hubspot" />
        </ListItemButton>
      )}
      <ListItemButton
        component={Link}
        href={leadImagesUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemIcon>
          <Photo />
        </ListItemIcon>
        <ListItemText primary="Uploaded photos" />
      </ListItemButton>
    </Card>
  )
}
