export const getInnerTextFromJSX = (jsx) => {
  if (typeof jsx === 'string') {
    return jsx
  }

  if (typeof jsx === 'number') {
    return jsx.toString()
  }

  if (Array.isArray(jsx)) {
    return jsx.map(getInnerTextFromJSX).join('')
  }

  if (typeof jsx === 'object') {
    if (jsx.props && jsx.props.children) {
      return getInnerTextFromJSX(jsx.props.children)
    }
  }

  return ''
}
