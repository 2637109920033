import { Alert, AlertTitle, LinearProgress, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { getListings, listingsByIdsSelector } from '../listings/listingsSlice'
import {
  multipleListingIdsTagsConnector,
  multipleListingIdsTagsSelector,
} from '../listings/listingTagsSlice'
import { PredictionSample } from './components/PredictionSample'
import {
  getSample,
  selectComparables,
  selectError,
  selectSample,
  selectStatus,
  selectTask,
} from './dsAVMPredictionSlice'

export const DSAVMPredictionSamplePage = ({ match }) => {
  const dispatch = useDispatch()
  const { predictTaskId, sampleId } = match.params
  const firestoreStatus = useSelector(selectStatus)
  const error = useSelector(selectError)

  const sample = useSelector(selectSample)
  const comparables = useSelector(selectComparables)
  const task = useSelector(selectTask)

  const listingIds = [...Object.keys(comparables), sample?.listing_id].filter(
    (id) => id
  )

  const listings = useSelector((state) =>
    listingsByIdsSelector(state, listingIds)
  )

  useFirestoreConnect(multipleListingIdsTagsConnector(listingIds))
  const tags = useSelector((state) =>
    multipleListingIdsTagsSelector(state, listingIds)
  )

  useEffect(() => {
    if (task?.task_id === predictTaskId && sample?.listing_id === sampleId)
      return
    dispatch(getSample({ predictTaskId, sampleId }))
      .unwrap()
      .then(({ listingIds }) => {
        dispatch(getListings({ listingIds }))
      })
  }, [dispatch, predictTaskId, sampleId, task?.task_id, sample?.listing_id])

  if (firestoreStatus === 'pending')
    return (
      <>
        <Alert severity="info">
          <AlertTitle>Loading</AlertTitle>
          <Typography>Loading sample from Firestore ...</Typography>
        </Alert>
        <LinearProgress />
      </>
    )

  // models without comparables!
  if (Object.keys(listings)?.length <= 1)
    return (
      <>
        <Alert severity="info">
          <AlertTitle>Loading</AlertTitle>
          <Typography>Loading listings from Elasticsearch ...</Typography>
          <Typography>
            If this never finishes, try restarting movesta-api.
          </Typography>
        </Alert>
        <LinearProgress />
      </>
    )

  if (firestoreStatus === 'rejected')
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <pre>{JSON.stringify(error)}</pre>
      </Alert>
    )

  return (
    <>
      <Helmet>
        <title>
          {sample?.listing_id ?? '-'} · {task?.task_id ?? '-'}
        </title>
      </Helmet>
      <PredictionSample
        task={task}
        sample={sample}
        comparables={comparables}
        listings={listings}
        tags={tags}
      />
    </>
  )
}
