import { Button, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

export function AddSelectMenu({ icon, buttonVariant, options, onSelectId }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (id) => {
    setAnchorEl(null)
    if (id) onSelectId(id)
  }

  return (
    <>
      <Button type="button" onClick={handleClick} variant={buttonVariant}>
        {icon}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option) => (
          <MenuItem onClick={() => handleClose(option.id)} key={option.id}>
            {option.display_name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
