export const TENANTS = {
  'b3092042-5b76-4bb7-999e-651d317c0072': 'Web form (inbound)',
  '1fe3922e-bd85-4a69-b809-207935c73e42': 'Web form (inbound) - DEVELOPMENT',
  'b455d3d1-9dd9-4a81-9bb1-2039bc297128': 'Sabi import (outbound)',
  '8c522e5d-1c51-4ed6-bd9c-ed4ed31b23e2':
    'Sabi import (outbound) - DEVELOPMENT',
  'a1181d4c-3164-4cce-957a-efb0ec01d640': 'House ID',
  '0775a7ae-3a05-4eae-bf72-dd3ced7f7920': 'House ID (2)',
  'b2cff9bd-8764-454f-b551-8950210f2d19': 'Internal testing',
}
