import { Box } from '@mui/system'

import ListingView from '../../listings/components/ListingView'

export const ExpandedTableRow = ({ data: { listing, sample } }) => {
  if (!listing) return null

  return (
    <Box sx={{ width: '100%' }}>
      <ListingView
        listing={listing}
        index="index"
        updateURLForGallery={false}
        showTags={false}
      />
    </Box>
  )
}
