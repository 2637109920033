import { createSlice } from '@reduxjs/toolkit'

const createConfigReducer = (initialConfig) =>
  createSlice({
    name: 'config',
    initialState: initialConfig,
    reducers: {},
  }).reducer

export const configSelector = (state) => state.config

export default createConfigReducer
