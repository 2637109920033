import _ from 'lodash'

export function formErrorsAsList(errors) {
  // {fieldName: [ "This is an error", {"fieldNameNested" : ["This is a nested error"]}]}

  if (_.isEmpty(errors)) {
    return []
  }

  const flat = []
  const list = typeof errors === 'object' ? Object.values(errors) : errors
  list.forEach((error) => {
    if (typeof error === 'string' || error instanceof String) {
      flat.push(error)
    } else if (
      Array.isArray(error) ||
      (typeof error === 'object' && !_.isEmpty(error))
    ) {
      flat.push(...formErrorsAsList(error))
    }
  })

  return flat
}

export const removeNumberFormatting = (value) => value?.replace(',', '')
