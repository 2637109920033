import {
  Alert,
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

export const appealLabels = ['😩', '😕', '😐', '😀', '😍']
export const appealHints = [
  'strongly negative',
  'negative',
  'neutral',
  'positive',
  'strongly positive',
]

export const conditionLabels = ['⚠️', '👎', '🆗', '👍', '🌟']
export const conditionHints = [
  'strongly negative',
  'negative',
  'neutral',
  'positive',
  'strongly positive',
]

export const wizardSteps = [
  {
    title: 'Welcome',
    contents: (
      <>
        <Typography fontSize="8em" sx={{ mt: 3, textAlign: 'center' }}>
          🏚️ ❓ 🏠
        </Typography>
        <Typography fontSize="3em" sx={{ textAlign: 'center' }}>
          Welcome to the
          <br />
          Movesta Labelling Competition
        </Typography>
      </>
    ),
  },
  {
    title: 'Competition',
    contents: (
      <Box sx={{ textAlign: 'center' }}>
        <Typography fontSize="2em" sx={{ mb: 3 }}>
          ✏️ You rate images of homes
        </Typography>
        <Typography fontSize="2em" sx={{ mb: 3 }}>
          🤖 We improve the valuation model together
        </Typography>
        <Typography fontSize="2em" sx={{ mb: 3 }}>
          🏆 You and your team win
        </Typography>
        <Alert severity="warning" sx={{ mt: 5 }}>
          Please read the following instructions thoroughly.
        </Alert>
      </Box>
    ),
  },
  {
    title: 'Condition',
    contents: (
      <>
        <Box sx={{ mb: 3 }}>
          <Typography fontSize="2em" gutterBottom>
            Condition
          </Typography>
          Here, you are given the - extremely difficult - task of judging how
          good the condition of the part of the home is. Basically the “quality”
          of the used materials and appliances vs their age and wear.
          <br />
          <br />
          This is not really possible from styled images taken from carefully
          selected angles obviously, but you can give it your best try. And at
          least in some cases, it might be obvious.
          <br />
          <br />
          <em>
            Sales usually looks at: stove, dishwasher, fridge/freezer,
            cupboards, kitchen counter, materials used (stone, wood type and
            color, cheap plastic) and the room size.
          </em>
        </Box>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableRow>
            {conditionLabels.map((label) => (
              <TableCell align="center" sx={{ fontSize: '3em' }}>
                {label}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {conditionHints.map((label) => (
              <TableCell align="center">{label}</TableCell>
            ))}
          </TableRow>
        </Table>
      </>
    ),
  },
  {
    title: 'Appeal',
    contents: (
      <>
        <Box sx={{ mb: 3 }}>
          <Typography fontSize="2em" gutterBottom>
            Appeal
          </Typography>
          This is a fully subjective rating: how happy would you feel about
          living in a home with this bathroom or kitchen (etc.), or how much
          would it affect your willingness to buy this home, in a negative or
          positive way, if you were browsing Hemnet.
          <br />
          <br />
          Base the judgment on your personal opinion, but if you think you have
          a pretty unique taste, then maybe at least consider what you think the
          average potential buyer would feel about the case. (This is no strict
          real science ;))
        </Box>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableRow>
            {appealLabels.map((label) => (
              <TableCell align="center" sx={{ fontSize: '3em' }}>
                {label}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {appealHints.map((label) => (
              <TableCell align="center">{label}</TableCell>
            ))}
          </TableRow>
        </Table>
      </>
    ),
  },
  {
    title: 'Wrong images',
    contents: (
      <>
        <Box>
          <Typography fontSize="5em" textAlign="center" sx={{ mb: 3 }}>
            🗑️
          </Typography>
          <Typography textAlign="center">
            Sometimes, some of the images you will see are not showing the
            correct room.
            <br />
            Please <strong>unselect these before you rate</strong> condition and
            appeal of the other images. If there are two different kitchens,
            unselect the second one.
            <br />
            <br />
            If you cannot put any rating on the room, feel free to skip it.
          </Typography>
        </Box>
      </>
    ),
  },
]
