import _ from 'lodash'

import { formatDateTime, formatSEK } from '../../../utils/formatUtils'

export const mapFlattenOfferWithPopulatedFields = (offer) => {
  const { lead, manual_valuation, ...rest } = offer

  const manualValuationFlat = manual_valuation
    ? flattenNestedObject(manual_valuation, 'manual_valuation')
    : {}
  const leadsFlat = lead ? flattenNestedObject(lead, 'lead') : {}

  const objWithNulls = {
    ...rest,
    ...manualValuationFlat,
    ...leadsFlat,
    is_presented: !!offer.presented_at,
  }

  return _.omitBy(objWithNulls, _.isNull)
}

const flattenNestedObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '_' : ''
    if (obj[k] !== null && typeof obj[k] === 'object')
      Object.assign(acc, flattenNestedObject(obj[k], pre + k))
    else acc[pre + k] = obj[k]
    return acc
  }, {})
}

export const getColumnKeysPopulated = (tableData) => {
  if (!tableData?.length) return []
  const columns = Array.from(
    new Set(
      tableData.reduce((acc, row) => {
        return [...acc, ...Object.keys(row)]
      }, [])
    )
  )
  return columns.map(getMUIDataTableColumn)
}

const _DATE_RENDERER = (v) => v && formatDateTime(v)
const _SEK_RENDERER = (v) => v && formatSEK(v)

const CUSTOM_BODY_RENDERERS = {
  guarantee_price: _SEK_RENDERER,
  fee: _SEK_RENDERER,
  created_at: _DATE_RENDERER,
  presented_at: _DATE_RENDERER,
  presented_high: _SEK_RENDERER,
  presented_low: _SEK_RENDERER,
  customer_expectation_high: _SEK_RENDERER,
  customer_expectation_low: _SEK_RENDERER,
  customer_perception_high: _SEK_RENDERER,
  customer_perception_low: _SEK_RENDERER,
  manual_valuation_created_at: _DATE_RENDERER,
  manual_valuation_valuation_estimate_expectation: _SEK_RENDERER,
  manual_valuation_valuation_estimate_low_80: _SEK_RENDERER,
  manual_valuation_valuation_estimate_low_95: _SEK_RENDERER,
  manual_valuation_valuation_estimate_high_80: _SEK_RENDERER,
  manual_valuation_valuation_estimate_high_95: _SEK_RENDERER,
  lead_created_at: _DATE_RENDERER,
}

const DEFAULT_DISPLAY = {
  title: true,
  guarantee_price: true,
  fee: true,
  presented_at: true,
}

const getMUIDataTableColumn = (key) => {
  const label = _.startCase(key)
  const options = {
    display: DEFAULT_DISPLAY[key] || false,
    customBodyRender: CUSTOM_BODY_RENDERERS[key],
  }
  return { name: key, options, label }
}
