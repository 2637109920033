import { Chip } from '@mui/material'

const EMOJIS = ['🥳', '😁', '😊', '😐', '😕', '😟', '😞', '😣', '😩']

const COLORS = ['#4caf50', '#ff9800', '#f44336']

export const EmojiPercentageChip = ({ value, start, end }) => {
  const valueCapped = Math.max(Math.min(value, end), start)

  const color =
    COLORS[
      Math.round(((valueCapped - start) / (end - start)) * (COLORS.length - 1))
    ]
  const emoji =
    EMOJIS[
      Math.round(((valueCapped - start) / (end - start)) * (EMOJIS.length - 1))
    ]

  const emojiAndPct = (
    <>
      {emoji} {Math.round(value * 1e3) / 1e1}%
    </>
  )

  return (
    <Chip
      label={emojiAndPct}
      size="small"
      variant="outlined"
      sx={{
        borderColor: color,
        borderWidth: 2,
        color: color,
        fontWeight: 'bold',
      }}
    />
  )
}
