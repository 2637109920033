import {
  Checkbox,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import _, { capitalize } from 'lodash'
import { useEffect, useMemo } from 'react'

export default function MultiSelectFilter({
  label,
  values,
  setValues,
  options,
  sort = true,
  count = false,
  alert = null,
  sx,
}) {
  const optionsNamePct = useMemo(() => {
    const optionsNameCount = options.map((option) =>
      count ? option : [option, 1]
    )
    const totalSum = optionsNameCount.reduce((sum, [, count]) => sum + count, 0)
    const optionsNamePct = optionsNameCount.map(([option, count]) => [
      option,
      count / totalSum,
    ])
    return sort
      ? optionsNamePct.sort((a, b) => `${a[0]}`.localeCompare(b[0]))
      : optionsNamePct
  }, [options, sort, count])

  const id = label.replace(/\W/g, '')

  const { width, height } = sx

  useEffect(() => {
    const reduced = values.filter((value) =>
      _.includes(
        optionsNamePct.map(([name]) => name),
        value
      )
    )
    if (!_.isEqual(reduced, values)) {
      setValues(reduced)
    }
  }, [setValues, values, optionsNamePct])

  return (
    <FormControl sx={{ width: width }}>
      <InputLabel shrink id={id} sx={{ background: 'white', px: 1 }}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        multiple
        value={values}
        displayEmpty
        onChange={(e) => {
          setValues(e.target.value)
        }}
        label={false}
        renderValue={(selected) =>
          selected.length === 0 ? '—' : selected.map(capitalize).join(', ')
        }
        sx={{ height: height }}
      >
        {alert}
        {optionsNamePct.map(([name, pct]) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={values.includes(name)} />
            <ListItemText
              primary={capitalize(name)}
              secondary={
                count && (
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={pct * 100}
                  />
                )
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
