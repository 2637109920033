import { Wrapper } from '@googlemaps/react-wrapper'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import ViewContainer from './components/ViewContainer'
import { configSelector } from './configSlice'
import AuthPage from './features/auth/AuthPage'
import { withAuthenticationRequired } from './features/auth/withAuthenticationRequired'
import { withFirebaseTokenRequired } from './features/auth/withFirebaseTokenRequired'
import { DSAVMPredictionSamplePage } from './features/ds_avm_predictions/DSAVMPredictionSamplePage'
import { DSAVMSamplesQueuePage } from './features/ds_avm_predictions/DSAVMSamplesQueuePage'
import { Images } from './features/images/Images'
import LabellingPage from './features/labelling/LabellingPage'
import { LeadDetailPage } from './features/leads/LeadDetailPage'
import { LeadsPage } from './features/leads/LeadsPage'
import { ImportListingToHubspot } from './features/listings/ImportListingToHubspot'
import ListingPage from './features/listings/ListingPage'
import OpenFromHemnetPage from './features/listings/OpenFromHemnetPage'
import EditHomePage from './features/marketplace/EditHomePage'
import { MarketplacePage } from './features/marketplace/MarketplacePage'
import NewMarketplaceHomePage from './features/marketplace/NewMarketplaceHomePage'
import MetricsPage from './features/metrics/MetricsPage'
import { NoMatch } from './features/no_match/NoMatch'
import OfferPage from './features/offers/OfferPage'
import OffersPage from './features/offers/OffersPage'
import RequireOfferPage from './features/offers/RequireOfferPage'
import { SGOfferPage } from './features/offers/sale_guarantee/SGOfferPage'
import { SGOffersPage } from './features/offers/sale_guarantee/SGOffersPage'
import RenovationEstimatePage from './features/renovation_estimate/RenovationEstimatePage'
import ResourcesPage from './features/resources/ResourcesPage'
import SearchPage from './features/search/SearchPage'
import StartPage from './features/start/StartPage'
import ValuationDetailPage from './features/valuate/ValuationDetailPage'
import ValuationFormPage from './features/valuate/ValuationFormPage'
import ValuationsPage from './features/valuate/ValuationsPage'

const Routes = () => {
  const config = useSelector(configSelector)

  // connect to certain hosts to speed up loading certain images (later on)
  const preconnectURLs = config ? [config.REACT_APP_IMAGE_PROXY_URL] : []

  return (
    <Wrapper
      apiKey={config.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={['places, geocoder']}
    >
      <ViewContainer preconnectURLs={preconnectURLs}>
        <Switch>
          <Route
            exact
            path="/"
            component={withAuthenticationRequired(StartPage)}
          />
          <Route path="/auth" component={AuthPage}></Route>
          <Route
            path="/images"
            component={withAuthenticationRequired(Images)}
          />
          <Route
            path="/metrics"
            component={withAuthenticationRequired(MetricsPage)}
          />
          <Route
            path="/offers/sale-guarantee/:id"
            component={withFirebaseTokenRequired(SGOfferPage)}
          />
          <Route
            path="/offers/sale-guarantee/"
            component={withFirebaseTokenRequired(SGOffersPage)}
          />
          <Route
            path="/offer"
            component={withAuthenticationRequired(OfferPage)}
          />
          <Route
            path="/offers"
            component={withAuthenticationRequired(OffersPage)}
          />
          <Route
            path="/importListingToHubspot"
            component={withAuthenticationRequired(ImportListingToHubspot)}
          />
          <Route
            path="/requireOffer"
            component={withAuthenticationRequired(RequireOfferPage)}
          />
          <Route
            path="/listing/:listingId"
            component={withFirebaseTokenRequired(ListingPage)}
          />
          <Route
            path="/labelling/"
            component={withFirebaseTokenRequired(LabellingPage)}
          />
          <Route
            path="/from-hemnet/:url"
            component={withAuthenticationRequired(OpenFromHemnetPage)}
          />
          <Route
            path="/valuate/new/"
            component={withFirebaseTokenRequired(ValuationFormPage)}
          />
          <Route
            path="/valuate/:manualValuationId"
            component={withFirebaseTokenRequired(ValuationDetailPage)}
          />
          <Route
            path="/valuate/"
            component={withFirebaseTokenRequired(ValuationsPage)}
          />
          <Route
            path="/valuate/"
            component={withFirebaseTokenRequired(ValuationsPage)}
          />
          <Route
            path="/search/"
            component={withFirebaseTokenRequired(SearchPage)}
          />
          <Route
            path="/leads/"
            component={withFirebaseTokenRequired(LeadsPage)}
          />
          <Route
            path="/lead/:id"
            component={withFirebaseTokenRequired(LeadDetailPage)}
          />
          <Route
            path="/renovation-estimate/:renovationEstimateId"
            component={withFirebaseTokenRequired(RenovationEstimatePage)}
          />
          <Route
            path="/marketplace/new"
            component={withAuthenticationRequired(NewMarketplaceHomePage)}
          />
          <Route
            path="/marketplace/:homeId"
            component={withAuthenticationRequired(EditHomePage)}
          />
          <Route
            path="/marketplace"
            component={withAuthenticationRequired(MarketplacePage)}
          />
          <Route
            path="/resources"
            component={withAuthenticationRequired(ResourcesPage)}
          />
          <Route
            path="/ds-avm-predict/labelling-queue/:predictTaskId/:sampleIds"
            component={withAuthenticationRequired(DSAVMSamplesQueuePage)}
          />
          <Route
            path="/ds-avm-predict/:predictTaskId/:sampleId"
            component={withFirebaseTokenRequired(DSAVMPredictionSamplePage)}
          />
          <Route path="*" component={NoMatch} />
        </Switch>
      </ViewContainer>
    </Wrapper>
  )
}

export default Routes
