const BOUNDING_BOX_SWEDEN = {
  nwlat: 69.059,
  nwlng: 11.027,
  selat: 55.361,
  selng: 24.153,
}

export const getBoundsZoomLevel = ({
  coordinatesList,
  mapWidth,
  mapHeight,
  maxZoomLevel,
}) => {
  // https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
  const WORLD_DIM = { height: 256, width: 256 }
  const ZOOM_MAX = 18

  maxZoomLevel = maxZoomLevel || ZOOM_MAX

  function latRad(lat) {
    const sin = Math.sin((lat * Math.PI) / 180)
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
  }

  const boundingBox = getBoundingBox(coordinatesList)

  const latFraction =
    (latRad(boundingBox.north) - latRad(boundingBox.south)) / Math.PI

  const lngDiff = boundingBox.east - boundingBox.west
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  const relevant = 0.9 // -10% because we don't want markers at the edge

  const latZoom = zoom(mapHeight * relevant, WORLD_DIM.height, latFraction)
  const lngZoom = zoom(mapWidth * relevant, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export const getCoordinatesCenter = (coordinatesList) => {
  const boundingBox = getBoundingBox(coordinatesList)

  return {
    lng: (boundingBox.west + boundingBox.east) / 2,
    lat: (boundingBox.south + boundingBox.north) / 2,
  }
}

export const listingToMapItem = (listing) => {
  return {
    id: listing.id,
    title: listing.streetAddress,
    images: listing.thumbImages,
    coordinates: listing.coordinates,
    listing,
  }
}
export const comparableToMapItem = (comparable) => {
  if (comparable.type !== 'hemnet_listing') return null

  const features = comparable.features

  return {
    id: comparable.source_id,
    title: [
      features?.postal_address?.route_name ?? '?',
      features?.postal_address?.street_number ?? '?',
    ].join(' '),
    images: [],
    coordinates: {
      lng: features.geo_location.longitude,
      lat: features.geo_location.latitude,
    },
    listing: null,
  }
}

const getBoundingBox = (coordinatesList) => {
  const mins = { lat: 1e5, lng: 1e5 }
  const maxs = { lat: -1e5, lng: -1e5 }

  coordinatesList.forEach((element) => {
    mins.lat = Math.min(mins.lat, element.lat)
    mins.lng = Math.min(mins.lng, element.lng)
    maxs.lat = Math.max(maxs.lat, element.lat)
    maxs.lng = Math.max(maxs.lng, element.lng)
  })

  return {
    north: maxs.lat,
    south: mins.lat,
    east: maxs.lng,
    west: mins.lng,
  }
}

export const waitForMapsWrapper = () => {
  return () =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      resolve(google.maps)
    })
}

export const distance = (point1, point2) => {
  if (
    point1.lat === undefined ||
    point1.lng === undefined ||
    point2.lat === undefined ||
    point2.lng === undefined
  ) {
    throw new Error('Invalid point')
  }

  const R = 6371e3 // meters
  const phi1 = (point1.lat * Math.PI) / 180 // φ, λ in radians
  const phi2 = (point2.lat * Math.PI) / 180
  const deltaPhi = ((point2.lat - point1.lat) * Math.PI) / 180
  const deltaLambda = ((point2.lng - point1.lng) * Math.PI) / 180

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c // distance in meters
}

export const getCenterFromBounds = ({ nw, se }) => {
  if (!nw || !se) {
    throw Error('getCenterFromBounds: missing nw or se', { nw, se })
  }
  return {
    lat: (nw.lat + se.lat) / 2,
    lng: (nw.lng + se.lng) / 2,
  }
}

export const boundsToList = ({ nw, se }) => {
  const converted = [nw.lat, nw.lng, se.lat, se.lng]

  if (
    nw.lat > BOUNDING_BOX_SWEDEN.nwlat ||
    nw.lng < BOUNDING_BOX_SWEDEN.nwlng ||
    se.lat < BOUNDING_BOX_SWEDEN.selat ||
    se.lng > BOUNDING_BOX_SWEDEN.selng
  ) {
    console.warn('Bounds outside of Sweden', converted)
  }

  return converted
}

export const boundsToObject = ([nwlat, nwlng, selat, selng]) => {
  const converted = {
    nw: {
      lat: nwlat,
      lng: nwlng,
    },
    se: {
      lat: selat,
      lng: selng,
    },
    ne: {
      lat: nwlat,
      lng: selng,
    },
    sw: {
      lat: selat,
      lng: nwlng,
    },
  }

  if (
    converted.nw.lat > BOUNDING_BOX_SWEDEN.nwlat ||
    converted.nw.lng < BOUNDING_BOX_SWEDEN.nwlng ||
    converted.se.lat < BOUNDING_BOX_SWEDEN.selat ||
    converted.se.lng > BOUNDING_BOX_SWEDEN.selng
  ) {
    console.warn('Bounds outside of Sweden', converted)
  }

  return converted
}

export const LONGLAT_STORE_PRECISION = 5

export const getBoundingBoxAroundPoint = ({ lng, lat }, distanceMeters) => {
  const earthRadius = 6378.137 // km
  const coeff = 1 / (((2 * Math.PI) / 360) * earthRadius) / 1000
  const blurFactor = distanceMeters * coeff // depending on the north, south use - or + on meters

  return {
    nw: {
      lat: lat + blurFactor,
      lng: lng - blurFactor / Math.cos(lat * 0.018),
    },
    se: {
      lat: lat - blurFactor,
      lng: lng + blurFactor / Math.cos(lat * 0.018),
    },
    ne: {
      lat: lat + blurFactor,
      lng: lng + blurFactor / Math.cos(lat * 0.018),
    },
    sw: {
      lat: lat - blurFactor,
      lng: lng - blurFactor / Math.cos(lat * 0.018),
    },
  }
}

export const cropBoundsEpsilon = (bounds) => {
  const epsilon = 2 / 10 ** LONGLAT_STORE_PRECISION
  return {
    nw: {
      lat: bounds.nw.lat - epsilon,
      lng: bounds.nw.lng + epsilon,
    },
    se: {
      lat: bounds.se.lat + epsilon,
      lng: bounds.se.lng - epsilon,
    },
  }
}
