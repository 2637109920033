import { Inbox, PriceChange } from '@mui/icons-material'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'

import { TagsSection } from '../../../../components/tags/TagsSection'
import { formatSEK } from '../../../../utils/formatUtils'
import { getFeaturesAsList, getStreetAddress } from '../../../leads/homeUtils'
import { getContactName } from '../../../leads/leadUtils'

const TABLE_CELL_WIDTH = 150

export const OfferSourceInfo = ({ lead, manualValuation, flex }) => {
  if (!lead) {
    return <></>
  }

  const homeFeatures = manualValuation?.features_snapshot?.features

  return (
    <Box
      flex={flex}
      bgcolor={(theme) => theme.palette.grey[100]}
      p={2}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="h5">
          <Inbox sx={{ color: 'primary.main', mr: 2, mb: -0.5 }} />
          Lead
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}>
                Address
              </TableCell>
              <TableCell>
                {homeFeatures
                  ? [
                      getStreetAddress(homeFeatures),
                      homeFeatures?.postal_address?.postal_town ||
                        homeFeatures?.postal_address?.postal_code,
                    ].join(', ')
                  : '—'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}>
                Name
              </TableCell>
              <TableCell>{getContactName(lead)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}>
                E-mail
              </TableCell>
              <TableCell>{lead.contact_details?.email}</TableCell>
            </TableRow>
            {getFeaturesAsList(
              manualValuation?.features_snapshot?.features
            ).map(({ name, value }) => (
              <TableRow key={name}>
                <TableCell sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}>
                  {name}
                </TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          variant="outlined"
          component={Link}
          to={`/lead/${lead.id}`}
          target="_blank"
        >
          Open lead
        </Button>
      </Box>
      {manualValuation && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h5">
            <PriceChange sx={{ color: 'primary.main', mr: 2, mb: -0.5 }} />
            Manual valuation
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}>
                  Expectation
                </TableCell>
                <TableCell>
                  {formatSEK(manualValuation.valuation_estimate?.expectation)}
                </TableCell>
              </TableRow>
              {manualValuation.valuation_estimate?.low_80 ? (
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}
                  >
                    1 out of 5 sales
                  </TableCell>
                  <TableCell>
                    {formatSEK(manualValuation.valuation_estimate?.low_80)}
                    {' — '}
                    {formatSEK(manualValuation.valuation_estimate?.high_80)}
                  </TableCell>
                </TableRow>
              ) : null}
              {manualValuation.valuation_estimate?.low_95 ? (
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}
                  >
                    1 out of 20 sales
                  </TableCell>
                  <TableCell>
                    {formatSEK(manualValuation.valuation_estimate?.low_95)}
                    {' — '}
                    {formatSEK(manualValuation.valuation_estimate?.high_95)}
                  </TableCell>
                </TableRow>
              ) : null}
              {manualValuation.comment?.length ? (
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: 'bold', width: TABLE_CELL_WIDTH }}
                  >
                    Comment
                  </TableCell>
                  <TableCell
                    sx={{
                      wordWrap: 'break-word',
                      wordBreak: 'break-all',
                      hyphens: 'auto',
                      maxWidth: '300px',
                    }}
                  >
                    {manualValuation.comment?.slice(0, 100)}
                    {manualValuation.comment?.length > 100 ? '...' : null}
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
          {manualValuation.tags?.length ? (
            <TagsSection
              tags={manualValuation.tags}
              canModify={false}
              suggestionsDocKey="manual_valuation"
            />
          ) : null}
          <Button
            variant="outlined"
            component={Link}
            to={`/valuate/${manualValuation.id}`}
            target="_blank"
          >
            Open manual valuation
          </Button>
        </Box>
      )}
    </Box>
  )
}
