import { AutoFixHigh } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, useForm } from 'react-final-form'

import Suggestion from './Suggestion'

function Suggestions({
  name,
  assetSuggestion,
  hemnetSuggestion,
  geoLocationSuggestion,
}) {
  const form = useForm()
  return (
    <Field name={name}>
      {({ input: { value } }) => {
        if (
          (assetSuggestion === undefined || assetSuggestion === value) &&
          (hemnetSuggestion === undefined || hemnetSuggestion === value) &&
          (geoLocationSuggestion === undefined ||
            geoLocationSuggestion === value)
        )
          return null

        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width={30}
          >
            <Tooltip
              title={
                <>
                  <Suggestion
                    use={() =>
                      form.mutators.applySuggestion(name, assetSuggestion)
                    }
                    label="Asset sheet"
                    value={assetSuggestion}
                    currentValue={value}
                  />
                  <Suggestion
                    use={() =>
                      form.mutators.applySuggestion(name, hemnetSuggestion)
                    }
                    label="Hemnet listing"
                    value={hemnetSuggestion}
                    currentValue={value}
                  />
                  <Suggestion
                    use={() =>
                      form.mutators.applySuggestion(name, geoLocationSuggestion)
                    }
                    label="Address"
                    value={geoLocationSuggestion}
                    currentValue={value}
                  />
                </>
              }
            >
              <AutoFixHigh />
            </Tooltip>
          </Box>
        )
      }}
    </Field>
  )
}

Suggestions.propTypes = {
  assetSuggestion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  geoLocationSuggestion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  hemnetSuggestion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Suggestions.defaultProps = {
  assetSuggestion: undefined,
  hemnetSuggestion: undefined,
  geoLocationSuggestion: undefined,
}

export default Suggestions
