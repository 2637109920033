import * as Yup from 'yup'

import fields from './fields'

const schema = Yup.object({
  visibility: Yup.string()
    .label(fields.editable.visibility.label)
    .oneOf(Object.keys(fields.editable.visibility.values)),
  hemnet_listing_id: Yup.number()
    .nullable()
    .label(fields.editable.hemnet_listing_id.label)
    .min(0),
  hemnet_sale_url: Yup.string()
    .nullable()
    .label(fields.editable.hemnet_sale_url.label),
  num_rooms: Yup.number()
    .nullable()
    .label(fields.editable.COLUMN_AREAS.num_rooms.label)
    .min(1)
    .max(20),
  living_area: Yup.number()
    .nullable()
    .label(fields.editable.COLUMN_AREAS.living_area.label)
    .min(0)
    .max(999),
  plot_area: Yup.number()
    .nullable()
    .label(fields.editable.COLUMN_MORE_AREAS.plot_area.label),
  supplemental_area: Yup.number()
    .nullable()
    .label(fields.editable.COLUMN_MORE_AREAS.supplemental_area.label)
    .min(0)
    .max(999),
  housing_type: Yup.string()
    .label(fields.editable.housing_type.label)
    .oneOf(Object.keys(fields.editable.housing_type.values)),
  construction_year: Yup.number()
    .nullable()
    .label(fields.editable.construction_year.label)
    .min(0),
  price: Yup.number().nullable().label(fields.editable.price.label).min(0),
  description: Yup.string().nullable().label(fields.editable.description.label),
  planned_date: Yup.date().nullable().label(fields.editable.planned_date.label),
  referral_enabled: Yup.bool().label(fields.editable.referral_enabled.label),
  geo_location: Yup.object().shape({
    latitude: Yup.number().nullable().min(-90).max(90),
    longitude: Yup.number().nullable().min(-180).max(180),
  }),
  images: Yup.array()
    .label('images')
    .of(Yup.object().shape({ src: Yup.string(), excluded: Yup.bool() })),
})

export default schema
