import { ArrowDownward, ArrowUpward, CompareArrows } from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { Grid, Typography } from '@mui/material'
import React from 'react'

import {
  formatPercentage,
  formatSEK,
  formatSEKPerArea,
} from '../../../utils/formatUtils'
import { isSold, listingToSoldRelative } from '../listingUtils'
import ListingSection from './ListingSection'

export default function FinanceInfo({ listing }) {
  return (
    <ListingSection icon={<AttachMoneyIcon fontSize="large" color="primary" />}>
      {listing.listingPrice !== null && (
        <Grid item md={4} lg={4}>
          <Typography variant="h5">
            {formatSEK(listing.listingPrice)}
          </Typography>
          <Typography variant="body1">Listing price</Typography>
        </Grid>
      )}
      <Grid item md={4} lg={4}>
        {isSold(listing) && (
          <>
            <Typography variant="h5" fontWeight="bold">
              {formatSEK(listing.soldPrice)}
            </Typography>
            <Typography variant="body1">Sold price</Typography>
          </>
        )}
      </Grid>
      {listing.listingPrice !== null && (
        <Grid item md={4} lg={4}>
          {isSold(listing) && (
            <>
              <Typography variant="h5">
                <PerformanceArrow relative={listingToSoldRelative(listing)} />
                {formatPercentage(listingToSoldRelative(listing))}
              </Typography>
              <Typography variant="body1">Sale performance</Typography>
            </>
          )}
        </Grid>
      )}
      {listing.listingPrice !== null && (
        <Grid item md={4} lg={4}>
          <Typography variant="h5">
            {formatSEKPerArea(listing.listingPricePerLivingArea)}
          </Typography>
          <Typography variant="body1">Listing price per living area</Typography>
        </Grid>
      )}
      <Grid item md={4} lg={4}>
        {isSold(listing) && (
          <>
            <Typography variant="h5">
              {formatSEKPerArea(listing.soldPricePerLivingArea)}
            </Typography>
            <Typography variant="body1">Sold price per living area</Typography>
          </>
        )}
      </Grid>
      <Grid item md={null} lg={null} />
      {listing.housingCooperativeFeePerMonth && (
        <Grid item md={4} lg={4}>
          <Typography variant="h5">
            {formatSEK(listing.housingCooperativeFeePerMonth)}
          </Typography>
          <Typography variant="body1">BRF fee per month</Typography>
        </Grid>
      )}
      {listing.operatingCostPerYear && (
        <Grid item md={4} lg={4}>
          <Typography variant="h5">
            {formatSEK(listing.operatingCostPerYear)}
          </Typography>
          <Typography variant="body1">Operating cost per year</Typography>
        </Grid>
      )}
    </ListingSection>
  )
}

const PerformanceArrow = ({ relative }) => {
  if (relative === 0) return <CompareArrows sx={{ mr: 2 }} />
  else if (relative > 0) return <ArrowUpward sx={{ mr: 2 }} color="success" />
  else return <ArrowDownward sx={{ mr: 2 }} color="error" />
}
