export const getOptionDefault = (optionConfig, values) => {
  const default_ = optionConfig.cost.default
  let value = 0
  if (default_ && default_.type === 'source_default') {
    value = values[default_.source] * default_.factor
  } else if (default_ && default_.type === 'fixed_default') {
    value = default_.value
  }

  const total = calculateOptionTotal(
    optionConfig.cost.type,
    optionConfig.cost.base,
    optionConfig.cost.per_unit,
    value
  )

  return {
    id: optionConfig.id,
    value,
    total,
  }
}

const addAutoOptions = (renovationEstimateConfig, itemId, values) => {
  return Object.values(renovationEstimateConfig[itemId].options)
    .filter((optionConfig) => optionConfig.auto_add)
    .map((optionConfig) => getOptionDefault(optionConfig, values))
}

export const addItem = (renovationEstimateConfig, fields, itemId, values) => {
  const item = {
    id: itemId,
    options: addAutoOptions(renovationEstimateConfig, itemId, values),
    total: 0,
  }
  item.total = calculateItemTotal(item)
  fields.push(item)
}

export const addOption = (
  renovationEstimateConfig,
  fields,
  itemId,
  optionId,
  values
) => {
  const optionConfig = renovationEstimateConfig[itemId].options[optionId]
  fields.push(getOptionDefault(optionConfig, values))
}

export const addAllDefaults = (configNotDeprecated, fields, values) => {
  Object.values(configNotDeprecated)
    .filter((itemConfig) => itemConfig.auto_add)
    .forEach((itemConfig) => {
      addItem(configNotDeprecated, fields, itemConfig.id, values)
    })
}

export const calculateOptionTotal = (type, base, perUnit, units) =>
  type === 'per_unit_cost' ? base + perUnit * units : base

export const calculateItemTotal = (item) =>
  item.options.map((item) => item.total).reduce((a, b) => a + b, 0) || 0
