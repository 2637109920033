import { CheckCircle } from '@mui/icons-material'
import {
  Alert,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  LinearProgress,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useOffers } from './populateOffersHook'
import {
  getColumnKeysPopulated,
  mapFlattenOfferWithPopulatedFields,
} from './sgOfferUtils'

export const ADDITIONAL_COLUMNS_START = [
  {
    name: 'is_presented',
    label: 'Presented',
    options: {
      customBodyRender: (value) => {
        return value ? (
          <CheckCircle sx={{ ml: 2, fontSize: '1em', color: 'primary.main' }} />
        ) : null
      },
    },
  },
]

export const ADDITIONAL_COLUMNS_END = [
  {
    name: 'id',
    label: ' ',
    options: {
      customBodyRender: (value) => {
        return <Link to={`/offers/sale-guarantee/${value}`}>Open</Link>
      },
    },
  },
]

export const SGOffersPage = () => {
  const history = useHistory()

  const [populate, setPopulate] = useState(false)
  const { offers, isLoaded } = useOffers({ populate })

  const tableData = useMemo(
    () => offers.map(mapFlattenOfferWithPopulatedFields),
    [offers]
  )

  const tableColumns = useMemo(
    () => [
      ...ADDITIONAL_COLUMNS_START,
      ...getColumnKeysPopulated(tableData),
      ...ADDITIONAL_COLUMNS_END,
    ],
    [tableData]
  )

  const options = {
    selectableRows: 'none',
    onRowClick: (rowData) =>
      history.push(
        `/offers/sale-guarantee/${
          rowData[tableColumns.findIndex((c) => c.name === 'id')]
        }`
      ),
    print: false,
    downloadOptions: {
      filename: `sale-guarantee-offers-${moment().format('YYYYMMDD-HHmm')}.csv`,
    },
    elevation: 1,
    sortOrder: {
      name: 'created_at',
      direction: 'desc',
    },
  }

  return (
    <Container>
      <Typography variant="h1" gutterBottom>
        Sale Guarantee Offers
      </Typography>
      <Alert severity="info" sx={{ mb: 3 }}>
        Create new offers from a <Link to="/leads">lead</Link> after creating a
        manual valuation.
      </Alert>
      {isLoaded ? (
        <MUIDataTable
          data={tableData}
          columns={tableColumns}
          options={options}
        />
      ) : (
        <LinearProgress />
      )}
      <Box mt={3} display="flex" gap={2}>
        <Button variant="outlined" component={Link} to="/leads">
          Leads
        </Button>
        <Button variant="outlined" component={Link} to="/offers">
          Sell-to offers
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={populate}
              onChange={(e) => setPopulate(e.target.checked)}
            />
          }
          label="Load data from leads and manual valuations"
        />
      </Box>
    </Container>
  )
}
