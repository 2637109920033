export const getTableData = (sample, comparables, listings, tags) => {
  if (!sample || !comparables || !listings) return []

  const merged = [
    {
      ...sample,
      ...calculateTransformationsTarget(sample, sample),
      __isTarget: true,
    },
    ...Object.values(comparables)?.map((c) => ({
      ...c,
      ...calculateTransformationsComparable(sample, c),
      __isTarget: false,
    })),
  ]

  // map to object: {sample, listing}
  const samplesAndListings = merged.map((r) => ({
    sample: {
      ...r, // includes weight
      image: listings[r.listing_id]?.thumbImages[0],
      address_formatted: listings[r.listing_id]?.streetAddress,
      tags: tags[r.listing_id],
    },
    id: r.__id,
    listing: listings[r.listing_id],
  }))

  return samplesAndListings
}

function calculateTransformationsPair(target, comparable) {
  const hoxTarget = target.hox_all_swe_mrr
  const hoxComparable = comparable.hox_all_swe_mrr
  const soldPrice = comparable.sold_price
  const canCalculateNormalization =
    soldPrice > 0 && hoxTarget > 0 && hoxComparable > 0

  const livingArea = comparable.living_area

  return {
    sold_price_hox_target: canCalculateNormalization
      ? (soldPrice / hoxComparable) * hoxTarget
      : null,
    sold_price_per_m2_hox_target:
      canCalculateNormalization && livingArea > 0
        ? (soldPrice / livingArea / hoxComparable) * hoxTarget
        : null,
  }
}

function calculateTransformationsComparable(target, comparable) {
  return calculateTransformationsPair(target, comparable)
}

function calculateTransformationsTarget(target) {
  const comparable = target

  const ape =
    target?.y_pred && target?.sold_price
      ? Math.round(
          (Math.abs(target.y_pred - target.sold_price) / target.sold_price) *
            1e4
        ) / 1e4
      : null

  return {
    ...calculateTransformationsPair(target, comparable),
    absolute_percentage_error: ape,
  }
}
