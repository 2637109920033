import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// https://github.com/MovestaDev/movesta/blob/master/lib/auth/movesta/auth/client.py#L49
const COOKIE_EXPIRE_SECONDS = 604800

export const setCookie = createAsyncThunk(
  'images/setCookie',
  async (_, thunkAPI) => {
    // if .getAccessTokenSilently() fails it will signal the user is
    // logged out and trigger withAuthenticationRequired() to redirect to
    // the login page
    //
    // this causes a redirect loop on safari where
    // .getAccessTokenSilently() can only work if there already is a
    // refresh token in local storage.
    //
    // the workaround is to trigger a login before calling setCookie
    // (which uses .getAccessTokenSilently() under the hood).
    // this will populate the cache so .getAccessTokenSilently()
    // will then succeed.
    //
    // we store when the cookie will expire so we can skip doing this
    // too often
    await thunkAPI.extra().imageProxyApi.setCookie()
    const nowSeconds = Math.floor(Date.now() / 1000)
    const expiresAt = nowSeconds + COOKIE_EXPIRE_SECONDS
    return expiresAt
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async ({ returnTo }, thunkAPI) => {
    const response = await thunkAPI.extra().imageProxyApi.deleteCookie()
    await thunkAPI.extra().getFirebase().auth().signOut()
    await thunkAPI.extra().auth0.logout({ returnTo })
    return response.data
  }
)

export const getFirebaseToken = createAsyncThunk(
  'sabi/getFirebaseToken',
  async (_, thunkAPI) => {
    const user = thunkAPI.extra().auth0.user
    let profile = {}
    if (user) {
      profile = {
        username: user.email,
        displayName: user.name,
        photoURL: user.picture,
        email: user.email,
        emailVerified: user.email_verified,
      }
    }

    const response = await thunkAPI.extra().sabiApi.getFirebaseToken()
    await thunkAPI.extra().getFirebase().login({
      token: response.data.token,
      profile: profile,
    })
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: 'idle',
    imageProxyCookieExpiresAt: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCookie.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.imageProxyCookieExpiresAt = action.payload
      })
      .addCase(setCookie.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(setCookie.rejected, (state, action) => {
        console.error(action.error)
        if (
          action.error.name === 'TypeError' &&
          action.error.message ===
            "Cannot read properties of null (reading 'location')"
        ) {
          console.error('pop-up blocked - please allow pop-ups!')
        }
        state.loading = { status: 'rejected', payload: action }
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.imageProxyCookieExpiresAt = 0
      })
      .addCase(logout.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = { status: 'rejected', payload: action }
      })
  },
})

export const authSelector = (state) => state.firebase.auth

export const selectLoading = (state) => state.auth.loading

export const selectIsImageProxyCookieExpired = (state) => {
  // do we expect the proxy cookie to still be valid in an hour
  const nowSeconds = Math.floor(Date.now() / 1000)
  return state.auth.imageProxyCookieExpiresAt - 3600 < nowSeconds
}

export default authSlice.reducer
