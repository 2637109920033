import Lightbox from 'react-image-lightbox'

export default function Gallery({ images, imageId, setImageId }) {
  const currentId = parseInt(imageId)
  const nextId = (currentId + 1) % images.length
  const prevId = (currentId + images.length - 1) % images.length

  return (
    <>
      <link rel="prefetch" as="image" href={images[nextId]}></link>
      <link rel="prefetch" as="image" href={images[prevId]}></link>
      <Lightbox
        mainSrc={images[currentId]}
        nextSrc={images[nextId]}
        prevSrc={images[prevId]}
        onCloseRequest={() => setImageId(null)}
        onMovePrevRequest={() => setImageId(prevId)}
        onMoveNextRequest={() => setImageId(nextId)}
      />
    </>
  )
}
