import { Box } from '@mui/system'
import React from 'react'

export const HoverPreviewImage = ({ src }) => {
  const imageHeight = 80

  return (
    <Box sx={{ height: imageHeight, my: -1 }}>
      <Box
        sx={{
          height: imageHeight,
          width: imageHeight * 1.5,
          backgroundColor: 'grey.300',
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          margin: 'auto',
          '&:hover': {
            position: 'absolute',
            zIndex: 1000,
            height: 200,
            width: 300,
            boxShadow: 16,
          },
        }}
      />
    </Box>
  )
}
