import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import {
  tagSuggestionConnector,
  tagSuggestionSelector,
} from './tagSuggestionsSelector'

export const TagsSection = ({
  tags,
  onRemove,
  onAdd,
  suggestionsDocKey = null,
  canModify = true,
  ...props
}) => {
  useFirestoreConnect(tagSuggestionConnector(suggestionsDocKey))
  const tagSuggestions = useSelector((state) =>
    tagSuggestionSelector(state, suggestionsDocKey)
  )

  const [isPromptOpen, setIsPromptOpen] = useState(false)

  return (
    <Box {...props}>
      <TagsList
        tags={tags}
        onRemove={onRemove}
        canModify={canModify}
        tagSuggestions={tagSuggestions}
      />
      {canModify && (
        <>
          <Chip
            label={
              <>
                <Add sx={{ verticalAlign: 'top', fontSize: '1.4em' }} /> Add tag
              </>
            }
            sx={{ mr: 1, mb: 1, fontWeight: 'bold' }}
            onClick={() => setIsPromptOpen(true)}
          />
          <TagsDialogWithInputField
            isOpen={isPromptOpen}
            onClose={() => setIsPromptOpen(false)}
            onSubmit={onAdd}
            suggestions={tagSuggestions}
            tags={tags}
            onRemove={onRemove}
          />
        </>
      )}
    </Box>
  )
}

export const TagsList = ({ tags, onRemove, canModify, tagSuggestions }) => {
  return (
    <>
      {tags?.map((tag) => (
        <>
          <Chip
            key={tag}
            onDelete={canModify && onRemove ? () => onRemove(tag) : null}
            label={
              <Tooltip>
                <Typography fontSize="inherit">{tag}</Typography>
              </Tooltip>
            }
            sx={{
              mr: 1,
              mb: 1,
              background: `#${tagSuggestions?.[tag]?.color ?? 'bbb'}`,
            }}
          />
        </>
      ))}
    </>
  )
}

export const TagsDialogWithInputField = ({
  isOpen,
  onClose,
  onSubmit,
  onRemove,
  tags,
  suggestions,
}) => {
  const [inputValue, setInputValue] = useState('')

  const onSubmitHandler = useCallback(() => {
    onSubmit(inputValue)
    setInputValue('')
  }, [inputValue, onSubmit])

  const onCloseHandler = () => {
    onClose()
    setInputValue('')
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add tag</DialogTitle>
      <DialogContent sx={{ minWidth: '500px', maxWidth: 'lg', width: '100%' }}>
        {tags?.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Existing tags
            </Typography>
            <TagsList
              tags={tags}
              onRemove={onRemove}
              tagSuggestions={suggestions}
              canModify={true}
            />
          </>
        )}

        <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
          Suggested tags
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {Object.entries(suggestions)
            ?.filter(([label]) => !tags?.includes(label))
            .map(([label, { color }]) => (
              <Chip
                key={label}
                label={label}
                sx={{ background: `#${color}`, cursor: 'pointer' }}
                onClick={() => {
                  onSubmit(label)
                  onCloseHandler()
                }}
              />
            ))}
          <Tooltip
            title="Reuse existing tags whenever possible!"
            disableHoverListener
            placement="top"
          >
            <TextField
              name="tag"
              label="Add new tag"
              type="text"
              size="small"
              sx={{ width: '160px' }}
              InputLabelProps={{ sx: { fontSize: '0.9em' } }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSubmitHandler()
                }
              }}
            />
          </Tooltip>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmitHandler} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
