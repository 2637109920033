import { createAsyncThunk } from '@reduxjs/toolkit'

export const avmRequestForHome = createAsyncThunk(
  'manual-valuation/avm-request',
  async function ({ homeId, force }, thunkAPI) {
    try {
      const result = await thunkAPI.extra().sabiApi.avmRequestForHome({
        homeId,
        force,
      })
      return result.data
    } catch (error) {
      return thunkAPI.rejectWithValue([error.message, error.response.data])
    }
  }
)
