import _ from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectAvailableTableColumns,
  selectCurrentTableColumns,
  selectMapColumns,
  selectPointsOffset,
  setDSPredictAvailableTableColumns,
  setDSPredictCurrentTableColumns,
  setDSPredictMapColumns,
  setDSPredictPointsOffset,
} from '../settings/settingsSlice'
import { MAP_COLUMNS } from './mapPoints'
import { tableColumnsDefaultVisible } from './tableColumns'

export const useTableSettings = () => {
  const dispatch = useDispatch()

  const availableTableColumns = useSelector(selectAvailableTableColumns)
  const currentTableColumns = useSelector(selectCurrentTableColumns)

  const setAvailableTableColumns_ = useCallback(
    (columnsFromSample) => {
      const columns = _.uniq([
        ...columnsFromSample,
        ...tableColumnsDefaultVisible,
      ])

      if (!_.isEqual(new Set(columns), new Set(availableTableColumns))) {
        dispatch(setDSPredictAvailableTableColumns(columns))
      }
    },
    [availableTableColumns, dispatch]
  )

  const setCurrentTableColumns_ = useCallback(
    (columns) => {
      dispatch(setDSPredictCurrentTableColumns(columns))
    },
    [dispatch]
  )

  return {
    currentTableColumns:
      currentTableColumns?.length > 0
        ? currentTableColumns
        : tableColumnsDefaultVisible,
    availableTableColumns,
    setAvailableTableColumns: setAvailableTableColumns_,
    setCurrentTableColumns: setCurrentTableColumns_,
  }
}

export const useMapSettings = () => {
  const dispatch = useDispatch()

  const mapColumns =
    useSelector(selectMapColumns) || Object.keys(MAP_COLUMNS)[0]
  const pointsOffset = useSelector(selectPointsOffset) || 0

  return {
    mapColumns,
    setMapColumns: (columns) => dispatch(setDSPredictMapColumns(columns)),
    pointsOffset,
    setPointsOffset: (offset) => dispatch(setDSPredictPointsOffset(offset)),
  }
}
