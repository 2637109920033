export const estimateBasedOnComparables = ({
  comparables,
  targetLivingArea,
  roundTo = 1000,
}) => {
  const validComparables = Object.values(comparables).filter(
    (c) => c.features.living_area && c.sale_price
  )

  const averagePerLivingArea =
    validComparables.reduce(
      (previous, c) => previous + c.sale_price / c.features.living_area,
      0
    ) / validComparables.length

  return {
    averagePerLivingArea: Math.round(averagePerLivingArea / roundTo) * roundTo,
    estimatedPrice:
      Math.round((averagePerLivingArea * targetLivingArea) / roundTo) * roundTo,
  }
}
