// eslint-disable-next-line no-unused-vars
// globals process.env

// need to be REACT_APP_ prefix to be available in frontend js
// compare: https://create-react-app.dev/docs/adding-custom-environment-variables/
module.exports = {
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_MOVESTA_API_URL: process.env.REACT_APP_MOVESTA_API_URL,
  REACT_APP_IMAGE_PROXY_URL: process.env.REACT_APP_IMAGE_PROXY_URL,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_IMAGE_PROXY_AUDIENCE: process.env.REACT_APP_IMAGE_PROXY_AUDIENCE,
  REACT_APP_MOVESTA_API_AUDIENCE: process.env.REACT_APP_MOVESTA_API_AUDIENCE,
  REACT_APP_APP_URL: process.env.REACT_APP_APP_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENABLED: process.env.REACT_APP_SENTRY_ENABLED,
  REACT_APP_MARKETPLACE_SHEET: process.env.REACT_APP_MARKETPLACE_SHEET,
  REACT_APP_MARKETPLACE_GDRIVE_FOLDER_ID:
    process.env.REACT_APP_MARKETPLACE_GDRIVE_FOLDER_ID,
  REACT_APP_MARKETPLACE_OAUTH_CLIENT_ID:
    process.env.REACT_APP_MARKETPLACE_OAUTH_CLIENT_ID,
  REACT_APP_MARKETPLACE_GPICKER_API_KEY:
    process.env.REACT_APP_MARKETPLACE_GPICKER_API_KEY,
}
