import _ from 'lodash'
import moment from 'moment'

import { FILTER_CONFIG } from './filterConfig'

export const queryTransformCustom = (query, dateFieldName) => {
  const newQuery = { ...query }

  if (newQuery.sale_status && newQuery.sale_status.values.length === 1) {
    const [saleStatus] = newQuery.sale_status.values

    // hack: we have listings in Elastic that have a sold_at date but are not
    // marked as SOLD (status). This is a temporary fix to make sure that we
    // show these listings as SOLD. TODO: normalize upstream

    if (saleStatus === 'sold') {
      newQuery.sold_at = {
        type: 'exists',
        values: [true],
      }
    } else {
      const mappedValues = {
        onmarket: ['OPEN', 'BIDDING', 'UPCOMING_SALE'],
        other: ['REMOVED_UNKNOWN', 'REMOVED_BEFORE_VIEWING'],
      }[saleStatus]

      newQuery.sale_page_status = {
        type: 'term',
        values: mappedValues,
      }
    }
  }

  if (newQuery.date) {
    newQuery[dateFieldName] = {
      ...newQuery.date,
    }
    delete newQuery.date
  }

  return newQuery
}

export const generateDslQuery = ({ parametersTyped, fieldsIgnore = [] }) => {
  const dateFieldName =
    parametersTyped.sale_status?.values?.[0] === 'sold'
      ? 'sold_at'
      : 'publication_date'

  parametersTyped = queryTransformCustom(parametersTyped, dateFieldName)

  const getFieldNamesOfType = (type) =>
    Object.keys(parametersTyped)
      .filter((fieldName) => !_.includes(fieldsIgnore, fieldName))
      .filter((fieldName) => parametersTyped[fieldName].type === type)

  const filter = []
  const must = []
  const should = []

  getFieldNamesOfType('term').forEach((fieldName) => {
    const { values } = parametersTyped[fieldName]
    if (values.length > 0) {
      filter.push({
        terms: { [fieldName]: values },
      })
    }
  })

  getFieldNamesOfType('range').forEach((fieldName) => {
    const { values } = parametersTyped[fieldName]
    const bounds = {}
    if (fieldName === 'sold_at' || fieldName === 'publication_date') {
      if (values[0] !== -1) bounds.lte = moment().subtract(values[0], 'months')
      if (values[1] !== -1) bounds.gte = moment().subtract(values[1], 'months')
    } else {
      if (values[0] !== -1) bounds.gte = values[0]
      if (values[1] !== -1) bounds.lte = values[1]
    }
    if (Object.keys(bounds).length > 0) {
      filter.push({ range: { [fieldName]: bounds } })
    }
  })

  getFieldNamesOfType('prefix-either').forEach((fieldName) => {
    const { values } = parametersTyped[fieldName]
    const { elasticFields } = FILTER_CONFIG[fieldName]
    if (values.length > 0) {
      filter.push({
        bool: {
          should: elasticFields
            .map((elasticField) =>
              values.map((value) => ({
                prefix: { [elasticField]: { value, case_insensitive: true } },
              }))
            )
            .flat(),
          minimum_should_match: 1,
        },
      })
    }
  })

  getFieldNamesOfType('match').forEach((fieldName) => {
    const { values } = parametersTyped[fieldName]
    if (values.length > 0) {
      filter.push({
        bool: {
          should: values.map((value) => ({
            match: { [fieldName]: { query: value, fuzziness: 'AUTO' } },
          })),
          minimum_should_match: 1,
        },
      })
    }
  })

  if (parametersTyped.bounds) {
    const [lat1, lon1, lat2, lon2] = parametersTyped.bounds.values
    if (lat1 && lon1 && lat2 && lon2) {
      filter.push({
        geo_shape: {
          location: {
            shape: {
              type: 'envelope',
              coordinates: [
                [lon1, lat1],
                [lon2, lat2],
              ],
            },
            relation: 'within',
          },
        },
      })
    }
  }

  filter.push({
    bool: {
      should: [
        {
          exists: {
            field: dateFieldName,
          },
        },
      ],
      minimum_should_match: 1,
    },
  })

  return {
    query: {
      bool: {
        filter: filter,
        must: must,
        should: should,
      },
    },
    size: parametersTyped?.number_search_results?.values[0] ?? 500,
    sort: [{ [dateFieldName]: { order: 'desc' } }],
  }
}
