import { Delete, FileCopy } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useState } from 'react'
import useDrivePicker from 'react-google-drive-picker'

import config from '../../../config'
import { getServices } from '../../../ServicesStoreProvider'

const MAX_GDRIVE_IMAGES = 15

export const AddImagesDialog = ({
  open,
  onClose,
  homeId,
  canSyncFromListingId,
}) => {
  const [sourceType, setSourceType] = useState('1')
  const [selectedImagesMetadata, setSelectedImagesMetadata] = useState([])
  const [syncEventsCreated, setSyncEventsCreated] = useState(false)
  const [openPicker] = useDrivePicker()

  const handleOpenPicker = useCallback(() => {
    openPicker({
      clientId: config.REACT_APP_MARKETPLACE_OAUTH_CLIENT_ID,
      developerKey: config.REACT_APP_MARKETPLACE_GPICKER_API_KEY,
      appId: config.REACT_APP_FIREBASE_PROJECT_ID,
      disableDefaultView: true,
      customScopes: [
        'https://www.googleapis.com/auth/drive.file',
        'https://www.googleapis.com/auth/drive.readonly',
      ],
      setParentFolder: config.REACT_APP_MARKETPLACE_GDRIVE_FOLDER_ID,
      supportDrives: true,
      multiselect: true,
      setIncludeFolders: true,
      customViews: [new google.picker.DocsView().setIncludeFolders(true)],
      callbackFunction: (data) => {
        if (data.action === 'picked') {
          const updated = [...selectedImagesMetadata, ...data.docs]
            // remove duplicates based on id
            .filter((metadata, index, self) => {
              return self.findIndex((m) => m.id === metadata.id) === index
            })
          setSelectedImagesMetadata(updated)
        }
      },
    })
  }, [openPicker, selectedImagesMetadata, setSelectedImagesMetadata])

  const onSyncEventsCreated = useCallback((response) => {
    console.log('onSyncEventsCreated', response)
    setSyncEventsCreated(true)
    setSelectedImagesMetadata([])
    setTimeout(() => {
      window.location.reload()
    }, 10000)
  }, [])

  const onAddGDrive = useCallback(async () => {
    const fileIds = selectedImagesMetadata.map((metadata) => metadata.id)
    const response = await getServices().sabiApi.syncMarketplaceGDriveFiles(
      homeId,
      fileIds
    )
    onSyncEventsCreated(response)
  }, [selectedImagesMetadata, homeId, onSyncEventsCreated])

  const onAddHemnetListingID = useCallback(async () => {
    const response = await getServices().sabiApi.syncMarketplaceHemnetListing(
      homeId
    )
    onSyncEventsCreated(response)
  }, [homeId, onSyncEventsCreated])

  return (
    <Dialog
      open={open}
      onClose={syncEventsCreated ? null : onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      // MUI dialog z-index is 1300, picker z-index is 1000
      // so we need to set the dialog z-index to be lower
      // than the picker z-index
      sx={{ zIndex: 999 }}
    >
      <DialogTitle id="form-dialog-title">Add Images</DialogTitle>
      {syncEventsCreated ? (
        <DialogContent>
          <Alert severity="success" icon={<CircularProgress />}>
            Images are being copied over in the background. This page will
            reload in a few seconds, please wait.
          </Alert>
        </DialogContent>
      ) : (
        <DialogContent>
          <TabContext value={sourceType}>
            <TabList onChange={(_, value) => setSourceType(value)}>
              <Tab label="Hemnet listing" value="1" />
              <Tab label="Google Drive" value="2" />
            </TabList>
            <TabPanel value="1">
              <Typography variant="body2" sx={{ mb: 2 }}>
                You can automatically import images from a Hemnet listing that
                we have already scraped. To do that, please enter a listing ID
                in the form and save the form.
              </Typography>
              {!canSyncFromListingId ? (
                <Alert severity="warning" sx={{ mb: 2 }}>
                  Please enter a Hemnet listing ID in the form and{' '}
                  <em>save the form</em> before you can sync images from a
                  Hemnet listing.
                  <br />
                  <br />
                  It can take several hours for the listing to be available in
                  our database after the listing is published on Hemnet.
                </Alert>
              ) : null}
              <Box>
                <Button
                  variant="contained"
                  onClick={onAddHemnetListingID}
                  disabled={!canSyncFromListingId}
                >
                  Add
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Alert severity="warning" sx={{ mb: 2 }}>
                You can only select images from Movesta's Google Drive.
              </Alert>
              <List>
                {selectedImagesMetadata.map((image) => (
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          setSelectedImagesMetadata(
                            selectedImagesMetadata.filter(
                              (metadata) => metadata.id !== image.id
                            )
                          )
                        }
                      >
                        <Delete />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FileCopy />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={image.name} />
                  </ListItem>
                ))}
              </List>
              <Box>
                {selectedImagesMetadata.length > MAX_GDRIVE_IMAGES && (
                  <Alert severity="error">
                    You can only select up to {MAX_GDRIVE_IMAGES} images.
                  </Alert>
                )}
                <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                  <Button variant="outlined" onClick={handleOpenPicker}>
                    Select files
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddGDrive}
                    disabled={
                      selectedImagesMetadata.length === 0 ||
                      selectedImagesMetadata.length > MAX_GDRIVE_IMAGES
                    }
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={syncEventsCreated}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
