import { FormControl, InputLabel, Slider, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

export default function ScalarFilter({
  label,
  value,
  setValue,
  minValue,
  maxValue,
  step,
  unit,
  sx,
  includeMaxValue,
}) {
  const [displayValue, setDisplayValue] = useState(value)

  const commitValues = useCallback(() => {
    setValue(
      displayValue <= minValue
        ? -1
        : displayValue > maxValue && includeMaxValue
        ? -1
        : displayValue >= maxValue && !includeMaxValue
        ? -1
        : displayValue
    )
  }, [setValue, displayValue, minValue, maxValue, includeMaxValue])

  useEffect(() => {
    setDisplayValue(value)
  }, [value])

  const rangeLabel = getRangeLabel(
    displayValue,
    minValue,
    maxValue,
    unit,
    includeMaxValue
  )

  return (
    <FormControl
      sx={{
        ...sx,
        px: 3,
        py: 1,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey.400',
        borderRadius: 1,
      }}
    >
      <Slider
        min={minValue}
        step={step}
        max={maxValue}
        value={displayValue}
        onChange={(e) => setDisplayValue(e.target.value)}
        onChangeCommitted={() => commitValues()}
        disableSwap
      />
      <InputLabel sx={{ mt: 2.75 }}>
        <Typography sx={{ fontSize: '0.8em' }} component="span">
          {label} {rangeLabel}
        </Typography>
      </InputLabel>
    </FormControl>
  )
}

const getRangeLabel = (value, minValue, maxValue, unit, includeMaxValue) => {
  const none =
    value <= minValue ||
    (value > maxValue && includeMaxValue) ||
    (value >= maxValue && !includeMaxValue)

  return none ? (
    ''
  ) : (
    <Typography
      key={1}
      sx={{ fontWeight: 'bold', ml: 1.5, fontSize: '1em' }}
      component="span"
    >
      {` ${value} ${unit}`}
    </Typography>
  )
}
