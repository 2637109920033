import { Box } from '@mui/system'
import { useEffect, useRef, useState } from 'react'

export const RemainingHeightContainer = ({
  spacingBottom = 10,
  children,
  ...props
}) => {
  const [height, setHeight] = useState(null)
  const ref = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      const offsetTop = ref.current?.getBoundingClientRect().top
      const visibleHeight = window.innerHeight
      setHeight(visibleHeight - offsetTop - spacingBottom)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [spacingBottom])

  const { sx: boxSx, ...boxProps } = props
  const sx = { ...boxSx, height: `${height}px` }

  return (
    <Box ref={ref} {...boxProps} sx={sx}>
      {height && children(height)}
    </Box>
  )
}
