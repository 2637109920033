import moment from 'moment'

// decided by jsonable_encoder in backend
const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'

export function toTimestampString(ts) {
  const tsMoment = moment(ts)
  return tsMoment.utc().format(TIMESTAMP_FORMAT)
}

export function nowString() {
  return toTimestampString(moment())
}
