import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import { Link, List, ListItem, ListItemText } from '@mui/material'
import { Box } from '@mui/system'

export default function Documents({ documents, legacyBucketUrl }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box mr={3}>
        <DocumentScannerIcon fontSize="large" color="primary" />
      </Box>
      <Box sx={{ flexGrow: 1, mt: -1 }}>
        <List>
          {documents.map((doc) => (
            <ListItem
              component={Link}
              key={document.url}
              href={doc.url}
              target="_blank"
              rel="noreferrer noopener"
              disablePadding
            >
              <ListItemText>{doc.name}</ListItemText>
            </ListItem>
          ))}
          {documents.length === 0 && (
            <ListItem key="no-documents" disablePadding>
              <ListItemText>
                No indexed documents found for this item.
              </ListItemText>
            </ListItem>
          )}
          {legacyBucketUrl && (
            <ListItem
              component={Link}
              key="bucket-link"
              href={legacyBucketUrl}
              target="_blank"
              rel="noreferrer noopener"
              disablePadding
            >
              <ListItemText>
                Open listing bucket in Google Cloud Console
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  )
}
