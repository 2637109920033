import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useQuery } from '../../clients/routerUseQuery'
import { importListingToHubspot, selectLoading } from './listingsSlice'

export const ImportListingToHubspot = (props) => {
  const { listingId, index } = useQuery()
  const loading = useSelector(selectLoading)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(importListingToHubspot({ listingId, index }))
  }, [dispatch, listingId, index])

  return <pre>{JSON.stringify(loading, null, 2)}</pre>
}
