import FileSaver from 'file-saver'
import JSZip from 'jszip'

export async function downloadImagesAsArchive({
  images,
  fileName,
  setStatusMessage,
}) {
  const archive = new JSZip()
  setStatusMessage('Initializing')

  let counter = 0
  const setStatusPerFile = () => {
    counter += 1
    setStatusMessage(`Downloading file ${counter}/${images.length}`)
  }

  images.forEach((src, index) => {
    archive.file(`image-${index}.jpg`, urlToPromise(src, setStatusPerFile), {
      base64: true,
    })
  })

  return archive
    .generateAsync({
      type: 'blob',
    })
    .then((blob) => {
      setStatusMessage('Creating archive')
      FileSaver.saveAs(blob, `${fileName}.zip`)
    })
}

const urlToPromise = (url, setStatusMessage) => {
  return new Promise(function (resolve, reject) {
    const img = new Image()
    img.crossOrigin = 'use-credentials'
    img.addEventListener(
      'load',
      () => {
        resolve(getBase64Image(img))
        setStatusMessage()
      },
      false
    )
    // chrome bug related to caching
    // https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
    const sep = url.indexOf('?') === -1 ? '?' : '&'
    img.src = url + sep + 'not-from-cache-please'
  })
}

const getBase64Image = (img) => {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  canvas.getContext('2d').drawImage(img, 0, 0)

  const dataURL = canvas.toDataURL('image/png')
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
}
