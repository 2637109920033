import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

import { authSelector } from '../auth/authSlice'

const collectionData = 'renovation_estimate'
const collectionConfig = 'renovation_estimate_config'

export const renovationEstimateConfigConnector = () => [
  { collection: collectionConfig },
]

export const renovationEstimateConfigSelector = createSelector(
  (state) => state.firestore.ordered[collectionConfig],
  (renovationEstimateConfig) => {
    if (!renovationEstimateConfig) return renovationEstimateConfig
    // map list items with id to object with id as keys
    const obj = {}
    renovationEstimateConfig.forEach((item) => {
      obj[item.id] = item
    })
    return obj
  }
)

export const createRenovationEstimate = createAsyncThunk(
  'renovation_estimate/create',
  async ({ renovationEstimate, shouldRedirect }, thunkAPI) => {
    const state = thunkAPI.getState()
    const auth = authSelector(state)
    const { id, ...props } = renovationEstimate

    if (!id) {
      console.error('no id for renovation')
      return
    }

    const renovationEstimateDoc = {
      id,
      ...props,
      created_at: renovationEstimate.created_at || moment().toISOString(),
      created_by: renovationEstimate.created_by || auth.uid,
      updated_at: moment().toISOString(),
      updated_by: auth.uid,
    }

    const response = await thunkAPI
      .extra()
      .getFirestore()
      .collection(collectionData)
      .doc(id)
      .set(renovationEstimateDoc)

    if (shouldRedirect) {
      thunkAPI.extra().history.push(`/renovation-estimate/${id}`)
    }

    return response
  }
)

export const renovationEstimateByIdConnector = (renovationEstimateId) => () =>
  [
    {
      collection: collectionData,
      doc: renovationEstimateId,
    },
  ]

export const renovationEstimateByIdSelector = createSelector(
  ({ firestore: { data } }, renovationEstimateId) => {
    return data[collectionData] && data[collectionData][renovationEstimateId]
  },
  (renovation) => renovation
)
