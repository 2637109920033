import { Alert, AlertTitle, LinearProgress, Typography } from '@mui/material'
import _ from 'lodash'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'

import { RenovationsEstimateForm } from './components/RenovationEstimateForm.js'
import {
  renovationEstimateByIdConnector,
  renovationEstimateByIdSelector,
  renovationEstimateConfigConnector,
  renovationEstimateConfigSelector,
} from './renovationEstimateSelectors.js'

export default function RenovationEstimatePage({ match }) {
  const { params } = match
  const renovationEstimateId = params.renovationEstimateId

  useFirestoreConnect(renovationEstimateConfigConnector())
  const renovationEstimateConfig = useSelector(renovationEstimateConfigSelector)

  useFirestoreConnect(renovationEstimateByIdConnector(renovationEstimateId))
  const renovationEstimate = useSelector((state) =>
    renovationEstimateByIdSelector(state, renovationEstimateId)
  )
  console.log('Renovation estimate loaded', renovationEstimate)

  const configNotDeprecated = useMemo(() => {
    return _.pickBy(renovationEstimateConfig, (r) => !r.deprecated)
  }, [renovationEstimateConfig])

  if (!isLoaded(renovationEstimateConfig)) {
    return <LinearProgress />
  } else if (_.isEmpty(renovationEstimate)) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>Renovation estimate not found.
      </Alert>
    )
  }

  return (
    <>
      <Helmet>
        <title>Renovation Estimator</title>
      </Helmet>
      <Typography variant="h2" sx={{ mb: 3 }}>
        Renovation Estimator
      </Typography>
      <RenovationsEstimateForm
        renovationEstimate={renovationEstimate}
        renovationEstimateConfig={renovationEstimateConfig}
        configNotDeprecated={configNotDeprecated}
      />
    </>
  )
}
