import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

import { useAuth0 } from '@auth0/auth0-react'
import firebase from 'firebase/app'
import React from 'react'
import { Provider } from 'react-redux'
import { getFirebase, ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance, getFirestore } from 'redux-firestore'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import createStore from './app/store'
import createImageProxyApi from './clients/imageProxyApi'
import createMetricsApi from './clients/metricsApi'
import createSabiApi from './clients/sabiApi'
import config from './config'

// using the extraArgument for thunk we provide a service registry for
// our api request action generators

let services

export const getServices = () => {
  return services
}

const instantiateServices = (auth0, history) => {
  console.log('instantiateServices called (ServicesStoreProvider rendered)')

  services = {
    auth0,
    config,
    history,
    getFirebase,
    getFirestore,
    imageProxyApi: createImageProxyApi(config, auth0.getAccessTokenSilently),
    metricsApi: createMetricsApi(config, auth0.getAccessTokenSilently),
    sabiApi: createSabiApi(config, auth0.getAccessTokenSilently),
  }
}

const store = createStore(getServices)

let persistor = persistStore(store)

const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: `${config.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: `${config.FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.firestore()

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
    updateProfileOnLogin: true,
    enableClaims: true,
    mergeOrderedCollectionUpdates: false,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const ServicesStoreProvider = ({ history }) => {
  const auth0 = useAuth0()
  instantiateServices(auth0, history)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <App history={history} />
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  )
}

export default ServicesStoreProvider
