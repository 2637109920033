import { Circle } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React from 'react'

import { getColorForInteger } from '../utils/colors'

export const ColoredIntegerChip = ({ value }) => {
  const v = parseInt(value)
  return Number.isInteger(v) ? (
    <Typography sx={{ width: '90px', fontSize: 'inherit' }}>
      <Circle
        sx={{
          color: getColorForInteger(v),
          verticalAlign: 'baseline',
          fontSize: '1em',
          position: 'relative',
          top: '2px',
          mr: 1,
        }}
      />
      {v}
    </Typography>
  ) : (
    value
  )
}
