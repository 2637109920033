import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'

import { getFirebaseToken } from './authSlice'

export const withFirebaseTokenRequired = (Component) => {
  const WithFirebaseTokenRequired = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.firebase.auth)

    const isAuthenticated = isLoaded(auth) && !isEmpty(auth)

    useEffect(() => {
      isAuthenticated || dispatch(getFirebaseToken())
    }, [dispatch, isAuthenticated])

    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <pre>loading firebase token...</pre>
    )
  }

  return withAuthenticationRequired(WithFirebaseTokenRequired)
}
