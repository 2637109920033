import { createTheme } from '@mui/material/styles'

const palette = {
  primary: {
    main: '#2d7a72',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#153450',
  },
  highlight: {
    main: 'rgb(188, 118, 69)',
    light: 'rgba(188, 118, 69, 0.2)',
    contrastText: '#ffffff',
  },
  tertiary: {
    main: 'rgb(180, 199, 209, 0.6)',
  },
  overlay: {
    main: 'rgba(248, 247, 244, 0.5)',
  },
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 770,
    lg: 1200,
    xl: 1536,
  },
}

export const theme = createTheme({
  palette,
  breakpoints,
  typography: {
    fontSize: 16,
    fontFamily: 'Montserrat, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '14px',
        },
        body: {
          fontSize: '14px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    // make h1-h6 tags use the same font as the rest of the app
    // and smaller
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '3.5rem',
          marginBottom: '1.5rem',
        },
        h2: {
          fontSize: '3rem',
          marginBottom: '1rem',
        },
        h3: {
          fontSize: '2.5rem',
          marginBottom: '0.5rem',
        },
        h4: {
          fontSize: '2.25rem',
          marginBottom: '0.5rem',
        },
        h5: {
          fontSize: '2rem',
          marginBottom: '0.5rem',
        },
        h6: {
          fontSize: '1.5rem',
          marginBottom: '0.5rem',
        },
      },
    },
  },
})
