import { HelpOutlineRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import { ColoredIntegerChip } from '../../components/ColoredIntegerChip'
import { HoverPreviewImage } from '../../components/HoverPreviewImage'
import { TagsListV2ReadOnly } from '../../components/tags/TagsListV2'
import {
  formatArea,
  formatDate,
  formatSEK,
  formatSEKPerArea,
} from '../../utils/formatUtils'
import { EmojiPercentageChip } from './components/EmojiPercentageChip'

function columnLabelWithTooltip(label, tooltip) {
  return (
    <>
      {label}{' '}
      <Tooltip title={tooltip} arrow placement="top">
        <HelpOutlineRounded sx={{ fontSize: '1em' }} />
      </Tooltip>
    </>
  )
}

export const tableColumnsConfigDefault = [
  {
    name: '__id',
    label: 'Listing ID',
    options: {
      customBodyRender: (v) => <ColoredIntegerChip value={v} />,
    },
  },
  {
    name: '__weight',
    label: 'Weight',
  },
  {
    name: '__adjustment_norm',
    label: columnLabelWithTooltip(
      'Adjustment (norm)',
      'adjustment factor, normalized HOX/sqm price'
    ),
  },
  {
    name: '__adjustment_denorm',
    label: columnLabelWithTooltip(
      'Adjustment (denorm)',
      'adjustment factor, denormalized sold price'
    ),
  },
  {
    name: 'image',
    label: 'Preview image',
    options: {
      sort: false,
      customBodyRender: (v) => v && <HoverPreviewImage src={v} />,
    },
  },
  {
    name: 'housing_type_main',
    label: 'Housing type',
  },
  { name: 'address_formatted', label: 'Address' },
  {
    name: 'sold_price',
    label: 'ACTUAL sold price',
    options: { customBodyRender: (v) => v && formatSEK(v) },
  },
  {
    name: 'y_pred',
    label: 'PREDICTED sold price',
    options: {
      customBodyRender: (v) => v && formatSEK(v),
    },
  },
  {
    name: 'absolute_percentage_error',
    label: 'Absolute percentage error',
    options: {
      customBodyRender: (v) =>
        v && <EmojiPercentageChip value={v} start={0.0} end={0.25} />,
    },
  },
  {
    name: 'price_per_m2',
    label: 'ACTUAL price/m²',
    options: { customBodyRender: (v) => v && formatSEKPerArea(v) },
  },
  {
    name: 'sold_at',
    label: 'Sale date',
    options: { customBodyRender: formatDate },
  },
  {
    name: 'living_area',
    label: 'Living area',
    options: { customBodyRender: (v) => v && formatArea(v) },
  },
  {
    name: 'no_rooms',
    label: 'Rooms',
    options: { customBodyRender: (v) => v },
  },
  {
    name: 'plot_size',
    label: 'Plot',
    options: { customBodyRender: (v) => v && formatArea(v) },
  },
  {
    name: 'tags',
    label: 'Tags',
    options: {
      customBodyRender: (v) => (
        // columnName is a hack to identify the cell on click
        <Box columnName="tags">
          <TagsListV2ReadOnly tags={v} />
        </Box>
      ),
    },
  },
  {
    name: 'sold_price_hox_target',
    label: columnLabelWithTooltip(
      'HOXed sold price',
      "actual sold price, normalized via target's HOX"
    ),
    options: {
      customBodyRender: (v) => v && formatSEK(v),
      display: false,
    },
  },
  {
    name: 'sold_price_per_m2_hox_target',
    label: columnLabelWithTooltip(
      'HOXed sold price/m²',
      "actual sold price, normalized via target's HOX"
    ),
    options: {
      customBodyRender: (v) => v && formatSEKPerArea(v),
      display: false,
    },
  },
  {
    name: 'lower',
    label: 'PREDICTED sold price (lower)',
    options: {
      customBodyRender: (v) => v && formatSEK(v),
      display: false,
    },
  },
  {
    name: 'upper',
    label: 'PREDICTED sold price (upper)',
    options: {
      customBodyRender: (v) => v && formatSEK(v),
      display: false,
    },
  },
]

export const tableColumnsDefaultVisible = tableColumnsConfigDefault
  .filter((c) => c?.options?.display !== false)
  .map((c) => c.name)

export const getColumnsConfig = (availableTableColumns, columnsVisible) => {
  const availableTableColumnsSorted = [...availableTableColumns].sort()

  const allColumns = [
    ...(columnsVisible || []),
    ...tableColumnsDefaultVisible,
    ...availableTableColumnsSorted,
  ].filter(
    (v, i, a) => a.indexOf(v) === i // unique
  )

  const columnsConfig = allColumns.map((c) => {
    const config = tableColumnsConfigDefault.find((c2) => c2.name === c)
    return {
      name: c,
      label: config?.label ?? c,
      options: {
        ...config?.options,
        display: columnsVisible.includes(c),
      },
    }
  })

  return columnsConfig
}
