import React from 'react'
import { FormSpy } from 'react-final-form'

// taken from react final form example
class AutoSave extends React.Component {
  constructor(props) {
    super(props)
    this.state = { values: props.values, submitting: false }
  }

  componentWillReceiveProps(nextProps) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(this.save, this.props.debounce)
  }

  save = async () => {
    if (this.promise) {
      await this.promise
    }
    const { values, save } = this.props

    if (JSON.stringify(values) !== JSON.stringify(this.state.values)) {
      this.setState({ submitting: true, values })
      this.promise = save(values)
      await this.promise
      delete this.promise
      this.setState({ submitting: false })
    }
  }

  render() {
    // could display a spinner here
    return <></>
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
export default function FinalFormAutoSave(props) {
  return (
    <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
  )
}
