import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { configSelector } from '../../configSlice'
import Listing from '../../data_models/Listing'

export const getListing = createAsyncThunk(
  'listing/get',
  async ({ listingId, index }, thunkAPI) => {
    const request = {
      query: {
        ids: {
          values: [listingId],
        },
      },
      size: 1,
    }
    const response = await thunkAPI.extra().sabiApi.elasticSearch({
      request,
      index,
    })

    try {
      thunkAPI.dispatch(getBrokerPagesForListing({ listingId }))
    } catch (e) {
      console.error(e)
    }

    return response.data
  }
)

export const getListings = createAsyncThunk(
  'listing/getMultiple',
  async ({ listingIds, index }, thunkAPI) => {
    const request = {
      query: {
        ids: {
          values: listingIds,
        },
      },
    }
    const response = await thunkAPI.extra().sabiApi.elasticSearch({
      request,
      index,
    })

    listingIds.forEach((listingId) => {
      try {
        thunkAPI.dispatch(getBrokerPagesForListing({ listingId }))
      } catch (e) {
        console.error(e)
      }
    })

    return response.data
  }
)

export const getListingViaHemnetId = createAsyncThunk(
  'listing/viaHemnetId',
  async ({ hemnetId, index }, thunkAPI) => {
    const request = {
      query: {
        bool: {
          should: [
            { term: { sale_web_id: hemnetId } },
            { term: { sold_web_id: hemnetId } },
          ],
          minimum_should_match: 1,
        },
      },
    }
    const response = await thunkAPI.extra().sabiApi.elasticSearch({
      request,
      index,
    })
    return response.data
  }
)

export const importListingToHubspot = createAsyncThunk(
  'listing/importToHubspot',
  async ({ listingId, index }, thunkAPI) => {
    const response = await thunkAPI
      .extra()
      .sabiApi.importListingToHubspot(listingId, index)
    if (typeof window !== 'undefined') {
      window.open(response.data.url, '_blank')
    }
    return response.data
  }
)

export const hubspotDealFromListing = createAsyncThunk(
  'listing/hubspotDealFromListing',
  async ({ listingId, index }, thunkAPI) => {
    const response = await thunkAPI
      .extra()
      .sabiApi.hubspotDealFromListing(listingId, index)
    return response.data
  }
)

export const getSearchResults = createAsyncThunk(
  'search/getSearchResults',
  async ({ query, sort, index, size }, thunkAPI) => {
    const response = await thunkAPI.extra().sabiApi.elasticSearch({
      request: {
        query,
        size,
        sort,
      },
      index,
    })
    return response.data
  }
)

export const getBrokerPagesForListing = createAsyncThunk(
  'listing/getBrokerPagesForListing',
  async ({ listingId }, thunkAPI) => {
    const response = await thunkAPI
      .extra()
      .sabiApi.getBrokerPagesForListing({ listingId })
    return response.data
  }
)

const listingsAdapter = createEntityAdapter({
  selectId: (listing) => listing['id'],
  // sortComparer: (a, b) => a.localeCompare(b),
})

const brokerPagesByListingIdAdapter = createEntityAdapter({
  selectId: (brokerPagesList) => brokerPagesList['listing_id'],
})

const listingsSlice = createSlice({
  name: 'listings',
  initialState: {
    listings: listingsAdapter.getInitialState(),
    loading: 'pending',
    currentSearch: {
      hits: [],
      requestId: null,
      listingViaHemnetId: null,
    },
    brokerPagesByListingId: brokerPagesByListingIdAdapter.getInitialState(),
    hubspotDealInfos: {},
    hubspotDealInfoLoading: {},
    searchParameters: null,
  },
  reducers: {
    setSearchParameters: (state, action) => {
      state.searchParameters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListing.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(getListing.fulfilled, (state, action) => {
        const hit = action.payload.hits.hits.map((hit) => ({
          id: hit._id,
          ...hit._source,
        }))[0]
        if (!hit) {
          console.error('Listing does not exist')
          return { ...state, loading: 'rejected' }
        }
        state.loading = 'idle'
        listingsAdapter.upsertOne(state.listings, hit)
      })
      .addCase(getListing.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))

      .addCase(getListings.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(getListings.fulfilled, (state, action) => {
        const hits = action.payload.hits.hits.map((hit) => ({
          id: hit._id,
          ...hit._source,
        }))
        listingsAdapter.upsertMany(state.listings, hits)
        state.loading = 'idle'
      })
      .addCase(getListings.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
      .addCase(getListingViaHemnetId.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(getListingViaHemnetId.fulfilled, (state, action) => ({
        ...state,
        listingViaHemnetId:
          action.payload.hits.hits.length === 1 &&
          action.payload.hits.hits[0]._id,
        loading: 'idle',
      }))
      .addCase(getListingViaHemnetId.rejected, (state, action) => ({
        ...state,
        listingViaHemnetId: null,
        loading: { status: 'rejected', payload: action },
      }))
      .addCase(getBrokerPagesForListing.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(getBrokerPagesForListing.fulfilled, (state, action) => {
        brokerPagesByListingIdAdapter.upsertOne(
          state.brokerPagesByListingId,
          action.payload
        )
        state.loading = 'idle'
      })
      .addCase(getBrokerPagesForListing.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
      .addCase(importListingToHubspot.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(importListingToHubspot.fulfilled, (state, action) => ({
        ...state,
        loading: 'idle',
        hubspotDealInfos: {
          ...state.hubspotDealInfos,
          [action.meta.arg.listingId]: action.payload,
        },
      }))
      .addCase(importListingToHubspot.rejected, (state, action) => {
        const listingId = action.meta.arg.listingId
        const updated = { ...state.hubspotDealInfos }
        delete updated[listingId]

        return {
          ...state,
          loading: 'idle',
          hubspotDealInfos: updated,
        }
      })
      .addCase(hubspotDealFromListing.pending, (state, action) => ({
        ...state,
        hubspotDealInfoLoading: {
          ...state.hubspotDealInfoLoading,
          [action.meta.arg.listingId]: 'pending',
        },
      }))
      .addCase(hubspotDealFromListing.fulfilled, (state, action) => ({
        ...state,
        hubspotDealInfoLoading: {
          ...state.hubspotDealInfoLoading,
          [action.meta.arg.listingId]: 'idle',
        },
        hubspotDealInfos: {
          ...state.hubspotDealInfos,
          [action.meta.arg.listingId]: action.payload,
        },
      }))
      .addCase(hubspotDealFromListing.rejected, (state, action) => {
        const listingId = action.meta.arg.listingId
        const updated = { ...state.hubspotDealInfos }
        delete updated[listingId]

        return {
          ...state,
          loading: 'idle',
          hubspotDealInfos: updated,
          hubspotDealInfoLoading: {
            ...state.hubspotDealInfoLoading,
            [action.meta.arg.listingId]: 'idle',
          },
        }
      })
      .addCase(getSearchResults.fulfilled, (state, action) => {
        // handle multiple dispatched searches:
        // only accept ultimate request
        if (state.currentSearch.requestId !== action.meta.requestId) {
          return
        }

        const hits = action.payload.hits.hits.map((hit) => ({
          id: hit._id,
          ...hit._source,
        }))
        listingsAdapter.upsertMany(state.listings, hits)
        state.currentSearch.hits = action.payload.hits.hits.map((hit) => ({
          id: hit._id,
          _score: hit._score,
          _explanation: hit._explanation,
        }))
        state.loading = 'idle'
      })
      .addCase(getSearchResults.pending, (state, action) => {
        state.currentSearch.requestId = action.meta.requestId
        state.loading = 'pending'
      })
      .addCase(getSearchResults.rejected, (state, action) => {
        state.currentSearch.hits = []
        state.currentSearch.requestId = null
        state.loading = { status: 'rejected', payload: action }
      })
  },
})

export const { setSearchParameters } = listingsSlice.actions

export const searchParametersSelector = (state) =>
  state.listings.searchParameters

export const isSearchQueryInitializedSelector = (state) =>
  state.listings.searchParameters !== null

const hubspotDealsSelector = (state, listingId) =>
  state.listings.hubspotDealInfos[listingId]
export const hubspotDealByListingIdSelector = createSelector(
  hubspotDealsSelector,
  (hubspotDealInfo) => hubspotDealInfo
)

const hubspotDealsLoadingSelector = (state, listingId) =>
  state.listings.hubspotDealInfoLoading[listingId]
export const hubspotDealByListingIdLoadingSelector = createSelector(
  hubspotDealsLoadingSelector,
  (hubspotDealInfo) => hubspotDealInfo === 'pending'
)

const selectListingViaHemnetId = (state) => state.listings.listingViaHemnetId
export const listingViaHemnetIdSelector = createSelector(
  selectListingViaHemnetId,
  (listing) => listing
)

const hitsSelector = (state) => state.listings.currentSearch.hits
const listingsSelector = (state) => state.listings.listings

const listingsSelectors = listingsAdapter.getSelectors(listingsSelector)

export const selectLoading = (state) => state.listings.loading

const rawListingByIdSelector = createSelector(
  listingsSelectors.selectById,
  (listingRaw) => listingRaw
)

export const listingByIdSelector = createSelector(
  configSelector,
  rawListingByIdSelector,
  (config, listingRaw) => listingRaw && new Listing(listingRaw, config)
)

const rawListingsByIdsSelector = createSelector(
  listingsSelectors.selectEntities,
  (listingsRaw) => listingsRaw
)

export const listingsByIdsSelector = createSelector(
  configSelector,
  rawListingsByIdsSelector,
  (config, listingsRaw) =>
    Object.fromEntries(
      Object.entries(listingsRaw).map(([id, listingRaw]) => [
        id,
        new Listing(listingRaw, config),
      ])
    )
)

const brokerPagesByListingIdSelectors =
  brokerPagesByListingIdAdapter.getSelectors(
    (state) => state.listings.brokerPagesByListingId
  )

export const brokerPagesByListingIdSelector = createSelector(
  brokerPagesByListingIdSelectors.selectById,
  (brokerPages) => brokerPages
)

export const selectSearchResult = createSelector(
  configSelector,
  listingsSelector,
  hitsSelector,
  (config, listings, hits) => {
    return (
      listings &&
      hits &&
      hits.map(
        (hit) =>
          new Listing(
            {
              ...listings.entities[hit.id],
              _score: hit._score,
              _explanation: hit._explanation,
            },
            config
          )
      )
    )
  }
)

export default listingsSlice.reducer
