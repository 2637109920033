import { Home, LaunchRounded } from '@mui/icons-material'
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import config from '../../../config'
import { listHomes } from '../redux/marketplaceActions'
import {
  homesLoadingSelector,
  homesSelector,
} from '../redux/marketplaceSelectors'

export const HomesList = ({ searchValue }) => {
  const filter = useMemo(
    () =>
      new RegExp(
        searchValue
          .trim()
          .split(' ')
          .map((s) => `^${s}`)
          .join('|'),
        'i'
      ),
    [searchValue]
  )
  const dispatch = useDispatch()
  const homesLoading = useSelector(homesLoadingSelector)
  const homes = useSelector(homesSelector)

  useEffect(() => {
    dispatch(listHomes())
  }, [dispatch])

  const BoldIfMatch = ({ test, children }) => {
    if (searchValue !== '' && filter.test(test)) return <b>{children}</b>
    return children
  }

  const score = (string) => Number(string !== undefined && filter.test(string))

  return (
    <>
      {homesLoading === 'fulfilled' && (
        <List
          sx={{
            width: '100%',
            maxWidth: 'lg',
            bgcolor: 'background.paper',
            maxHeight: '400px',
            overflowY: 'auto',
          }}
          dense
        >
          {homes
            ? homes
                .map((home) => ({
                  ...home,
                  searchScore:
                    score(home.postal_address.route_name) +
                    score(home.postal_address.postal_town) +
                    score(home.postal_address.street_number) +
                    score(home.project_number),
                }))
                .filter(({ searchScore }) => searchScore > 0)
                .sort(
                  (home1, home2) =>
                    home2.searchScore - home1.searchScore ||
                    home2.project_number - home1.project_number
                )
                .map((home) => (
                  <ListItem
                    secondaryAction={
                      <IconButton
                        LinkComponent={Link}
                        href={`${config.REACT_APP_APP_URL}/homes/${home.id}`}
                        target="_blank"
                      >
                        <LaunchRounded />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      component={RouterLink}
                      to={`/marketplace/${home.id}`}
                    >
                      <ListItemIcon>
                        <Home />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography>
                            <BoldIfMatch test={home.project_number}>
                              {home.project_number}
                            </BoldIfMatch>{' '}
                            -{' '}
                            <BoldIfMatch test={home.postal_address.route_name}>
                              {home.postal_address.route_name}
                            </BoldIfMatch>{' '}
                            <BoldIfMatch
                              test={home.postal_address.street_number}
                            >
                              {home.postal_address.street_number || ''}
                            </BoldIfMatch>
                            {', '}
                            <BoldIfMatch test={home.postal_address.postal_town}>
                              {home.postal_address.postal_town}
                            </BoldIfMatch>
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))
            : 'none'}
        </List>
      )}
      {homesLoading === 'pending' && (
        <Box sx={{ p: 3 }}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {homesLoading.status === 'rejected' && (
        <Alert severity="error">
          {homesLoading.payload.error && homesLoading.payload.error.message}
        </Alert>
      )}
    </>
  )
}
