import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import firebase from 'firebase'

export const allManualValuationsConnector = () => [
  { collection: 'manual_valuation' },
]

export const allManualValuationsSelector = createSelector(
  (state) => state.firestore.ordered.manual_valuation,
  (manualValuations) =>
    manualValuations?.filter((valuationRaw) => !valuationRaw.deleted)
)

export const multipleManualValuationsConnector = (ids) => [
  {
    collection: 'manual_valuation',
    where: [['id', 'in', [...ids, 0]]], // add 0 to avoid error when ids is empty
  },
]

export const multipleManualValuationsSelector = createSelector(
  ({ firestore }, valuationIds) => {
    return valuationIds.map(
      (valuationId) => firestore.data.manual_valuation?.[valuationId]
    )
  },
  (valuations) => valuations || []
)

export const manualValuationByIdConnector = (manualValuationId) => () => {
  return [
    {
      collection: 'manual_valuation',
      doc: manualValuationId,
    },
  ]
}

export const manualValuationByIdSelector = createSelector(
  ({ firestore: { data } }, manualValuationId) => {
    return data?.manual_valuation?.[manualValuationId]
  },
  (manualValuation) => manualValuation
)

export const manualValuationsByHomeIdConnector = (homeId) => () => {
  return [
    {
      collection: 'manual_valuation',
      where: [['home_id', '==', homeId]],
      storeAs: 'manual_valuation_by_home_id',
    },
  ]
}

export const manualValuationsByHomeIdSelector = createSelector(
  ({ firestore: { data } }, homeId) => {
    return (
      data?.manual_valuation_by_home_id &&
      Object.values(data?.manual_valuation_by_home_id)
        .filter((manualValuation) => manualValuation?.home_id === homeId)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    )
  },
  (manualValuations) => manualValuations
)

export const setManualValuationEstimate = createAsyncThunk(
  'manual-valuation/set-estimate',
  async ({ manualValuationId, values }, thunkAPI) => {
    const values_ = {
      low_95: values.low_95,
      low_80: values.low_80,
      expectation: values.expectation,
      high_80: values.high_80,
      high_95: values.high_95,
    }

    const firestore = thunkAPI.extra().getFirestore()
    return await firestore
      .collection('manual_valuation')
      .doc(manualValuationId)
      .update({
        valuation_estimate: values_,
      })
  }
)

export const addTagToManualValuation = createAsyncThunk(
  'manual-valuation/add-tag',
  async ({ manualValuationId, tag }, thunkAPI) => {
    const firestore = thunkAPI.extra().getFirestore()
    return await firestore
      .collection('manual_valuation')
      .doc(manualValuationId)
      .update({
        tags: firebase.firestore.FieldValue.arrayUnion(tag),
      })
  }
)

export const removeTagFromManualValuation = createAsyncThunk(
  'manual-valuation/remove-tag',
  async ({ manualValuationId, tag }, thunkAPI) => {
    const firestore = thunkAPI.extra().getFirestore()
    return await firestore
      .collection('manual_valuation')
      .doc(manualValuationId)
      .update({
        tags: firebase.firestore.FieldValue.arrayRemove(tag),
      })
  }
)
