import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import config from '../config'
import { getEnvironment } from './environment'

export function initSentry(history) {
  Sentry.init({
    dsn: config.REACT_APP_SENTRY_DSN,
    release: config.REACT_APP_VERSION,
    // adjust if we ever get a very high load in Sabi
    tracesSampleRate: 1.0,
    // don't set to high, otherwise we get a HTTP 413 error
    // (for large deeply nested objects) and the event is not logged
    normalizeDepth: 5,
    environment: getEnvironment(),
    enabled: config.REACT_APP_SENTRY_ENABLED === 'on',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
  })
}
