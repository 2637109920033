import {
  Alert,
  AlertTitle,
  Container,
  LinearProgress,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { FORM_ERROR } from 'final-form'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'

import { formatDateTime } from '../../../utils/formatUtils'
import { cast } from './components/FormFields'
import { OfferSourceInfo } from './components/OfferSourceInfo'
import { SGOfferForm } from './components/SGOfferForm'
import { commitSGOffer, deleteSGOffer, updateSGOffer } from './sgOfferActions'
import { sgOfferByIdConnector, sgOfferByIdSelector } from './sgOfferSelectors'

export const SGOfferPage = ({ match }) => {
  const dispatch = useDispatch()

  const offerId = match.params.id
  const createMode = offerId === 'create'

  useFirestoreConnect(sgOfferByIdConnector(offerId))
  const offer = useSelector((state) => sgOfferByIdSelector(state, offerId))

  const onSubmit = useCallback(
    (valuesUncasted, form) => {
      const values = cast(valuesUncasted, { stripUnknown: true })
      // as utc iso string
      values.expired_at = moment(values.expired_at).toISOString()
      const isCommitted = offer?.presented_at
      return dispatch(updateSGOffer({ offerId, values, isCommitted }))
        .unwrap()
        .then(() => {
          form.reset(values)
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error('submission error', rejectedValueOrSerializedError)
          return { [FORM_ERROR]: rejectedValueOrSerializedError }
        })
    },
    [dispatch, offerId, offer]
  )

  const onCommit = useCallback(
    () => dispatch(commitSGOffer({ offerId })).unwrap(),
    [dispatch, offerId]
  )

  console.log('offer', offer)

  const [initialValues, setInitialValues] = useState(null)
  useEffect(() => {
    if (!initialValues && isLoaded(offer)) {
      const { manual_valuation, lead, ...values } = offer
      setInitialValues({
        ...values,
        expired_at:
          values?.expired_at ?? moment().endOf('day').add(7, 'days').toDate(),
        market_risk_level:
          values?.market_risk_level ??
          'Påtaglig - Fortsatt negativ trend bedöms som trolig.',
        market_risk_comment:
          values?.market_risk_comment ??
          'Marknadsrisken bedöms som “påtaglig”. Detta beror främst på geopolitiska ' +
            'omständigheter och konjunkturläget kombinerat med genomförda och väntade ' +
            'räntehöjningar. Under perioden mars till december 2022 sjönk prisindex ' +
            'för bostäder i Sverige med 16,5% (!) och en fortsatt negativ trend ' +
            'bedöms som trolig.',
        commitment_status: values?.commitment_status ?? 'preliminary',
      })
    }
  }, [initialValues, offer])

  const title = createMode
    ? 'New sale guarantee offer'
    : offer?.title || 'Edit sale guarantee offer'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {!isLoaded(offer) ? (
        <LinearProgress />
      ) : (
        <Container sx={{ pb: 4 }}>
          <Typography variant="h1">{title}</Typography>
          {offer?.presented_at ? (
            <Alert severity="info" sx={{ mb: 3 }}>
              <AlertTitle>Offer presented</AlertTitle>
              This offer has been marked as presented, you can only add a subset
              of the fields.
              <br />({offer?.presented_by},{' '}
              {formatDateTime(offer?.presented_at)})
            </Alert>
          ) : null}
          {offer?.deleted_at ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              <AlertTitle>Offer deleted</AlertTitle>
              This offer has been deleted.
              <br />({offer?.deleted_by}, {formatDateTime(offer?.deleted_at)})
            </Alert>
          ) : (
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
              <SGOfferForm
                flex="0 0 400px"
                initialValues={initialValues}
                onSubmit={onSubmit}
                committed={offer?.presented_at}
                onCommit={onCommit}
                onDelete={() =>
                  dispatch(
                    deleteSGOffer({
                      offerId,
                      getRedirectUrl: () => '/offers/sale-guarantee',
                    })
                  )
                }
              />
              <OfferSourceInfo
                flex="1 1 auto"
                lead={offer.lead}
                manualValuation={offer.manual_valuation}
              />
            </Box>
          )}
        </Container>
      )}
    </>
  )
}
