import { Help } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

export const TooltipSimpleEstimate = () => (
  <Tooltip title="Takes the square meter price of the selected comparables, averages them and multiplies the average with the the target living area.">
    <Help
      sx={{
        fontSize: '16px',
        verticalAlign: 'baseline',
        color: 'gray',
      }}
    />
  </Tooltip>
)
