import {
  Add,
  DynamicFeedRounded,
  Sync,
  TaskAltRounded,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import { makeValidate } from 'mui-rff'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import FFSubmitError from '../../../components/FFSubmitErrors'
import { AddImagesDialog } from '../components/AddImagesDialog'
import { formComponentMapper } from '../components/FormComponent'
import { saveHome, syncWithSpreadsheet } from '../redux/marketplaceActions'
import fields from '../schema/fields'
import schema from '../schema/homeValidationSchema'
import SectionImages from './SectionImages'
import SubmitSection from './SubmitSection'

function EditForm({ mapRef, home }) {
  const homeId = home.id
  const dispatch = useDispatch()
  const initialValues = useMemo(() => mapHome(home), [home])
  const [isRearranging, setIsRearranging] = useState(false)
  const [showAddImagesDialog, setShowAddImagesDialog] = useState(false)

  const onSyncWithSpreadsheet = useCallback(() => {
    dispatch(syncWithSpreadsheet(homeId))
      .unwrap()
      .then(() => window.location.reload())
  }, [homeId, dispatch])

  return (
    <Form
      debug={(state) => console.debug('Form state', state)}
      onSubmit={(data) =>
        dispatch(saveHome({ homeId, data: formatForSave(data) }))
          .unwrap()
          .then(() => window.location.reload())
      }
      mutators={{
        ...arrayMutators,
        applySuggestion: ([key, value], state, utils) => {
          utils.changeValue(state, key, () => value)
        },
      }}
      initialValues={initialValues}
      validate={makeValidate(schema)}
      render={({ handleSubmit, submitErrors, errors, values, dirtyFields }) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate={true}>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 2,
              },
            })}
          >
            <Box sx={{ gridColumn: '1 / -1' }}>
              <FFSubmitError submitErrors={submitErrors} />
              <Field name="visibility">
                {({ input }) =>
                  input.value === 'private' ? (
                    <Alert severity="warning">
                      <AlertTitle>Home not visible</AlertTitle>
                      The visibility is set to "private" which means it can't be
                      seen anywhere except from here
                    </Alert>
                  ) : null
                }
              </Field>
            </Box>

            <Paper sx={{ p: 2 }}>
              <Typography variant="h5">About</Typography>
              <List>
                {Object.entries(fields.readOnly).map(([name, text]) => (
                  <Field name={name} key={name}>
                    {({ input }) => (
                      <ListItem disablePadding>
                        <ListItemText
                          primary={text}
                          secondary={
                            input.value
                              ? moment(input.value).format('YYYY-MM-DD')
                              : 'Unknown'
                          }
                        />
                      </ListItem>
                    )}
                  </Field>
                ))}
              </List>
              <Button
                variant="outlined"
                startIcon={<Sync />}
                onClick={onSyncWithSpreadsheet}
              >
                Load dates from spreadsheet
              </Button>
              <Box ref={mapRef} sx={{ height: '40vh', mb: 3 }} />
            </Paper>
            <Paper sx={{ p: 2 }}>
              <Stack direction="column" gap={2}>
                <Typography variant="h5">Info</Typography>
                {formComponentMapper(fields.editable)}
              </Stack>
            </Paper>
            <Paper
              sx={{
                p: 2,
                mb: 10,
                gridColumn: '1 / -1',
              }}
            >
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h4" mb={2}>
                  Images
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => setShowAddImagesDialog(true)}
                >
                  <Add /> Add images
                </IconButton>
              </Box>
              <Alert severity="info">
                After the home is sold, only the first 5 images will be shown on
                the marketplace.
              </Alert>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mb={2}
              >
                {values?.images?.length ? (
                  <Button
                    onClick={() => setIsRearranging(!isRearranging)}
                    endIcon={
                      isRearranging ? (
                        <TaskAltRounded />
                      ) : (
                        <DynamicFeedRounded />
                      )
                    }
                  >
                    {isRearranging ? 'Done' : 'Reorder images'}
                  </Button>
                ) : null}
              </Box>
              <SectionImages isRearranging={isRearranging} />
              <AddImagesDialog
                open={showAddImagesDialog}
                onClose={() => setShowAddImagesDialog(false)}
                homeId={homeId}
                canSyncFromListingId={
                  !!home.hemnet_listing_id && !dirtyFields.hemnet_listing_id
                }
              />
            </Paper>
          </Box>
          <SubmitSection
            homeId={homeId}
            errorFields={errors ? Object.keys(errors) : []}
          />
        </form>
      )}
    />
  )
}

EditForm.propTypes = {
  mapRef: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
}

export default EditForm

function mapHome(home) {
  if (!home) return null
  return {
    ...home,
    planned_date: home.planned_date
      ? moment(home.planned_date).toISOString()
      : null,
    available_images: home.available_image_ids.map((id) => ({
      src: home.image_id_to_url[id],
      id,
    })),
    images: home.image_ids.map((id) => ({
      src: home.image_id_to_url[id],
      id,
    })),
  }
}

function formatForSave({ images, planned_date, ...data }) {
  const plannedDate = moment(planned_date)

  return {
    ...data,
    available_images: undefined,
    image_id_to_url: {},
    available_image_ids: [],
    planned_date: plannedDate.isValid()
      ? plannedDate.format('YYYY-MM-DD')
      : null,
    image_ids: images.map(({ id }) => id),
  }
}
