import { createAsyncThunk } from '@reduxjs/toolkit'
import firebase from 'firebase'
import moment from 'moment'

import { getServices } from '../../ServicesStoreProvider'
import { avmRequestForHome } from '../avm/avmActions'

export const createManualValuation = createAsyncThunk(
  'manual-valuation/create',
  async function (
    { homeFeatures, title, getRedirectUrl, avmRequest = true },
    thunkAPI
  ) {
    const result = await getServices().sabiApi.createManualValuation({
      homeFeatures,
      title,
    })

    const { manual_valuation_id: manualValuationId, home_id: homeId } =
      result.data

    if (getRedirectUrl) {
      thunkAPI.extra().history.push(getRedirectUrl(manualValuationId))
    }

    if (avmRequest) {
      thunkAPI.dispatch(avmRequestForHome({ homeId }))
    }
  }
)

export const createManualValuationForLead = createAsyncThunk(
  'manual-valuation/create-for-lead',
  async function ({ leadId, title, getRedirectUrl }, thunkAPI) {
    const result = await getServices().sabiApi.createManualValuation({
      leadId,
      title,
    })

    const { manual_valuation_id: manualValuationId } = result.data

    if (getRedirectUrl) {
      thunkAPI.extra().history.push(getRedirectUrl(manualValuationId))
    }
  }
)

export const addComparableToManualValuation = createAsyncThunk(
  'manual-valuation/remove-comparable',
  async function ({ type, manualValuationId, listingId }, thunkAPI) {
    if (type !== 'hemnet_listing') {
      console.error('Unsupported type', type)
      return
    }
    await thunkAPI
      .extra()
      .sabiApi.addHemnetListingAsComparable(manualValuationId, listingId)
  }
)

export const removeComparableFromManualValuation = createAsyncThunk(
  'manual-valuation/remove-comparable',
  async ({ manualValuationId, comparableId }, thunkAPI) => {
    return thunkAPI
      .extra()
      .getFirestore()
      .collection('manual_valuation')
      .doc(manualValuationId)
      .update({
        [`comparables.${comparableId}`]: firebase.firestore.FieldValue.delete(),
      })
  }
)

export const commitManualValuation = createAsyncThunk(
  'manual-valuation/commit',
  async ({ manualValuationId }, thunkAPI) => {
    return thunkAPI
      .extra()
      .getFirestore()
      .collection('manual_valuation')
      .doc(manualValuationId)
      .update({
        committed_at: moment().utc().toISOString(),
        committed_by: thunkAPI.extra().getFirebase().auth().currentUser.uid,
      })
  }
)

export const setManualValuationComment = createAsyncThunk(
  'manual-valuation/set-comment',
  async ({ manualValuationId, comment }, thunkAPI) => {
    return thunkAPI
      .extra()
      .getFirestore()
      .collection('manual_valuation')
      .doc(manualValuationId)
      .update({
        comment,
      })
  }
)

export const deleteManualValuation = createAsyncThunk(
  'manual-valuation/delete',
  async ({ manualValuationId }, thunkAPI) => {
    return thunkAPI
      .extra()
      .getFirestore()
      .collection('manual_valuation')
      .doc(manualValuationId)
      .update({
        deleted: true,
      })
  }
)
