import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'

import {
  multipleLeadsConnector,
  multipleLeadsSelector,
} from '../../leads/leadsSelectors'
import {
  multipleManualValuationsConnector,
  multipleManualValuationsSelector,
} from '../../valuate/valuationsSelectors'
import { allSGOffersConnector, allSGOffersSelector } from './sgOfferSelectors'

export const useOffers = ({ populate = false }) => {
  useFirestoreConnect(allSGOffersConnector())
  const offers = useSelector((state) => allSGOffersSelector(state))

  const manualValuationIds =
    populate && offers ? offers.map((o) => o.manual_valuation_id) : []
  useFirestoreConnect(multipleManualValuationsConnector(manualValuationIds))
  const manualValuations = useSelector((state) =>
    multipleManualValuationsSelector(state, manualValuationIds)
  )

  const leadIds = populate ? offers.map((o) => o.lead_id) : []
  useFirestoreConnect(multipleLeadsConnector(leadIds))
  const leads = useSelector((state) => multipleLeadsSelector(state, leadIds))

  return {
    offers:
      offers?.map((offer) => {
        return {
          ...offer,
          lead: leads.find((l) => l?.id === offer.lead_id),
          manual_valuation: manualValuations.find(
            (v) => v?.id === offer.manual_valuation_id
          ),
        }
      }) ?? [],
    isLoaded: isLoaded(offers),
  }
}
