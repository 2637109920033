import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

export const getMetricsAiAttribution = createAsyncThunk(
  'metrics/getMetricsAiAttribution',
  async (_, thunkAPI) => {
    const response = await thunkAPI.extra().metricsApi.getMetricsAiAttribution()
    return response.data
  }
)

export const STAGES = ['received_offer', 'closed_won']
export const TAGS = ['discovery', 'evaluation']

const metricsSlice = createSlice({
  name: 'metrics',
  initialState: {
    data: Object.fromEntries(
      STAGES.map((s) => [s, Object.fromEntries(TAGS.map((t) => [t, []]))])
    ),
    loading: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMetricsAiAttribution.fulfilled, (state, action) => {
        state.loading = 'idle'
        state.data = action.payload
      })
      .addCase(getMetricsAiAttribution.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(getMetricsAiAttribution.rejected, (state, action) => {
        state.loading = { status: 'rejected', payload: action }
      })
  },
})

export const metricsSelector = (state) => state.metrics.data

export const selectLoading = (state) => state.metrics.loading

export const metricsTableSelector = createSelector(metricsSelector, (data) => {
  const tidy = Object.fromEntries(TAGS.map((t) => [t, {}]))
  STAGES.forEach((stage) => {
    TAGS.forEach((tag) => {
      Object.assign(
        tidy[tag],
        Object.fromEntries(
          data[stage][tag].map((row) => [
            row['date'],
            {
              ...tidy[tag][row['date']],
              [stage]: row['score'],
            },
          ])
        )
      )
    })
  })
  return tidy
})

export default metricsSlice.reducer
