import 'typeface-montserrat'
import 'typeface-playfair-display'

import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Router } from 'react-router-dom'

import { authSelector } from './features/auth/authSlice'
import Routes from './Routes'

const App = ({ history }) => {
  const auth = useSelector(authSelector)
  useEffect(() => {
    if (auth && auth.uid) {
      Sentry.setUser({ email: auth.uid })
    } else {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [auth])

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s - Sabi Movesta 🏠"
        defaultTitle="Sabi Movesta 🏠"
      ></Helmet>
      <Router history={history}>
        <Routes />
      </Router>
    </React.Fragment>
  )
}

export default App
