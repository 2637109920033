import { Check } from '@mui/icons-material'
import { Button, InputAdornment, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { makeValidate, TextField } from 'mui-rff'
import { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import FinalFormAutoSave from '../../../components/FinalFormAutoSave'
import NumberFormatCustom from '../../../components/NumberFormatCustom'
import { setManualValuationEstimate } from '../valuationsSelectors'

const fieldLabels = [
  { name: 'low_95' },
  { name: 'low_80' },
  { name: 'expectation', label: '🎯' },
  { name: 'high_80' },
  { name: 'high_95' },
]

const createFields = (disabled) =>
  Object.fromEntries(
    fieldLabels.map(({ name, label }) => [
      name,
      <TextField
        size="small"
        sx={{ width: '140px' }}
        variant="standard"
        disabled={!!disabled}
        name={name}
        InputProps={{
          inputComponent: NumberFormatCustom,
          sx: { height: '32px' },
          startAdornment: (
            <InputAdornment position="start">{label}</InputAdornment>
          ),
          endAdornment: <InputAdornment position="end">kr</InputAdornment>,
        }}
      />,
    ])
  )

const FIELDS = createFields(false)
const FIELDS_DISABLED = createFields(true)

const schema = Yup.object().shape({
  low_95: Yup.number().default(0).required(),
  low_80: Yup.number().default(0).required(),
  expectation: Yup.number().default(0).required(),
  high_80: Yup.number().default(0).required(),
  high_95: Yup.number().default(0).required(),
})

const validate = makeValidate(schema)

export default function EstimateForm({
  valuationEstimate,
  manualValuationId,
  disabled,
}) {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    (values, reset) => {
      const numericValues = schema.cast(values, {
        assert: false,
        stripUnknown: true,
      })
      return dispatch(
        setManualValuationEstimate({
          manualValuationId,
          values: numericValues,
        })
      )
        .unwrap()
        .then(() => reset(numericValues))
    },
    [dispatch, manualValuationId]
  )

  const fields = useMemo(
    () => (disabled ? FIELDS_DISABLED : FIELDS),
    [disabled]
  )

  return (
    <Form
      initialValues={valuationEstimate}
      validate={validate}
      onSubmit={onSubmit}
      render={({ dirty, valid, handleSubmit, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              mx: 'auto',
              width: '600px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {fields.expectation}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              {fields.low_80}
              <Box>
                <Typography sx={{ fontWeight: 700 }}>1 out of 5 😐</Typography>
              </Box>

              {fields.high_80}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {fields.low_95}
              <Box>
                <Typography sx={{ fontWeight: 700 }}>1 out of 20 😔</Typography>
              </Box>
              {fields.high_95}
            </Box>
          </Box>

          {!disabled && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button type="submit" disabled={!dirty}>
                {dirty ? (
                  'Save'
                ) : (
                  <>
                    Saved <Check />
                  </>
                )}
              </Button>
              <FinalFormAutoSave
                debounce={900}
                save={(values) => !disabled && valid && onSubmit(values, reset)}
              />
            </Box>
          )}
        </form>
      )}
    />
  )
}
