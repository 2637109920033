import LinkIcon from '@mui/icons-material/Link'
import {
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from '@mui/material'
import { createElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export default function StartLink({ label, href, icon, external }) {
  const iconElement = createElement(icon || LinkIcon, {
    sx: { fontSize: '4em' },
  })

  const children = (
    <CardActionArea>
      <CardContent>
        {iconElement}
        <Typography gutterBottom variant="h4" sx={{ paddingTop: '1em' }}>
          {label}
        </Typography>
      </CardContent>
    </CardActionArea>
  )

  if (external) {
    return (
      <Card sx={{ flexBasis: '20em', flexShrink: 0, flexGrow: 0 }}>
        <Link href={href} target="_blank" rel="noreferrer">
          {children}
        </Link>
      </Card>
    )
  } else {
    return (
      <Card sx={{ flexBasis: '20em', flexShrink: 0, flexGrow: 0 }}>
        <Link component={RouterLink} to={href}>
          {children}
        </Link>
      </Card>
    )
  }
}
