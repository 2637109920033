import { LONGLAT_STORE_PRECISION } from '../../utils/mapUtils'
import { FILTER_CONFIG } from './filterConfig'

const DELIMITER_PARAMETERS = '|'
const DELIMITER_PARAMETER_PARTS = ':'
const DELIMITER_PARAMETER_VALUES = ','

export const encodeToURLParameter = (obj) => {
  const s = Object.keys(obj)
    .map((fieldName) => {
      const fieldType = obj[fieldName].type
      const fieldValues = (obj[fieldName].values || [])
        .map(convertCoords)
        .map(encodeURIComponent)
        .join(DELIMITER_PARAMETER_VALUES)

      return (
        fieldType &&
        fieldValues.length > 0 &&
        [fieldName, fieldType, fieldValues].join(DELIMITER_PARAMETER_PARTS)
      )
    })
    .filter(Boolean)
    .join(DELIMITER_PARAMETERS)

  return s
}

export const decodeFromURLParameter = (str) => {
  const obj = {}
  if (!str) {
    return obj
  }

  str.split(DELIMITER_PARAMETERS).forEach((parameter) => {
    const sub = parameter.split(DELIMITER_PARAMETER_PARTS)
    if (sub.length !== 3) return
    const fieldName = sub[0]
    const fieldType = sub[1]
    let fieldValues = sub[2]
      .split(DELIMITER_PARAMETER_VALUES)
      .map(decodeURIComponent)

    if (fieldValues.length === 0) return

    if (fieldType === 'term' || fieldType === 'prefix-either') {
      fieldValues = fieldValues.map(decodeURIComponent)
    } else if (fieldType === 'range') {
      fieldValues = [...fieldValues, -1, -1].map((x) => parseInt(x))
      fieldValues.length = 2
    } else if (fieldType === 'coords') {
      fieldValues = [...fieldValues, -1, -1].map((x) => parseFloat(x))
      fieldValues.length = 2
    } else if (fieldType === 'bounds') {
      fieldValues = [...fieldValues].map((x) => parseFloat(x))
      fieldValues.length = 4
    } else if (fieldType === 'scalar') {
      fieldValues = [...fieldValues, -1].map((x) => parseInt(x))
      fieldValues.length = 1
    }
    obj[fieldName] = {
      type: fieldType,
      values: fieldValues,
    }
  })

  return obj
}

export const mergeSearchParameters = (searchParametersList) => {
  const obj = {}
  searchParametersList.forEach((searchParameters) => {
    Object.keys(searchParameters).forEach((fieldName) => {
      // handle both typed (object value is object) and untyped (object value is array) parameters
      const fieldType =
        searchParameters[fieldName]?.type ?? FILTER_CONFIG[fieldName]?.type
      if (!fieldType) {
        console.warn('Unknown field type for field', fieldName)
        return
      }
      const fieldValues = searchParameters[fieldName]?.values
      if (!fieldValues) return
      obj[fieldName] = {
        type: fieldType,
        values: fieldValues,
      }
    })
  })
  return obj
}

const convertCoords = (value) => {
  if (!Number.isFinite(value) || Number.isInteger(value)) return value
  return Number.parseFloat(value).toFixed(LONGLAT_STORE_PRECISION)
}
