import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { debugSelector } from '../../debug/debugSlice'
import { getListing, listingByIdSelector } from '../../listings/listingsSlice'
import {
  appealHints,
  appealLabels,
  conditionHints,
  conditionLabels,
} from '../labellingInfo'
import {
  getNextLabellingSample,
  isLoadingSelector,
  nextSampleSelector,
  numLabelledSelector,
  submitLabel,
} from '../labellingSlice'
import ImagesGroup from './ImageGroup'
import { LabelButtons } from './LabelButtons'

export function ImageLabeller() {
  const dispatch = useDispatch()

  const sample = useSelector(nextSampleSelector)
  const isSampleLoading = useSelector(isLoadingSelector)
  const numLabelled = useSelector(numLabelledSelector)
  const listing = useSelector((state) =>
    listingByIdSelector(state, sample && sample.listing_id)
  )
  const isDebug = useSelector(debugSelector)

  const [appeal, setAppeal] = useState(null)
  const [condition, setCondition] = useState(null)
  const [rejected, setRejected] = useState(false)
  const [comment, setComment] = useState('')
  const [timestampStarted, setTimestampStarted] = useState(moment())
  const [misclassifiedImages, setMisclassifiedImages] = useState([])
  const [submitting, setSubmittingImmediately] = useState(false)

  const toggleMisclassifiedImage = useCallback(
    (index) => {
      const arrayIndex = misclassifiedImages.indexOf(index)
      if (arrayIndex === -1) {
        setMisclassifiedImages([...misclassifiedImages, index])
      } else {
        setMisclassifiedImages(misclassifiedImages.filter((x) => x !== index))
      }
    },
    [misclassifiedImages]
  )
  const triggerSubmitting = () => {
    setSubmittingImmediately(true)
    setTimeout(() => setSubmittingImmediately(false), 1500)
  }
  const reset = () => {
    setCondition(null)
    setAppeal(null)
    setRejected(false)
    setComment('')
    setMisclassifiedImages([])
    setTimestampStarted(moment())
  }

  useEffect(() => {
    if (!sample) return
    reset()
    dispatch(getListing({ listingId: sample.listing_id, index: 'listing' }))
  }, [dispatch, sample])

  useEffect(() => {
    dispatch(getNextLabellingSample())
  }, [dispatch])

  useEffect(() => {
    if ((sample != null && appeal !== null && condition !== null) || rejected) {
      const submission = {
        condition,
        appeal,
        rejected,
        comment,
        misclassified: misclassifiedImages,
        time_spent_ms: moment().diff(timestampStarted, 'milliseconds'),
      }
      triggerSubmitting()
      dispatch(submitLabel({ submission, id: sample._id }))
        .unwrap()
        .then(() => {
          dispatch(getNextLabellingSample())
          reset()
        })
    }
  }, [
    condition,
    appeal,
    dispatch,
    sample,
    comment,
    rejected,
    misclassifiedImages,
    timestampStarted,
  ])

  console.log({ sample, listing, misclassifiedImages, numLabelled })

  const relevantImagesIndices = sample && sample.relevant_images

  if (!isSampleLoading && !sample) {
    return (
      <>
        <Alert severity="info" sx={{ mb: 3 }}>
          <AlertTitle>No more images left to label.</AlertTitle>
        </Alert>
        {numLabelled && numLabelled > 0 && (
          <Alert severity="info">
            You have already labelled {numLabelled || 0} images.
          </Alert>
        )}
      </>
    )
  }

  return (
    <>
      {isDebug && <pre>{JSON.stringify({ sample, listing }, null, 4)}</pre>}
      <SubmittedBackdrop open={submitting || isSampleLoading} />

      {sample && (
        <>
          <Typography variant="h2">Rate the {sample.case_category}</Typography>
          <Chip
            label={`${sample.relevant_images.length} image${
              sample.relevant_images.length > 1 ? 's' : ''
            }`}
            sx={{ mb: 4 }}
          />
          <Chip label={`Round ${sample.round + 1}`} sx={{ mb: 4, ml: 2 }} />
          <Chip label={`${numLabelled} labelled`} sx={{ mb: 4, ml: 2 }} />
          <Box sx={{ opacity: submitting ? 0.5 : 1 }}>
            <ImagesGroup
              listing={listing}
              imageIndices={relevantImagesIndices}
              toggleMisclassifiedImage={toggleMisclassifiedImage}
              misclassifiedImages={misclassifiedImages}
            />
          </Box>
          <Box sx={{ maxWidth: 850 }}>
            <Card sx={{ my: 2 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ width: 220 }}>
                  <Chip label="1" color="secondary" sx={{ mr: 2 }} />
                  Select
                </Typography>
                <Box>
                  <Typography>
                    Please unselect all images that do not contain a kitchen.
                  </Typography>
                  <Box sx={{ height: '1.5em' }}>
                    {misclassifiedImages.length > 0 ? (
                      <Chip
                        label={`${misclassifiedImages.length} unselected`}
                        color="primary"
                        size="small"
                      />
                    ) : (
                      <em>If in doubt, unselect.</em>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ my: 2 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ width: 220 }}>
                  <Chip label="2" color="secondary" sx={{ mr: 2 }} />
                  Condition
                </Typography>
                <LabelButtons
                  hints={conditionHints}
                  labels={conditionLabels}
                  value={condition}
                  onChange={(event, value) => setCondition(value)}
                  width={500}
                />
              </CardContent>
            </Card>
            <Card sx={{ my: 2 }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ width: 220 }}>
                  <Chip label="3" color="secondary" sx={{ mr: 2 }} />
                  Appeal
                </Typography>
                <LabelButtons
                  hints={appealHints}
                  labels={appealLabels}
                  value={appeal}
                  onChange={(event, value) => setAppeal(value)}
                  width={500}
                />
              </CardContent>
            </Card>
            <Card sx={{ my: 5 }}>
              <CardContent
                sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
              >
                <TextField
                  multiline
                  fullWidth
                  label="Comment"
                  minRows={3}
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setRejected(true)}
                  sx={{ width: 500 }}
                >
                  Cannot rate
                </Button>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

const SubmittedBackdrop = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={() => ({})}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  )
}
