import axios from 'axios'

const createMetricsApi = (config, getAccessTokenSilently) => {
  const getAxiosConfig = async () => {
    const token = await getAccessTokenSilently()
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }

  return {
    getMetricsAiAttribution: async function () {
      return axios.get(
        `${config.REACT_APP_MOVESTA_API_URL}/metrics/ai-attribution`,
        {
          ...(await getAxiosConfig()),
        }
      )
    },
  }
}

export default createMetricsApi
