import axios from 'axios'

export const getImageProxyAuth0Options = (config) => ({
  audience: config.REACT_APP_IMAGE_PROXY_AUDIENCE,
  scope: 'read:images',
})

const createImageProxyApi = (config, getAccessTokenSilently) => {
  const getAxiosConfig = async () => {
    const token = await getAccessTokenSilently(
      getImageProxyAuth0Options(config)
    )
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    }
  }

  return {
    setCookie: async () =>
      axios.get(
        `${config.REACT_APP_IMAGE_PROXY_URL}/auth/set_cookie`,
        await getAxiosConfig()
      ),
    deleteCookie: async () =>
      axios.get(
        `${config.REACT_APP_IMAGE_PROXY_URL}/auth/delete_cookie`,
        await getAxiosConfig()
      ),
  }
}

export default createImageProxyApi
