export const getContactName = (lead) => {
  const contact = lead.contact_details
  return contact.name?.length
    ? contact.name
    : [contact.first_name, contact.last_name].filter((x) => x).join(' ')
}

export const getGoogleSearchLink = (query, site) => {
  query = encodeURIComponent(query)
  site = encodeURIComponent(site)
  if (!site) {
    return `https://www.google.se/search?q=${query}`
  }
  return `https://www.google.se/search?q=${query}+inurl%3A${site}`
}

export const filterLeadsBasedOnQuery = (leads, keywords) => {
  if (!keywords?.length) return leads

  return leads?.filter((lead) => {
    const postalAddress = lead.home.features_snapshot.features.postal_address
    const streetAddress =
      postalAddress.street_address_formatted ||
      [postalAddress.route_name, postalAddress.street_number]
        .map((x) => x)
        .join(' ')
    const name = [
      lead.contact_details.first_name,
      lead.contact_details.last_name,
      lead.contact_details.name,
    ]
      .filter((x) => x)
      .join(' ')

    // return true if any of the keywords are found in the lead name or address
    return keywords.some(
      (keyword) =>
        streetAddress.toLowerCase().includes(keyword.toLowerCase()) ||
        name.toLowerCase().includes(keyword.toLowerCase())
    )
  })
}
