import { Alert, AlertTitle, Button } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'

export default function FFSubmitError({ submitErrors, sx = {} }) {
  const [showDetails, setShowDetails] = useState(false)

  if (_.isEmpty(submitErrors)) {
    return <></>
  }

  return Object.values(submitErrors).map((error) => (
    <Alert severity="error" sx={{ mb: 2, ...sx }}>
      <AlertTitle>
        {error.message || 'Submission error'}
        {!showDetails && (
          <Button
            size="small"
            variant="outlined"
            color="error"
            sx={{ ml: 4 }}
            onClick={() => setShowDetails(true)}
          >
            Details
          </Button>
        )}
      </AlertTitle>
      {showDetails && <pre>{JSON.stringify(error, null, 4)}</pre>}
    </Alert>
  ))
}
