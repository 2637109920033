import { Box, Card, CardContent, Typography } from '@mui/material'
import moment from 'moment'
import { TextField } from 'mui-rff'
import { Field } from 'react-final-form'

import { formatSEK } from '../../../utils/formatUtils'

export function RenovationEstimateProperties({
  valuation_id,
  created_at,
  created_by,
  updated_at,
  updated_by,
}) {
  // TODO not working anymore because it was barely used, need to hook up with new valuation data model

  const valuation = null

  const pctRange = 0.15

  const formatAsRange = (value, percentage) => {
    if (!value) return '--'

    const valueLeft = Math.round((value * (1 - percentage)) / 1000) * 1000
    const valueRight = Math.round((value * (1 + percentage)) / 1000) * 1000

    return ` ${formatSEK(valueLeft, true)} ≈ ${formatSEK(valueRight)}`
  }

  return (
    <Card
      sx={{
        position: 'sticky',
        top: 10,
        borderColor: 'primary.main',
        borderWidth: '1px',
        mr: 5,
        mb: 5,
      }}
    >
      <CardContent>
        <Field name="total">
          {(props) => (
            <Box sx={{ mb: 5 }}>
              <Typography fontSize={30}>
                {!props.input.value
                  ? '--'
                  : props.input.value > 1000
                  ? formatAsRange(props.input.value, pctRange)
                  : `< ${formatSEK(1000)}`}
              </Typography>
              <Typography>
                <strong>Total</strong> <small> ±{pctRange * 100}% range</small>
              </Typography>
            </Box>
          )}
        </Field>

        {valuation && (
          <Box sx={{ mb: 5 }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {valuation.streetAddress} {valuation.city}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            flexBasis: 200,
          }}
        >
          <TextField
            size="small"
            name="living_area"
            label="Living area (sqm)"
            FormHelperTextProps={{ sx: { display: 'none' } }}
            default={0}
          />
          <TextField
            size="small"
            name="supplementary_area"
            label="Supplementary area (sqm)"
            default={0}
            FormHelperTextProps={{ sx: { display: 'none' } }}
          />
          <TextField
            size="small"
            name="indoor_area"
            label="Indoor area (sqm, auto)"
            disabled
            FormHelperTextProps={{ sx: { display: 'none' } }}
            default={0}
          />
          <TextField
            size="small"
            name="num_floors"
            label="Floors"
            default={0}
            FormHelperTextProps={{ sx: { display: 'none' } }}
          />
          <TextField
            name="notes"
            multiline
            minRows={2}
            label="Notes"
            FormHelperTextProps={{ sx: { display: 'none' } }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            mt: 5,
            fontSize: '90%',
          }}
        >
          Last update from {updated_by.split('@')[0]},{' '}
          {moment(updated_at).fromNow()}
          <br />
          Created from {created_by.split('@')[0]},{' '}
          {moment(created_at).fromNow()}
        </Box>
      </CardContent>
    </Card>
  )
}
