import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Typography,
} from '@mui/material'
import { Helmet } from 'react-helmet'

import { SabiHemnetLink } from './components/SabiHemnetLink'

const HEMNET_PLUGIN_LINK =
  'https://chrome.google.com/webstore/detail/movesta-avm-hemnet-plugin/onklkbjgkcaeebljcoogcfhnlmcbmacb?hl=en&authuser=0'

export default function ResourcesPage() {
  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
      <Helmet>
        <title>Resources</title>
      </Helmet>

      <Alert severity="info" sx={{ mb: 3 }}>
        Please read the individual instructions.
      </Alert>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" mb={2}>
              Hemnet/Sabi LINK plug-in
            </Typography>
            <Typography paragraph fontWeight="bold">
              When you're browsing a listing on Hemnet, this small plug-in helps
              you to jump right into Sabi to see all the data we have about it.
            </Typography>
            <Typography paragraph>
              Drag&amp;drop this link to your Browser's bookmarks toolbar and
              click it whenever you're viewing an individual listing on Hemnet:
            </Typography>
            <SabiHemnetLink />
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h5" mb={2}>
              Hemnet AVM plug-in
            </Typography>

            <Typography paragraph fontWeight="bold">
              This Chrome Extension shows the prediction of the Movesta AVM for
              a sold, selling or upcoming listing directly on Hemnet.
            </Typography>
            <Typography paragraph>
              You need to be logged in with your Movesta GSuite account before
              you can see the item. Additionally your email address needs to be
              added to the chrome webstore by an admin.
            </Typography>
            <Button
              component={Link}
              variant="outlined"
              target="_blank"
              rel="noreferrer"
              href={HEMNET_PLUGIN_LINK}
            >
              Chrome Web Store
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}
