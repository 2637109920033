import { Check } from '@mui/icons-material'
import { Button, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

function Suggestion({ label, value, use, currentValue }) {
  const [open, setOpen] = useState(false)
  if (!value || value === currentValue) return null
  const showInModal = value.toString().length > 10

  if (showInModal) {
    return (
      <Box>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          sx={{ zIndex: '2000 !important' }} // make sure it is above tooltip
        >
          <Box
            sx={{
              width: 400,
              bgcolor: '#fff',
              margin: '20px auto',
              maxHeight: 'calc(100vh - 40px)',
              p: 2,
              overflow: 'auto',
            }}
          >
            <Typography variant="h5" gutterBottom>
              Suggestion from {label}
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{value}</Typography>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Ignore
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                use()
                setOpen(false)
              }}
            >
              Use
            </Button>
          </Box>
        </Modal>
        <Typography component="span" variant="body2">
          {label}
        </Typography>
        <Button
          size="small"
          sx={{ ml: 1 }}
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          <Typography color="primary.contrastText">show</Typography>
        </Button>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
      <Typography
        color="primary.contrastText"
        textTransform="none"
        fontSize="small"
      >
        via {label}: {value}
      </Typography>
      <Button size="small" onClick={use} variant="outlined">
        <Typography color="primary.contrastText" fontSize="small">
          <Check />
        </Typography>
      </Button>
    </Box>
  )
}

Suggestion.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  use: PropTypes.func.isRequired,
}

Suggestion.defaultProps = {
  value: null,
}

export default Suggestion
