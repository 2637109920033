import {
  ArrowDownward,
  ArrowUpward,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import _ from 'lodash'
import { useCallback, useState } from 'react'

import { getInnerTextFromJSX } from '../../../utils/reactUtils'
import { MAP_COLUMNS } from '../mapPoints'
import { useMapSettings, useTableSettings } from '../settingsHook'
import { getColumnsConfig } from '../tableColumns'

export const SettingsModal = ({ open, onClose }) => {
  const [tab, setTab] = useState('1')

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(_, v) => setTab(v)}>
              <Tab label="Columns" value="1" />
              <Tab label="Map" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ColumnsTabContent />
          </TabPanel>
          <TabPanel value="2">
            <MapTabContent />
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const ColumnsTabContent = () => {
  const { currentTableColumns, availableTableColumns, setCurrentTableColumns } =
    useTableSettings()

  const tableConfig = getColumnsConfig(
    availableTableColumns,
    currentTableColumns
  )

  const invisibleColumns = availableTableColumns.filter(
    (c) => !currentTableColumns.includes(c)
  )
  const [searchTerm, setSearchTerm] = useState('')

  const invisibleColumnsSorted = invisibleColumns.sort((a, b) => {
    const aConfig = tableConfig.find((col) => col.name === a)
    const aLabel = getInnerTextFromJSX(aConfig?.label ?? a).toLowerCase()
    const bConfig = tableConfig.find((col) => col.name === b)
    const bLabel = getInnerTextFromJSX(bConfig?.label ?? b).toLowerCase()
    return aLabel.localeCompare(bLabel)
  })

  const visibleAndInvisibleColumns = [
    ...currentTableColumns,
    ...invisibleColumnsSorted,
  ]

  const searchFilter = useCallback(
    (c) => {
      const columnConfig = tableConfig.find((col) => col.name === c)
      const labelWrapped = columnConfig?.label ?? c // could be JSX
      const labelString = getInnerTextFromJSX(labelWrapped)
      const name = columnConfig?.name ?? c
      return (
        labelString.toLowerCase().includes(searchTerm.toLowerCase()) ||
        name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    },
    [searchTerm, tableConfig]
  )

  if (_.isEmpty(visibleAndInvisibleColumns)) {
    return null
  }

  return (
    <>
      <Box>
        <TextField
          label="Search"
          fullWidth
          variant="standard"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <List dense>
        {visibleAndInvisibleColumns.filter(searchFilter).map((c) => (
          <ListItem key={c}>
            <ListItemIcon>
              <Checkbox
                sx={{ py: 0 }}
                edge="start"
                checked={currentTableColumns.includes(c)}
                tabIndex={-1}
                disableRipple
                onChange={(e) => {
                  if (e.target.checked) {
                    setCurrentTableColumns([...currentTableColumns, c])
                  } else {
                    setCurrentTableColumns(
                      currentTableColumns.filter((c2) => c2 !== c)
                    )
                  }
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={tableConfig.find((col) => col.name === c)?.label ?? c}
            />
            {currentTableColumns.includes(c) && (
              <Box display="flex" alignItems="center">
                <Button
                  sx={{ minWidth: '24px' }}
                  size="small"
                  disabled={currentTableColumns.indexOf(c) === 0}
                  onClick={() => {
                    // move to top
                    const index = currentTableColumns.indexOf(c)
                    const newList = [...currentTableColumns]
                    newList.splice(index, 1)
                    newList.unshift(c)
                    setCurrentTableColumns(newList)
                  }}
                >
                  <KeyboardDoubleArrowUp />
                </Button>
                <Button
                  sx={{ minWidth: '24px' }}
                  size="small"
                  disabled={currentTableColumns.indexOf(c) === 0}
                  onClick={() => {
                    const index = currentTableColumns.indexOf(c)
                    const newList = [...currentTableColumns]
                    newList[index] = newList[index - 1]
                    newList[index - 1] = c
                    setCurrentTableColumns(newList)
                  }}
                >
                  <ArrowUpward />
                </Button>
                <Button
                  sx={{ minWidth: '24px' }}
                  size="small"
                  disabled={
                    currentTableColumns.indexOf(c) ===
                    currentTableColumns.length - 1
                  }
                  onClick={() => {
                    const index = currentTableColumns.indexOf(c)
                    const newList = [...currentTableColumns]
                    newList[index] = newList[index + 1]
                    newList[index + 1] = c
                    setCurrentTableColumns(newList)
                  }}
                >
                  <ArrowDownward />
                </Button>
                <Button
                  sx={{ minWidth: '24px' }}
                  size="small"
                  disabled={
                    currentTableColumns.indexOf(c) ===
                    currentTableColumns.length - 1
                  }
                  onClick={() => {
                    // move to bottom
                    const index = currentTableColumns.indexOf(c)
                    const newList = [...currentTableColumns]
                    newList.splice(index, 1)
                    newList.push(c)
                    setCurrentTableColumns(newList)
                  }}
                >
                  <KeyboardDoubleArrowDown />
                </Button>
              </Box>
            )}
          </ListItem>
        ))}
      </List>
      <Button onClick={() => setCurrentTableColumns([])}>Reset columns</Button>
    </>
  )
}

const MapTabContent = () => {
  const { mapColumns, setMapColumns, pointsOffset, setPointsOffset } =
    useMapSettings()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        gap: 4,
      }}
    >
      <FormControl sx={{ width: 300 }}>
        <InputLabel htmlFor="map-columns">Map columns</InputLabel>
        <Select
          id="map-columns"
          value={mapColumns}
          sx={{ mt: 2 }}
          onChange={(e) => {
            setMapColumns(e.target.value)
          }}
          renderValue={(key) => MAP_COLUMNS[key].label}
          size="small"
        >
          {Object.entries(MAP_COLUMNS).map(([key, { label }]) => (
            <MenuItem value={key}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="map-offset">Offset map points</InputLabel>
        <Select
          id="map-offset"
          value={pointsOffset}
          sx={{ mt: 2, width: 150 }}
          size="small"
          onChange={(e) => {
            setPointsOffset(e.target.value)
          }}
        >
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
