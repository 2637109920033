import { Help } from '@mui/icons-material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import StorefrontIcon from '@mui/icons-material/Storefront'
import {
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

import { formatDate, getDaysBetweenDates } from '../../../utils/formatUtils'
import { debugSelector } from '../../debug/debugSlice'
import { isSold } from '../listingUtils'
import ListingSection from './ListingSection'

const MISSING_PUBLICATION_DATE_TOOLTIP = `
The publication date is missing. This is probably because we never 
found a SALE page for this listing. This can happen if the listing
was sold before we started scraping Hemnet or if realtors
only put up the listing on Hemnet after it has been sold.`

export default function SaleInfo({ listing, brokerPages }) {
  const isDebug = useSelector(debugSelector)

  return (
    <ListingSection icon={<StorefrontIcon fontSize="large" color="primary" />}>
      <Grid item md={6} lg={4}>
        {listing.publicationDate ? (
          <Typography variant="h5">
            {formatDate(listing.publicationDate)}
          </Typography>
        ) : (
          <Typography variant="h5">
            Unknown
            <Tooltip title={MISSING_PUBLICATION_DATE_TOOLTIP}>
              <Help
                sx={{
                  fontSize: '0.7em',
                  ml: 0.2,
                  mb: 0.5,
                  color: 'primary.main',
                }}
              />
            </Tooltip>
          </Typography>
        )}
        {listing.salePageURL && (
          <Typography
            variant="body1"
            component={Link}
            href={listing.salePageURL}
            rel="noreferrer"
            target="_blank"
          >
            Published
            <OpenInNewIcon sx={{ fontSize: '1.1em', ml: 0.5 }} />
          </Typography>
        )}
      </Grid>
      {isSold(listing) && (
        <>
          <Grid item md={6} lg={4}>
            <Typography variant="h5">
              {formatDate(listing.soldDate) || '?'}
            </Typography>
            <Typography
              variant="body1"
              component={Link}
              href={listing.soldPageURL}
              rel="noreferrer"
              target="_blank"
            >
              Sold
              <OpenInNewIcon sx={{ fontSize: '1.1em', ml: 0.5 }} />
            </Typography>
          </Grid>
          <Grid item md={6} lg={4}>
            <Typography variant="h5">
              {getDaysBetweenDates(listing.publicationDate, listing.soldDate) ||
                '?'}{' '}
              days
            </Typography>
            <Typography variant="body1">On market</Typography>
          </Grid>
        </>
      )}
      <Grid item md={12} lg={12} />
      {listing.brokerName && (
        <Grid item md={6} lg={4}>
          <Typography variant="h5">{listing.brokerName}</Typography>
          <Typography variant="body1">Broker name</Typography>
        </Grid>
      )}
      {listing.brokerPhone && (
        <Grid item md={6} lg={4}>
          <Typography variant="h5">
            <a href={`tel:${listing.brokerPhone}`}>{listing.brokerPhone}</a>
          </Typography>
          <Typography variant="body1">Phone</Typography>
        </Grid>
      )}
      {listing.brokerFirm && (
        <Grid item md={6} lg={4}>
          <Typography variant="h5">{listing.brokerFirm}</Typography>
          <Typography variant="body1">Firm</Typography>
        </Grid>
      )}

      <Grid item md={4} lg={3}>
        <Typography variant="h5">{listing.id}</Typography>
        <Typography variant="body1">Movesta listing ID</Typography>
      </Grid>
      {isDebug && brokerPages?.pages?.length > 0 && (
        <Grid item md={12} lg={12}>
          <BrokerPagesButton
            brokerPages={brokerPages.pages}
            soldAt={listing.soldDate}
            publicationDate={listing.publicationDate}
          />
        </Grid>
      )}
    </ListingSection>
  )
}

const BrokerPagesButton = ({ brokerPages, soldAt, publicationDate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isOpen = Boolean(anchorEl)

  return (
    <>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="outlined"
      >
        Available listing broker pages
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={() => setAnchorEl(null)}
      >
        {[...brokerPages]
          .sort((a, b) => {
            if (a.date < b.date) {
              return 1
            }
            if (a.date > b.date) {
              return -1
            }
            return 0
          })
          .map((brokerPage) => (
            <MenuItem
              value={brokerPage.url}
              component={Link}
              href={brokerPage.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {formatDate(brokerPage.date)}
              {soldAt
                ? `, ${moment(soldAt).diff(
                    moment(brokerPage.date),
                    'days'
                  )} days before sold`
                : publicationDate
                ? `, ${
                    moment(publicationDate).diff(
                      moment(brokerPage.date),
                      'days'
                    ) + 1
                  } days after publication`
                : ''}{' '}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}
