import { Home } from '@mui/icons-material'
import {
  Card,
  CardContent,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'

import { getListing, listingByIdSelector } from '../../listings/listingsSlice'

export const OutboundListingCard = ({ listingId, sx }) => {
  const dispatch = useDispatch()
  const listing = useSelector((state) => listingByIdSelector(state, listingId))

  useEffect(() => {
    listingId && dispatch(getListing({ listingId, index: 'listing' }))
  }, [dispatch, listingId])

  return listing ? (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">Hemnet listing</Typography>
      </CardContent>

      <Box
        component="img"
        sx={{ width: '100%' }}
        src={listing.thumbImages[0]}
      />

      <ListItemButton
        component={ReactRouterLink}
        to={`/listing/${listingId}`}
        target="_blank"
      >
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary="Listing" />
      </ListItemButton>
    </Card>
  ) : (
    <></>
  )
}
