import { HomeRepairService, MoveToInbox } from '@mui/icons-material'
import DataObjectIcon from '@mui/icons-material/DataObject'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import SearchIcon from '@mui/icons-material/Search'
import ShopIcon from '@mui/icons-material/ShoppingBasket'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Helmet } from 'react-helmet'

import StartLink from './components/StartLink'

export default function StartPage() {
  return (
    <Box container sx={{ pt: '2em' }}>
      <Helmet>
        <title>Start</title>
      </Helmet>
      <Typography variant="h1" gutterBottom>
        Welcome to Sabi!
      </Typography>
      <Box sx={{ width: 'auto' }}>
        <Box sx={{ display: 'flex', gap: '2em', flexWrap: 'wrap' }}>
          <StartLink label="Search" href="/search" icon={SearchIcon} />
          <StartLink label="Leads" href="/leads" icon={MoveToInbox} />
          <StartLink label="Valuate" href="/valuate" icon={PriceChangeIcon} />
          <StartLink
            label="Offers"
            href="/offers/sale-guarantee"
            icon={LocalOfferIcon}
          />
          <StartLink label="Marketplace" href="/marketplace" icon={ShopIcon} />
          <StartLink
            label="Resources"
            href="/resources"
            icon={HomeRepairService}
          />
          <StartLink
            label="Kibana"
            href="https://sabi-kibana.movesta.com/"
            external
            icon={DataObjectIcon}
          />
        </Box>
      </Box>
    </Box>
  )
}
