import { useTheme } from '@mui/styles'
import { Box } from '@mui/system'
import _ from 'lodash'

import styles from './CircleMarker.module.css'

export const CircleMarker = ({
  fillColor,
  fillColor2 = '#ccc',
  fillPercentage = 100,
  fillOpacity,
  borderColor,
  size = 15,
  children,
  hover = false,
  active = false,
  ...props
}) => {
  const theme = useTheme()

  // resolve e.g. primary.main so we can use it for the gradient
  fillColor = _.get(theme.palette, fillColor, fillColor)
  fillColor2 = _.get(theme.palette, fillColor2, fillColor2)

  return (
    <Box
      className={styles.pie}
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        border: `2px solid ${borderColor}`,
        boxShadow: active
          ? `0 0 10px 5px ${fillColor}`
          : '0 0 4px 1px rgba(0,0,0,0.5)',
        textShadow: '0 0 4px rgba(0,0,0,0.5)',
        opacity: fillOpacity,
        // on hover increase box shadow radius
        '&:hover':
          hover && !active
            ? {
                boxShadow: '0 0 8px 3px rgba(0,0,0, 0.5)',
              }
            : {},
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1rem',
        color: 'white',
      }}
      {...props}
      style={{
        '--pct': fillPercentage,
        '--s': fillPercentage < 50 ? 0 : 1,
        '--fg': fillColor,
        '--bg': fillColor2,
      }}
    >
      {children}
    </Box>
  )
}
