import { Add, Remove } from '@mui/icons-material'
import { Alert, Box, IconButton, Typography } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window-dynamic'

import {
  addComparableToManualValuation,
  removeComparableFromManualValuation,
} from '../../valuate/valuationActions'
import { isEditable } from '../../valuate/valuationUtils'
import { activeIdSelector, setActivePoint } from '../mapSearchSlice'
import { useListingSearch } from '../searchHook'
import { ListingCard } from './ListingCard'

const ALERT_BOX_HEIGHT = 70

export const SearchResultList = ({ manualValuation }) => {
  const { searchResult, isSearchLoading, maxSearchResultReached } =
    useListingSearch()

  const dispatch = useDispatch()
  const history = useHistory()
  const activeId = useSelector(activeIdSelector)

  const resultsContainerRef = useRef()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceRememberScroll = useCallback(
    _.debounce((runnable) => runnable(), 500),
    []
  )
  const storeScrollPosition = useCallback(
    ({ scrollOffset }) => {
      const state = {
        ...history.location.state,
        resultsScrollPos: scrollOffset,
        resultsScrollFingerprint: searchResult.length,
      }
      debounceRememberScroll(() =>
        history.replace(
          document.location.pathname + document.location.search,
          state
        )
      )
    },
    [history, debounceRememberScroll, searchResult]
  )

  // we need to flatten the groups ("August 2022") with the listings
  // for the list virtualization
  const searchResultWithMonthLabels = useMemo(() => {
    const groups = []
    let currentGroup = null
    let currentGroupDate = null
    searchResult?.forEach((listing) => {
      const listingDate = new Date(listing.soldDate || listing.publicationDate)
      const listingMonth = listingDate.getMonth()
      const listingYear = listingDate.getFullYear()
      if (
        !currentGroup ||
        listingMonth !== currentGroupDate.getMonth() ||
        listingYear !== currentGroupDate.getFullYear()
      ) {
        currentGroup = []
        currentGroupDate = listingDate
        groups.push(currentGroup)
      }
      currentGroup.push(listing)
    })

    // flatten groups and listings to same level
    return groups
      .map((group) => [
        {
          date: moment(group[0].soldDate || group[0].publicationDate).startOf(
            'month'
          ),
        },
        ...group.map((listing) => ({
          listing,
        })),
      ])
      .flat()
  }, [searchResult])

  useEffect(() => {
    if (
      history.location.state?.resultsScrollFingerprint !== searchResult.length
    ) {
      resultsContainerRef?.current?.scrollTo(0)
    }
    resultsContainerRef?.current?.resetAfterIndex(0, true) // reset list item size caching
  }, [
    searchResultWithMonthLabels,
    history.location.state?.resultsScrollFingerprint,
    searchResult.length,
  ])

  const { renderRow, getItemSize } = getRenderRowAndItemSize(
    searchResultWithMonthLabels,
    dispatch,
    activeId,
    manualValuation
  )

  return (
    <>
      <Box height={`${ALERT_BOX_HEIGHT}px`}>
        {isSearchLoading ? (
          <Alert severity="info" sx={{ mb: 2 }}>
            Loading ...
          </Alert>
        ) : maxSearchResultReached ? (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Max search result reached. Please zoom in or refine your search.
          </Alert>
        ) : (
          <Alert
            severity={searchResult.length > 0 ? 'info' : 'error'}
            sx={{ mb: 1 }}
          >
            {searchResult.length} listings found that match your filter
          </Alert>
        )}
      </Box>
      <ReactVirtualizedAutoSizer>
        {({ height, width }) =>
          !maxSearchResultReached && (
            <VariableSizeList
              ref={resultsContainerRef}
              height={height - ALERT_BOX_HEIGHT}
              width={width}
              itemSize={(props) => getItemSize(props)}
              itemCount={searchResultWithMonthLabels.length}
              overscanCount={5}
              initialScrollOffset={history.location.state?.resultsScrollPos}
              onScroll={storeScrollPosition}
            >
              {renderRow}
            </VariableSizeList>
          )
        }
      </ReactVirtualizedAutoSizer>
    </>
  )
}

function getRenderRowAndItemSize(
  searchResultWithMonthLabels,
  dispatch,
  activeId,
  manualValuation
) {
  const getItemSize = (index) => {
    const item = searchResultWithMonthLabels[index]
    if (item.listing) {
      return manualValuation && isEditable(manualValuation) ? 200 : 150
    }
    return 50
  }

  const renderRow = ({ index, style }) => {
    const { listing, date } = searchResultWithMonthLabels?.[index] || {}

    if (!listing && !date) return null

    if (date) {
      return (
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1, ...style }}>
          {date.format('MMMM YYYY')}
        </Typography>
      )
    } else if (listing) {
      return (
        <Box
          onMouseEnter={() =>
            dispatch(setActivePoint({ id: `hemnet_listing_${listing.id}` }))
          }
          sx={{
            ml: '-3px',
            borderRightStyle: 'solid',
            borderRightWidth: '3px',
            borderRightColor:
              activeId === `hemnet_listing_${listing.id}`
                ? 'highlight.main'
                : 'transparent',
            mb: 2,
            ...style,
          }}
        >
          <ListingCard
            streetAddress={listing.streetAddress}
            housingType={listing.housingType}
            numRooms={listing.noRooms}
            livingArea={listing.livingArea}
            plotArea={listing.plotSize}
            saleStatus={listing.statusFormatted}
            saleDate={listing.soldDate}
            salePrice={listing.soldPrice}
            listingDate={listing.publicationDate}
            listingPrice={listing.listingPrice}
            thumbSrc={listing.thumbImages?.[0]}
            sublocality={null}
            link={`/listing/${listing.id}`}
          >
            {manualValuation &&
              isEditable(manualValuation) &&
              (Object.keys(manualValuation.comparables).includes(
                `hemnet_listing_${listing.id}`
              ) ? (
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => {
                    dispatch(
                      removeComparableFromManualValuation({
                        comparableId: `hemnet_listing_${listing.id}`,
                        manualValuationId: manualValuation.id,
                      })
                    )
                  }}
                >
                  <Remove />
                  Remove
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    dispatch(
                      addComparableToManualValuation({
                        type: 'hemnet_listing',
                        listingId: listing.id,
                        manualValuationId: manualValuation.id,
                      })
                    )
                  }}
                >
                  <Add /> Add
                </IconButton>
              ))}
          </ListingCard>
        </Box>
      )
    }
  }

  return {
    getItemSize,
    renderRow,
  }
}
