import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import {
  createNewHome,
  getHome,
  getHomeSuggestions,
  getNewPreview,
  listHomes,
  saveHome,
  syncMarketplaceHome,
} from './marketplaceActions'

const marketplaceAdapter = createEntityAdapter({
  selectId: (home) => home['id'],
  //sortComparer: (a, b) => a.created_at.localeCompare(b.created_at) * -1,
})

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState: marketplaceAdapter.getInitialState({
    homesLoading: 'idle',
    syncLoading: 'idle',
    homeLoading: 'idle',
    suggestionsLoading: 'idle',
    previewLoading: 'idle',
    createLoading: 'idle',
    saveLoading: 'idle', // This scales kinda bad
    preview: null,
    suggestions: null,
    createdHome: null,
    demoMode: false,
  }),
  reducers: {
    setDemoMode: (state, action) => ({ ...state, demoMode: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      /* List Homes */
      .addCase(listHomes.pending, (state, action) => ({
        ...state,
        homesLoading: 'pending',
      }))
      .addCase(listHomes.fulfilled, (state, action) => {
        console.log({ action })
        marketplaceAdapter.upsertMany(state, action.payload)
        state.homesLoading = 'fulfilled'
      })
      .addCase(listHomes.rejected, (state, action) => ({
        ...state,
        homesLoading: { status: 'rejected', payload: action },
      }))
      /* Get home */
      .addCase(getHome.pending, (state, action) => ({
        ...state,
        homeLoading: 'pending',
      }))
      .addCase(getHome.fulfilled, (state, action) => {
        state.homeLoading = 'fulfilled'
        if (!action.payload) return
        marketplaceAdapter.upsertOne(state, action.payload)
      })
      .addCase(getHome.rejected, (state, action) => ({
        ...state,
        homeLoading: { status: 'rejected', payload: action },
      }))
      /* Get home suggestions */
      .addCase(getHomeSuggestions.pending, (state) => ({
        ...state,
        suggestionsLoading: 'pending',
      }))
      .addCase(getHomeSuggestions.fulfilled, (state, action) => {
        state.suggestionsLoading = 'fulfilled'
        state.suggestions = action.payload
      })
      .addCase(getHomeSuggestions.rejected, (state, action) => ({
        ...state,
        suggestionsLoading: { status: 'rejected', payload: action },
      }))
      /* Get preview */
      .addCase(getNewPreview.pending, (state) => ({
        ...state,
        previewLoading: 'pending',
      }))
      .addCase(getNewPreview.fulfilled, (state, action) => {
        state.previewLoading = 'fulfilled'
        if (!action.payload) return
        state.preview = action.payload
      })
      .addCase(getNewPreview.rejected, (state, action) => ({
        ...state,
        previewLoading: { status: 'rejected', payload: action },
      }))
      /* Create new home */
      .addCase(createNewHome.pending, (state) => ({
        ...state,
        createLoading: 'pending',
      }))
      .addCase(createNewHome.fulfilled, (state, action) => {
        state.createLoading = 'fulfilled'
        if (!action.payload) return
        state.createdHome = action.payload
      })
      .addCase(createNewHome.rejected, (state, action) => ({
        ...state,
        createLoading: { status: 'rejected', payload: action },
      }))
      /* Sync marketplace */
      .addCase(syncMarketplaceHome.pending, (state, action) => ({
        ...state,
        syncLoading: 'pending',
      }))
      .addCase(syncMarketplaceHome.fulfilled, (state, action) => ({
        ...state,
        syncResult: action.payload,
        syncLoading: 'fulfilled',
      }))
      .addCase(syncMarketplaceHome.rejected, (state, action) => ({
        ...state,
        syncLoading: { status: 'rejected', payload: action },
        syncResult: null,
      }))
      /* save home */
      .addCase(saveHome.pending, (state) => ({
        ...state,
        saveLoading: 'pending',
      }))
      .addCase(saveHome.fulfilled, (state, action) => ({
        ...state,
        saveLoading: 'fulfilled',
      }))
      .addCase(saveHome.rejected, (state, action) => ({
        ...state,
        saveLoading: { status: 'rejected', payload: action },
      }))
  },
})

//const {selectAll} = marketplaceAdapter.getSelectors()

export default marketplaceSlice.reducer
