import { ContentCopy } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  Container,
  Input,
  Link,
  Typography,
} from '@mui/material'
import Form from '@rjsf/core'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useQuery } from '../../clients/routerUseQuery'
import { configSelector } from '../../configSlice'
import { theme } from '../../theme'
import {
  getOffer,
  getOfferByIdSelector,
  selectLoading,
  updateOffer,
} from './offersSlice'
import schema from './schema/OfferConfigure.json'

const uiSchema = {
  email: { 'ui:readonly': true },
  contact_name: { 'ui:readonly': true },
  address_slug: { 'ui:readonly': true },
  reference: {
    total_after_costs: { 'ui:readonly': true }, // inferred from other fields
  },
}

const getDefaultReference = (fixedAmount) => {
  const referenceAmount =
    typeof fixedAmount === 'object'
      ? (fixedAmount['min'] + fixedAmount['max']) / 2
      : fixedAmount
  return {
    selling_price: {
      min: referenceAmount * 1.05,
      max: referenceAmount * 1.08,
    },
    broker_fee: {
      type: 'relative',
      value: 0.03,
    },
    preparation_cost: {
      type: 'relative',
      value: 0.02,
    },
    additional_cost: {
      type: 'relative',
      value: 0.01,
    },
  }
}

const computeTotals = (reference) => {
  const isAmountOrCompleteRange = (value) => {
    if (typeof value === 'number') {
      return true
    }
    if (!value) {
      return false
    }
    if (typeof value.min === 'number' && typeof value.max === 'number') {
      return true
    }
    return false
  }
  const sellingPrice = reference.selling_price
  if (!isAmountOrCompleteRange(sellingPrice)) {
    return
  }
  const minSellingPrice =
    typeof sellingPrice === 'number' ? sellingPrice : sellingPrice.min
  const maxSellingPrice =
    typeof sellingPrice === 'number' ? sellingPrice : sellingPrice.max
  var min = minSellingPrice
  var max = maxSellingPrice

  const subtractCost = (costItem) => {
    const costEstimate = reference[costItem]
    if (
      costEstimate &&
      costEstimate.type &&
      isAmountOrCompleteRange(costEstimate.value)
    ) {
      if (typeof costEstimate.value === 'number') {
        min =
          min -
          (costEstimate.type === 'absolute'
            ? costEstimate.value
            : costEstimate.value * minSellingPrice)
        max =
          max -
          (costEstimate.type === 'absolute'
            ? costEstimate.value
            : costEstimate.value * maxSellingPrice)
      } else {
        min =
          min -
          (costEstimate.type === 'absolute'
            ? costEstimate.value.max
            : costEstimate.value.max * minSellingPrice)
        max =
          max -
          (costEstimate.type === 'absolute'
            ? costEstimate.value.min
            : costEstimate.value.min * maxSellingPrice)
      }
    }
  }

  const costItems = ['broker_fee', 'preparation_cost', 'additional_cost']
  costItems.forEach(subtractCost)

  return min === max ? min : { min, max }
}

/**
 * Recursively replace all $ref with the actual referenced schema.
 *
 * This is required for rjsf to preselect the right option in anyOf clauses based
 * on form data.
 *
 * When a custom title is added to a referenced object the schema is (e.g.):
 * {
 *   "anyOf": [
 *     {
 *       "title": "Preparation Cost (Besiktning, reparationer, styling)",
 *       "allOf": [
 *         {
 *           "$ref": "#/definitions/CostEstimate"
 *         }
 *       ]
 *     },
 *     {
 *       "type": "null",
 *       "title": "Not set (Preparation Cost (Besiktning, reparationer, styling))"
 *     }
 *   ],
 * }
 * In these cases, the whole parent object containing "allOf" should be replaced.
 */
function resolveRefs(obj, definitions) {
  // TODO apply on schema export in pydantic
  // TODO/beware circular dependencies can appear in schemas -> infinite recursion
  if (obj) {
    if (Array.isArray(obj)) {
      return obj.map((v) => (v === Object(v) ? resolveRefs(v, definitions) : v))
    }
    if (obj['allOf'] && Object.keys(obj['allOf']) === ['$ref']) {
      var updated = { ...obj, ...resolveRefs(obj['allOf'][0], definitions) }
      delete updated.allOf
      return updated
    }
    if (obj['$ref']) {
      return resolveRefs(
        definitions[obj['$ref'].slice('#/definitions/'.length)],
        definitions
      )
    }
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [
        k,
        v === Object(v) ? resolveRefs(v, definitions) : v,
      ])
    )
  }
}
const schemaResolved = resolveRefs(schema, schema.definitions)

export default function OfferPage(props) {
  const { offerId, debug } = useQuery()
  // const offer = useSelector(offerNoNullsByIdSelector(offerId))
  const offer = useSelector(getOfferByIdSelector(offerId))
  const loading = useSelector(selectLoading)
  const config = useSelector(configSelector)
  const dispatch = useDispatch()
  const offerLink = `${config.REACT_APP_APP_URL}/offer?offerId=${offerId}`

  const [formData, setFormData] = useState(offer)

  const onChange = useCallback(
    (e) => {
      const reference = e.formData.reference
      setFormData({
        ...e.formData,
        reference: reference
          ? {
              ...reference,
              total_after_costs: computeTotals(reference),
            }
          : null, // NOTE we want an explicit null, not undefined
      })
    },
    [setFormData]
  )
  const autofillReference = useCallback(() => {
    if (formData.amount) {
      const reference = getDefaultReference(formData.amount)
      setFormData({
        ...formData,
        reference: {
          ...reference,
          total_after_costs: computeTotals(reference),
        },
      })
    }
  }, [setFormData, formData])

  const validate = (formData, errors) => {
    if (!formData.amount && !formData.sell_with) {
      const atLeastOneMsg =
        'You must set at least one of "Sell to" or "Sell with" amounts.'
      errors.amount.addError(atLeastOneMsg)
      errors.sell_with.addError(atLeastOneMsg)
    }
    return errors
  }
  const submit = useCallback(
    ({ formData }) => {
      dispatch(updateOffer({ offerId, offer: formData }))
    },
    [dispatch, offerId]
  )

  useEffect(() => {
    dispatch(getOffer({ offerId })).then((action) => {
      if (action.type === 'offer/get/fulfilled') {
        setFormData(action.payload)
      }
    })
  }, [dispatch, offerId, setFormData])

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
  }

  return loading === 'idle' ? (
    <Container>
      <Typography variant="h3">Offer editor</Typography>
      <Card sx={{ my: 3 }}>
        <CardContent sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            component={Link}
            href={offerLink}
            target="_blank"
          >
            Open offer page
          </Button>
          <Input value={offerLink} sx={{ flexGrow: 1 }} readOnly />
          <Button onClick={() => copyToClipboard(offerLink)}>
            Copy <ContentCopy sx={{ ml: 1, fontSize: '1.5em' }} />
          </Button>
        </CardContent>
      </Card>

      <div style={{ ...theme.typography }}>
        <div>
          <Form
            schema={schemaResolved}
            uiSchema={uiSchema}
            formData={_.cloneDeep(formData)}
            onChange={onChange}
            onSubmit={submit}
            validate={validate}
          />
        </div>
        <Card sx={{ my: 3 }}>
          <CardContent sx={{ display: 'flex', gap: 2 }}>
            <Button variant="outlined" onClick={autofillReference}>
              Autofill "Reference"
            </Button>
            <Typography>
              * NOTE you need to toggle from "Not set" to "Reference" to see
              changes
            </Typography>
          </CardContent>
        </Card>
        {debug ? (
          <div>
            <Typography>
              <Typography variant="h2">formData (draft)</Typography>
              <pre>{JSON.stringify(formData, null, 2)}</pre>
            </Typography>
            <Typography>
              <Typography variant="h2">offer (backend)</Typography>
              <pre>{JSON.stringify(offer, null, 2)}</pre>
            </Typography>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Container>
  ) : (
    <pre>{JSON.stringify(loading, null, 2)}</pre>
  )
}
