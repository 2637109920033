import { FmdGood } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  LinearProgress,
} from '@mui/material'
import { compose } from '@reduxjs/toolkit'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { Helmet } from 'react-helmet'
import { connect, useDispatch, useSelector } from 'react-redux'
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { useLocation } from 'react-router'

import { useQuery } from '../../clients/routerUseQuery'
import { RemainingHeightContainer } from '../../components/RemainingHeightContainer'
import { boundsToObject } from '../../utils/mapUtils'
import { getSearchResults } from '../listings/listingsSlice'
import { ValuationPanel } from '../valuate/components/ValuationPanel'
import {
  manualValuationByIdConnector,
  manualValuationByIdSelector,
} from '../valuate/valuationsSelectors'
import { MapPointsPreview } from './components/MapPointsPreview'
import SearchFilters from './components/SearchFilters'
import { SearchMap } from './components/SearchMap'
import { SearchResultList } from './components/SearchResultList'
import { generateDslQuery } from './elasticUtils'
import {
  getListingsInBounds,
  previewPointsSelector,
  setPreviewIds,
  setSearchResultIds,
} from './mapSearchSlice'
import { useListingSearch } from './searchHook'
import { encodeToURLParameter } from './urlFilterUtils'

function SearchPage({ manualValuation }) {
  const dispatch = useDispatch()
  const previewMapPoints = useSelector(previewPointsSelector)
  const searchMapRef = useRef(null)
  const {
    isSearchLoading,
    updateLocation,
    initSearch,
    initialized,
    searchParametersTyped,
    searchResult,
  } = useListingSearch()

  useEffect(() => {
    initSearch({ manualValuation })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const valuationLoading =
    !isLoaded(manualValuation) && !isEmpty(manualValuation)
  const loading = isSearchLoading || valuationLoading

  const onSearchFiltersUpdate = useCallback(
    (searchParametersTypedUpdated) => {
      if (!initialized) return

      // don't run search if parameters are not changed and
      // we have a search result (i.e. not init)
      if (
        encodeToURLParameter(searchParametersTypedUpdated) ===
          encodeToURLParameter(searchParametersTyped) &&
        !_.isEmpty(searchResult)
      ) {
        return
      }

      const dslQuery = generateDslQuery({
        parametersTyped: searchParametersTypedUpdated,
      })
      const mapPointsPromise = dispatch(
        getListingsInBounds({
          bounds: boundsToObject(searchParametersTypedUpdated.bounds.values),
        })
      )
      const searchResultsPromise = dispatch(
        getSearchResults({ ...dslQuery })
      ).unwrap()
      Promise.all([mapPointsPromise, searchResultsPromise]).then(
        ([mapPoints, searchResults]) => {
          if (!searchResults?.hits) return
          const ids = searchResults.hits.hits.map(
            (v) => `hemnet_listing_${v._id}`
          )
          dispatch(setSearchResultIds({ searchResultIds: ids }))
          dispatch(setPreviewIds({ ids: [] }))
          updateLocation(searchParametersTypedUpdated)
        }
      )
    },
    [dispatch, initialized, updateLocation, searchParametersTyped, searchResult]
  )

  const moveMap = useCallback(
    ({ bounds }) => {
      if (!initialized) return
      searchMapRef.current.fitMapBounds({ bounds })
    },
    [initialized, searchMapRef]
  )

  const manualValuationMarker = useMemo(() => {
    const { latitude, longitude } =
      manualValuation?.features_snapshot?.features?.geo_location || {}
    return latitude && longitude ? (
      <FmdGood
        lat={latitude}
        lng={longitude}
        sx={{
          color: 'highlight.main',
          transform: 'translate(-50%, -100%)',
          fontSize: '40px',
          clipPath: 'inset(3px 7px 3px 7px)',
        }}
      />
    ) : null
  }, [manualValuation])

  return !isLoaded(manualValuation) && !isEmpty(manualValuation) ? (
    <LinearProgress />
  ) : (
    <Container maxWidth="1200px">
      <Helmet>
        <title>Search</title>
      </Helmet>
      <Box sx={{ height: 2, mb: 2 }}>{loading && <LinearProgress />}</Box>
      <Box sx={{ mb: 5 }}>
        <SearchFilters
          onSearchFiltersUpdate={onSearchFiltersUpdate}
          moveMap={moveMap}
        />
      </Box>
      <RemainingHeightContainer display="flex" flexDirection="row" gap={3}>
        {(height) => (
          <>
            <Box flex="0 0 500px">
              {previewMapPoints?.length ? (
                <MapPointsPreview
                  previewMapPoints={previewMapPoints}
                  manualValuation={manualValuation}
                  onClose={() => dispatch(setPreviewIds({ ids: [] }))}
                />
              ) : (
                <SearchResultList manualValuation={manualValuation} />
              )}
            </Box>

            <Box flex="1 0 400px">
              <ContainerDimensions>
                {({ width }) =>
                  initialized && (
                    <SearchMap
                      height={height}
                      width={width}
                      onSearchFiltersUpdate={onSearchFiltersUpdate}
                      mapRef={searchMapRef}
                    >
                      {manualValuationMarker}
                    </SearchMap>
                  )
                }
              </ContainerDimensions>
            </Box>

            {manualValuation && (
              <ValuationPanel
                flex="0 0 450px"
                manualValuation={manualValuation}
              />
            )}
          </>
        )}
      </RemainingHeightContainer>
    </Container>
  )
}

function SearchPageLoader({ manualValuation, ...props }) {
  return isLoaded(manualValuation) ? (
    isEmpty(manualValuation) ? (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Valuation not found
      </Alert>
    ) : (
      <SearchPage manualValuation={manualValuation} {...props} />
    )
  ) : (
    <LinearProgress />
  )
}

// attaches a listener
const SearchPageWithManualValuation = compose(
  firestoreConnect((props) =>
    manualValuationByIdConnector(props.manualValuationId)()
  ),
  connect((state, props) => ({
    manualValuation: manualValuationByIdSelector(
      state,
      props.manualValuationId
    ),
  }))
)(SearchPageLoader)

export default function SearchPageWrapper() {
  const manualValuationId = useQuery(useLocation().search).target

  return manualValuationId ? (
    <SearchPageWithManualValuation manualValuationId={manualValuationId} />
  ) : (
    <SearchPage />
  )
}
