import { Alert, AlertTitle } from '@mui/material'
import { useState } from 'react'
import { browserName, isMobile } from 'react-device-detect'

export default function BrowserWarning() {
  const [hidden, setHidden] = useState(false)

  if (hidden) return <></>

  if (browserName !== 'Chrome') {
    return (
      <Alert severity="warning" onClose={() => setHidden(true)}>
        <AlertTitle>Your browser is not fully supported.</AlertTitle>
        Since Sabi is an internal tool, we unfortunately cannot support all
        available browsers. Please use Google Chrome Desktop on Mac, Windows or
        Linux.
      </Alert>
    )
  }

  if (isMobile) {
    return (
      <Alert severity="warning" onClose={() => setHidden(true)}>
        <AlertTitle>
          Google Chrome on Mobile is not fully supported yet.
        </AlertTitle>
        You might experience issues using Sabi on mobile devices.
      </Alert>
    )
  }

  return <></>
}
