import { Add } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'

import GeoMap from '../../components/GeoMap'
import { TagsSection } from '../../components/tags/TagsSection'
import {
  formatDateTime,
  formatSEK,
  formatSEKPerArea,
  renderTextWithLinks,
} from '../../utils/formatUtils'
import { comparableToMapItem } from '../../utils/mapUtils'
import HomeAVMValuations from '../avm/components/HomeAVMValuations'
import { estimateBasedOnComparables } from '../compare/comparisonUtils'
import ComparablesTableV2 from '../compare/components/ComparablesTableV2'
import { HomeFeaturesCard } from '../leads/components/HomeFeaturesCard'
import { getStreetAddress } from '../leads/homeUtils'
import { getGoogleSearchLink } from '../leads/leadUtils'
import EstimateForm from './components/EstimateForm'
import { TooltipSimpleEstimate } from './components/TooltipSimpleEstimate'
import { ValuationCommentForm } from './components/ValuationCommentForm'
import {
  commitManualValuation,
  deleteManualValuation,
  removeComparableFromManualValuation,
} from './valuationActions'
import {
  addTagToManualValuation,
  manualValuationByIdConnector,
  manualValuationByIdSelector,
  removeTagFromManualValuation,
} from './valuationsSelectors'
import { isEditable, manualValuationFormatted } from './valuationUtils'

export default function ValuationDetailPage({ match }) {
  const dispatch = useDispatch()
  const { params } = match

  const history = useHistory()
  const urlSearchParams = new URLSearchParams(history.location.search)

  const activeTab = urlSearchParams.get('tab') || 'overview'
  const setActiveTab = useCallback(
    (tab) => {
      history.push({
        search: `?tab=${tab}`,
      })
    },
    [history]
  )

  const manualValuationId = params.manualValuationId
  useFirestoreConnect(manualValuationByIdConnector(manualValuationId))
  const manualValuation = useSelector((state) =>
    manualValuationByIdSelector(state, manualValuationId)
  )
  const loading = !isLoaded(manualValuation)

  console.log('manualValuation', manualValuation)

  const mapItems = Object.values(manualValuation?.comparables || {})
    .map(comparableToMapItem)
    .filter((m) => m)

  const features = manualValuation?.features_snapshot?.features

  const commit = useCallback(() => {
    dispatch(commitManualValuation({ manualValuationId }))
  }, [manualValuationId, dispatch])

  const delete_ = useCallback(() => {
    dispatch(deleteManualValuation({ manualValuationId }))
  }, [manualValuationId, dispatch])

  const manualValuationEstimateFormatted = manualValuationFormatted(
    manualValuation?.valuation_estimate
  )

  const actions = useMemo(() => {
    const noComparables = _.isEmpty(manualValuation?.comparables)

    return (
      features &&
      [
        {
          label: 'Commit',
          color: 'primary',
          onClick: commit,
          disabled: noComparables || !isEditable(manualValuation),
        },
        {
          label: 'Delete',
          color: 'error',
          onClick: delete_,
          disabled: !isEditable(manualValuation),
        },
        {
          label: 'Hemnet listing',
          color: 'primary',
          to: `/listing/${manualValuation?.source_id}`,
          hide: manualValuation?.source_type !== 'hemnet_listing',
          outlined: true,
        },
        {
          label: 'Renovation estimate',
          color: 'primary',
          to: `/renovation-estimate/${manualValuationId?.renovation_estimate_id}`,
          hide: !manualValuation?.renovation_estimate_id,
          outlined: true,
        },
      ].filter((a) => !a.hide)
    )
  }, [manualValuation, manualValuationId, commit, delete_, features])

  const externalLinks = useMemo(() => {
    if (!features) return []
    const streetAddress = getStreetAddress(features)
    const postalTownOrCode =
      features?.postal_address?.postal_town ||
      features?.postal_address?.postal_code
    return [
      {
        label: 'Booli',
        href: getGoogleSearchLink(
          `${streetAddress} ${postalTownOrCode}`,
          'booli.se'
        ),
      },
      {
        label: 'Hemnet',
        href: getGoogleSearchLink(
          `${streetAddress} ${postalTownOrCode}`,
          'hemnet.se'
        ),
      },
      {
        label: 'Mäklarstatistik',
        href: getGoogleSearchLink(postalTownOrCode, 'maklarstatistik.se'),
      },
    ]
  }, [features])

  if (loading) {
    return (
      <>
        <LinearProgress />
      </>
    )
  }

  if (_.isEmpty(manualValuation)) {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>Manual valuation not found.
        </Alert>
      </Box>
    )
  }

  const targetMapItem = features?.geo_location && {
    coordinates: {
      lat: features.geo_location.latitude,
      lng: features.geo_location.longitude,
    },
  }

  const sqmBasedEstimate = estimateBasedOnComparables({
    comparables: manualValuation?.comparables,
    targetLivingArea: features?.living_area,
    roundTo: 10000,
  })

  return (
    manualValuation && (
      <>
        <Helmet>
          <title>{manualValuation.title}</title>
        </Helmet>
        <Typography variant="h2">{manualValuation.title}</Typography>
        {manualValuation.deleted && (
          <Alert severity="error" sx={{ mb: 3 }}>
            <AlertTitle>Deleted</AlertTitle>
            This valuation has been deleted.
          </Alert>
        )}
        <TagsSection
          tags={manualValuation.tags}
          canModify={isEditable(manualValuation)}
          suggestionsDocKey="manual_valuation"
          onAdd={(tag) => {
            dispatch(addTagToManualValuation({ manualValuationId, tag }))
          }}
          onRemove={(tag) => {
            dispatch(removeTagFromManualValuation({ manualValuationId, tag }))
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1 }}>
          {actions?.map(({ onClick, color, label, disabled, to, outlined }) => (
            <Button
              component={onClick ? 'button' : Link}
              key={label}
              variant={outlined ? 'outlined' : 'contained'}
              color={color}
              onClick={onClick || undefined}
              to={to}
              disabled={disabled}
            >
              {label}
            </Button>
          ))}
        </Box>

        <Tabs
          value={activeTab}
          onChange={(_, v) => setActiveTab(v)}
          sx={{ mb: 2 }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Manual valuation" value="manual" />
          <Tab label="AVM valuation" value="avm" />
        </Tabs>

        {activeTab === 'overview' && (
          <>
            <Box container sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
              <Card sx={{ flex: '1 0 300px' }}>
                <CardContent>
                  <Typography variant="h5">Details</Typography>
                </CardContent>
                <Table>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 700 }}
                    >
                      Created
                    </TableCell>
                    <TableCell>
                      {formatDateTime(manualValuation.created_at)}
                      <br />
                      {manualValuation.created_by}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 700 }}
                    >
                      Commited
                    </TableCell>
                    <TableCell>
                      {manualValuation.committed_by ? (
                        <>
                          {formatDateTime(manualValuation.committed_at)}
                          <br />
                          {manualValuation.committed_by}
                        </>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 700 }}
                    >
                      Source
                    </TableCell>
                    <TableCell>{manualValuation.source_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 700 }}
                    >
                      Manual valuation
                    </TableCell>
                    <TableCell>{manualValuationEstimateFormatted}</TableCell>
                  </TableRow>
                </Table>
              </Card>

              <HomeFeaturesCard
                sx={{ flex: '1 0 300px' }}
                features={features}
              />

              <Card sx={{ flex: '1 0 400px' }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Comment
                  </Typography>

                  <Box
                    sx={{
                      overflowX: 'auto',
                      overflowY: 'scroll',
                      maxHeight: 300,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {renderTextWithLinks(manualValuation.comment)}
                  </Box>
                </CardContent>
              </Card>

              <Card sx={{ flex: '1 0 300px' }}>
                <CardContent>
                  <Typography variant="h5">Links</Typography>
                </CardContent>
                <List>
                  {externalLinks.map(({ label, href }) => (
                    <ListItemButton
                      key={label}
                      component="a"
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText primary={label} />
                    </ListItemButton>
                  ))}
                </List>
              </Card>
            </Box>
          </>
        )}

        {activeTab === 'manual' && (
          <>
            <Box container sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
              <Card sx={{ flex: '1 0 600px' }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Estimate
                  </Typography>
                  <EstimateForm
                    valuationEstimate={manualValuation.valuation_estimate}
                    manualValuationId={manualValuation.id}
                    disabled={!isEditable(manualValuation)}
                  />
                </CardContent>
              </Card>

              <Card sx={{ flex: '1 0 300px' }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Comment
                  </Typography>
                  <ValuationCommentForm
                    manualValuation={manualValuation}
                    disabled={!isEditable(manualValuation)}
                  />
                </CardContent>
              </Card>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              mt={3}
              alignItems="end"
            >
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Box />
                <Box sx={{ textAlign: 'center' }}>
                  {sqmBasedEstimate.estimatedPrice ? (
                    <>
                      <Typography fontWeight={700}>
                        Simple estimate <TooltipSimpleEstimate />:{' '}
                      </Typography>
                      <Typography>
                        {formatSEK(sqmBasedEstimate.estimatedPrice)} (
                        {formatSEKPerArea(
                          sqmBasedEstimate.averagePerLivingArea
                        )}
                        )
                      </Typography>
                    </>
                  ) : null}
                </Box>
                <Button
                  variant="contained"
                  component={Link}
                  to={`/search/?target=${manualValuationId}`}
                  color="primary"
                  disabled={!isEditable(manualValuation)}
                >
                  <Add sx={{ mr: 1 }} />
                  Add comparables
                </Button>
              </Box>

              <ComparablesTableV2
                width="100%"
                valuationFeatures={features}
                comparables={manualValuation.comparables}
                onRemoveComparable={
                  manualValuation.deleted
                    ? null
                    : (comparableId) =>
                        dispatch(
                          removeComparableFromManualValuation({
                            manualValuationId,
                            comparableId,
                          })
                        )
                }
              />
            </Box>

            {mapItems.length > 1 && (
              <Grid sx={{ mt: 3 }}>
                <GeoMap
                  mapItems={mapItems}
                  target={targetMapItem}
                  height={500}
                />
              </Grid>
            )}
          </>
        )}

        {activeTab === 'avm' && (
          <Box>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  AVM valuation
                </Typography>

                {manualValuation?.home_id ? (
                  <HomeAVMValuations homeId={manualValuation.home_id} large />
                ) : (
                  <Alert severity="info">
                    <AlertTitle>No AVM valuation possible</AlertTitle>
                    This valuation is not linked to a home, so no AVM valuation
                    is possible. Probably the valuation is too old.
                  </Alert>
                )}
              </CardContent>
            </Card>
          </Box>
        )}
      </>
    )
  )
}
