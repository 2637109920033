import { createSelector } from '@reduxjs/toolkit'

export const tagSuggestionConnector = (tagGroup) => [
  // need fallback, otherwise it will be undefined on component initialization and rff hates that
  { collection: 'sabi_tag_suggestion', doc: tagGroup ?? 'default' },
]

export const tagSuggestionSelector = createSelector(
  ({ firestore: { data } }, tagGroup) => data.sabi_tag_suggestion?.[tagGroup],
  (doc) => doc?.tags ?? {}
)
