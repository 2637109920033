import { ArrowBackIosNewRounded } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { getHome, getHomeSuggestions } from './redux/marketplaceActions'
import {
  homeLoadingSelector,
  homeSelector,
  suggestionsLoadingSelector,
} from './redux/marketplaceSelectors'
import EditForm from './sections/EditForm'

function EditHomePage() {
  const { homeId } = useParams()
  const dispatch = useDispatch()

  const home = useSelector((state) => homeSelector(state, homeId))

  const homeLoading = useSelector(homeLoadingSelector)
  const suggestionsLoading = useSelector(suggestionsLoadingSelector)
  const mapRef = useRef()
  /* const [suggestions, setSuggestions] = useState({})
  const updateSuggestion = useCallback(
    (key, value) => setSuggestions(state => ({...state, [key]: value})),
    [setSuggestions],
  ) */

  console.log('home', home)

  useEffect(() => {
    dispatch(getHome(homeId))
      .unwrap()
      .then(() => dispatch(getHomeSuggestions(homeId)))
  }, [dispatch, homeId])

  useEffect(
    () =>
      homeLoading === 'fulfilled' &&
      home?.geo_location &&
      createGoogleMap(mapRef, {
        lat: home.geo_location.latitude,
        lng: home.geo_location.longitude,
      }),
    [mapRef, homeLoading, home]
  )

  if (homeLoading?.status === 'rejected') {
    return (
      <Alert severity="error">
        <AlertTitle>Something went wrong</AlertTitle>
        {JSON.stringify(homeLoading.payload)}
      </Alert>
    )
  }

  if (home === undefined && homeLoading === 'fulfilled') {
    return (
      <Alert severity="error">
        <AlertTitle>Home not found</AlertTitle>
        Check the URL or try again later
      </Alert>
    )
  }

  return (
    <Container>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <IconButton LinkComponent={Link} to="/marketplace">
          <ArrowBackIosNewRounded />
        </IconButton>
        {homeLoading === 'pending' ? (
          <Skeleton
            variant="text"
            sx={{
              height: 70,
              width: '80%',
              maxWidth: '300px',
            }}
          />
        ) : (
          <Typography variant="h4">
            {home?.postal_address.street_address_formatted ||
              (home?.project_number &&
                `Project ${home.project_number}, ${
                  home.postal_address?.route_name || ''
                } ${home.postal_address?.street_number || ''}`) ||
              'Edit marketplace home'}
          </Typography>
        )}
      </Box>
      {suggestionsLoading === 'pending' ? (
        <Box>Loading suggestions</Box>
      ) : (
        suggestionsLoading?.status === 'rejected' && (
          <Box>
            Could not load suggestions:{' '}
            <pre>{JSON.stringify(suggestionsLoading)}</pre>
          </Box>
        )
      )}
      {homeLoading === 'pending' || !home ? (
        <CircularProgress />
      ) : (
        <EditForm mapRef={mapRef} home={home} />
      )}
    </Container>
  )
}

function createGoogleMap(mapRef, latLng) {
  if (!mapRef.current || !latLng) return
  const map = new window.google.maps.Map(mapRef.current, {
    center: latLng,
    zoom: 17,
  })

  new window.google.maps.Marker({
    position: latLng,
    map,
  })
}

export default EditHomePage
