import HelpIcon from '@mui/icons-material/Help'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import { ImageLabeller } from './components/ImageLabeller'
import { LabellingWizard } from './components/LabellingWizard'
import { hideIntro, shouldShowIntroSelector, showIntro } from './labellingSlice'

export default function LabellingPage() {
  const shouldShowIntro = useSelector(shouldShowIntroSelector)
  const dispatch = useDispatch()

  const onWizardCompleted = () => {
    console.log('finished intro')
    dispatch(hideIntro())
  }

  return (
    <Box container sx={{ pt: '2em' }}>
      <Helmet>
        <title>Labelling</title>
      </Helmet>
      {shouldShowIntro ? (
        <LabellingWizard onCompleted={onWizardCompleted} />
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button variant="outlined" onClick={() => dispatch(showIntro())}>
              Help <HelpIcon />
            </Button>
          </Box>
          <ImageLabeller />
        </>
      )}
    </Box>
  )
}
