import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { NumericFormat } from 'react-number-format'

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={({ floatValue }) => {
        onChange({
          target: {
            name: props.name,
            value: floatValue,
          },
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
})

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default NumberFormatCustom
