import { CollectionsRounded } from '@mui/icons-material'
import { Box, FormControlLabel, Paper, Switch, Typography } from '@mui/material'
import React, { memo, useCallback, useMemo } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Field, useFormState } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { DraggableImage } from '../components/FormComponents'

function SectionImages({ isRearranging }) {
  const { values } = useFormState('images', { subscription: { values: true } })
  const includedImages = useMemo(
    () => values.images.map(({ id }) => id),
    [values]
  )

  return (
    <>
      <FieldArray name="images">
        {({ fields }) => (
          <Box
            sx={{
              width: '300px',
              marginInline: 'auto',
              display: isRearranging ? 'block' : 'none',
            }}
          >
            <DragDropContext
              onDragEnd={({ reason, source, destination }) =>
                reason === 'DROP' &&
                destination &&
                fields.move(source.index, destination.index)
              }
            >
              <Droppable droppableId="droppable-list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {fields.map((name, index) => (
                      <DraggableImage
                        name={name}
                        index={index}
                        moveToTop={() => fields.move(index, 0)}
                        moveToBottom={() =>
                          fields.move(index, fields.length - 1)
                        }
                        key={name}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
      </FieldArray>
      <FieldArray name="available_images">
        {({ fields }) => {
          if (fields.length === 0)
            return (
              <Box
                minHeight="200px"
                py={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <CollectionsRounded
                  sx={{ fontSize: 70, mb: 2, color: 'primary.main' }}
                />
                <Typography variant="h6">No images added yet.</Typography>
              </Box>
            )
          return (
            <FieldArray name="images">
              {({ fields: includedFields }) => (
                <Box
                  sx={{
                    display: isRearranging ? 'none' : 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                    gap: 2,
                  }}
                >
                  {fields.map((name) => (
                    <Paper sx={{ position: 'relative' }}>
                      <Field name={name}>
                        {({ input }) => (
                          <ImageSquare
                            value={input.value}
                            includedImages={includedImages}
                            push={includedFields.push}
                            remove={includedFields.remove}
                          />
                        )}
                      </Field>
                    </Paper>
                  ))}
                </Box>
              )}
            </FieldArray>
          )
        }}
      </FieldArray>
    </>
  )
}

SectionImages.propTypes = {}

const ImageSquare = memo(({ value, includedImages, push, remove }) => {
  const isIncluded = useMemo(
    () => includedImages.includes(value.id),
    [includedImages, value.id]
  )
  const toggle = useCallback(
    () => (isIncluded ? remove(includedImages.indexOf(value.id)) : push(value)),
    [isIncluded, remove, includedImages, push, value]
  )

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          px: 1,
          backgroundColor: 'overlay.main',
          zIndex: 1,
        }}
      >
        <FormControlLabel
          control={<Switch onChange={toggle} checked={isIncluded} />}
          label="Included"
        />
      </Box>
      <Box
        onClick={toggle}
        sx={{
          height: 'inherit',
          display: 'flex',
          opacity: isIncluded ? 1 : 0.8,
        }}
      >
        <Box
          component="img"
          src={value.src}
          sx={{
            aspectRatio: '1 / 1',
            width: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
    </>
  )
})

export default memo(SectionImages)
