import { Alert, LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import GoogleMapReact, { fitBounds } from 'google-map-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSupercluster from 'use-supercluster'

import {
  boundsToList,
  boundsToObject,
  cropBoundsEpsilon,
  getCenterFromBounds,
  waitForMapsWrapper,
} from '../../../utils/mapUtils'
import {
  isMapPointsLoading,
  mapPointsSelector,
  maxMapPointsReachedSelector,
  setPreviewIds,
} from '../mapSearchSlice'
import { useListingSearch } from '../searchHook'
import { CircleMarker } from './CircleMarker'

const MIN_ZOOM = 5
const MAX_ZOOM = 20

export function SearchMap({
  height,
  width,
  onSearchFiltersUpdate,
  children,
  mapRef,
}) {
  const dispatch = useDispatch()

  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(null)
  const [center, setCenter] = useState(null)

  const isLoading = useSelector(isMapPointsLoading)
  const maxMapPointsReached = useSelector(maxMapPointsReachedSelector)
  const points = useSelector(mapPointsSelector)
  const { searchParameters, setSearchParameter } = useListingSearch()
  const searchParamBounds =
    searchParameters?.bounds && boundsToObject(searchParameters.bounds)

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: bounds && [
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat,
    ],
    zoom,
    options: { radius: 75, maxZoom: 22 },
  })

  // map should be moved
  const fitMapBounds = useCallback(
    ({ bounds }) => {
      console.log('SearchMap fitMapBounds', bounds)
      if (!bounds?.nw || !bounds?.se) throw new Error('invalid bounds')
      const { zoom: newZoom, newBounds: newBoundsFitted } = fitBounds(bounds, {
        width,
        height,
      })
      setZoom(newZoom)
      setCenter(getCenterFromBounds(newBoundsFitted))
    },
    [width, height]
  )

  const onChange = useCallback(
    (data) => {
      setZoom(data.zoom)
      setBounds(data.bounds)
      setCenter(data.center)

      const updated = setSearchParameter('bounds', boundsToList(data.bounds))
      onSearchFiltersUpdate(updated)
      console.log('SearchMap onChange', data)
    },
    [onSearchFiltersUpdate, setSearchParameter]
  )

  useEffect(() => {
    fitMapBounds({ bounds: cropBoundsEpsilon(searchParamBounds) })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    mapRef.current = { fitMapBounds }
  }, [mapRef, fitMapBounds])

  return (
    <Box>
      {/* overlay over parent element */}
      <LinearProgress
        sx={{
          visibility: isLoading ? 'visible' : 'hidden',
          height: '4px',
          mt: '-4px',
        }}
      />
      <Box sx={{ height: 0, width: 'fit-content' }}>
        {maxMapPointsReached && (
          <Alert
            severity="warning"
            sx={{ position: 'absolute', zIndex: 1000, maxWidth: '100%' }}
          >
            Please zoom in to see all markers
          </Alert>
        )}
      </Box>

      <div style={{ width: `${width}px`, height: `${height}px` }}>
        <GoogleMapReact
          // no api keys, use existing global google maps object
          googleMapLoader={waitForMapsWrapper()}
          defaultZoom={18}
          zoom={zoom}
          center={center}
          onChange={onChange}
          options={{
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
          }}
        >
          {!maxMapPointsReached &&
            clusters.map(
              renderCluster(supercluster, (ids) =>
                dispatch(setPreviewIds({ ids }))
              )
            )}
          {children}
        </GoogleMapReact>
      </div>
    </Box>
  )
}

const renderCluster = (supercluster, setPreviewIds) => (cluster) => {
  const [longitude, latitude] = cluster.geometry.coordinates
  const {
    cluster: isCluster,
    point_count: pointCount,
    cluster_id: clusterId,
  } = cluster.properties

  const leaves = isCluster
    ? supercluster.getLeaves(clusterId, Infinity)
    : [cluster]

  const numInSearchResult = leaves.filter(
    (p) => p?.properties?.searchResult
  ).length
  const clusterInSearchResult = numInSearchResult > 0
  const active = leaves.some((p) => !!p?.properties?.active)

  const fillColor = active
    ? 'highlight.main'
    : clusterInSearchResult
    ? 'primary.main'
    : '#ccc'

  if (isCluster) {
    return (
      <CircleMarker
        key={`cluster-${clusterId}`}
        lat={latitude}
        lng={longitude}
        borderColor="white"
        fillPercentage={Math.max((100 * numInSearchResult) / pointCount, 10)}
        hover
        fillColor={fillColor}
        active={active}
        size={Math.min(20 + pointCount * 2, 50)}
        onClick={() => setPreviewIds(leaves.map((p) => p.properties.id))}
      >
        {pointCount}
      </CircleMarker>
    )
  }

  return (
    <CircleMarker
      key={`point-${cluster.properties.id}`}
      lat={latitude}
      lng={longitude}
      borderColor="white"
      hover
      active={active}
      fillColor={fillColor}
      size={20}
      onClick={() => setPreviewIds(leaves.map((p) => p.properties.id))}
    >
      1
    </CircleMarker>
  )
}

/*

const getDebugMarkers = ({ searchParamBounds, bounds, center }) => {
  const size = '80px'

  return [
    <Circle
      lat={searchParamBounds?.nw?.lat}
      lng={searchParamBounds?.nw?.lng}
      sx={{
        fontSize: size,
        color: 'red',
        transform: 'translate(-50%, -50%)',
      }}
    />,
    <Circle
      lat={searchParamBounds?.se?.lat}
      lng={searchParamBounds?.se?.lng}
      sx={{
        fontSize: size,
        color: 'red',
        transform: 'translate(-50%, -50%)',
      }}
    />,
    <Add
      lat={bounds?.nw?.lat}
      lng={bounds?.nw?.lng}
      sx={{
        fontSize: size,
        color: 'green',
        transform: 'translate(-50%, -50%)',
      }}
    />,
    <Add
      lat={bounds?.se?.lat}
      lng={bounds?.se?.lng}
      sx={{
        fontSize: size,
        color: 'green',
        transform: 'translate(-50%, -50%)',
      }}
    />,
    <Add
      lat={center?.lat}
      lng={center?.lng}
      sx={{
        fontSize: size,
        color: 'blue',
        transform: 'translate(-50%, -50%)',
      }}
    />,
  ]
}

*/
