import { Button, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { capitalize } from 'lodash'
import moment from 'moment'

export default function HubspotActions({ loading, info, onImport }) {
  if (loading) {
    return <CircularProgress size="2em" sx={{ ml: 2 }} />
  }

  if (info) {
    return (
      <Box>
        <Button
          variant="contained"
          color="primary"
          href={info.url}
          target="_blank"
          sx={{ mr: 1 }}
        >
          HubSpot
        </Button>
        <Button
          variant="outlined"
          color="primary"
          href={`/lead/${info.uuid}`}
          sx={{ mr: 2 }}
        >
          Lead
        </Button>
        {capitalize(info.stage)}, updated {moment(info.updated_at).fromNow()}
      </Box>
    )
  }

  return (
    <Button variant="outlined" color="primary" onClick={onImport}>
      Import lead
    </Button>
  )
}
