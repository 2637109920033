import { formatSEK } from '../../utils/formatUtils'

export const isEditable = (manualValuation) => {
  return !manualValuation?.deleted && !manualValuation?.committed_at
}

export const manualValuationFormatted = (manualValuation, noRange = false) => {
  if (noRange || !manualValuation?.low_80 || !manualValuation?.high_80) {
    return `${
      manualValuation?.expectation
        ? formatSEK(manualValuation.expectation)
        : '-'
    }`
  } else {
    return `${
      manualValuation?.low_80 ? formatSEK(manualValuation.low_80) : '-'
    } — ${manualValuation?.high_80 ? formatSEK(manualValuation.high_80) : '-'}`
  }
}
