import { CheckRounded, Warning } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Link, Paper, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Field, useFormState } from 'react-final-form'

import config from '../../../config'
import fields from '../schema/fields'

export default function SubmitSection({ homeId, errorFields = [] }) {
  const { submitting, valid, validating, submitSucceeded } = useFormState()
  return (
    <Paper
      sx={{
        position: 'sticky',
        zIndex: 1,
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        width: '100%',
        bottom: 0,
        pt: 2,
        pb: 1,
      }}
      elevation={4}
    >
      {errorFields.length > 0 && (
        <Box sx={{ pt: 1 }}>
          <Tooltip
            title={`Errors in the following fields: ${errorFields
              .map((f) => fields.editable[f]?.label || 'unknown')
              .join(', ')}`}
          >
            <Warning color="error" />
          </Tooltip>
        </Box>
      )}
      <LoadingButton
        type="submit"
        variant="contained"
        fullWidth
        loading={submitting}
        disabled={!valid || validating}
        sx={{ maxWidth: '350px' }}
        endIcon={submitSucceeded ? <CheckRounded /> : null}
      >
        {submitSucceeded ? 'saved' : 'save'}
      </LoadingButton>
      <Field name="visibility">
        {({ input }) =>
          input.value !== 'private' ? (
            <Button
              LinkComponent={Link}
              href={`${config.REACT_APP_APP_URL}/homes/${homeId}`}
              target="_blank"
            >
              View home
            </Button>
          ) : null
        }
      </Field>
    </Paper>
  )
}
