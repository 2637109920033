import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useState } from 'react'

import { TagsListV2 } from './TagsListV2'

export const TagsDialogWithInputFieldV2 = ({
  isOpen,
  onClose,
  onSubmit,
  onRemove,
  tagsObj,
  suggestions,
}) => {
  const [inputValue, setInputValue] = useState('')

  const onSubmitHandler = useCallback(() => {
    onSubmit(inputValue)
    setInputValue('')
    onClose()
  }, [inputValue, onSubmit, onClose])

  const onCloseHandler = useCallback(() => {
    onClose()
    setInputValue('')
  }, [onClose])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit tags</DialogTitle>
      <DialogContent sx={{ width: '600px', maxWidth: '100%' }}>
        <Box mb={2}>
          {!_.isEmpty(tagsObj) ? (
            <TagsListV2
              tagsObj={tagsObj}
              onRemove={onRemove}
              tagSuggestions={suggestions}
              canModify={true}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              No tags yet
            </Typography>
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            background: 'gray[200]',
            width: '100%',
            gap: 2,
            mt: 2,
          }}
        >
          {Object.entries(suggestions)
            ?.filter(([tagId]) => !tagsObj?.[tagId])
            .map(([tagId, { color, label }]) => (
              <Chip
                key={label}
                label={label}
                sx={{ background: `#${color}`, cursor: 'pointer' }}
                onClick={() => {
                  onSubmit(label)
                  onCloseHandler()
                }}
              />
            ))}
        </Box>
        <Tooltip
          title="Reuse existing tags whenever possible!"
          disableHoverListener
          placement="top"
        >
          <TextField
            name="tag"
            label="Add new tag"
            type="text"
            size="small"
            sx={{ width: '160px', mt: 3 }}
            InputLabelProps={{ sx: { fontSize: '0.9em' } }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmitHandler()
              }
            }}
          />
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler} color="primary">
          Cancel
        </Button>
        <Button
          onClick={onSubmitHandler}
          color="primary"
          disabled={!inputValue?.trim()?.length}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}
