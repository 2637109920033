import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { boundsToList, getBoundingBoxAroundPoint } from '../../utils/mapUtils'
import {
  isSearchQueryInitializedSelector,
  searchParametersSelector,
  selectLoading,
  selectSearchResult,
  setSearchParameters,
} from '../listings/listingsSlice'
import { DEFAULT_FILTERS, FILTER_CONFIG } from './filterConfig'
import {
  decodeFromURLParameter,
  encodeToURLParameter,
  mergeSearchParameters,
} from './urlFilterUtils'

export const useListingSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const searchParametersTyped = useSelector(searchParametersSelector)
  const searchResult = useSelector(selectSearchResult) || []
  const initialized = useSelector(isSearchQueryInitializedSelector)
  const isSearchLoading = useSelector(selectLoading) === 'pending'

  const searchParameters = useMemo(
    () =>
      !initialized
        ? {}
        : Object.fromEntries(
            Object.entries(searchParametersTyped).map(([key, value]) => [
              key,
              value.values,
            ])
          ),
    [searchParametersTyped, initialized]
  )

  const maxSearchResultReached =
    searchResult.length >= searchParameters.number_search_results

  const setSearchParameter = useCallback(
    (fieldName, values) => {
      if (FILTER_CONFIG[fieldName] === undefined) {
        console.log('ERROR: field does not exist', fieldName)
        return
      }
      if (fieldName === 'bounds') {
        const [nwlat, nwlng, selat, selng] = values
        if (nwlat < selat || nwlng > selng) {
          console.error('ERROR: invalid bounds', { nwlat, nwlng, selat, selng })
          throw Error('Tried to set invalid bounds')
        }
      }
      const newSearchParametersTyped = {
        [fieldName]: { values },
      }
      return dispatch(
        setSearchParameters(
          mergeSearchParameters([
            searchParametersTyped,
            newSearchParametersTyped,
          ])
        )
      ).payload
    },
    [searchParametersTyped, dispatch]
  )

  const updateLocation = useCallback(
    (searchParametersTypedUpdated) => {
      if (!initialized) return
      const urlSearchParams = new URLSearchParams(location.search)
      // would URIencode, we want to avoid that
      urlSearchParams.set('q', 'UNENCODEDQUERY')
      const search = urlSearchParams
        .toString()
        .replace(
          'UNENCODEDQUERY',
          encodeToURLParameter(searchParametersTypedUpdated)
        )
      if ('?' + search !== history.location.search) {
        history.replace({
          pathname: history.location.pathname,
          state: {
            ...history.location.state, // keep, e.g.for scroll state memorizer
          },
          search,
        })
      }
    },
    [history, location, initialized]
  )

  const initSearch = useCallback(
    ({ manualValuation }) => {
      const urlParameters = new URLSearchParams(location.search)
      const encodedParameters = urlParameters.get('q')

      // search query (parameters) already in store
      if (
        searchParametersTyped &&
        encodedParameters === encodeToURLParameter(searchParametersTyped)
      ) {
        return
      }

      const parametersToMerge =
        manualValuation && !encodedParameters
          ? [getDefaultQuery(), getQueryForManualValuation(manualValuation)]
          : [
              getDefaultQuery(),
              encodedParameters
                ? decodeFromURLParameter(encodedParameters)
                : {},
            ]

      const searchParameters = mergeSearchParameters(parametersToMerge)
      dispatch(setSearchParameters(searchParameters))
    },
    [location, dispatch, searchParametersTyped]
  )

  const resetSearchParameters = useCallback(
    (updateLocation) => {
      const { bounds } = searchParametersTyped
      const parameters = mergeSearchParameters([
        getDefaultQuery(),
        !updateLocation ? { bounds } : {},
      ])
      return dispatch(setSearchParameters(parameters)).payload
    },
    [dispatch, searchParametersTyped]
  )

  return {
    isSearchLoading,
    searchParameters,
    searchParametersTyped,
    resetSearchParameters,
    setSearchParameter,
    searchResult,
    updateLocation,
    initSearch,
    initialized,
    maxSearchResultReached,
  }
}

export const getQueryForManualValuation = (manualValuation) => {
  if (_.isEmpty(manualValuation)) return {}

  const f = manualValuation?.features_snapshot?.features

  const filterHousingType = []
  if (f.housing_type === 'apartment') {
    filterHousingType.push('lägenhet')
    filterHousingType.push('bostadsratt')
  } else if (f.housing_type === 'detached') {
    filterHousingType.push('villa')
  } else if (f.housing_type === 'townhouse') {
    filterHousingType.push('radhus')
    filterHousingType.push('bostadsratt')
  }

  const livingAreaMargin = Math.ceil(f.living_area * 0.15)
  const filterLivingArea = [
    f.living_area - livingAreaMargin,
    f.living_area + livingAreaMargin,
  ]

  const bounds = boundsToList(
    getBoundingBoxAroundPoint(
      { lat: f.geo_location.latitude, lng: f.geo_location.longitude },
      200
    )
  )

  const query = {
    housing_type: { type: 'term', values: filterHousingType },
    living_area: { type: 'range', values: filterLivingArea },
    bounds: { type: 'bounds', values: bounds },
    date: { type: 'range', values: [-1, 12] },
  }

  if (f?.num_rooms) {
    query.no_rooms = {
      type: 'range',
      values: [Math.floor(f?.num_rooms - 1), Math.ceil(f?.num_rooms + 1)],
    }
  }

  return query
}

export const getDefaultQuery = () => {
  const obj = {}
  DEFAULT_FILTERS.forEach((name) => {
    obj[name] = {
      type: FILTER_CONFIG[name].type,
      values: FILTER_CONFIG[name].default,
    }
  })
  obj.sale_status = {
    type: 'custom',
    values: ['sold'],
  }
  return obj
}
