import { HelpOutline, MoreHoriz, RestartAlt } from '@mui/icons-material'
import { Alert, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { getBoundingBoxAroundPoint } from '../../../utils/mapUtils'
import { debugSelector } from '../../debug/debugSlice'
import { generateDslQuery } from '../elasticUtils'
import { FILTER_CONFIG } from '../filterConfig'
import { selectStreetNames } from '../mapSearchSlice'
import { getDefaultQuery, useListingSearch } from '../searchHook'
import { AddressPickerButton } from './AddressPicker'
import MultiSelectFilter from './MultiSelectFilter'
import RangeFilter from './RangeFilter'
import SaleStatusToggle from './SaleStatusToggle'
import ScalarFilter from './ScalarFilter'

const DEFAULT_QUERY_PARAMETERS = Object.keys(getDefaultQuery())

export default function SearchFilters({ onSearchFiltersUpdate, moveMap }) {
  const {
    searchParametersTyped,
    setSearchParameter,
    initialized,
    resetSearchParameters,
  } = useListingSearch()
  const debugMode = useSelector(debugSelector)
  const [showAllFilters, setShowAllFilters] = useState(false)
  const [showQuery, setShowQuery] = useState(false)

  const setAddressBounds = useCallback(
    ([lat, lng]) => {
      const bounds = getBoundingBoxAroundPoint({ lat, lng }, 250)
      moveMap({ bounds })
    },
    [moveMap]
  )

  const streetNameOptions = useSelector(selectStreetNames)

  const set = useCallback(
    (fieldName, values) => {
      const updated = setSearchParameter(fieldName, values)
      onSearchFiltersUpdate(updated)
    },
    [onSearchFiltersUpdate, setSearchParameter]
  )

  const reset = useCallback(() => {
    const updated = resetSearchParameters(false)
    onSearchFiltersUpdate(updated)
  }, [resetSearchParameters, onSearchFiltersUpdate])

  if (!initialized) {
    return <></>
  }

  const visibleParameters = DEFAULT_QUERY_PARAMETERS.filter((fieldName) => {
    if (FILTER_CONFIG[fieldName].hiddenByDefault && !showAllFilters) {
      return false
    } else {
      return true
    }
  })
  const rangeParameters = visibleParameters.filter(
    (fieldName) => searchParametersTyped[fieldName].type === 'range'
  )
  const termParameters = visibleParameters.filter(
    (fieldName) => searchParametersTyped[fieldName].type === 'term'
  )
  const scalarParameters = visibleParameters.filter(
    (fieldName) => searchParametersTyped[fieldName].type === 'scalar'
  )

  const filterControls = [
    <Box key="sale_status" width={290} sx={{ flexGrow: 0 }}>
      <SaleStatusToggle
        value={searchParametersTyped.sale_status.values}
        setValue={(value) => set('sale_status', [value])}
        sx={{ width: '100%', height: 65 }}
      />
    </Box>,
    ...termParameters.map((fieldName) => (
      <Box key={fieldName} width={180} sx={{ flexGrow: 0 }}>
        <MultiSelectFilter
          label={FILTER_CONFIG[fieldName].label}
          values={searchParametersTyped[fieldName].values}
          setValues={(options) => set(fieldName, options)}
          options={FILTER_CONFIG[fieldName].options}
          multiple={FILTER_CONFIG[fieldName].multiple}
          sx={{ width: '100%', height: 65 }}
        />
      </Box>
    )),
    ...rangeParameters.map((fieldName) => (
      <Box key={fieldName} width={180} sx={{ flexGrow: 0 }}>
        <RangeFilter
          label={FILTER_CONFIG[fieldName].label}
          values={searchParametersTyped[fieldName].values}
          setValues={(range) => set(fieldName, range)}
          minValue={FILTER_CONFIG[fieldName].min}
          maxValue={FILTER_CONFIG[fieldName].max}
          step={FILTER_CONFIG[fieldName].step}
          unit={FILTER_CONFIG[fieldName].unit}
          sx={{ width: '100%', height: 65 }}
        />
      </Box>
    )),
    ...scalarParameters.map((fieldName) => (
      <Box key={fieldName} width={180} sx={{ flexGrow: 0 }}>
        <ScalarFilter
          label={FILTER_CONFIG[fieldName].label}
          value={searchParametersTyped[fieldName].values[0]}
          setValue={(value) => set(fieldName, [value])}
          minValue={FILTER_CONFIG[fieldName].min}
          maxValue={FILTER_CONFIG[fieldName].max}
          step={FILTER_CONFIG[fieldName].step}
          unit={FILTER_CONFIG[fieldName].unit}
          sx={{ width: '100%', height: 65 }}
          includeMaxValue={FILTER_CONFIG[fieldName].includeMaxValue}
        />
      </Box>
    )),

    <Box key="street" width={200} sx={{ flexGrow: 0 }}>
      <MultiSelectFilter
        label={FILTER_CONFIG.street.label}
        values={searchParametersTyped.street.values}
        setValues={(options) => set('street', [...options])}
        options={streetNameOptions}
        multiple={true}
        sort={false}
        count={true}
        sx={{ width: '100%', height: 65 }}
        alert={<StreetDataQualityAlert />}
      />
    </Box>,
  ]

  return (
    <>
      <Box
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <AddressPickerButton
          setValues={setAddressBounds}
          width={150}
          height={65}
        />
        {filterControls}

        {!showAllFilters ? (
          <Tooltip title="Show all filters">
            <IconButton onClick={() => setShowAllFilters(true)}>
              <MoreHoriz />
            </IconButton>
          </Tooltip>
        ) : (
          <Button onClick={() => setShowAllFilters(false)}>Hide filters</Button>
        )}

        <Tooltip title="Reset filters">
          <IconButton onClick={reset}>
            <RestartAlt />
          </IconButton>
        </Tooltip>
      </Box>
      {debugMode && (
        <Box>
          <Button onClick={() => setShowQuery(!showQuery)}>
            Elasticsearch query
          </Button>
          {showQuery && (
            <Typography sx={{ flex: 1, fontSize: 9 }}>
              <pre onClick={() => setShowQuery(false)}>
                {/*JSON.stringify(uniqueStreetAddresses, null, 2)*/}

                {JSON.stringify(
                  generateDslQuery({ parametersTyped: searchParametersTyped }),
                  null,
                  2
                )}
              </pre>
            </Typography>
          )}
        </Box>
      )}
    </>
  )
}

const TOOLTIP_TEXT = (
  <Typography sx={{ fontSize: '1.3em' }}>
    Sometimes realtors put wrong street names on Hemnet, which is the data we
    rely on.
    <br />
    Make sure you check the WHOLE dropdown for different spellings and the map
    for points close by.
  </Typography>
)

const StreetDataQualityAlert = () => {
  return (
    <Alert severity="warning">
      <Tooltip title={TOOLTIP_TEXT}>
        <Typography>
          Partially inaccurate!
          <HelpOutline
            sx={{ fontSize: '1.2em', verticalAlign: 'top', ml: 1 }}
          />
        </Typography>
      </Tooltip>
    </Alert>
  )
}
