import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { TagsDialogWithInputFieldV2 } from '../../../components/tags/TagsDialogWithInputFieldV2'
import {
  tagSuggestionConnector,
  tagSuggestionSelector,
} from '../../../components/tags/tagSuggestionsSelector'
import {
  addTagToListing,
  removeTagFromListing,
} from '../../listings/listingTagsSlice'

export const ListingTagsDialog = ({ tags, onClose, open, listingId }) => {
  const dispatch = useDispatch()

  useFirestoreConnect(tagSuggestionConnector('listing_tag'))
  const tagSuggestions = useSelector((state) =>
    tagSuggestionSelector(state, 'listing_tag')
  )

  return (
    <TagsDialogWithInputFieldV2
      isOpen={open}
      onClose={onClose}
      onSubmit={(tag) => {
        dispatch(addTagToListing({ listingId, tag }))
      }}
      onRemove={(tag) => {
        dispatch(removeTagFromListing({ listingId, tag }))
      }}
      suggestions={tagSuggestions}
      tagsObj={tags}
    />
  )
}
