import { configureStore } from '@reduxjs/toolkit'
import { actionTypes as rrfActionTypes } from 'react-redux-firebase'
import { constants as rfConstants } from 'redux-firestore'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import { createRootReducer } from './rootReducer'

const ROOT_KEY = 'root'
const VERSION = 1

const persistConfig = {
  key: ROOT_KEY,
  storage,
  stateReconciler: autoMergeLevel2,
  version: VERSION,
  whitelist: ['auth', 'debug', 'labelling', 'settings'],
}

const createStore = (getServices) => {
  const rootReducer = createRootReducer()
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: {
          warnAfter: 100,
        },
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
            // just ignore every redux-firebase and react-redux-firebase action type
            ...Object.keys(rfConstants.actionTypes).map(
              (type) => `${rfConstants.actionsPrefix}/${type}`
            ),
            ...Object.keys(rrfActionTypes).map(
              (type) => `@@reactReduxFirebase/${type}`
            ),
          ],
          ignoredPaths: ['firebase.profile.token'],
          warnAfter: 150,
        },
        thunk: {
          extraArgument: getServices,
        },
      }),
  })
}

export default createStore
