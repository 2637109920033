import {
  Alert,
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Container } from '@mui/system'
import { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { initQueue, selectQueue } from './dsAVMPredictionSlice'

export const DSAVMSamplesQueuePage = ({ match }) => {
  const dispatch = useDispatch()
  const { predictTaskId, sampleIds: sampleIdsString } = match.params

  const sampleIds = sampleIdsString.split(',')

  const queue = useSelector(selectQueue)

  const restartLabelling = useCallback(() => {
    if (!queue?.predictTaskId || !queue?.sampleIds?.length) {
      return
    }
    dispatch(
      initQueue({
        predictTaskId: queue.predictTaskId,
        sampleIds: queue.sampleIds,
      })
    )
  }, [queue?.predictTaskId, queue?.sampleIds, dispatch])

  useEffect(() => {
    if (predictTaskId && queue?.predictTaskId !== predictTaskId) {
      console.log('init queue')
      dispatch(initQueue({ predictTaskId, samples: sampleIds }))
    }
  }, [queue?.predictTaskId, predictTaskId, sampleIds, dispatch])

  if (!queue?.predictTaskId) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error">No queue found</Alert>
      </Container>
    )
  }

  const { sampleIdx } = queue

  return (
    <>
      <Helmet>
        <title>Samples Queue</title>
      </Helmet>
      <Container maxWidth="lg">
        <Typography variant="h4">Samples Queue</Typography>

        <Table>
          <TableRow>
            <TableCell variant="head">Task ID</TableCell>
            <TableCell>{predictTaskId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number of samples</TableCell>
            <TableCell>{sampleIds.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Sample IDs</TableCell>
            <TableCell>
              {' '}
              {sampleIds.map((sampleId) => (
                <Button
                  component={Link}
                  key={sampleId}
                  to={`/ds-avm-predict/${predictTaskId}/${sampleId}`}
                >
                  {sampleId}
                </Button>
              ))}
            </TableCell>
          </TableRow>
        </Table>

        <Box sx={{ mt: 3 }}>
          {sampleIdx > 0 ? (
            <Box>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                size="large"
                to={`/ds-avm-predict/${predictTaskId}/${sampleIds[sampleIdx]}`}
              >
                Continue
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={restartLabelling}
                size="large"
              >
                Restart
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                size="large"
                to={`/ds-avm-predict/${predictTaskId}/${sampleIds[sampleIdx]}`}
              >
                Start
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </>
  )
}
