import { FormControl, InputLabel, Slider, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

export default function RangeFilter({
  label,
  values,
  setValues,
  minValue,
  maxValue,
  step,
  unit,
  sx,
}) {
  const [displayValues, setDisplayValues] = useState([
    values[0] === -1 ? minValue : values[0],
    values[1] === -1 ? maxValue : values[1],
  ])

  useEffect(() => {
    setDisplayValues([
      values[0] === -1 ? minValue : values[0],
      values[1] === -1 ? maxValue : values[1],
    ])
  }, [values, minValue, maxValue])

  const commitValues = useCallback(() => {
    setValues([
      displayValues[0] <= minValue ? -1 : displayValues[0],
      displayValues[1] >= maxValue ? -1 : displayValues[1],
    ])
  }, [setValues, displayValues, minValue, maxValue])

  const rangeLabel = getRangeLabel(displayValues, minValue, maxValue, unit)

  return (
    <FormControl
      sx={{
        ...sx,
        px: 3,
        py: 1,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey.400',
        borderRadius: 1,
      }}
    >
      <Slider
        min={minValue}
        step={step}
        max={maxValue}
        value={displayValues}
        onChange={(e) => setDisplayValues(e.target.value)}
        onChangeCommitted={() => commitValues()}
        disableSwap
      />
      <InputLabel sx={{ mt: 2.75 }}>
        <Typography sx={{ fontSize: '0.8em' }} component="span">
          {label} {rangeLabel}
        </Typography>
      </InputLabel>
    </FormControl>
  )
}

const getRangeLabel = (values, minValue, maxValue, unit) => {
  const [lower, upper] = values

  if (lower === minValue && upper === maxValue) return ''

  const lowerDisplayed = lower <= minValue ? '..' : lower
  const upperDisplayed = upper >= maxValue ? '..' : upper

  return (
    <Typography
      key={1}
      sx={{ fontWeight: 'bold', ml: 1, fontSize: '1em' }}
      component="span"
    >
      {` ${lowerDisplayed}—${upperDisplayed} ${unit}`}
    </Typography>
  )
}
