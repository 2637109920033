import { LoadingButton } from '@mui/lab'
import { Alert, Button } from '@mui/material'
import { Box } from '@mui/system'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'

import { JSONErrorAlert } from '../../../../components/JSONErrorAlert'
import { debugSelector } from '../../../debug/debugSlice'
import { formFields, validate } from './FormFields'

export const SGOfferForm = ({
  initialValues,
  committed,
  onCommit,
  onSubmit,
  onDelete,
  flex,
}) => {
  const isDebug = useSelector(debugSelector)

  return (
    <Box flex={flex} p={2} pl={0}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({
          handleSubmit,
          submitting,
          valid,
          pristine,
          submitError,
          dirty,
          values,
        }) => (
          <>
            {!pristine && (
              <Alert
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  zIndex: 999,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  boxShadow: 5,
                }}
                severity="warning"
              >
                You have unsaved changes
              </Alert>
            )}
            {isDebug && (
              <Box maxWidth="sm">
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </Box>
            )}
            {submitError && (
              <JSONErrorAlert
                error={submitError}
                title="Form submission error"
                sx={{ mb: 3 }}
              />
            )}
            <form onSubmit={handleSubmit}>
              {formFields.map((row) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mb={3}
                  gap={2}
                >
                  {row
                    .filter((field) => shouldDisplay(values, field))
                    .map((field) => {
                      return {
                        ...field,
                        props: {
                          ...field.props,
                          disabled:
                            committed && !field.props?.editableAfterCommit,
                        },
                      }
                    })}
                </Box>
              ))}
              <LoadingButton
                type="submit"
                variant="contained"
                loading={submitting}
                disabled={submitting || !valid || pristine}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
              <Button
                variant="contained"
                sx={{ mt: 3, ml: 2 }}
                onClick={onCommit}
                disabled={submitting || dirty || !valid || committed}
              >
                {committed ? 'Presented' : 'Mark presentable'}
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ mt: 3, ml: 2 }}
                onClick={onDelete}
              >
                Delete
              </Button>
            </form>
          </>
        )}
      />
    </Box>
  )
}

const shouldDisplay = (values, field) => {
  const name = field?.props?.name
  if (values?.guarantee_price_type === 'fixed') {
    return name !== 'fee_percentage'
  }
  if (values?.guarantee_price_type === 'percentage') {
    return name !== 'fee_amount'
  }
  if (
    ['fee_amount', 'fee_percentage'].includes(name) &&
    !values?.guarantee_price_type?.length
  ) {
    return false
  }
  return true
}
