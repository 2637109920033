import { createSlice } from '@reduxjs/toolkit'

const debugSlice = createSlice({
  name: 'debug',
  initialState: false,
  reducers: {
    setDebugTrue: (state) => true,
    setDebugFalse: (state) => false,
  },
})

export const debugSelector = (state) => state.debug

export const { setDebugTrue, setDebugFalse } = debugSlice.actions
export default debugSlice.reducer
