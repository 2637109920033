import { Container, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'

import { FIREBASE_PROJECT_ID } from '../config'
import { theme } from '../theme'
import BrowserWarning from './BrowserWarning'
import Header from './Header'

export default function ViewContainer({ children, preconnectURLs }) {
  const backgroundPattern = FIREBASE_PROJECT_ID.startsWith('development')
    ? {
        background:
          'repeating-linear-gradient( -45deg, #fff, #fff 10px, hsl(208deg 60% 99%) 10px, hsl(208deg 60% 99%) 20px )',
        minHeight: '100vh',
      }
    : {}

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <BrowserWarning />
      <Container
        maxWidth="100%"
        sx={{ pt: 5, height: '100%', ...backgroundPattern }}
      >
        {children}
      </Container>
      {(preconnectURLs || []).map((url, idx) => (
        <link key={idx} rel="preconnect" href={url}></link>
      ))}
    </ThemeProvider>
  )
}
