import { capitalize } from 'lodash'

import { HOUSING_TYPES_MAPPING } from '../../utils/dataUtils'
import { formatArea } from '../../utils/formatUtils'

export const getFeaturesAsList = (features) =>
  features
    ? [
        {
          name: 'Housing type',
          value: capitalize(HOUSING_TYPES_MAPPING[features.housing_type]),
        },
        { name: 'Rooms', value: features.num_rooms },
        { name: 'Living area', value: formatArea(features.living_area) },
        { name: 'Plot area', value: formatArea(features.plot_area) },
        {
          name: 'Supplemental area',
          value: formatArea(features.supplemental_area),
        },
        { name: 'Floor', value: features.apartment_floor },
      ].filter((row) => row.value)
    : []

export const getStreetAddress = (features) => {
  const postalAddress = features?.postal_address
  return postalAddress
    ? postalAddress.street_address_formatted ||
        [
          postalAddress.route_name,
          postalAddress.street_number,
          postalAddress.street_entrance,
        ]
          .map((x) => x)
          .join(' ')
    : ''
}
