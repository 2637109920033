import {
  AspectRatio,
  AttachMoney,
  Balcony,
  CalendarToday,
  Crop169,
  CropLandscape,
  Deck,
  Description,
  Elevator,
  Engineering,
  Fireplace,
  Garage,
  Home,
  Kitchen,
  Layers,
  Percent,
  Pool,
  Sailing,
  Shower,
  Waves,
  Wc,
  Widgets,
} from '@mui/icons-material'
import { InputAdornment, MenuItem } from '@mui/material'
import { capitalize } from 'lodash'
import { makeRequired, Select, TextField } from 'mui-rff'
import { createElement } from 'react'
import * as Yup from 'yup'

const HOUSING_TYPES = {
  apartment: 'Apartment (lägenhet)',
  townhouse: 'Townhouse (radhus)',
  detached: 'Detached (villa)',
  holiday_home: 'Holiday home (fritidshus)',
}

const TENURES = {
  bostadsrätt: 'Bostadsrätt',
  ägarlägenhet: 'Ägarlägenhet',
  'andel i bostadsförening': 'Andel i bostadsförening',
  tomträtt: 'Tomträtt',
  andelsrätt: 'Andelsrätt',
  arrenderätt: 'Arrenderätt',
  äganderätt: 'Äganderätt',
}

const SELECT_FIELD_VALUES = {
  housingType: HOUSING_TYPES,
  tenure: TENURES,
}

const FIELD_ICONS = {
  housingType: Home,
  noRooms: Widgets,
  plotArea: AspectRatio,
  livingArea: CropLandscape,
  supplementalArea: Crop169,
  constructionYear: CalendarToday,
  numFloors: Layers,
  buildingNumStories: Layers,
  apartmentFloor: Layers,
  housingCooperativeShare: Percent,
  housingCooperativeFeePerMonth: AttachMoney,
  operatingCostPerYear: AttachMoney,
  isHeatingIncluded: Fireplace,
  numBalconies: Balcony,
  numBathrooms: Shower,
  numKitchens: Kitchen,
  numToilets: Wc,
  hasPatio: Deck,
  hasBalcony: Balcony,
  hasElevator: Elevator,
  hasSeaView: Sailing,
  hasSeasideLot: Waves,
  hasPool: Pool,
  tenure: Description,
  hasGarage: Garage,
  isNewProduction: Engineering,
}

export const schema = Yup.object({
  locationDetails: Yup.object({
    coordinates: Yup.object({
      lat: Yup.number(),
      lng: Yup.number(),
    }),
    place_id: Yup.string().test(
      'placeid-incorrect',
      'Invalid Google Maps place ID',
      (val) => val.length > 10
    ),
    structured: Yup.object({
      administrative_area_level_1: Yup.string(),
      country: Yup.string().test(
        'country-sweden',
        'Only Sweden supported',
        // TODO: standardise language!
        (x) => ['Sverige', 'Sweden'].includes(x)
      ),
      route: Yup.string(),
      postal_town: Yup.string(),
    }),
  })
    .default(undefined)
    .required(
      'Address must be in the format street with number, city, country.'
    ),
  housingType: Yup.mixed()
    .required()
    .oneOf(Object.keys(HOUSING_TYPES))
    .label('Housing type'),
  livingArea: Yup.number()
    .required()
    .positive()
    .integer()
    .min(5)
    .max(1000)
    .label('Living area'),
  noRooms: Yup.number().positive().max(25).label('Rooms'),
  plotArea: Yup.number()
    .positive()
    .integer()
    .min(30)
    .max(5000)
    .label('Plot area'),
  constructionYear: Yup.number()
    .positive()
    .integer()
    .min(1900)
    .max(2050)
    .label('Construction year'),
  supplementalArea: Yup.number()
    .positive()
    .integer()
    .min(0)
    .max(1000)
    .label('Supplemental area'),
  apartmentFloor: Yup.number()
    .integer()
    .min(-5)
    .max(30)
    .label('Floor (apartment)'),
  numFloors: Yup.number()
    .integer()
    .min(1)
    .max(30)
    .label('Number of floors (house)'),
  apartmentNumber: Yup.string().label('Apartment number'),
  buildingNumStories: Yup.number()
    .integer()
    .min(-5)
    .max(30)
    .label('Number of stories (apartment)'),
  tenure: Yup.mixed().oneOf(Object.keys(TENURES)).label('Tenure'),
  housingCooperativeShare: Yup.number()
    .min(0)
    .max(100)
    .label('Housing cooperative share (%)'),
  housingCooperativeFeePerMonth: Yup.number()
    .min(0)
    .max(50000)
    .label('Housing cooperative fee per month'),
  operatingCostPerYear: Yup.number()
    .min(0)
    .max(500000)
    .label('Operating cost per year'),
  isHeatingIncluded: Yup.boolean().label('Heating included'),
  numBathrooms: Yup.number()
    .integer()
    .min(0)
    .max(10)
    .label('Number of bathrooms'),
  numToilets: Yup.number().integer().min(0).max(10).label('Number of toilets'),
  numKitchens: Yup.number()
    .integer()
    .min(0)
    .max(10)
    .label('Number of kitchens'),
  numBalconies: Yup.number().label('Number of balconies'),
  hasPatio: Yup.boolean().nullable().label('Patio'),
  hasBalcony: Yup.boolean().nullable().label('Balcony'),
  hasElevator: Yup.boolean().nullable().label('Elevator'),
  hasSeaView: Yup.boolean().nullable().label('Sea view'),
  hasSeasideLot: Yup.boolean().nullable().label('Seaside lot'),
  hasGarage: Yup.boolean().nullable().label('Garage'),
  hasPool: Yup.boolean().nullable().label('Pool'),
  isNewProduction: Yup.boolean().nullable().label('New production'),
})

export const required = makeRequired(schema)

export const basicFormFieldNames = [
  'livingArea',
  'noRooms',
  'housingType',
  'plotArea',
  'supplementalArea',
]

export const valuationFormFields = Object.entries(schema.fields)
  .map(([fieldName, field]) => {
    const label = field.spec.label || capitalize(fieldName)

    switch (field.type) {
      case 'number':
        return (
          <TextField
            InputProps={{
              startAdornment: FIELD_ICONS[fieldName] && (
                <InputAdornment position="start">
                  {createElement(FIELD_ICONS[fieldName], {}, null)}
                </InputAdornment>
              ),
            }}
            key={fieldName}
            label={label}
            name={fieldName}
            required={required[fieldName]}
          />
        )

      case 'boolean':
        return (
          <Select
            key={fieldName}
            label={label}
            name={fieldName}
            required={required[fieldName]}
            sx={{ width: '100%' }}
            startAdornment={
              FIELD_ICONS[fieldName] && (
                <InputAdornment position="start">
                  {createElement(FIELD_ICONS[fieldName], {}, null)}
                </InputAdornment>
              )
            }
          >
            {[true, false, null].map((value) => (
              <MenuItem key={value} value={value}>
                {value === true
                  ? 'Yes'
                  : value === false
                  ? 'No'
                  : 'Not specified'}
              </MenuItem>
            ))}
          </Select>
        )
      case 'mixed':
        if (!SELECT_FIELD_VALUES[fieldName]) {
          return null
        }
        return (
          <Select
            key={fieldName}
            label={label}
            name={fieldName}
            required={required[fieldName]}
            startAdornment={
              FIELD_ICONS[fieldName] && (
                <InputAdornment position="start">
                  {createElement(FIELD_ICONS[fieldName], {}, null)}
                </InputAdornment>
              )
            }
          >
            {Object.entries(SELECT_FIELD_VALUES[fieldName]).map(
              ([key, label]) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              )
            )}
          </Select>
        )
      default:
        return null
    }
  })
  .filter((x) => x)
