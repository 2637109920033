import { Button, Link } from '@mui/material'

export const SabiHemnetLink = () => {
  const openInSabiScriptLink =
    // eslint-disable-next-line no-script-url
    'javascript:window.open("' +
    window.location.origin +
    '/from-hemnet/"+encodeURIComponent(document.location.href))'

  return (
    <>
      <Button
        component={Link}
        sx={{
          borderStyle: 'dotted',
          borderColor: 'primary.main',
          borderWidth: '1px',
        }}
        href={openInSabiScriptLink}
      >
        Open in Sabi
      </Button>
    </>
  )
}
