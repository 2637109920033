import { Place } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import GoogleMapReact from 'google-map-react'
import { capitalize } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

import { isSold } from '../features/listings/listingUtils'
import {
  formatArea,
  formatDate,
  formatRooms,
  formatSEK,
  formatSEKPerArea,
} from '../utils/formatUtils'
import {
  getBoundsZoomLevel,
  getCoordinatesCenter,
  waitForMapsWrapper,
} from '../utils/mapUtils'
import ThumbCarousel from './ThumbCarousel'

const MARKER_SIZE = 50
const MIN_ZOOM = 7
const MAX_ZOOM = 20
const NUM_MARKERS_DISPLAY = 10
const THUMB_HEIGHT = 180
const NUM_THUMBNAILS_PREFETCH = 3

const Z_INDEX = {
  marker: 0,
  markerHighlight: 2,
  target: 1,
  tooltip: 3,
}

const MapMarker = ({ focused, showTooltip }) => {
  return (
    <div>
      <Place
        color={focused ? 'highlight' : 'secondary'}
        onClick={showTooltip}
        stroke="#eee"
        strokeWidth={0.5}
        sx={{
          fontSize: MARKER_SIZE,
          position: 'relative',
          transform: 'translate(-50%, -100%)',
          zIndex: focused ? Z_INDEX.markerHighlight : Z_INDEX.marker,
        }}
      />
    </div>
  )
}

const Tooltip = ({ item, hide, open }) => {
  return (
    <Box
      container
      width={290}
      sx={{
        position: 'relative',
        transform: 'translate(-50%, -125%)',
        overflow: 'hidden',
        zIndex: Z_INDEX.tooltip,
      }}
    >
      <Card>
        <CardContent sx={{ boxShadow: 4 }}>
          <Box container sx={{ display: 'flex', width: '100%' }}>
            <Typography onClick={open} variant="h5" sx={{ flexGrow: 1 }}>
              {item.title}
            </Typography>
            <IconButton
              color="primary"
              sx={{ position: 'relative', transform: 'translate(25%, -25%)' }}
            >
              <CancelIcon sx={{ fontSize: 25 }} onClick={hide} />
            </IconButton>
          </Box>
          <CardActionArea onClick={open}>
            <ThumbCarousel images={item.images} imageHeight={THUMB_HEIGHT} />
          </CardActionArea>
          {item.listing && (
            <>
              <Box container sx={{ display: 'flex', width: '100%', mt: 2 }}>
                <Typography>
                  {isSold(item.listing)
                    ? `Sold on ${formatDate(item.listing.soldDate)}`
                    : `Listed since ${formatDate(
                        item.listing.publicationDate
                      )}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '2em' }}>
                <Typography sx={{ flex: 1 }}>
                  {`${formatArea(item.listing.livingArea)}, ${formatRooms(
                    item.listing.noRooms
                  )}`}
                </Typography>
                <Typography textAlign="right" fontWeight={700}>
                  {formatSEK(
                    isSold(item.listing)
                      ? item.listing.soldPrice
                      : item.listing.listingPrice
                  )}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '2em' }}>
                <Typography sx={{ flex: 1 }}>
                  {capitalize(item.listing.housingType)}
                </Typography>
                <Typography textAlign="right" sx={{ flex: 1 }}>
                  {formatSEKPerArea(
                    isSold(item.listing)
                      ? item.listing.soldPricePerLivingArea
                      : item.listing.listingPricePerLivingArea
                  )}
                </Typography>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

const PrefetchThumbnailElements = ({ mapItems }) =>
  mapItems
    .map((mapItem, itemIdx) =>
      mapItem.images
        .slice(0, NUM_THUMBNAILS_PREFETCH)
        .map((src, imgIdx) => (
          <link
            key={`${itemIdx}-${imgIdx}`}
            rel="prefetch"
            as="image"
            href={src}
          />
        ))
    )
    .flat()

export default function GeoMap({
  mapItems,
  setHighlightedMarkerId,
  highlightedMarkerId,
  height,
  tooltipOn,
  openById,
  target,
}) {
  // remove invalid coordinates to not mess up calculations
  mapItems = mapItems.filter(
    (l) => l.coordinates && l.coordinates.lat && l.coordinates.lng
  )

  // select NUM_MARKERS_DISPLAY map items for bounding box/center
  // (rest is visible if you pan/zoom out)
  const coordinates = mapItems
    .map((l) => l.coordinates)
    .slice(0, NUM_MARKERS_DISPLAY)
  if (target) {
    coordinates.unshift(target.coordinates)
  }

  // sort map items from north to south (for icon overlapping)
  mapItems.sort((a, b) => b.coordinates.lat - a.coordinates.lat)

  // actual size required for calculating zoom level
  // TODO: useEffect() for window resize?
  const containerRef = useRef()
  const mapWidth = containerRef.current ? containerRef.current.offsetWidth : 10
  const mapHeight = containerRef.current
    ? containerRef.current.offsetHeight
    : 10

  const center = getCoordinatesCenter(coordinates)
  const [openTooltipId, setOpenTooltipId] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(10)

  useEffect(() => {
    setZoomLevel(
      getBoundsZoomLevel({
        coordinatesList: coordinates,
        mapWidth,
        mapHeight,
      })
    )
  }, [coordinates, mapWidth, mapHeight])

  const showTooltip = useCallback(
    (mapItemId) => {
      if (!tooltipOn) return
      setHighlightedMarkerId(mapItemId)
      setOpenTooltipId(mapItemId)
    },
    [setHighlightedMarkerId, tooltipOn]
  )

  const validPositioning = center && center.lat > 0 && center.lng > 0

  const targetCoordinates = target && target.coordinates

  return (
    coordinates.length > 0 &&
    validPositioning && (
      <div style={{ height: height, width: '100%' }} ref={containerRef}>
        <GoogleMapReact
          // no api keys, use existing global google maps object
          googleMapLoader={waitForMapsWrapper()}
          center={center}
          zoom={zoomLevel}
          options={{
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            disableDoubleClickZoom: true,
          }}
        >
          {targetCoordinates && (
            <Place
              color="primary"
              // move a few meters in case target coords
              // equal comparable coords
              lat={targetCoordinates.lat + 0.00002}
              lng={targetCoordinates.lng + 0.00002}
              sx={{
                position: 'relative',
                fontSize: MARKER_SIZE,
                transform: 'translate(-50%, -100%)',
                zIndex: Z_INDEX.target,
              }}
            />
          )}
          {mapItems.map((mapItem) => (
            <MapMarker
              key={mapItem.id}
              lat={mapItem.coordinates.lat}
              lng={mapItem.coordinates.lng}
              focused={mapItem.id === highlightedMarkerId}
              showTooltip={() => showTooltip(mapItem.id)}
            />
          ))}
          {mapItems &&
            mapItems
              .filter((mapItem) => mapItem.id === openTooltipId)
              .map((mapItem) => (
                <Tooltip
                  key={mapItem.id}
                  lat={mapItem.coordinates.lat}
                  lng={mapItem.coordinates.lng}
                  item={mapItem}
                  open={() => openById(mapItem.id)}
                  hide={() => showTooltip(null)}
                />
              ))}
        </GoogleMapReact>
        <PrefetchThumbnailElements mapItems={mapItems} />
      </div>
    )
  )
}
