import { Link as MuiLink } from '@mui/material'
import { DatePicker, TextField } from 'mui-rff'
import { Link } from 'react-router-dom'

import { Boolean, Select } from '../components/FormComponents'

const fields = {
  readOnly: {
    acquisition_date: 'Acquisition date',
    listing_date: 'Listing date',
    sale_date: 'Sale date (contract)',
    created_date: 'Created date (marketplace)',
  },
  editable: {
    visibility: {
      label: 'Visibility',
      values: {
        public: 'Public',
        private: 'Private',
        link_only: 'Link only',
      },
      Component: Select,
    },
    status: {
      label: 'Status',
      Component: Select,
      values: {
        auto: 'Auto (via listing/sale date)',
        sold: 'Sold',
        for_sale: 'For sale',
        upcoming: 'Upcoming',
      },
    },
    description: {
      label: 'Description',
      Component: TextField,
      multiline: true,
      minRows: 3,
      maxRows: 10,
      infoBox: (
        <>
          You can even use formatting in the description via Markdown, more info{' '}
          <MuiLink
            href="https://www.markdownguide.org/basic-syntax/"
            target="_blank"
          >
            here
          </MuiLink>
          .
        </>
      ),
    },
    planned_date: {
      label: 'Planned date',
      Component: DatePicker,
    },
    COLUMN_AREAS: {
      num_rooms: {
        label: 'Rooms',
        Component: TextField,
      },
      living_area: {
        label: 'Living area',
        Component: TextField,
      },
    },
    COLUMN_MORE_AREAS: {
      plot_area: {
        label: 'Plot area',
        Component: TextField,
      },
      supplemental_area: {
        label: 'Supplemental area',
        Component: TextField,
      },
    },
    price: {
      label: 'Sale price',
      inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
      Component: TextField,
    },
    housing_type: {
      label: 'Housing type',
      values: {
        apartment: 'Apartment',
        detached: 'Detached',
        townhouse: 'Townhouse',
        holiday_home: 'Holiday home',
      },
      _house: ['detached', 'holiday_home', 'townhouse'],
      _non_house: ['apartment'],
      Component: Select,
    },
    construction_year: {
      label: 'Construction year',
      Component: TextField,
    },
    COLUMN_ADDRESS: {
      'postal_address.route_name': {
        label: 'Route name',
        Component: TextField,
      },
      'postal_address.street_number': {
        label: 'Street number',
        Component: TextField,
      },
    },
    COLUMN_POSTAL: {
      'postal_address.postal_code': {
        label: 'Postal Code',
        Component: TextField,
      },
      'postal_address.postal_town': {
        label: 'Postal Town',
        Component: TextField,
      },
    },
    COLUMN_LOCATION: {
      'geo_location.latitude': {
        label: 'Latitude coordinate',
        Component: TextField,
      },
      'geo_location.longitude': {
        label: 'Longitude coordinate',
        Component: TextField,
      },
    },

    referral_enabled: {
      label: 'Referral enabled',
      Component: Boolean,
    },
    hemnet_listing_id: {
      label: 'Hemnet listing id',
      Component: TextField,
      infoBox: (
        <>
          You can obtain the listing ID via{' '}
          <Link to="/search" target="_blank">
            Search
          </Link>
          . Open the listing and copy the "Movesta listing ID". Be aware that it
          might take up to a day after the listing is published on Hemnet for it
          to show up in our scraped data.
        </>
      ),
    },
    hemnet_sale_url: {
      label: 'External sale page URL (e.g. Hemnet)',
      Component: TextField,
    },
  },
}

export default fields
