import { ArrowBackIosNewRounded } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { createNewHome } from './redux/marketplaceActions'
import {
  createdHomeSelector,
  createLoadingSelector,
} from './redux/marketplaceSelectors'

const STEPS = {
  ENTER_NUMBER: {
    step: 0,
    label: 'Enter project',
  },
  CHECK_PREVIEW: {
    step: 1,
    label: 'Check preview',
  },
  FINSIH: {
    step: 2,
    label: 'Finish',
  },
}

export default function NewMarketplaceHomePage() {
  const dispatch = useDispatch()
  const createdHome = useSelector(createdHomeSelector)
  const createLoading = useSelector(createLoadingSelector)
  const [number, setNumber] = useState(null)
  const [activeStep, setActiveStep] = useState(STEPS.ENTER_NUMBER)

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(createNewHome(number)).then(() => setActiveStep(STEPS.FINSIH))
    },
    [dispatch, number]
  )

  console.log(createdHome)

  const renderStep = () => {
    switch (activeStep.step) {
      case STEPS.ENTER_NUMBER.step:
        return (
          <form onSubmit={handleSubmit}>
            <Box>
              <TextField
                label="Enter the number (Nr) of the project"
                fullWidth
                value={number}
                onInput={(e) => setNumber(e.target.value)}
              />
              <LoadingButton
                loading={createLoading === 'pending'}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
              >
                Add home
              </LoadingButton>
            </Box>
          </form>
        )
      case STEPS.FINSIH.step:
        return (
          <Card>
            {createLoading === 'pending' ||
            createLoading?.status === 'rejected' ? (
              <>
                <CardContent>
                  <Typography variant="h5">Something went wrong</Typography>
                  {createLoading.payload ? (
                    <Typography>
                      {createLoading.payload?.error?.message || 'Unknown error'}
                    </Typography>
                  ) : (
                    <Skeleton variant="rectangle" height="80px" />
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    startIcon={<ArrowBackIosNewRounded />}
                    size="small"
                    variant="contained"
                    onClick={() => setActiveStep(STEPS.ENTER_NUMBER)}
                  >
                    Back
                  </Button>
                  <LoadingButton
                    loading={createLoading === 'pending'}
                    size="small"
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    Retry
                  </LoadingButton>
                </CardActions>
              </>
            ) : (
              <>
                <CardContent>
                  <Typography variant="h5">Done!</Typography>
                  <Typography>The home is created</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    size="small"
                    LinkComponent={Link}
                    to={`/marketplace/${createdHome}`}
                  >
                    Go to home editor
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    component="a"
                    onClick={() => window.location.reload()}
                  >
                    Add another
                  </Button>
                </CardActions>
              </>
            )}
          </Card>
        )
      default:
        return <Box>Something went wrong</Box>
    }
  }

  return (
    <Container maxWidth="sm">
      <Box display="flex" alignItems="center" mb={3}>
        <IconButton size="large" LinkComponent={Link} to="/marketplace">
          <ArrowBackIosNewRounded />
        </IconButton>
        <Typography variant="h4">Add new marketplace home</Typography>
      </Box>
      {renderStep()}
    </Container>
  )
}
