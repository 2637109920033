import { createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

const COLLECTION = 'listing_tag'
const COLLECTION_SUGGESTIONS = 'sabi_tag_suggestion'

export const listingIdTagsConnector = (listingId) => () =>
  [
    {
      collection: COLLECTION,
      doc: listingId,
    },
  ]

export const listingIdTagsSelector = createSelector(
  ({ firestore: { data } }, listingId) => {
    return data.listing_tag && data.listing_tag[listingId]
  },
  (listingTags) => listingTags
)

export const multipleListingIdsTagsConnector = (listingIds) => () =>
  listingIds.map((listingId) => ({
    collection: COLLECTION,
    doc: listingId,
  }))

export const multipleListingIdsTagsSelector = createSelector(
  ({ firestore: { data } }, listingIds) => {
    return listingIds.reduce((acc, listingId) => {
      acc[listingId] = data?.listing_tag?.[listingId]?.tags || []
      return acc
    }, {})
  },
  (listingTagss) => listingTagss
)

export const addTagToListing = createAsyncThunk(
  'listingTags/addTagToListing',
  async ({ listingId, tag }, thunkAPI) => {
    const firestore = thunkAPI.extra().getFirestore()
    const uid = thunkAPI.getState().firebase.auth.uid

    if (!uid) throw new Error('User not logged in.')

    // clean tag name so it can be used as a key for Firestore object
    const tagKey = tag
      // replace multiple non-alphanumeric characters with a single space
      .replace(/[^A-z0-9]+/gi, ' ')
      .trim()
      .replaceAll(' ', '-')
      .toLowerCase()

    await firestore
      .collection(COLLECTION)
      .doc(listingId)
      .set(
        {
          tags: {
            [tagKey]: {
              label: tag,
              created_by: uid,
              created_at: moment().utc().toISOString(),
            },
          },
          updated_at: moment().utc().toISOString(),
        },
        { merge: true }
      )

    await thunkAPI.dispatch(addListingTagSuggestion({ tagKey, tagLabel: tag }))
  }
)

export const removeTagFromListing = createAsyncThunk(
  'listingTags/removeTagFromListing',
  async ({ listingId, tag }, thunkAPI) => {
    const firestore = thunkAPI.extra().getFirestore()

    if (!tag.match(/^[a-z0-9-]+$/)) {
      throw new Error('Invalid tag name.')
    }

    return await firestore
      .collection(COLLECTION)
      .doc(listingId)
      .update({
        [`tags.${tag}`]: firestore.FieldValue.delete(),
        updated_at: moment().utc().toISOString(),
      })
  }
)

const addListingTagSuggestion = createAsyncThunk(
  'listingTags/addListingTagSuggestion',

  async ({ tagKey, tagLabel }, thunkAPI) => {
    const firestore = thunkAPI.extra().getFirestore()

    const existingTagSuggestions =
      (
        await firestore.collection(COLLECTION_SUGGESTIONS).doc(COLLECTION).get()
      ).data()?.tags ?? {}

    const existingTagSuggestion = existingTagSuggestions[tagKey]

    if (existingTagSuggestion) {
      return
    }

    await firestore
      .collection(COLLECTION_SUGGESTIONS)
      .doc(COLLECTION)
      .update({
        [`tags.${tagKey}`]: {
          label: tagLabel,
          color: 'bbb',
        },
      })
  }
)

// unused
export const removeListingTagSuggestion = createAsyncThunk(
  'listingTags/removeListingTagSuggestion',
  async ({ tagKey }, thunkAPI) => {
    const firestore = thunkAPI.extra().getFirestore()

    return await firestore
      .collection(COLLECTION_SUGGESTIONS)
      .doc(COLLECTION)
      .update({
        [`tags.${tagKey}`]: firestore.FieldValue.delete(),
      })
  }
)
