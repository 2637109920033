import { Check, Clear } from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home'
import { Grid, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import React from 'react'

import { formatArea } from '../../../utils/formatUtils'
import ListingSection from './ListingSection'

export default function FeatureInfo({ listing }) {
  return (
    <ListingSection icon={<HomeIcon fontSize="large" color="primary" />}>
      <Grid item md={4} lg={3}>
        <Typography variant="h5">{formatArea(listing.livingArea)}</Typography>
        <Typography variant="body1">Living area</Typography>
      </Grid>
      {listing.plotSize && (
        <Grid item md={4} lg={3}>
          <Typography variant="h5">{formatArea(listing.plotSize)}</Typography>
          <Typography variant="body1">Plot size</Typography>
        </Grid>
      )}
      {listing.supplementaryArea >= 0 && (
        <Grid item md={4} lg={3}>
          <Typography variant="h5">
            {formatArea(listing.supplementaryArea) || '?'}
          </Typography>
          <Typography variant="body1">Supplementary Area</Typography>
        </Grid>
      )}
      <Grid item md={4} lg={3}>
        <Typography variant="h5">{listing.noRooms || '?'}</Typography>
        <Typography variant="body1">Rooms</Typography>
      </Grid>
      <Grid item md={4} lg={3}>
        <Typography variant="h5">{listing.constructionYear || '?'}</Typography>
        <Typography variant="body1">Construction year</Typography>
      </Grid>
      {listing.hasBalcony !== null && (
        <Grid item md={4} lg={3}>
          <Typography variant="h5">
            {listing.hasBalcony ? <Check /> : <Clear />}
          </Typography>
          <Typography variant="body1">Balcony</Typography>
        </Grid>
      )}
      {listing.hasPatio !== null && (
        <Grid item md={4} lg={3}>
          <Typography variant="h5">
            {listing.hasPatio ? <Check /> : <Clear />}
          </Typography>
          <Typography variant="body1">Patio</Typography>
        </Grid>
      )}
      {listing.tenure !== null && (
        <Grid item md={5} lg={4}>
          <Typography variant="h5" sx={{ width: 'fit-content' }}>
            {capitalize(listing.tenure)}
          </Typography>
          <Typography variant="body1">Tenure</Typography>
        </Grid>
      )}
    </ListingSection>
  )
}
