import { Alert, AlertTitle, Button, Collapse, Typography } from '@mui/material'
import { useState } from 'react'

export const JSONErrorAlert = ({ error, title = 'Error', ...props }) => {
  const [open, setOpen] = useState(false)
  return (
    <Alert severity="error" {...props}>
      <AlertTitle>{title}</AlertTitle>
      <Button
        onClick={() => setOpen(!open)}
        variant="outlined"
        size="small"
        color="inherit"
      >
        {open ? 'Hide' : 'Show'} Details
      </Button>
      <Collapse in={open}>
        <Typography
          mt={1}
          component="pre"
          fontFamily="monospace"
          fontSize="0.9em"
          whiteSpace="pre-wrap"
        >
          {JSON.stringify(error, null, 2)}
        </Typography>
      </Collapse>
    </Alert>
  )
}
