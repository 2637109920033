import { AttachMoney } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Link as ReactRouterLink } from 'react-router-dom'

import { utcIso } from '../../../utils/dateUtils'
import { formatSEK } from '../../../utils/formatUtils'
import {
  sgOffersByLeadIdConnector,
  sgOffersByLeadIdSelector,
} from '../../offers/sale_guarantee/sgOfferSelectors'

export const SaleGuaranteeOffersCard = ({ leadId, sx }) => {
  useFirestoreConnect(sgOffersByLeadIdConnector(leadId ?? null))
  const saleGuaranteeOffers = useSelector((state) =>
    sgOffersByLeadIdSelector(state, leadId ?? null)
  )

  console.log('saleGuaranteeOffers', saleGuaranteeOffers)

  return (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">Sale guarantee</Typography>
      </CardContent>
      {saleGuaranteeOffers?.length ? (
        <List sx={{ overflowY: 'scroll', maxHeight: '310px', mb: 2 }}>
          {saleGuaranteeOffers?.map((offer) => (
            <ListItemButton
              component={ReactRouterLink}
              to={`/offers/sale-guarantee/${offer.id}`}
            >
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText
                primary={formatSEK(offer.guarantee_price) || 'No price'}
                secondary={
                  <>
                    {moment(utcIso(offer?.created_at)).fromNow()}
                    <br />
                    {offer?.created_by}
                  </>
                }
              />
            </ListItemButton>
          ))}
        </List>
      ) : (
        <Alert severity="info">
          <AlertTitle>No sale guarantee offers yet</AlertTitle>
          Create a manual valuation and then create a sale guarantee offer.
        </Alert>
      )}
    </Card>
  )
}
