import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

export const readHubspotDeal = createAsyncThunk(
  'hubspot-deals/read-by-id',
  async ({ leadId }, thunkAPI) =>
    thunkAPI
      .extra()
      .sabiApi.readHubspotDeal({ uuid: leadId })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error, error.response)
        return thunkAPI.rejectWithValue([error.message, error.response.data])
      })
)

export const searchHubspotDeals = createAsyncThunk(
  'hubspot-deals/search',
  async ({ query, page = 0 }, thunkAPI) =>
    thunkAPI
      .extra()
      .sabiApi.searchHubspotDeals({ query, page })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error, error.response)
        return thunkAPI.rejectWithValue([error.message, error.response.data])
      })
)

const hubspotDealsAdapter = createEntityAdapter({
  selectId: (lead) => lead?.uuid,
  sortComparer: (a, b) => `${b?.created_at}`.localeCompare(a.created_at),
})

const hubspotDealsSlice = createSlice({
  name: 'hubspotDeals',
  initialState: hubspotDealsAdapter.getInitialState({
    loading: 'idle',
    search: {
      query: null,
      page: -1,
      results: [],
      parsingFailed: {},
      total: 0,
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(readHubspotDeal.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(readHubspotDeal.fulfilled, (state, action) => {
        hubspotDealsAdapter.setOne(state, action.payload)
        state.loading = 'idle'
      })
      .addCase(readHubspotDeal.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
      .addCase(searchHubspotDeals.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(searchHubspotDeals.fulfilled, (state, action) => {
        hubspotDealsAdapter.setAll(state, action.payload.deals)
        state.search = {
          ...state.search,
          page: action.payload.current_page,
          numPages: action.payload.num_pages,
          results: action.payload.deals,
          total: action.payload.total,
          parsingFailed: action.payload.parsing_failed,
        }
        state.loading = 'idle'
      })
      .addCase(searchHubspotDeals.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
  },
})

const hubspotDealsSelectors = hubspotDealsAdapter.getSelectors(
  (state) => state.hubspotDeals
)

export const selectHubspotLeadLoading = (state) => state.hubspotDeals.loading

export const hubspotLeadByIdSelector = createSelector(
  (state, leadId) => hubspotDealsSelectors.selectById(state, leadId),
  (hubspotLead) => hubspotLead
)

const selectHubspotSearchLoading = (state) => state.hubspotDeals.loading

export const isHubspotSearchLoadingSelector = createSelector(
  selectHubspotSearchLoading,
  (loading) => loading === 'pending'
)

const selectHubspotSearchError = (state) =>
  state.hubspotDeals.loading?.status === 'rejected' &&
  state.hubspotDeals.loading?.payload

export const hubspotSearchErrorSelector = createSelector(
  selectHubspotSearchError,
  (error) => error
)

const selectHubspotSearchResult = (state) => state.hubspotDeals.search

export const hubspotSearchResultSelector = createSelector(
  selectHubspotSearchResult,
  (search) => search
)

export default hubspotDealsSlice.reducer
