import { Auth0Provider } from '@auth0/auth0-react'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'

import { initSentry } from './app/sentry'
import config from './config'
import ServicesStoreProvider from './ServicesStoreProvider'
import * as serviceWorker from './serviceWorker'

const history = createBrowserHistory()

initSentry(history)

const onRedirectCallback = (appState) => {
  console.log('auth0 onRedirectCallback appState', appState)
  if (appState?.setCookie) {
    history.replace('/auth?set_cookie=1', {
      returnTo: appState?.returnTo,
    })
  } else {
    history.replace(appState?.returnTo || window.location.pathname)
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      audience={config.REACT_APP_MOVESTA_API_AUDIENCE}
      cacheLocation="localstorage"
      domain={config.REACT_APP_AUTH0_DOMAIN}
      clientId={config.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/auth`}
      onRedirectCallback={onRedirectCallback}
      scope={[
        'read:images',
        'read:listings',
        'read:listing_tags',
        'write:listing_tags',
        'read:hubspot_deals',
        'write:hubspot_deals',
        'read:offers',
        'write:offers',
        'read:valuations',
        'write:valuations',
        'read:avm',
        'read:renovation_estimate',
        'write:renovation_estimate',
        'read:labelling',
        'write:labelling',
        'read:marketplace',
        'write:marketplace',
        'read:metrics',
        'read:avm_experiments',
        'write:avm_experiments',
      ].join(' ')}
      useRefreshTokens
      useRefreshTokensFallback={false}
    >
      <ServicesStoreProvider history={history} />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
