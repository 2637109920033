import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { capitalize } from 'lodash'

export const MAX_POINTS_MAP = 3000
const MAX_POINTS_CACHE = MAX_POINTS_MAP * 10

export const getListingsInBounds = createAsyncThunk(
  'map-points/get-listings-in-bounds',
  async ({ bounds, index = 'listing' }, thunkAPI) => {
    const boundsList = [
      [bounds.nw.lng, bounds.nw.lat],
      [bounds.se.lng, bounds.se.lat],
    ]

    const result = await thunkAPI.extra().sabiApi.elasticSearch({
      request: {
        query: {
          bool: {
            must: {
              match_all: {},
            },
            filter: {
              geo_shape: {
                location: {
                  shape: {
                    type: 'envelope',
                    coordinates: boundsList,
                  },
                  relation: 'within',
                },
              },
            },
          },
        },
        size: MAX_POINTS_MAP,
        fields: [
          'location',
          'postal_address.streetAddress',
          'google_maps_address.route',
        ],
        _source: false,
      },
      index,
    })

    return result.data
  }
)

const mapPointsAdapter = createEntityAdapter({
  selectId: (point) => point.properties.id,
  // sortComparer: (a, b) => a.localeCompare(b),
})

const mapSearchSlice = createSlice({
  name: 'mapPoints',
  initialState: {
    /* format (required by supercluster):
    {
      "hemnet_listing_123": {
        type: 'Feature',
        properties: {
          cluster: false,
          id: `hemnet_listing_123`,
          source: "hemnet",
          sourceId: 123,
          active: false,
          searchResult: false,
        },
        geometry: {
          type: 'Point',
          coordinates: [12.345, 67.890],
        },
      },
      ...
    }
    */
    points: mapPointsAdapter.getInitialState(),
    loading: 'pending',
    maxMapPointsReached: false,
    // keep track of the order that points were added
    // so that we can remove the oldest ones when we reach the limit
    pointIdsCacheQueue: [],
    previewIds: [],
    streetNames: [],
  },
  reducers: {
    setSearchResultIds(state, action) {
      const { searchResultIds } = action.payload
      Object.values(state.points.entities).forEach((point) => {
        point.properties.searchResult = searchResultIds.includes(
          point.properties.id
        )
      })
    },
    setActivePoint(state, action) {
      const { id } = action.payload
      Object.values(state.points.entities).forEach((point) => {
        point.properties.active = point.properties.id === id
      })
    },
    setPreviewIds(state, action) {
      const { ids } = action.payload
      state.previewIds = ids || []
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListingsInBounds.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(getListingsInBounds.fulfilled, (state, action) => {
        const points = action.payload.hits.hits.map(
          mapListingDocToSuperclusterPoint
        )
        state.maxMapPointsReached = points.length >= MAX_POINTS_MAP
        mapPointsAdapter.upsertMany(state.points, points)

        state.streetNames = getUniqueStreetAddresses(action.payload.hits.hits)

        // caching
        const pointIdsAdded = points.map((point) => point.properties.id)
        state.pointIdsCacheQueue = [
          ...pointIdsAdded,
          ...state.pointIdsCacheQueue.filter(
            (id) => !pointIdsAdded.includes(id)
          ),
        ].slice(0, MAX_POINTS_CACHE)

        // remove points that are not in the cache queue
        Object.keys(state.points.entities).forEach((id) => {
          if (!state.pointIdsCacheQueue.includes(id)) {
            mapPointsAdapter.removeOne(state.points, id)
          }
        })

        state.loading = 'idle'
      })
      .addCase(getListingsInBounds.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
  },
})

export const selectStreetNames = createSelector(
  (state) => state.mapPoints.streetNames,
  (streetNames) => streetNames.map((streetName) => streetName)
)

const { selectAll: selectAllMapPoints } = mapPointsAdapter.getSelectors(
  (state) => state.mapPoints.points
)

export const mapPointsSelector = createSelector(
  (state) => selectAllMapPoints(state),
  (mapPoints) => mapPoints
)

export const isMapPointsLoading = createSelector(
  (state) => state.mapPoints.loading,
  (loading) => loading === 'pending'
)

export const maxMapPointsReachedSelector = createSelector(
  (state) => state.mapPoints.maxMapPointsReached,
  (maxMapPointsReached) => maxMapPointsReached
)

export const activeIdSelector = createSelector(
  (state) => selectAllMapPoints(state),
  (mapPoints) =>
    mapPoints.find((point) => point.properties.active)?.properties.id
)

export const previewPointsSelector = createSelector(
  (state) => selectAllMapPoints(state),
  (state) => state.mapPoints.previewIds,
  (mapPoints, previewIds) =>
    mapPoints.filter((point) => previewIds.includes(point.properties.id))
)

export const { setSearchResultIds, setActivePoint, setPreviewIds } =
  mapSearchSlice.actions

export default mapSearchSlice.reducer

const mapListingDocToSuperclusterPoint = (doc) => ({
  type: 'Feature',
  properties: {
    cluster: false,
    id: `hemnet_listing_${doc._id}`,
    source: 'hemnet',
    sourceId: doc._id,
    active: false,
    searchResult: false,
  },
  geometry: {
    type: 'Point',
    coordinates: [
      parseFloat(doc?.fields?.location?.[0]?.coordinates?.[0]),
      parseFloat(doc?.fields?.location?.[0]?.coordinates?.[1]),
    ],
  },
})

const getUniqueStreetAddresses = (hits) => {
  const streetAddresses = hits
    .map(
      // prefer Google Maps address
      (hit) =>
        hit.fields?.['google_maps_address.route']?.[0] ||
        hit.fields?.['postal_address.streetAddress']?.[0]
    )
    .flat()
    .filter(Boolean)
    .map(
      // split by first number
      (streetAddressFull) =>
        streetAddressFull.split(/\d/)[0].trim().toLowerCase()
    )
    .map((street) => street.replace('s:t', 'sankt'))
    .map(capitalize)

  // grouped and count, sorted by count
  return Array.from(new Set(streetAddresses))
    .map((streetAddress) => [
      streetAddress,
      streetAddresses.filter((street) => street === streetAddress).length,
    ])
    .sort((a, b) => b[1] - a[1])
}
