import { convertSWEREF99ToWGS84 } from '../avm/avmUtils'

const _MAP_COLUMNS = [
  {
    columns: ['location.northing', 'location.easting'],
    isSWEREF99: true,
  },
  {
    columns: ['location.lat', 'location.lon'],
  },
  {
    columns: [
      'google_maps_address.location.lat',
      'google_maps_address.location.lon',
    ],
  },
]

export const MAP_COLUMNS = Object.fromEntries(
  _MAP_COLUMNS.map((mc) => [
    mc.columns.join(','),
    {
      ...mc,
      label: mc.columns.join(', '),
      isSWEREF99: !!mc.isSWEREF99,
    },
  ])
)

export const getMapPoints = (tableDataAndListings, columns, isSWEREF99) => {
  const [col1, col2] = columns

  if (
    col1.includes('lon') ||
    col2.includes('lat') ||
    col1.includes('lng') ||
    col1.includes('easting') ||
    col2.includes('northing')
  ) {
    throw new Error('Column names or order is not correct: ' + columns)
  }

  const mapPoints = tableDataAndListings
    .filter(({ sample }) => sample?.[col1] && sample?.[col2])
    .map(({ sample, ...rest }) => {
      const mapCoordinates = isSWEREF99
        ? convertSWEREF99ToWGS84({
            northing: sample[col1],
            easting: sample[col2],
          })
        : { lat: sample[col1], lng: sample[col2] }

      return {
        ...rest,
        sample,
        ...mapCoordinates,
      }
    })

  return mapPoints
}
