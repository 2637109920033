import { useState } from 'react'

import MainMenu from './MainMenu'
import TopBar from './TopBar'

export default function Header() {
  const [isMainMenuOpen, setMainMenuOpen] = useState(false)
  return (
    <>
      <TopBar toggleMenu={() => setMainMenuOpen(!isMainMenuOpen)} />
      <MainMenu isOpen={isMainMenuOpen} setOpen={setMainMenuOpen} />
    </>
  )
}
