import {
  useAuth0,
  withAuthenticationRequired as withAuthenticationRequiredAuth0,
} from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getImageProxyAuth0Options } from '../../clients/imageProxyApi'
import { configSelector } from '../../configSlice'
import { selectIsImageProxyCookieExpired, selectLoading } from './authSlice'

export const withAuthenticationRequired = (Component) => {
  const WithImageProxyCookieSet = (props) => {
    const { loginWithRedirect } = useAuth0()
    const config = useSelector(configSelector)
    const imageProxyAuth0Options = getImageProxyAuth0Options(config)
    const isImageProxyCookieExpired = useSelector(
      selectIsImageProxyCookieExpired
    )
    const loading = useSelector(selectLoading)
    useEffect(() => {
      if (loading === 'idle' && isImageProxyCookieExpired) {
        console.log('WithImageProxyCookieSet useEffect loginWithRedirect')
        loginWithRedirect({
          appState: {
            setCookie: true,
            returnTo: `${window.location.pathname}${window.location.search}`,
          },
          ...imageProxyAuth0Options,
        })
      }
    }, [
      imageProxyAuth0Options,
      isImageProxyCookieExpired,
      loading,
      loginWithRedirect,
    ])

    return <Component {...props} />
  }

  return withAuthenticationRequiredAuth0(WithImageProxyCookieSet)
}
