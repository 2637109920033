import { Mail, PersonSearch, Phone } from '@mui/icons-material'
import {
  Card,
  CardContent,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

import { getContactName, getGoogleSearchLink } from '../leadUtils'

export const PersonCard = ({ lead, sx }) => {
  const contactName = getContactName(lead)

  return (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">{contactName}</Typography>
      </CardContent>
      <List>
        {lead?.contact_details?.email && (
          <ListItemButton
            component={Link}
            href={`mailto:${lead.contact_details.email}`}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText primary={lead.contact_details.email} />
          </ListItemButton>
        )}
        {lead.contact_details.phone && (
          <ListItemButton
            component={Link}
            href={`tel:${lead.contact_details.phone}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ListItemIcon>
              <Phone />
            </ListItemIcon>
            <ListItemText primary={lead.contact_details.phone} />
          </ListItemButton>
        )}
        <ListItemButton
          component={Link}
          href={getGoogleSearchLink(`${contactName}`, 'mrkoll.se')}
          target="_blank"
        >
          <ListItemIcon>
            <PersonSearch />
          </ListItemIcon>
          <ListItemText primary="Search on mrkoll.se" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          href={getGoogleSearchLink(`${contactName}`, 'ratsit.se')}
          target="_blank"
        >
          <ListItemIcon>
            <PersonSearch />
          </ListItemIcon>
          <ListItemText primary="Search on ratsit.se" />
        </ListItemButton>
      </List>
    </Card>
  )
}
