import { History, Refresh } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'

import { DebugJSONPopupButton } from '../../../components/DebugJSONPopupButton'
import { utcIso } from '../../../utils/dateUtils'
import { formatSEK } from '../../../utils/formatUtils'
import { avmRequestForHome } from '../avmActions'

const HomeAVMValuations = ({
  home,
  valuationSnapshots = {},
  large = false,
}) => {
  const dispatch = useDispatch()

  const [manuallySelectedTimestamp, setSelectedTimestamp] = useState(null)
  const [error, setError] = useState(null)

  const selectedTimestamp =
    manuallySelectedTimestamp ||
    (valuationSnapshots && Object.keys(valuationSnapshots).slice(-1)[0])
  const selectedSnapshot = selectedTimestamp
    ? valuationSnapshots?.[selectedTimestamp]
    : home?.valuation_snapshot

  const postprocessed = selectedSnapshot?.post_processed_valuation_response
  const [isUpdating, setIsUpdating] = useState(false)

  const fetchAVM = useCallback(
    (force) => {
      dispatch(avmRequestForHome({ homeId: home?.id, force }))
        .unwrap()
        .then(() => {
          setIsUpdating(false)
          setError(null)
        })
        .catch((error) => {
          setIsUpdating(false)
          setError(error)
        })
    },
    [dispatch, home?.id]
  )

  const onUpdate = useCallback(
    (e) => {
      const force = e.shiftKey
      if (force) {
        console.log('forcing update')
      }
      setIsUpdating(true)
      setSelectedTimestamp(null)
      fetchAVM(force)
    },
    [setIsUpdating, setSelectedTimestamp, fetchAVM]
  )

  return (
    <>
      {!home && isLoaded(home) ? (
        <Box>
          <Alert severity="error">
            <AlertTitle>Unknown home</AlertTitle>
          </Alert>
        </Box>
      ) : !postprocessed ? (
        <Box>
          <Typography sx={{ my: 2 }}>
            Please click to get the latest AVM valuation.
          </Typography>
          <UpdateButton isUpdating={isUpdating} onUpdate={onUpdate} />
          {error && <ErrorAlert error={error} />}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: large ? 'row' : 'column',
            gap: 2,
            mt: 3,
          }}
        >
          <Box
            sx={{
              flex: large ? '0 0 400px' : null,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Chip
              color="primary"
              sx={{ fontSize: '200%', height: '55px', px: '10px' }}
              label={'≈ ' + formatSEK(postprocessed.expectation)}
            />
            <Typography>
              {formatSEK(postprocessed.range.low)} ...{' '}
              {formatSEK(postprocessed.range.high)}
            </Typography>

            <Tooltip
              title={moment(utcIso(selectedSnapshot?.timestamp)).format(
                'YYYY-MM-DD, HH:mm'
              )}
            >
              <Typography variant="caption">
                {moment(utcIso(selectedSnapshot?.timestamp)).fromNow()}
              </Typography>
            </Tooltip>

            <Box>
              {!large && selectedTimestamp && valuationSnapshots && (
                <Select
                  size="small"
                  label="History"
                  value={selectedTimestamp}
                  onChange={(e) => setSelectedTimestamp(e.target.value)}
                >
                  {Object.entries(valuationSnapshots)
                    .reverse()
                    .map(([timestamp]) => (
                      <MenuItem value={timestamp} key={timestamp}>
                        {moment(utcIso(timestamp)).format('YYYY-MM-DD HH:mm')}
                      </MenuItem>
                    ))}
                </Select>
              )}
              <DebugJSONPopupButton
                data={selectedSnapshot}
                title={`Valuation snapshot for home ${home?.id}`}
              />
              <UpdateButton isUpdating={isUpdating} onUpdate={onUpdate} />
              {error && <ErrorAlert error={error} />}
            </Box>
          </Box>
          {large && valuationSnapshots && (
            <Box
              sx={{
                flex: '0 0 400px',
              }}
            >
              <List
                sx={{ width: '300px', maxHeight: '500px', overflow: 'auto' }}
              >
                {Object.entries(valuationSnapshots)
                  .reverse()
                  .map(([timestamp]) => (
                    <ListItemButton
                      onClick={() => setSelectedTimestamp(timestamp)}
                      key={timestamp}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '40px',
                          color:
                            timestamp === selectedTimestamp && 'primary.main',
                        }}
                      >
                        <History />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              color:
                                timestamp === selectedTimestamp &&
                                'primary.main',
                              fontWeight:
                                timestamp === selectedTimestamp && 'bold',
                            }}
                          >
                            {moment(utcIso(timestamp)).format(
                              'YYYY-MM-DD HH:mm'
                            )}
                          </Typography>
                        }
                        secondary={formatSEK(
                          valuationSnapshots[timestamp]
                            .post_processed_valuation_response.expectation
                        )}
                      />
                    </ListItemButton>
                  ))}
              </List>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

const subcollectionAs = 'active_home_valuation_snapshot'

export default compose(
  firestoreConnect((props) => [
    { collection: 'home_root', doc: props.homeId },
    {
      collection: 'home_root',
      doc: props.homeId,
      subcollections: [{ collection: 'home_valuation_snapshot' }],
      storeAs: subcollectionAs,
    },
  ]),
  connect(({ firestore: { data } }, props) => {
    return {
      home: data.home_root && data.home_root?.[props.homeId],
      valuationSnapshots: data[subcollectionAs],
    }
  })
)(HomeAVMValuations)

const UpdateButton = ({ isUpdating, onUpdate }) => (
  <LoadingButton onClick={onUpdate} disabled={isUpdating} loading={isUpdating}>
    <Refresh />
    Update
  </LoadingButton>
)

const ErrorAlert = ({ error }) => (
  <Alert severity="error" sx={{ mt: 2 }}>
    <AlertTitle>{error[0]}</AlertTitle>
    {error[1]?.detail}
  </Alert>
)
