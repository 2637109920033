import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase'
import moment from 'moment'

import { authSelector } from '../auth/authSlice'
import { authAsDocId } from './labellingUtil'

const collectionSamples = 'labelling_sample'

export const submitLabel = createAsyncThunk(
  'labelling/submit',
  async ({ id, submission }, thunkAPI) => {
    const state = thunkAPI.getState()
    const auth = authSelector(state)

    const submissionDoc = {
      ...submission,
      created_at: submission.created_at || moment().toISOString(),
      created_by: submission.created_by || auth.uid,
    }

    return thunkAPI
      .extra()
      .getFirestore()
      .collection(collectionSamples)
      .doc(id)
      .update({
        label_count: firebase.firestore.FieldValue.increment(1),
        ['label_by.' + authAsDocId(auth)]: submissionDoc,
      })
  }
)

export const getNextLabellingSample = createAsyncThunk(
  'labelling/get-next',
  async (_, thunkAPI) => {
    const references = await thunkAPI
      .extra()
      .getFirestore()
      .collection('labelling_sample')
      .orderBy('round', 'asc')
      .get()

    const uid = authAsDocId(thunkAPI.getState().firebase.auth)

    const docs = references.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }))

    const filterDocAvailable = (doc) => {
      // keys of this attribute are escaped email addresses (via authAsDocId())
      const alreadyLabelledBy = Object.keys(doc.label_by || {})
      const maxLabelCount =
        doc.max_label_count === undefined ? Infinity : doc.max_label_count
      return (
        alreadyLabelledBy.indexOf(uid) === -1 && doc.label_count < maxLabelCount
      )
    }

    const available = docs
      .filter((doc) => filterDocAvailable(doc.data))
      .map((doc) => ({ _id: doc.id, ...doc.data }))

    let nextSample = null
    if (available.length > 0) {
      // doc at the beginning of the list contains the "active round"
      const round = available[0].round
      const availableInRound = available.filter((doc) => doc.round === round)

      console.log({ available, availableInRound })

      const randomIndex = Math.floor(Math.random() * availableInRound.length)
      nextSample = availableInRound[randomIndex]
    }

    const numLabelled = docs.filter(
      (doc) => Object.keys(doc.data.label_by || {}).indexOf(uid) !== -1
    ).length

    return { nextSample, numLabelled }
  }
)

const labellingSlice = createSlice({
  name: 'labellingSamples',
  initialState: {
    nextSample: null,
    shouldShowIntro: true,
    numLabelled: 0,
    loading: 'pending',
  },
  reducers: {
    showIntro: (state) => ({ ...state, shouldShowIntro: true }),
    hideIntro: (state) => ({ ...state, shouldShowIntro: false }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNextLabellingSample.pending, (state, action) => ({
        ...state,
        loading: 'pending',
      }))
      .addCase(getNextLabellingSample.fulfilled, (state, action) => ({
        ...state,
        loading: 'idle',
        nextSample: action.payload.nextSample,
        numLabelled: action.payload.numLabelled || 0,
      }))
      .addCase(getNextLabellingSample.rejected, (state, action) => ({
        ...state,
        loading: { status: 'rejected', payload: action },
      }))
  },
})

export const nextSampleSelector = createSelector(
  (state) => state.labelling.nextSample,
  (sample) => sample
)

export const isLoadingSelector = createSelector(
  (state) => state.labelling.loading === 'pending',
  (loading) => loading
)

export const numLabelledSelector = createSelector(
  (state) => state.labelling.numLabelled,
  (numLabelled) => numLabelled
)

export const shouldShowIntroSelector = createSelector(
  (state) => state.labelling.shouldShowIntro,
  (shouldShowIntro) => !!shouldShowIntro
)

export const { showIntro, hideIntro } = labellingSlice.actions
export default labellingSlice.reducer
