export const isSold = (listing) => listing.status === 'SOLD'

export const listingToSoldRelative = (listing) =>
  (listing.soldPrice - listing.listingPrice) / listing.listingPrice

export const getImageUrl = (image_proxy_url, object_path) =>
  `${image_proxy_url}/images?object_path=${object_path}`

export const getDocumentUrl = (image_proxy_url, object_path) =>
  `${image_proxy_url}/documents?object_path=${object_path}`

const THUMB_WIDTH = 300
export const getThumbImageUrl = (image_proxy_url, object_path) =>
  `${image_proxy_url}/images?object_path=${object_path}&width=${THUMB_WIDTH}`
