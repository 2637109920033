import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

export const getSample = createAsyncThunk(
  'ds_avm_predictions/getSample',
  async ({ predictTaskId, sampleId }, thunkAPI) => {
    const firestore = thunkAPI.extra().getFirestore()

    const taskData = (
      await firestore
        .collection('ds_avm_pipeline_predict')
        .doc(predictTaskId)
        .get()
    ).data()

    const task = {
      ...taskData,
      // can't store in Redux store as Date object
      created_at: taskData?.created_at?.toDate().toISOString(),
    }

    const sampleDataPredict = (
      await firestore
        .collection('ds_avm_pipeline_predict')
        .doc(predictTaskId)
        .collection('ds_avm_pipeline_predict_sample')
        .doc(sampleId)
        .get()
    ).data()

    const avmDatasetTaskId = sampleDataPredict?.avm_dataset_task_id
    const comparableIds = sampleDataPredict?.comparables_listing_ids || []
    const comparableWeights =
      sampleDataPredict?.comparables_weights?.map(parseFloat) ?? []
    const comparableAdjustmentsNormalized =
      sampleDataPredict?.comparables_adjustments_normalized?.map(parseFloat) ??
      []
    const comparableAdjustmentsDenormalized =
      sampleDataPredict?.comparables_adjustments_denormalized?.map(
        parseFloat
      ) ?? []

    const sampleDataInput = (
      await firestore
        .collection('ds_avm_pipeline_avm_dataset')
        .doc(avmDatasetTaskId)
        .collection('ds_avm_pipeline_avm_dataset_sample')
        .doc(sampleId)
        .get()
    ).data()

    const sample = {
      ...sampleDataInput,
      ...sampleDataPredict,
      __id: parseInt(sampleId),
    }

    const comparableDocs = await Promise.all(
      comparableIds.map((comparableId) =>
        firestore
          .collection('ds_avm_pipeline_avm_dataset')
          .doc(avmDatasetTaskId)
          .collection('ds_avm_pipeline_avm_dataset_sample')
          .doc(comparableId)
          .get()
      )
    )

    const comparablesList = comparableDocs
      .filter((doc) => doc.exists)
      .map((doc, idx) => ({
        ...doc.data(),
        __id: parseInt(doc.id),
        __weight: comparableWeights[idx],
        __adjustment_norm: comparableAdjustmentsNormalized[idx],
        __adjustment_denorm: comparableAdjustmentsDenormalized[idx],
      }))

    const comparables = Object.fromEntries(
      comparablesList.map((comparable) => [comparable.listing_id, comparable])
    )

    const listingIds = [sample.listing_id, ...comparableIds]
      .filter((id) => id)
      .map((id) => parseInt(id))

    const { _schema_version, ...sampleFiltered } = sample
    const comparablesFiltered = Object.fromEntries(
      Object.entries(comparables).map(
        ([id, { _schema_version, ...comparable }]) => [id, comparable]
      )
    )

    return {
      task,
      sample: sampleFiltered,
      comparables: comparablesFiltered,
      listingIds,
    }
  }
)

const slice = createSlice({
  name: 'ds_avm_predictions',
  initialState: {
    task: null,
    sample: null,
    comparables: {},
    error: null,
    status: 'idle',
    queue: null,
  },
  reducers: {
    initQueue(state, action) {
      state.queue = {
        predictTaskId: action.payload.predictTaskId,
        samples: action.payload.samples,
        sampleIdx: 0,
      }
    },
    setSampleIdx(state, action) {
      state.queue.sampleIdx = action.payload.sampleIdx
    },
    clearQueue(state) {
      state.queue = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSample.pending, (state, action) => ({
        ...state,
        status: 'pending',
        error: null,
      }))
      .addCase(getSample.fulfilled, (state, action) => ({
        ...state,
        task: action.payload.task,
        sample: action.payload.sample,
        comparables: action.payload.comparables,
        status: 'fulfilled',
        error: null,
      }))
      .addCase(getSample.rejected, (state, action) => ({
        ...state,
        status: 'rejected',
        error: action.error.message,
      }))
  },
})

export const { initQueue, setSampleIdx, clearQueue } = slice.actions

export const selectTask = createSelector(
  (state) => state.ds_avm_predictions.task,
  (task) => task
)
export const selectSample = createSelector(
  (state) => state.ds_avm_predictions.sample,
  (sample) => sample
)
export const selectComparables = createSelector(
  (state) => state.ds_avm_predictions.comparables,
  (comparables) => comparables
)

export const selectStatus = createSelector(
  (state) => state.ds_avm_predictions.status,
  (status) => status
)
export const selectError = createSelector(
  (state) => state.ds_avm_predictions.error,
  (error) => error
)

export const selectQueue = createSelector(
  (state) => state.ds_avm_predictions.queue,
  (queue) => queue
)

export default slice.reducer
