import { LocationOn } from '@mui/icons-material'
import { Alert, Button, Collapse, MenuItem } from '@mui/material'
import { Box } from '@mui/system'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'
import _ from 'lodash'
import { Autocomplete, makeValidate, Select } from 'mui-rff'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import GeoMap from '../../../components/GeoMap'
import {
  hasStreetNumber,
  isValidStreetFormat,
} from '../../../utils/addressUtils'
import { basicFormFieldNames, schema, valuationFormFields } from './FormFields'

const validate = (values) => {
  if (values.addressHelper && values.locationDetails) {
    if (values.addressHelper.place_id !== values.locationDetails.place_id) {
      console.warn(
        'place_id inconsistent with addressHelper',
        values.addressHelper.place_id,
        values.locationDetails.place_id
      )
      return {
        locationDetails: {
          message: 'place_id inconsistent with addressHelper',
          addressHelper_place_id: values.addressHelper.place_id,
          locationDetails_place_id: values.locationDetails.place_id,
        },
      }
    }
  }
  return makeValidate(schema)(values)
}

const mapsPlacesServiceOptions = {
  // "null" makes the library look for an already
  // initialized google service object
  apiKey: null,
  debounce: 200,
  language: 'en',
  options: {
    types: ['address'],
    componentRestrictions: { country: ['se'] },
  },
}

const streetWithoutNumber = (values) => {
  return (
    values &&
    values.locationDetails &&
    isValidStreetFormat(values.locationDetails.description) &&
    !hasStreetNumber(values.locationDetails.description) &&
    !hasStreetNumber(values.locationDetails.queryAddressString)
  )
}

export function CreateForm({ submitAction, defaults }) {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService(mapsPlacesServiceOptions)

  const [placesServiceInitialized, setPlacesServiceInitialized] = useState(null)

  useEffect(() => {
    console.log('placesService updated', placesService)
  }, [placesService])

  useEffect(() => {
    if (!placesService) {
      console.log('cold-start places service')
      getPlacePredictions({ input: '' })
    }
  }, [placesService, getPlacePredictions])

  useEffect(() => {
    if (placesService && !placesServiceInitialized) {
      setPlacesServiceInitialized(placesService)
    }
  }, [placesService, placesServiceInitialized])

  const addressHelperDecorator = useMemo(() => {
    console.log('addressHelperDecorator memo updating')
    return createDecorator({
      field: 'addressHelper',
      updates: {
        locationDetails: (value) => {
          if (
            !value ||
            !value.place_id ||
            !isValidStreetFormat(value.description)
          ) {
            return null
          }
          return new Promise((resolve, reject) => {
            placesServiceInitialized.getDetails(
              {
                placeId: value.place_id,
                fields: [
                  'geometry.location',
                  'formatted_address',
                  'address_component',
                ],
              },
              (placeDetails) => {
                const newLocationDetails = {
                  place_id: value.place_id,
                  description: placeDetails.formatted_address,
                  structured: structureAddressComponentsArray(
                    placeDetails.address_components
                  ),
                  queryAddressString: value.description,
                  coordinates: {
                    lat: placeDetails.geometry.location.lat(),
                    lng: placeDetails.geometry.location.lng(),
                  },
                }
                console.log(
                  'Hidden form field value (post places services)',
                  newLocationDetails
                )
                resolve(newLocationDetails)
              }
            )
          })
        },
      },
    })
  }, [placesServiceInitialized])

  const [showAdvancedFeatures, setShowAdvancedFeatures] = useState(false)

  const onSubmit = useCallback(
    (values) => {
      const title = values.title
      /* 
        for some addresses (street name and number), 
        Google Maps API returns a place ID that if used
        for reverse lookup (place ID -> coordinates)
        returns only the street name without a number
      */
      const { postal_code, postal_town, route, street_number } =
        values.locationDetails.structured

      const homeFeatures = {
        living_area: values.livingArea,
        num_rooms: values.noRooms,
        housing_type: values.housingType,
        plot_area: values.plotArea,
        supplemental_area: values.supplementalArea,
        tenure: values.tenure,
        construction_year: values.constructionYear,
        apartment_floor: values.apartmentFloor,
        apartment_number: values.apartmentNumber,
        building_num_storeys: values.buildingNumStories,
        housing_cooperative_share: values.housingCooperativeShare / 100,
        housing_cooperative_fee_per_month: values.housingCooperativeFeePerMonth,
        is_heating_included: values.isHeatingIncluded,
        operating_cost_per_year: values.operatingCostPerYear,
        num_floors: values.numFloors,
        num_balconies: values.numBalconies,
        num_bathrooms: values.numBathrooms,
        num_toilets: values.numToilets,
        num_kitchens: values.numKitchens,
        has_patio: values.hasPatio,
        has_balcony: values.hasBalcony,
        has_sea_view: values.hasSeaView,
        has_seaside_lot: values.hasSeasideLot,
        has_elevator: values.hasElevator,
        has_garage: values.hasGarage,
        has_pool: values.hasPool,
        is_new_production: values.isNewProduction,
        postal_address: {
          route_name: route,
          street_number: street_number,
          postal_town: postal_town,
          postal_code: postal_code,
          country_code: 'SE',
        },
        geo_location: {
          latitude: values.locationDetails.coordinates.lat,
          longitude: values.locationDetails.coordinates.lng,
        },
      }

      const getRedirectUrl = (id) =>
        values.skipManual ? `/valuate/${id}?tab=avm` : `/search?target=${id}`

      console.log('Submitting valuation', homeFeatures)
      return submitAction({ homeFeatures, title, getRedirectUrl }).catch(
        (rejectedValueOrSerializedError) => {
          console.error('submission error', rejectedValueOrSerializedError)
          return { [FORM_ERROR]: rejectedValueOrSerializedError }
        }
      )
    },
    [submitAction]
  )

  if (_.isNil(placesServiceInitialized)) {
    return <pre>placesService loading...</pre>
  }

  // Autocomplete control does not accept initialValues (library bug),
  // use disabled control if defaults are given
  const addressControl =
    defaults && defaults.addressHelper ? (
      <>
        <Select label="Address" name="addressHelper" disabled required>
          <MenuItem value={defaults.addressHelper}>
            {defaults.addressHelper.description}
          </MenuItem>
        </Select>
      </>
    ) : (
      <Autocomplete
        filterOptions={(x) => x}
        label="Address"
        name="addressHelper"
        onInputChange={(e) => {
          e &&
            e.target &&
            getPlacePredictions({
              input: e.target.value,
            })
        }}
        required={true}
        noOptionsText="Please enter a valid address."
        loadingText="Loading ..."
        loading={isPlacePredictionsLoading}
        options={placePredictions}
        getOptionValue={(option) => option}
        getOptionLabel={(option) => option.description}
        id="search-address" // to hide password manager
      />
    )

  const basicFormFields = [
    addressControl,
    ...valuationFormFields
      .filter((field) => basicFormFieldNames.includes(field.key))
      .map((field) => [field]),
  ]

  const advancedFormFields = valuationFormFields
    .filter((field) => !basicFormFieldNames.includes(field.key))
    .map((field) => [field])

  return (
    <Form
      debug={(state) => console.debug('Form state', state)}
      onSubmit={onSubmit}
      validate={validate}
      decorators={[addressHelperDecorator]}
      initialValues={{
        ...defaults,
        skipManual: false,
        addressHelper: (defaults && defaults.addressHelper) || null,
      }}
      render={({
        handleSubmit,
        submitting,
        submitError,
        values,
        valid,
        validating,
        form,
      }) => (
        <form
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="new-password"
        >
          {submitError && (
            <Alert severity="error" sx={{ mb: 4 }}>
              <p>Form submission error:</p>
              {JSON.stringify(submitError, undefined, 2)}
            </Alert>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 3,
              mb: 5,
            }}
          >
            <Box sx={{ flex: '1 0 200px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  gap: 2,
                }}
              >
                {basicFormFields}

                <Button
                  onClick={() => setShowAdvancedFeatures(!showAdvancedFeatures)}
                >
                  {showAdvancedFeatures ? 'Hide advanced' : 'Show advanced'}
                </Button>

                <Collapse in={showAdvancedFeatures}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'wrap',
                      gap: 2,
                    }}
                  >
                    {advancedFormFields}
                  </Box>
                </Collapse>
              </Box>
            </Box>
            <Box sx={{ flex: '1 0 200px' }}>
              {streetWithoutNumber(values) || !values.noRooms ? (
                <Alert severity="warning" sx={{ mb: 3 }}>
                  Please enter a <strong>street number</strong> and{' '}
                  <strong>rooms</strong> to get an AVM estimate.
                </Alert>
              ) : null}
              <Box mb={3}>
                <Button
                  variant="contained"
                  type="submit"
                  name="manual"
                  disabled={submitting || !valid}
                  sx={{ mb: 1 }}
                  fullWidth
                >
                  Manual valuation
                </Button>

                <Button
                  variant="outlined"
                  type="submit"
                  onClick={() => {
                    form.change('skipManual', true)
                  }}
                  disabled={submitting || !valid}
                  sx={{ my: 1 }}
                  fullWidth
                >
                  AVM valuation
                </Button>
              </Box>

              <Field name="locationDetails">
                {({ input, meta }) =>
                  input.value && input.value.coordinates ? (
                    <>
                      {meta.error && (
                        <Alert severity="error" sx={{ mb: 4 }}>
                          {JSON.stringify(meta.error, undefined, 2)}
                        </Alert>
                      )}
                      <GeoMap
                        mapItems={[
                          {
                            id: 0,
                            title: '',
                            images: [],
                            coordinates: input.value.coordinates,
                            open: () => {},
                          },
                        ]}
                        width="100%"
                        height="250px"
                      />
                    </>
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '250px',
                        background: '#eee',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <LocationOn sx={{ fontSize: '80px', color: '#ccc' }} />
                    </Box>
                  )
                }
              </Field>
            </Box>
          </Box>
        </form>
      )}
    ></Form>
  )
}

const structureAddressComponentsArray = (components) => {
  // https://developers.google.com/maps/documentation/javascript/geocoding
  const obj = {}
  components.forEach(({ long_name, types }) => {
    types.forEach((type) => {
      obj[type] = long_name
    })
  })
  return obj
}
