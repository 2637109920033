import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

export const updateHistory = (location, history, parameters, encode) => {
  const existing = queryString.parse(location.search)
  const updated = Object.assign(existing, parameters)
  encode = encode === undefined ? true : encode

  history.replace({
    pathname: location.pathname,
    search: queryString.stringify(updated, { skipNull: true, encode: encode }),
  })
}

export const useQuery = () => {
  return queryString.parse(useLocation().search)
}
