import _ from 'lodash'
import moment from 'moment'

const SEK_SUFFIX = ' kr'
const SEK_SQM_SUFFIX = ' kr/m²'
const SQM_SUFFIX = ' m²'

export const formatArea = (area) =>
  _.isNumber(area) && area.toLocaleString() + SQM_SUFFIX

export const formatRooms = (noRooms) =>
  _.isNumber(noRooms) && (noRooms === 1 ? '1 room' : `${noRooms} rooms`)

export const formatSEK = (amount, noSuffix) =>
  _.isNumber(amount) &&
  Math.round(amount).toLocaleString() + (noSuffix ? '' : SEK_SUFFIX)

export const formatSEKPerArea = (value) =>
  _.isNumber(value) && Math.round(value).toLocaleString() + SEK_SQM_SUFFIX

export const formatDate = (dateObj) => {
  return dateObj && moment(dateObj).format('D MMMM YYYY')
}

export const formatDateTime = (dateObj) => {
  return dateObj && moment(dateObj).format('D MMMM YYYY, HH:mm')
}

const ONE_DAY_IN_SECONDS = 1000 * 24 * 60 * 60
export const getDaysBetweenDates = (dateStart, dateEnd) =>
  dateStart &&
  dateEnd &&
  Math.round((dateEnd.getTime() - dateStart.getTime()) / ONE_DAY_IN_SECONDS)

export const formatPercentage = (relativeValue) => {
  const pctStr = Math.round(relativeValue * 100)
  if (relativeValue === 0) {
    return `±${pctStr} %`
  } else if (relativeValue > 0) {
    return `+${pctStr} %`
  }
  return `${pctStr} %`
}

export const formatLivingAreaRoomsAndSupplementaryArea = ({
  livingArea,
  supplementaryArea,
  noRooms,
}) =>
  [
    [formatArea(livingArea), formatArea(supplementaryArea)]
      .filter((x) => x)
      .join(' + '),
    formatRooms(noRooms) || 'N/A',
  ]
    .map((x) => x)
    .join(', ')

export const renderTextWithLinks = (text) => {
  const parts = text.split(/(https?:\/\/[^\s]+)/g)
  return parts.map((part, i) => {
    if (part.match(/https?:\/\/[^\s]+/)) {
      return (
        <a key={i} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      )
    }
    return part
  })
}
