import { Cancel, Search } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link as MuiLink,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { JSONErrorAlert } from '../../components/JSONErrorAlert'
import {
  hubspotSearchErrorSelector,
  hubspotSearchResultSelector,
  isHubspotSearchLoadingSelector,
  searchHubspotDeals,
} from './hubspotDealsSlice'

export const LeadsPage = () => {
  const dispatch = useDispatch()
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const searchResult = useSelector(hubspotSearchResultSelector)
  const searchError = useSelector(hubspotSearchErrorSelector)

  const loading = useSelector(isHubspotSearchLoadingSelector)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    _.debounce((query, page) => {
      dispatch(searchHubspotDeals({ query, page }))
    }, 500),
    [dispatch]
  )

  useEffect(() => {
    // replace double asterisks with single asterisk
    const queryWildcard = `*${query}*`.replace(/\*{2,}/g, '*')
    debounceSearch(queryWildcard, page)
  }, [dispatch, query, page, debounceSearch])

  return (
    <Container maxWidth="lg" sx={{ mb: 4 }}>
      <Helmet>
        <title>Leads</title>
      </Helmet>
      <Box>
        <Typography variant="h1">Leads</Typography>

        <Box mt={2}>
          <TextField
            label="Search"
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
            sx={{ mb: 3 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment:
                query?.length > 0 ? (
                  <InputAdornment position="end">
                    <Tooltip title="Clear search query">
                      <IconButton
                        aria-label="clear search query"
                        onClick={() => setQuery('')}
                      >
                        <Cancel />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Pagination
            count={searchResult.numPages}
            page={page + 1}
            onChange={(e, page) => setPage(page - 1)}
          />
          <Typography fontSize="1em">
            <strong>{searchResult.total}</strong> total results
          </Typography>
        </Box>
        <Box height={5} mt={2} mb={2}>
          {loading && <LinearProgress />}
        </Box>
        {searchError ? (
          <JSONErrorAlert error={searchError} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {!_.isEmpty(searchResult?.parsingFailed) && (
              <Alert severity="warning">
                <AlertTitle>Could not load all deals, parsing error</AlertTitle>
                {Object.entries(searchResult.parsingFailed).map(
                  ([url, name]) => (
                    <Typography key={name}>
                      <MuiLink
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {name} (Hubspot)
                      </MuiLink>
                    </Typography>
                  )
                )}
              </Alert>
            )}

            {searchResult?.results?.map((deal) => (
              <LeadCard key={deal.uuid} deal={deal} />
            ))}
          </Box>
        )}
      </Box>
    </Container>
  )
}

const LeadCard = ({ deal }) => {
  const createdAt = moment(deal?.created_at)

  return (
    deal && (
      <Card>
        <CardActionArea component={Link} to={`/lead/${deal.uuid}`}>
          <CardContent>
            <Typography variant="h5">{deal.deal_name}</Typography>
            <Typography>
              <Tooltip
                title={createdAt.format('YYYY-MM-DD HH:mm')}
                sx={{ display: 'inline' }}
              >
                <Typography component="span">{createdAt.fromNow()}</Typography>
              </Tooltip>
            </Typography>
            <Typography>{deal.contact_name}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  )
}
