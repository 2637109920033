import {
  Analytics,
  DataObject,
  HomeRepairService,
  MoveToInbox,
  Person,
  PlayCircle,
  PriceChange,
  Search,
  Sell,
  ShoppingBasket,
} from '@mui/icons-material'
import {
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export default function MainMenu({ isOpen, setOpen }) {
  const closeMenu = () => setOpen(false)
  const LOGO_PATH = '/logo-green.png'

  return (
    <>
      <link rel="prefetch" as="image" href={LOGO_PATH}></link>
      <Drawer anchor="left" open={isOpen} onClose={() => setOpen(false)}>
        <Box sx={{ width: 320, maxWidth: '100%' }}>
          <Box sx={{ mx: '15px', my: '30px' }}>
            <Link component={RouterLink} to="/" onClick={closeMenu}>
              <img src={LOGO_PATH} alt="logo" width="270" />
            </Link>
          </Box>
          <List>
            <MainMenuItem
              label="Start"
              href="/"
              closeMenu={closeMenu}
              icon={<PlayCircle />}
            />
          </List>
          <List>
            <MainMenuItem
              label="Search"
              href="/search"
              closeMenu={closeMenu}
              icon={<Search />}
            />
            <MainMenuItem
              label="Leads"
              href="/leads"
              closeMenu={closeMenu}
              icon={<MoveToInbox />}
            />
            <MainMenuItem
              label="Valuations"
              href="/valuate"
              closeMenu={closeMenu}
              icon={<PriceChange />}
            />
            <MainMenuItem
              label="Offers"
              href="/offers/sale-guarantee"
              closeMenu={closeMenu}
              icon={<Sell />}
            />
          </List>
          <List>
            <MainMenuItem
              label="Marketplace"
              href="/marketplace"
              closeMenu={closeMenu}
              icon={<ShoppingBasket />}
            />
          </List>
          <List>
            <MainMenuItem
              label="Kibana"
              href="https://sabi-kibana.movesta.com/"
              icon={<DataObject />}
              external
            />
            <MainMenuItem
              label="Resources"
              href="/resources"
              closeMenu={closeMenu}
              icon={<HomeRepairService />}
            />
          </List>
          <List>
            <MainMenuItem
              label="Metrics"
              href="/metrics"
              closeMenu={closeMenu}
              icon={<Analytics />}
            />
            <MainMenuItem
              label="Auth"
              href="/auth"
              closeMenu={closeMenu}
              icon={<Person />}
            />
          </List>
        </Box>
      </Drawer>
    </>
  )
}

const MainMenuItem = ({ label, href, closeMenu, external, icon }) => {
  if (external) {
    return (
      <ListItem button component={Link} href={href} onClick={closeMenu}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    )
  } else {
    return (
      <ListItem button component={RouterLink} to={href} onClick={closeMenu}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    )
  }
}
