import { createAsyncThunk } from '@reduxjs/toolkit'

export const listHomes = createAsyncThunk(
  'marketplace/list',
  async (_, thunkAPI) => {
    const response = await thunkAPI.extra().sabiApi.listMarketplaceHomes()
    return response && response.data
  }
)

export const getHome = createAsyncThunk(
  'marketplace/get-home',
  async (homeId, thunkAPI) => {
    const response = await thunkAPI.extra().sabiApi.getMarketplaceHome(homeId)
    return response && response.data
  }
)

export const getHomeSuggestions = createAsyncThunk(
  'marketplace/get-home-suggestions',
  async (homeId, thunkAPI) => {
    const response = await thunkAPI
      .extra()
      .sabiApi.getMarketplaceHomeSuggestions(homeId)
    return response && response.data
  }
)

export const syncMarketplaceHome = createAsyncThunk(
  'marketplace/sync-hemnet-listing',
  async (homeId, thunkAPI) => {
    const response = await thunkAPI.extra().sabiApi.syncMarketplace(homeId)
    return response && response.data
  }
)

export const syncWithSpreadsheet = createAsyncThunk(
  'marketplace/sync-with-spreadsheet',
  async (homeId, thunkAPI) => {
    const response = await thunkAPI.extra().sabiApi.syncWithSpreadsheet(homeId)
    return response && response.data
  }
)

export const getNewPreview = createAsyncThunk(
  'marketplace/get-new-preview',
  async (number, thunkAPI) => {
    const response = await thunkAPI
      .extra()
      .sabiApi.getNewMarketplaceHomePreview(number)
    return response && response.data
  }
)

export const createNewHome = createAsyncThunk(
  'marketplace/create-new',
  async (number, thunkAPI) => {
    const response = await thunkAPI
      .extra()
      .sabiApi.createNewMarketplaceHome(number)
    return response && response.data
  }
)

export const saveHome = createAsyncThunk(
  'marketplace/save',
  async ({ homeId, data }, thunkAPI) => {
    await thunkAPI.extra().sabiApi.saveMarketplaceHome(homeId, data)
  }
)
