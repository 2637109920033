import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { getImageProxyAuth0Options } from '../../clients/imageProxyApi'
import { useQuery } from '../../clients/routerUseQuery'
import { configSelector } from '../../configSlice'
import {
  logout,
  selectIsImageProxyCookieExpired,
  selectLoading,
  setCookie,
} from './authSlice'

export default function AuthPage() {
  const history = useHistory()
  const location = useLocation()
  const { set_cookie } = useQuery(location.search)

  const dispatch = useDispatch()
  const auth = useSelector((state) => state.firebase.auth)
  const profile = useSelector((state) => state.firebase.profile)
  const config = useSelector(configSelector)
  const imageProxyAuth0Options = getImageProxyAuth0Options(config)
  const isImageProxyCookieExpired = useSelector(selectIsImageProxyCookieExpired)
  const setCookieLoading = useSelector(selectLoading)

  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  const returnTo = `${window.location.origin}/auth`

  const logoutHandler = useCallback(() => {
    dispatch(logout({ returnTo }))
  }, [dispatch, returnTo])

  const setCookieCallback = useCallback(() => {
    loginWithRedirect({
      appState: {
        setCookie: true,
        returnTo: location.pathname,
      },
      ...imageProxyAuth0Options,
    })
  }, [imageProxyAuth0Options, location, loginWithRedirect])

  useEffect(() => {
    if (set_cookie) {
      console.log('/auth useEffect set_cookie', set_cookie)
      dispatch(setCookie())
        .unwrap()
        .then(() => {
          history.replace(location.state?.returnTo || '/')
        })
    }
  }, [dispatch, history, location, set_cookie])

  return (
    <Container>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>isAuthenticated</TableCell>
                <TableCell>{JSON.stringify(isAuthenticated)}</TableCell>
              </TableRow>
              {isAuthenticated && (
                <>
                  <TableRow>
                    <TableCell>user.name</TableCell>
                    <TableCell>{user.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>user.email</TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <TableCell>isImageProxyCookieExpired</TableCell>
                <TableCell>
                  {JSON.stringify(isImageProxyCookieExpired)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Button>
            <Button sx={{ m: 1 }} variant="contained" onClick={logoutHandler}>
              Log Out
            </Button>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={setCookieCallback}
            >
              Set proxy cookie
            </Button>
          </div>
          <div>
            <pre>{JSON.stringify(setCookieLoading, null, 2)}</pre>
          </div>
          <div>
            <pre>{JSON.stringify(user, null, 2)}</pre>
          </div>
          <div>
            <pre>{JSON.stringify(auth, null, 2)}</pre>
          </div>
          <div>
            <pre>{JSON.stringify(profile, null, 2)}</pre>
          </div>
        </div>
      )}
    </Container>
  )
}
