import proj4 from 'proj4'

export const roundToNearest = (value, accuracy) => {
  return Math.round(value / accuracy) * accuracy
}

proj4.defs(
  'EPSG:3006',
  '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
)

export const convertWGS84ToSWEREF99 = ({ lat, lng }) => {
  const conv = proj4('WGS84', 'EPSG:3006').forward([lng, lat])

  return {
    northing: conv[1],
    easting: conv[0],
  }
}

export const convertSWEREF99ToWGS84 = ({ northing, easting }) => {
  const conv = proj4('EPSG:3006', 'WGS84').forward([easting, northing])

  return {
    lat: conv[1],
    lng: conv[0],
  }
}

export const createAVMRequest = (valuation) => {
  // via average living area / average number of rooms across all listings (Elastic)
  const noRooms = valuation.noRooms || Math.round(valuation.livingArea / 25.4)

  const coordinates = valuation.coordinates
  const sweref = convertWGS84ToSWEREF99({ ...coordinates })

  const housingTypeMapped = {
    villa: 'detached',
    radhus: 'townhouse',
    lägenhet: 'apartment',
  }[valuation.housingType]

  const google_maps_address = valuation.geocodingResult
    ? {
        ...valuation.geocodingResult.structured,
        location_type: 'ROOFTOP',
        place_id: '',
        country: 'SE',
        location: {
          latitude: valuation.coordinates.lat,
          longitude: valuation.coordinates.lng,
        },
        types: [],
      }
    : undefined

  const request = {
    features: {
      housing_type: housingTypeMapped,
      living_area: valuation.livingArea,
      num_rooms: noRooms,
    },
    augmented: {
      geo_location_sweref: sweref,
      google_maps_address: google_maps_address,
    },
  }

  return request
}
