import { AddRounded, OpenInNew, SearchRounded } from '@mui/icons-material'
import {
  Button,
  Container,
  Divider,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import config from '../../config'
import { HomesList } from './components/HomesList'

export const MarketplacePage = () => {
  const [searchValue, setSearchValue] = useState('')
  return (
    <Container width="md">
      <Typography variant="h1">Marketplace</Typography>
      <Box my={3} display="flex" alignItems="flex-end" gap={2}>
        <TextField
          label="Search existing marketplace home"
          placeholder="search for number, address, area"
          value={searchValue}
          onInput={(e) => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1 }}
        />
        <Button
          LinkComponent={ReactRouterLink}
          to="/marketplace/new"
          startIcon={<AddRounded />}
          variant="outlined"
        >
          Add from project number
        </Button>{' '}
        <Button
          variant="outlined"
          component={Link}
          startIcon={<OpenInNew />}
          href={`${config.REACT_APP_APP_URL}/homes`}
          target="_blank"
        >
          Show on marketplace
        </Button>
      </Box>
      <HomesList searchValue={searchValue} />
      <Divider sx={{ my: 3 }} />
    </Container>
  )
}
