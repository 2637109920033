import { EditLocation } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

function AddressPicker({ label, setValues, sx }) {
  const mapsPlacesServiceOptions = {
    // "null" makes the library look for an already
    // initialized google service object
    apiKey: null,
    debounce: 200,
    language: 'en',
    options: {
      types: ['address'],
      componentRestrictions: { country: ['se'] },
    },
  }

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService(mapsPlacesServiceOptions)

  const resolveCoordinates = (event, value) => {
    if (!value || !value.place_id) {
      return
    }

    placesService.getDetails(
      {
        placeId: value.place_id,
        fields: ['geometry.location'],
      },
      (placeDetails) => {
        const coordinates = {
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng(),
        }
        if (coordinates.lat && coordinates.lng) {
          setValues([coordinates.lat, coordinates.lng])
        }
      }
    )
  }

  return (
    <FormControl sx={{ width: sx.width, py: 1 }}>
      <Autocomplete
        filterOptions={(x) => x}
        name="targetAddress"
        onInputChange={(e) =>
          e && e.target && getPlacePredictions({ input: e.target.value })
        }
        onChange={resolveCoordinates}
        noOptionsText="Please enter a valid address."
        loadingText="Loading ..."
        loading={isPlacePredictionsLoading}
        options={placePredictions}
        getOptionValue={(option) => option}
        getOptionLabel={(option) => option.description}
        sx={{ height: sx.height }}
        id="search-address" // to hide password manager
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              sx: { height: sx.height },
            }}
          />
        )}
      />
    </FormControl>
  )
}

// shows a button that opens a dialog with the address picker component
export const AddressPickerButton = ({ setValues, height, width }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ height, width }}
      >
        <EditLocation sx={{ fontSize: '2em', mr: 1 }} />
        Address
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <EditLocation
            sx={{ verticalAlign: 'middle', mr: 1.5, fontSize: '1.5em' }}
          />
          Jump to address
        </DialogTitle>
        <DialogContent sx={{ my: 2 }}>
          <AddressPicker
            label="Address"
            setValues={(v) => {
              setOpen(false)
              setValues(v)
            }}
            sx={{ width: '350px', height: '55px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
