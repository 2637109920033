import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

import { CreateForm } from './components/CreateForm'
import { createManualValuation } from './valuationActions'

export default function ValuationFormPage() {
  const dispatch = useDispatch()
  const submitAction = useCallback(
    ({ homeFeatures, title, getRedirectUrl }) => {
      return dispatch(
        createManualValuation({
          homeFeatures,
          title,
          getRedirectUrl,
        })
      ).unwrap()
    },
    [dispatch]
  )

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
      <Helmet>
        <title>Valuation</title>
      </Helmet>
      <Typography variant="h2" sx={{ my: 3 }}>
        Create valuation
      </Typography>
      <CreateForm submitAction={submitAction} />
    </Box>
  )
}
