import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { Children } from 'react'

export function LabelButtons({ labels, hints, value, onChange, width }) {
  const buttonWidth = width / labels.length

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={onChange}
      color="primary"
    >
      {Children.toArray(
        labels.map((label, index) => (
          <ToggleButton
            value={index}
            color="primary"
            sx={{ width: buttonWidth, height: 50 }}
          >
            <Tooltip
              title={hints[index]}
              placement="top"
              sx={{ width: '100%' }}
            >
              <Box sx={{ width: '100%', fontSize: '2em' }}>{label}</Box>
            </Tooltip>
          </ToggleButton>
        ))
      )}
    </ToggleButtonGroup>
  )
}
