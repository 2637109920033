import { Add, LocalOffer } from '@mui/icons-material'
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Link as ReactRouterLink } from 'react-router-dom'

import { utcIso } from '../../../utils/dateUtils'
import { formatSEK } from '../../../utils/formatUtils'
import { createSGOffer } from '../../offers/sale_guarantee/sgOfferActions'
import { createManualValuationForLead } from '../../valuate/valuationActions'
import {
  manualValuationsByHomeIdConnector,
  manualValuationsByHomeIdSelector,
} from '../../valuate/valuationsSelectors'

export const ManualValuationsCard = ({ lead, sx }) => {
  const dispatch = useDispatch()
  useFirestoreConnect(manualValuationsByHomeIdConnector(lead?.home?.id ?? null))
  const manualValuations = useSelector((state) =>
    manualValuationsByHomeIdSelector(state, lead?.home?.id ?? null)
  )

  return (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">Manual valuations</Typography>
      </CardContent>
      {manualValuations?.length && (
        <List sx={{ overflowY: 'scroll', maxHeight: '310px', mb: 2 }}>
          {manualValuations?.map((manualValuation) => (
            <ListItem>
              <ListItemButton
                component={ReactRouterLink}
                to={`/valuate/${manualValuation.id}`}
              >
                <ListItemText
                  primary={formatSEK(
                    manualValuation?.valuation_estimate?.expectation
                  )}
                  secondary={
                    <>
                      {moment(utcIso(manualValuation?.created_at)).fromNow()}
                      <br />
                      {manualValuation?.created_by}
                    </>
                  }
                />
              </ListItemButton>
              <ListItemButton
                sx={{ width: '20px' }}
                onClick={() => {
                  dispatch(
                    createSGOffer({
                      leadId: lead.id,
                      manualValuationId: manualValuation.id,
                      getRedirectUrl: (offerId) =>
                        `/offers/sale-guarantee/${offerId}`,
                    })
                  )
                }}
              >
                <ListItemIcon>
                  <Add />
                  <LocalOffer />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      <ListItemButton
        onClick={() =>
          dispatch(
            createManualValuationForLead({
              leadId: lead.id,
              getRedirectUrl: (manualValuationId) =>
                `/valuate/${manualValuationId}`,
            })
          )
        }
      >
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText primary="Create manual valuation" />
      </ListItemButton>
    </Card>
  )
}
