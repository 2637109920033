import { HelpOutline } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

export default function Hint({ text, size = 16 }) {
  if (!text || text === '') return <></>
  return (
    <Tooltip title={text}>
      <HelpOutline
        sx={{ ml: 0.5, fontSize: size, opacity: 0.5, mt: `-${size}px` }}
      />
    </Tooltip>
  )
}
