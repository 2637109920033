import {
  DragIndicatorRounded,
  KeyboardDoubleArrowDownRounded,
  KeyboardDoubleArrowUpRounded,
} from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  Tooltip,
} from '@mui/material'
import { Select as MUISelect } from 'mui-rff'
import { Draggable } from 'react-beautiful-dnd'
import { Field } from 'react-final-form'

export const Select = ({ values, ...props }) => (
  <MUISelect {...props}>
    {Object.entries(values).map(([value, label]) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </MUISelect>
)

export function Boolean({ name, ...props }) {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <FormControlLabel
          control={<Switch {...input} {...props} />}
          {...props}
        />
      )}
    </Field>
  )
}

export const DraggableImage = ({ name, moveToBottom, moveToTop, ...props }) => (
  <Draggable draggableId={name} {...props}>
    {(provided) => (
      <Paper
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={(theme) => ({
          backgroundColor: theme.palette.overlay.main,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 80,
          my: 1,
          pl: 2,
        })}
      >
        <DragIndicatorRounded />
        <Box sx={{ height: 'inherit', display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Tooltip title="Move to top" disableInteractive>
              <IconButton onClick={moveToTop} size="small">
                <KeyboardDoubleArrowUpRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Move to bottom" disableInteractive>
              <IconButton onClick={moveToBottom} size="small">
                <KeyboardDoubleArrowDownRounded />
              </IconButton>
            </Tooltip>
          </Box>
          <Field name={`${name}.src`}>
            {({ input }) => (
              <Box
                component="img"
                src={input.value}
                sx={{
                  aspectRatio: '1 / 1',
                  width: 'auto',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            )}
          </Field>
        </Box>
      </Paper>
    )}
  </Draggable>
)
