import { getEnvironment } from '../../app/environment'

export const DEFAULT_BOUNDING_BOX =
  getEnvironment() === 'production'
    ? // Gamla stan, Stockholm
      {
        nwlat: 59.325,
        nwlng: 18.065,
        selat: 59.323,
        selng: 18.072,
      }
    : // Uppsala city
      {
        nwlat: 59.857,
        nwlng: 17.636,
        selat: 59.856,
        selng: 17.642,
      }

export const FILTER_CONFIG = {
  sale_status: {
    type: 'custom',
  },
  no_rooms: {
    type: 'range',
    default: [-1, -1],
    label: 'Rooms',
    unit: '',
    min: 1,
    max: 10,
    step: 1,
  },
  living_area: {
    type: 'range',
    default: [-1, -1],
    label: 'Living area',
    unit: 'm²',
    min: 5,
    max: 300,
    step: 5,
  },
  housing_type: {
    type: 'term',
    default: [],
    label: 'Housing type',
    options: ['villa', 'radhus', 'bostadsratt', 'lägenhet', 'fritidshus'],
    multiple: true,
  },
  bounds: {
    label: 'Address',
    type: 'bounds',
    // to array
    default: [
      DEFAULT_BOUNDING_BOX.nwlat,
      DEFAULT_BOUNDING_BOX.nwlng,
      DEFAULT_BOUNDING_BOX.selat,
      DEFAULT_BOUNDING_BOX.selng,
    ],
  },
  plot_size: {
    type: 'range',
    default: [-1, -1],
    label: 'Plot size',
    unit: 'm²',
    min: 50,
    max: 2500,
    step: 50,
    hiddenByDefault: true,
  },
  street: {
    type: 'prefix-either',
    default: [],
    label: 'Street name',
    elasticFields: [
      'postal_address.streetAddress',
      'google_maps_address.route',
    ],
  },
  sold_price: {
    type: 'range',
    default: [-1, -1],
    label: 'Sold price',
    unit: 'SEK',
    min: 1,
    max: 15000000,
    step: 100000,
    hiddenByDefault: true,
  },
  number_search_results: {
    type: 'scalar',
    default: [500],
    label: 'Max results',
    unit: '',
    max: 800,
    min: 50,
    step: 50,
    hiddenByDefault: true,
    includeMaxValue: true,
  },
  date: {
    type: 'range',
    default: [0, 18],
    label: 'Date',
    unit: 'months ago',
    min: 1,
    max: 36,
    step: 1,
    hiddenByDefault: true,
  },
}

export const DEFAULT_FILTERS = Object.keys(FILTER_CONFIG).filter(
  (name) => FILTER_CONFIG[name].default
)
