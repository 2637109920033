import { Add } from '@mui/icons-material'
import { Box, Chip } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { TagsDialogWithInputFieldV2 } from './TagsDialogWithInputFieldV2'
import { TagsListV2 } from './TagsListV2'
import {
  tagSuggestionConnector,
  tagSuggestionSelector,
} from './tagSuggestionsSelector'

export const TagsSectionV2 = ({
  tagsObj,
  onRemove,
  onAdd,
  suggestionsDocKey = null,
  canModify = true,
  ...props
}) => {
  useFirestoreConnect(tagSuggestionConnector(suggestionsDocKey))
  const tagSuggestions = useSelector((state) =>
    tagSuggestionSelector(state, suggestionsDocKey)
  )

  const [isPromptOpen, setIsPromptOpen] = useState(false)

  return (
    <Box {...props}>
      <TagsListV2
        tagsObj={tagsObj}
        onRemove={onRemove}
        canModify={canModify}
        tagSuggestions={tagSuggestions}
      />
      {canModify && (
        <>
          <Chip
            label={
              <>
                <Add sx={{ verticalAlign: 'top', fontSize: '1.4em' }} /> Add tag
              </>
            }
            sx={{ mr: 1, mb: 1, fontWeight: 'bold' }}
            onClick={() => setIsPromptOpen(true)}
          />
          <TagsDialogWithInputFieldV2
            isOpen={isPromptOpen}
            onClose={() => setIsPromptOpen(false)}
            onSubmit={onAdd}
            suggestions={tagSuggestions}
            tagsObj={tagsObj}
            onRemove={onRemove}
          />
        </>
      )}
    </Box>
  )
}
