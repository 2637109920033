import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { TagsSectionV2 } from '../../../components/tags/TagsSectionV2'
import {
  addTagToListing,
  listingIdTagsConnector,
  listingIdTagsSelector,
  removeTagFromListing,
} from '../listingTagsSlice'

export default function Tagging({ listingId }) {
  const dispatch = useDispatch()

  useFirestoreConnect(listingIdTagsConnector(listingId))

  const listingTags =
    useSelector((state) => listingIdTagsSelector(state, listingId))?.tags ?? {}

  return (
    <TagsSectionV2
      tagsObj={listingTags}
      canModify={true}
      suggestionsDocKey="listing_tag"
      onAdd={(tag) => {
        dispatch(addTagToListing({ listingId, tag }))
      }}
      onRemove={(tag) => {
        dispatch(removeTagFromListing({ listingId, tag }))
      }}
    />
  )
}
