import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import { Badge, Box } from '@mui/material'
import _ from 'lodash'
import { Children } from 'react'

export default function ImagesGroup({
  listing,
  imageIndices,
  misclassifiedImages,
  toggleMisclassifiedImage,
}) {
  if (_.isEmpty(imageIndices) || !listing) {
    return <></>
  }

  const images = imageIndices.map((idx) => [listing.images[idx], idx])

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'left',
        justifyContent: 'start',
        minHeight: 300,
      }}
    >
      {Children.toArray(
        images.map((srcAndId, index) => (
          <LabelImage
            src={srcAndId[0]}
            index={srcAndId[1]}
            isMisclassified={misclassifiedImages.indexOf(srcAndId[1]) !== -1}
            toggleMisclassified={(index) => toggleMisclassifiedImage(index)}
          />
        ))
      )}
    </Box>
  )
}

const LabelImage = ({ src, index, isMisclassified, toggleMisclassified }) => {
  return (
    <Box
      sx={{
        mb: 3,
        mr: 3,
        opacity: isMisclassified ? 0.25 : 1.0,
        cursor: 'pointer',
      }}
    >
      <Badge
        badgeContent={isMisclassified ? <Add /> : <Remove />}
        color="primary"
        onClick={() => toggleMisclassified(index)}
      >
        <img
          alt={`To label, ${index + 1}`}
          key={src}
          src={src}
          style={{
            height: 350,
            minWidth: 350,
            background: 'lightgrey',
            display: 'inline-block',
          }}
        />
      </Badge>
    </Box>
  )
}
