import { createSelector } from '@reduxjs/toolkit'
import { populate } from 'react-redux-firebase'

const populatesOfferWithValuationAndLead = [
  {
    child: 'manual_valuation_id',
    root: 'manual_valuation',
    childAlias: 'manual_valuation',
  },
  { child: 'lead_id', root: 'lead_root', childAlias: 'lead' },
]

export const allSGOffersConnector = () => [
  {
    collection: 'sale_guarantee_offer',
    populates: populatesOfferWithValuationAndLead,
    where: [['deleted_at', '==', null]],
  },
]

export const allSGOffersSelector = createSelector(
  (state) => state.firestore.ordered.sale_guarantee_offer,
  (offers) => (offers ? Object.values(offers) : offers)
)

export const sgOfferByIdConnector = (offerId) => () =>
  [
    {
      collection: 'sale_guarantee_offer',
      doc: offerId || '0', // should not be undefined, otherwise we query AND populate all
      populates: populatesOfferWithValuationAndLead,
    },
  ]

export const sgOfferByIdSelector = createSelector(
  ({ firestore }, offerId) =>
    populate(
      firestore,
      'sale_guarantee_offer',
      populatesOfferWithValuationAndLead
    )?.[offerId],
  (offer) => offer
)

export const sgOffersByLeadIdConnector = (leadId) => () =>
  [
    {
      collection: 'sale_guarantee_offer',
      where: [
        ['lead_id', '==', leadId],
        ['deleted_at', '==', null],
      ],
      storeAs: `sale_guarantee_offer_for_lead_${leadId}`,
    },
  ]

export const sgOffersByLeadIdSelector = createSelector(
  ({ firestore }, leadId) =>
    firestore.ordered[`sale_guarantee_offer_for_lead_${leadId}`],
  (offers) =>
    Object.values(offers || {}).sort((a, b) =>
      `${b?.created_at}`?.localeCompare(a?.created_at)
    )
)
