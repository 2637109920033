import {
  Backdrop,
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { updateHistory, useQuery } from '../../../clients/routerUseQuery'
import { downloadImagesAsArchive } from '../../../utils/zipUtils'
import Gallery from './Gallery'

export default function ImageGrid({ listing, showAll, updateURL = true }) {
  const query = useQuery()
  const location = useLocation()
  const history = useHistory()

  const [imagesCollapsed, setImagesCollapsed] = useState(!showAll)
  const [fullImageId, setFullImageId_] = useState(query?.imageId ?? null)
  const setFullImageId = useCallback(
    (imageId) => {
      if (updateURL) {
        updateHistory(location, history, { imageId })
      }
      setFullImageId_(imageId)
    },
    [location, history, updateURL]
  )

  const [isBackdropOn, setBackdropOn] = useState(false)
  const [backdropStatusMessage, setBackdropStatusMessage] = useState('')

  const downloadImages = useCallback(() => {
    setBackdropOn(true)
    downloadImagesAsArchive({
      images: listing.images,
      fileName: listing.streetAddress,
      setStatusMessage: setBackdropStatusMessage,
    }).then(() => {
      setBackdropOn(false)
    })
  }, [listing])

  return (
    <>
      <ImageList variant="masonry" cols={3} gap={8}>
        {listing.thumbImages
          .slice(0, listing.images.length > 9 && imagesCollapsed ? 9 : -1)
          .map((src, index) => (
            <ImageListItem key={src}>
              <img
                src={src}
                srcSet={src}
                onClick={() => {
                  setFullImageId(index)
                }}
                alt="test"
                loading="lazy"
              />
            </ImageListItem>
          ))}
      </ImageList>
      {listing.images.length > 9 && imagesCollapsed && (
        <Button
          variant="outlined"
          onClick={() => setImagesCollapsed(false)}
          sx={{ mr: 2 }}
        >
          Show all ({listing.images.length})
        </Button>
      )}
      {listing.images.length > 0 && (
        <Button variant="outlined" onClick={downloadImages}>
          Download
        </Button>
      )}
      {fullImageId !== null && (
        <Gallery
          images={listing.images}
          imageId={fullImageId}
          setImageId={setFullImageId}
        />
      )}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        open={isBackdropOn}
      >
        <CircularProgress color="inherit" sx={{ mb: 2 }} />
        <Typography>{backdropStatusMessage}</Typography>
        <Typography>This might take a minute ...</Typography>
      </Backdrop>
    </>
  )
}
