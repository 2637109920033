import { Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { capitalize } from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GeoMap from '../../../components/GeoMap'
import { debugSelector } from '../../debug/debugSlice'
import ImageGrid from '../../images/components/ImageGrid'
import {
  brokerPagesByListingIdSelector,
  hubspotDealByListingIdLoadingSelector,
  hubspotDealByListingIdSelector,
  importListingToHubspot,
} from '../listingsSlice'
import Documents from './Documents'
import FeatureInfo from './FeatureInfo'
import FinanceInfo from './FinanceInfo'
import HubspotActions from './HubspotActions'
import ListingTagging from './ListingTagging'
import SaleInfo from './SaleInfo'

export default function ListingView({
  listing,
  index,
  updateURLForGallery = true,
  showTags = true,
}) {
  const dispatch = useDispatch()
  const debugMode = useSelector(debugSelector)

  const legacyBucketUrl = debugMode && listing && listing.bucketURL

  const hubspotDealInfo = useSelector((state) =>
    hubspotDealByListingIdSelector(state, listing?.id)
  )
  const hubspotDealInfoLoading = useSelector((state) =>
    hubspotDealByListingIdLoadingSelector(state, listing?.id)
  )

  const brokerPages = useSelector((state) =>
    brokerPagesByListingIdSelector(state, listing?.id)
  )

  const importToHubspotAndRedirect = useCallback(() => {
    if (!listing?.id) return
    dispatch(importListingToHubspot({ listingId: listing?.id, index }))
  }, [dispatch, listing?.id, index])

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item md={6}>
          <Typography
            variant="h2"
            sx={{ wordWrap: 'break-word', overflow: 'auto', maxWidth: '90vw' }}
          >
            {listing.streetAddress}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Chip
              label={listing.statusFormatted || 'Unknown'}
              color="primary"
              size="small"
              variant="outlined"
            />{' '}
            <Chip
              label={capitalize(listing.housingType || 'Unknown type')}
              color="secondary"
              size="small"
              variant="outlined"
            />{' '}
            <Typography>
              {listing.sublocality && listing.sublocality !== listing.city
                ? `${listing.sublocality}, `
                : ''}
              {listing.city}
            </Typography>
          </Box>

          <Box sx={{ height: 35, mt: 6, display: 'flex' }}>
            <HubspotActions
              loading={hubspotDealInfoLoading}
              info={hubspotDealInfo}
              onImport={importToHubspotAndRedirect}
            />
          </Box>

          <Box></Box>

          <Card sx={{ marginTop: 4 }}>
            <CardContent>
              <FinanceInfo listing={listing} />
            </CardContent>
          </Card>

          <Card sx={{ marginTop: 4 }}>
            <CardContent>
              <FeatureInfo listing={listing} />
            </CardContent>
          </Card>

          <Card sx={{ marginTop: 4 }}>
            <CardContent>
              <SaleInfo listing={listing} brokerPages={brokerPages} />
            </CardContent>
          </Card>

          {showTags && (
            <Box sx={{ marginTop: 4 }}>
              <ListingTagging listingId={listing.id} />
            </Box>
          )}

          {listing.description && (
            <Card sx={{ marginTop: 4 }}>
              <CardContent>
                {listing.description.split(/\r?\n/).map((p, idx) => (
                  <Typography key={idx} gutterBottom>
                    {p}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          )}

          <Card sx={{ marginTop: 4 }}>
            <CardContent>
              <Documents
                documents={listing.documents}
                legacyBucketUrl={legacyBucketUrl}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <ImageGrid listing={listing} updateURL={updateURLForGallery} />
          <Box sx={{ height: '40vh', marginTop: '2em' }}>
            <GeoMap
              mapItems={[
                {
                  id: listing.id,
                  title: '',
                  images: [],
                  coordinates: listing.coordinates,
                  open: () => {},
                },
              ]}
              height="300px"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
