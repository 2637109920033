import * as Yup from 'yup'

/* eslint-disable no-template-curly-in-string */

export const formSchema = Yup.object({
  living_area: Yup.number()
    .label('Living area')
    .required()
    .typeError('${path} must be a number')
    .min(1)
    .max(5000),
  supplementary_area: Yup.number()
    .label('Supplementary area')
    .required()
    .typeError('${path} must be a number')
    .min(0)
    .max(5000),
  num_floors: Yup.number()
    .label('Floors')
    .required()
    .typeError('${path} must be a number')
    .min(0)
    .max(100),
  notes: Yup.string().label('Notes').max(10000),
  items: Yup.array()
    .label('Renovation items list')
    .required()
    .min(1)
    .of(
      Yup.object({
        options: Yup.array()
          .label('Renovation item options')
          .required()
          .min(1)
          .of(
            Yup.object({
              value: Yup.number()
                .label('Renovation option value')
                .typeError('${path} must be a number'),
              note: Yup.string().optional(),
            })
          ),
        total: Yup.number().label('Item total'),
      })
    ),
  total: Yup.number().label('Total'),
})
/* eslint-enable no-template-curly-in-string */
