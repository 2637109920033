import { Alert, Box } from '@mui/material'
import _ from 'lodash'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import {
  suggestionsLoadingSelector,
  suggestionsSelector,
} from '../redux/marketplaceSelectors'
import Suggestions from './Suggestions'

function FormComponent([name, { Component, infoBox, ...data }]) {
  const suggestions = useSelector(suggestionsSelector)
  const suggestionsAvailable =
    useSelector(suggestionsLoadingSelector) === 'fulfilled'

  if (name.startsWith('COLUMN')) {
    return (
      <Box display="flex" gap={1}>
        {formComponentMapper(data)}
      </Box>
    )
  }
  return (
    <Fragment key={name}>
      <Component {...data} name={name} />
      {suggestionsAvailable && (
        <Suggestions
          name={name}
          assetSuggestion={_.get(suggestions, 'asset_sheet.' + name, undefined)}
          hemnetSuggestion={_.get(
            suggestions,
            'hemnet_listing.' + name,
            undefined
          )}
          geoLocationSuggestion={_.get(
            suggestions,
            'geo_location.' + name,
            undefined
          )}
        />
      )}
      {infoBox && (
        <Alert sx={{ mb: 1 }} severity="info">
          {infoBox}
        </Alert>
      )}
    </Fragment>
  )
}

FormComponent.propTypes = {}

export function formComponentMapper(fields) {
  return Object.entries(fields).map(FormComponent)
}

export default FormComponent
