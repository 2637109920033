import { Box } from '@mui/system'
import Carousel from 'react-material-ui-carousel'

export default function ThumbCarousel({ images, imageHeight }) {
  return (
    <>
      <Carousel
        indicators={false}
        autoPlay={false}
        animation="slide"
        sx={{ height: imageHeight, width: '100%' }}
      >
        {images.map((imageSrc, idx) => (
          <Box key={idx}>
            <Box
              sx={{
                width: '100%',
                height: imageHeight,
                justifyContent: 'center',
                background: `url('${imageSrc}')`,
                opacity: 0.55,
                filter: 'blur(3px)',
              }}
            ></Box>
            <Box
              sx={{
                width: '100%',
                height: imageHeight,
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            >
              <img
                src={imageSrc}
                alt={`Preview for home, ${idx}`}
                style={{
                  display: 'block',
                  objectFit: 'cover',
                  height: imageHeight,
                  margin: 'auto',
                }}
              />
            </Box>
          </Box>
        ))}
      </Carousel>
      {images.map((image, idx) => (
        <link key={idx} rel="preload" href={image} as="image" />
      ))}
    </>
  )
}
