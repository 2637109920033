import './image-gallery.css'

import { Alert } from '@mui/lab'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useQuery } from '../../clients/routerUseQuery'
import { configSelector } from '../../configSlice'
import {
  getListing,
  listingByIdSelector,
  selectLoading,
} from '../listings/listingsSlice'
import ImageGrid from './components/ImageGrid'

export const Images = () => {
  const query = useQuery()
  const { listingId, index } = query
  const loading = useSelector(selectLoading)
  const listing = useSelector((state) => listingByIdSelector(state, listingId))
  const dispatch = useDispatch()
  const config = useSelector(configSelector)

  useEffect(() => {
    dispatch(getListing({ listingId, index }))
  }, [dispatch, listingId, index])

  if (loading !== 'idle') {
    return (
      <div>
        <pre>{JSON.stringify(loading, null, 2)}</pre>
        <link rel="preconnect" href={config.REACT_APP_IMAGE_PROXY_URL}></link>
      </div>
    )
  }

  if (!listing) {
    return <Alert color="error">Listing does not exist.</Alert>
  }

  if (listing.images.length === 0) {
    return <pre>No images for this listing.</pre>
  }

  return <ImageGrid listing={listing} showAll />
}
