import { createAsyncThunk } from '@reduxjs/toolkit'

import { nowString, toTimestampString } from '../../../utils/firestoreUtils'
import { generateUUID } from '../../../utils/uuidUtils'
import { authSelector } from '../../auth/authSlice'
import { getStreetAddress } from '../../leads/homeUtils'
import { getContactName } from '../../leads/leadUtils'

const EDITABLE_FIELDS_POST_COMMIT = [
  'customer_expectation_low',
  'customer_expectation_high',
  'customer_perception_low',
  'customer_perception_high',
  'internal_comment',
]

const EDITABLE_FIELDS = [
  'presented_low',
  'presented_high',
  'guarantee_price',
  'guarantee_price_type',
  'customer_comment',
  'fee_amount',
  'fee_percentage',
  'market_risk_level',
  'market_risk_comment',
  'commitment_status',
  'expired_at',
  ...EDITABLE_FIELDS_POST_COMMIT,
]

export const updateSGOffer = createAsyncThunk(
  'sale_guarantee_offer/update',
  async ({ offerId, values, isCommitted }, thunkAPI) => {
    const fields = isCommitted ? EDITABLE_FIELDS_POST_COMMIT : EDITABLE_FIELDS
    const valuesSanitized = {
      ...values,
      fee_percentage:
        values.guarantee_price_type === 'percentage'
          ? values.fee_percentage
          : null,
      fee_amount:
        values.guarantee_price_type === 'fixed' ? values.fee_amount : null,
    }
    const valuesUpdate = fields.reduce((acc, field) => {
      if (valuesSanitized[field] !== undefined) {
        acc[field] = valuesSanitized[field]
      } else {
        acc[field] = null
      }
      return acc
    }, {})
    // TODO check fee type!
    await thunkAPI
      .extra()
      .getFirestore()
      .collection('sale_guarantee_offer')
      .doc(offerId)
      .update({
        ...valuesUpdate,
        expired_at: toTimestampString(valuesSanitized.expired_at),
        updated_at: nowString(),
        updated_by: authSelector(thunkAPI.getState()).uid,
      })

    await createSnapshot(thunkAPI.extra().getFirestore(), offerId)
  }
)

export const createSGOffer = createAsyncThunk(
  'sale_guarantee_offer/create',
  async ({ manualValuationId, leadId, getRedirectUrl }, thunkAPI) => {
    const state = thunkAPI.getState()
    const auth = authSelector(state)

    const lead = await thunkAPI
      .extra()
      .getFirestore()
      .collection('lead_root')
      .doc(leadId)
      .get()

    const manualValuation = await thunkAPI
      .extra()
      .getFirestore()
      .collection('manual_valuation')
      .doc(manualValuationId)
      .get()

    const title = [
      getStreetAddress(manualValuation.data().features_snapshot.features),
      getContactName(lead.data()),
    ].join(' - ')

    // generate uuid
    const createdAt = nowString()
    const docId = generateUUID()
    await thunkAPI
      .extra()
      .getFirestore()
      .collection('sale_guarantee_offer')
      .doc(docId)
      .set({
        id: docId,
        created_at: createdAt,
        created_by: auth.uid,
        updated_at: createdAt,
        updated_by: auth.uid,
        deleted_at: null,
        deleted_by: null,
        presented_at: null,
        presented_by: null,
        manual_valuation_id: manualValuationId,
        lead_id: leadId,
        title,
      })

    await createSnapshot(thunkAPI.extra().getFirestore(), docId)
    if (getRedirectUrl) {
      window.location.href = getRedirectUrl(docId)
    }

    return docId
  }
)

export const commitSGOffer = createAsyncThunk(
  'sale_guarantee_offer/commit',
  async ({ offerId, getRedirectUrl }, thunkAPI) => {
    const state = thunkAPI.getState()
    const auth = authSelector(state)

    const presentedAt = nowString()

    await thunkAPI
      .extra()
      .getFirestore()
      .collection('sale_guarantee_offer')
      .doc(offerId)
      .update({
        presented_at: presentedAt,
        presented_by: auth.uid,
        updated_at: presentedAt,
        updated_by: authSelector(thunkAPI.getState()).uid,
      })

    await createSnapshot(thunkAPI.extra().getFirestore(), offerId)

    if (getRedirectUrl) {
      window.location.href = getRedirectUrl(offerId)
    }

    return offerId
  }
)

export const deleteSGOffer = createAsyncThunk(
  'sale_guarantee_offer/delete',
  async ({ offerId, getRedirectUrl }, thunkAPI) => {
    const state = thunkAPI.getState()
    const auth = authSelector(state)

    const deletedAt = nowString()

    await thunkAPI
      .extra()
      .getFirestore()
      .collection('sale_guarantee_offer')
      .doc(offerId)
      .update({
        deleted_at: deletedAt,
        deleted_by: auth.uid,
        updated_at: deletedAt,
        updated_by: authSelector(thunkAPI.getState()).uid,
      })

    await createSnapshot(thunkAPI.extra().getFirestore(), offerId)

    if (getRedirectUrl) {
      window.location.href = getRedirectUrl()
    }
  }
)

const createSnapshot = async (firestore, docId) => {
  const doc = await firestore
    .collection('sale_guarantee_offer')
    .doc(docId)
    .get()

  const values = doc.data()

  if (!values.updated_at || !doc.id) {
    throw new Error('updated_at and id are required')
  }

  await firestore
    .collection('sale_guarantee_offer')
    .doc(values.id)
    .collection('sale_guarantee_offer_snapshot')
    .doc(values.updated_at)
    .set(values)
}
