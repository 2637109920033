import { Insights, LocalOffer } from '@mui/icons-material'
import { Typography } from '@mui/material'
import {
  DateTimePicker,
  makeRequired,
  makeValidate,
  Radios,
  TextField,
} from 'mui-rff'
import * as Yup from 'yup'

import NumberFormatCustom from '../../../../components/NumberFormatCustom'

const schema = Yup.object().shape({
  presented_low: Yup.number().min(1).required().label('Presented low'),
  presented_high: Yup.number().required().label('Presented high'),
  guarantee_price: Yup.number().required().label('Guarantee price'),
  guarantee_price_type: Yup.string()
    .oneOf(['fixed', 'percentage'])
    .required()
    .label('Guarantee price type'),
  customer_comment: Yup.string().default('').label('Customer comment'),
  fee_amount: Yup.number()
    .default(0)
    .when('guarantee_price_type', {
      is: 'fixed',
      then: Yup.number().min(0).required(),
    })
    .label('Fee amount'),
  fee_percentage: Yup.number()
    .max(100)
    .default(0)
    .when('guarantee_price_type', {
      is: 'percentage',
      then: Yup.number().min(0).required(),
    })
    .label('Fee percentage'),
  market_risk_level: Yup.string().default('').label('Market risk level'),
  market_risk_comment: Yup.string().default('').label('Market risk level'),
  commitment_status: Yup.string()
    .oneOf(['preliminary', 'verified'])
    .required()
    .label('Commitment status'),
  expired_at: Yup.date()
    .required()
    .nullable()
    .default(undefined)
    .label('Offer expiration'),
  customer_expectation_low: Yup.number()
    .nullable()
    .label('Customer expectation low'),
  customer_expectation_high: Yup.number()
    .nullable()
    .label('Customer expectation high'),
  customer_perception_low: Yup.number()
    .nullable()
    .label('Customer perception low'),
  customer_perception_high: Yup.number()
    .nullable()
    .label('Customer perception high'),
  internal_comment: Yup.string().default('').label('Internal comment'),
})

export const required = makeRequired(schema)

export const validate = makeValidate(schema)

export const cast = (values) => schema.cast(values)

const MAX_WIDTH = 300

const AMOUNT_FIELD_PROPS = {
  InputLabelProps: { shrink: true },
  InputProps: {
    endAdornment: 'SEK',
    inputComponent: NumberFormatCustom,
  },
  sx: { width: MAX_WIDTH },
}

export const formFields = [
  [
    <Typography variant="h5">
      <LocalOffer sx={{ color: 'primary.main', mr: 2, mb: -0.5 }} />
      Offering
    </Typography>,
  ],
  [
    <TextField
      name="presented_low"
      label="Presented low"
      {...AMOUNT_FIELD_PROPS}
      required={required.presented_low}
    />,
    <TextField
      name="presented_high"
      label="Presented high"
      required={required.presented_high}
      {...AMOUNT_FIELD_PROPS}
    />,
  ],
  [
    <TextField
      name="guarantee_price"
      label="Guarantee price"
      required={required.guarantee_price}
      {...AMOUNT_FIELD_PROPS}
    />,
  ],
  [
    <Radios
      name="guarantee_price_type"
      label="Fee type"
      data={[
        { label: 'Fixed price', value: 'fixed' },
        {
          label: 'Percentage of selling price',
          value: 'percentage',
        },
      ]}
      required={required.guarantee_price_type}
      radioGroupProps={{ row: true }}
    />,
  ],
  [
    <TextField
      name="fee_amount"
      label="Fee (fixed)"
      {...AMOUNT_FIELD_PROPS}
      required={required.fee_amount}
    />,
    <TextField
      name="fee_percentage"
      label="Fee (percentage, 0-100%)"
      InputLabelProps={{ shrink: true }}
      InputProps={{ endAdornment: '%' }}
      sx={{ width: MAX_WIDTH }}
      required={required.fee_percentage}
    />,
  ],
  [
    <TextField
      name="market_risk_level"
      multiline
      rows={1}
      label="Market risk level"
      required={required.customer_comment}
      fullWidth
    />,
  ],
  [
    <TextField
      name="market_risk_comment"
      multiline
      rows={5}
      label="Market risk comment"
      required={required.customer_comment}
      fullWidth
    />,
  ],
  [
    <Radios
      name="commitment_status"
      label="Offer (commitment) Status"
      data={[
        { label: 'Preliminary', value: 'preliminary' },
        {
          label: 'Verified',
          value: 'verified',
        },
      ]}
      required={required.commitment_status}
      radioGroupProps={{ row: true }}
    />,
  ],
  [
    <TextField
      name="customer_comment"
      multiline
      rows={3}
      label="Customer comment (for my pages)"
      required={required.customer_comment}
      fullWidth
    />,
  ],
  [
    // Date picker does not allow setting width on outer element :/
    <DateTimePicker
      label="Offer expiration"
      name="expired_at"
      format="YYYY-MM-DD HH:mm"
      ampm={false}
      required={required.expired_at}
      // set input width (not outer element)
      InputProps={{ style: { width: MAX_WIDTH } }}
    />,
  ],
  [
    <Typography variant="h5" sx={{ mt: 3 }}>
      <Insights sx={{ color: 'primary.main', mr: 2, mb: -0.5 }} />
      Internal
    </Typography>,
  ],
  [
    <TextField
      name="customer_expectation_low"
      label="Customer expectation low"
      required={required.customer_expectation_low}
      editableAfterCommit
      {...AMOUNT_FIELD_PROPS}
    />,
    <TextField
      name="customer_expectation_high"
      label="Customer expectation high"
      required={required.customer_expectation_high}
      editableAfterCommit
      {...AMOUNT_FIELD_PROPS}
    />,
  ],
  [
    <TextField
      name="customer_perception_low"
      label="Customer perception low"
      required={required.customer_perception_low}
      editableAfterCommit
      {...AMOUNT_FIELD_PROPS}
    />,
    <TextField
      name="customer_perception_high"
      label="Customer perception high"
      required={required.customer_perception_high}
      editableAfterCommit
      {...AMOUNT_FIELD_PROPS}
    />,
  ],
  [
    <TextField
      name="internal_comment"
      multiline
      minRows={3}
      maxRows={30}
      label="Movesta-internal comment"
      required={required.internal_comment}
      editableAfterCommit
      fullWidth
      inputProps={{ sx: { resize: 'vertical' } }}
    />,
  ],
]
