import { Grid } from '@mui/material'
import { Box } from '@mui/system'

export default function ListingSection({ icon, children }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: 75 }}>{icon}</Box>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {children}
      </Grid>
    </Box>
  )
}
