import { Check, SwapVert } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  formatArea,
  formatRooms,
  formatSEK,
  formatSEKPerArea,
} from '../../../utils/formatUtils'

export const ListingCard = ({
  streetAddress,
  housingType,
  numRooms,
  livingArea,
  plotArea,
  saleStatus,
  saleDate,
  salePrice,
  listingDate,
  listingPrice,
  sublocality,
  link,
  thumbSrc,
  children,
}) => {
  const attributes = useMemo(() => {
    const salePricePerLivingArea =
      salePrice && livingArea ? salePrice / livingArea : null

    return [
      numRooms && formatRooms(numRooms),
      livingArea && formatArea(livingArea),
      plotArea && formatArea(plotArea),
      salePricePerLivingArea && formatSEKPerArea(salePricePerLivingArea),
    ].filter((a) => a)
  }, [numRooms, livingArea, plotArea, salePrice])

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
      }}
    >
      <CardActionArea
        to={link}
        component={Link}
        sx={{ flex: '0 0 160px' }}
        style={{ textDecoration: 'none' }} // react-router Link doesn't support sx
      >
        <PreviewImage
          thumbSrc={thumbSrc}
          saleStatus={saleStatus}
          saleDate={saleDate}
          listingDate={listingDate}
        />
      </CardActionArea>

      <Box flex="1 0 200px">
        <CardActionArea
          to={link}
          component={Link}
          style={{ textDecoration: 'none' }} // react-router Link doesn't support sx
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: 1,
            px: 2,
            py: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap={1}
          >
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              title={streetAddress}
            >
              {streetAddress}
            </Typography>
            <Typography fontSize="0.9em" flex="1 0 70px" textAlign="right">
              {moment(saleDate || listingDate).format('YYYY-MM-DD')}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: '0.9em', color: '#aaa' }}>
            {[_.capitalize(housingType), sublocality]
              .filter((a) => a)
              .join(', ')}
          </Typography>
          {salePrice ? (
            <Typography sx={{ fontSize: '1.5em', fontWeight: 'bold' }}>
              {formatSEK(salePrice)}
              <Check sx={{ verticalAlign: 'middle', ml: 1, color: '#bbb' }} />
            </Typography>
          ) : listingPrice ? (
            <Typography sx={{ fontSize: '1.5em' }}>
              {formatSEK(listingPrice)}
              <SwapVert
                sx={{ verticalAlign: 'middle', ml: 1, color: '#bbb' }}
              />
            </Typography>
          ) : (
            <Typography sx={{ fontSize: '1.5em' }}>?</Typography>
          )}
          <Box display="flex" gap={2}>
            {attributes.map((a, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: '0.9em',
                  minWidth: '60px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {a}
              </Typography>
            ))}
          </Box>
        </CardActionArea>
        {children && (
          <CardActions sx={{ py: 0.5, pl: 1 }}>{children}</CardActions>
        )}
      </Box>
    </Card>
  )
}

const PreviewImage = ({ saleStatus, thumbSrc }) => {
  const boxTypographyStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    maxWidth: '90px',
    overflow: 'hidden',
    borderRadius: 2,
    p: 0.75,
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
        backgroundColor: '#eee',
        backgroundImage: thumbSrc ? `url(${thumbSrc})` : null,
        backgroundSize: 'cover',
        p: 1,
        gap: 1,
      }}
    >
      {saleStatus && (
        <Typography
          sx={{
            ...boxTypographyStyle,
            fontSize: '1.0em',
            fontWeight: 700,
          }}
        >
          {saleStatus}
        </Typography>
      )}
    </Box>
  )
}
