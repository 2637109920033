import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Button, IconButton, Toolbar } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { FIREBASE_PROJECT_ID } from '../config'
import {
  debugSelector,
  setDebugFalse,
  setDebugTrue,
} from '../features/debug/debugSlice'

export default function TopBar({ toggleMenu }) {
  const debugMode = useSelector(debugSelector)
  const dispatch = useDispatch()
  const toggleDebugMode = useCallback(() => {
    dispatch(debugMode ? setDebugFalse() : setDebugTrue())
  }, [dispatch, debugMode])

  return (
    <div>
      <AppBar
        position="static"
        color={
          FIREBASE_PROJECT_ID.startsWith('development')
            ? 'secondary'
            : 'primary'
        }
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            onClick={toggleMenu}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to="/">
            <img
              src="/logo-white.png"
              style={{ height: '1.5em' }}
              alt="Movesta logo"
            />
          </RouterLink>
          <Box sx={{ flex: 1 }} />
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color={debugMode ? 'tertiary' : 'inherit'}
              variant={debugMode ? 'contained' : null}
              onClick={toggleDebugMode}
            >
              Debug mode
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}
