import { House } from '@mui/icons-material'
import {
  Card,
  CardContent,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

import { getFeaturesAsList } from '../homeUtils'

export const HomeFeaturesCard = ({ features, sx }) => {
  const homeFeaturesList = getFeaturesAsList(features)

  return (
    <Card sx={sx}>
      <CardContent>
        <Typography variant="h5">Home features</Typography>
      </CardContent>
      <TableContainer sx={{ maxHeight: '300px', overflowY: 'auto' }}>
        <Table sx={{ width: '100%' }} aria-label="home features">
          <TableBody>
            {homeFeaturesList.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {false && (
        <ListItemButton sx={{ mt: 3 }}>
          <ListItemIcon>
            <House />
          </ListItemIcon>
          <ListItemText primary="Show home" />
        </ListItemButton>
      )}
    </Card>
  )
}
