import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { clearQueue, selectQueue, setSampleIdx } from '../dsAVMPredictionSlice'

export const SamplesQueueProgress = ({ predictTaskId, currentSampleId }) => {
  const queue = useSelector(selectQueue)
  const dispatch = useDispatch()
  const history = useHistory()
  const [stopDialogOpen, setStopDialogOpen] = useState(false)

  const samples = queue?.samples
  const sampleIdx = samples?.indexOf(currentSampleId) ?? 0
  const progress = sampleIdx
    ? Math.max((sampleIdx / samples.length) * 100, 3)
    : 0

  const jump = useCallback(
    (e) => {
      const sampleId = e.target.value
      const idx = samples.indexOf(sampleId)
      dispatch(setSampleIdx(idx))
      history.push(`/ds-avm-predict/${predictTaskId}/${sampleId}`)
    },
    [samples, history, dispatch, predictTaskId]
  )

  const next = useCallback(() => {
    if (samples && sampleIdx < samples?.length - 1) {
      dispatch(setSampleIdx(sampleIdx + 1))
      history.push(`/ds-avm-predict/${predictTaskId}/${samples[sampleIdx + 1]}`)
    }
  }, [sampleIdx, samples, history, dispatch, predictTaskId])

  const previous = useCallback(() => {
    if (samples && sampleIdx > 0) {
      dispatch(setSampleIdx(sampleIdx - 1))
      history.push(`/ds-avm-predict/${predictTaskId}/${samples[sampleIdx - 1]}`)
    }
  }, [sampleIdx, samples, history, dispatch, predictTaskId])

  if (
    !queue ||
    queue.predictTaskId !== predictTaskId ||
    !queue?.samples?.includes(currentSampleId)
  ) {
    return null
  }

  return (
    <>
      <ConfirmStopDialog
        open={stopDialogOpen}
        onClose={() => setStopDialogOpen(false)}
      />
      <Box sx={{ mb: 5 }}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Chip label={`Sample ${sampleIdx + 1} / ${samples.length}`} />
          <Button onClick={previous} disabled={sampleIdx === 0}>
            Previous
          </Button>
          <Select value={currentSampleId} onChange={jump} size="small">
            {samples.map((sampleId, idx) => (
              <MenuItem key={sampleId} value={sampleId}>
                {idx + 1}: {sampleId}
              </MenuItem>
            ))}
          </Select>

          <Button onClick={next} disabled={sampleIdx === samples.length - 1}>
            Next
          </Button>
          <Button onClick={() => setStopDialogOpen(true)}>Clear</Button>
        </Stack>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </>
  )
}

const ConfirmStopDialog = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const handleConfirm = () => {
    dispatch(clearQueue())
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Stop</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to clear the queue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} color="secondary">
          Clear queue
        </Button>
      </DialogActions>
    </Dialog>
  )
}
