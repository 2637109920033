import { Check, OpenInNew } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Box } from '@mui/system'
import { TextField } from 'mui-rff'
import { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import FinalFormAutoSave from '../../../components/FinalFormAutoSave'
import { renderTextWithLinks } from '../../../utils/formatUtils'
import { setManualValuationComment } from '../valuationActions'

export const ValuationCommentForm = ({ manualValuation, disabled }) => {
  const dispatch = useDispatch()

  const manualValuationId = manualValuation.id

  const onSubmit = useCallback(
    (values, reset) =>
      dispatch(
        setManualValuationComment({
          manualValuationId,
          comment: values?.comment ?? '',
        })
      )
        .unwrap()
        .then(() => reset({ comment: values?.comment ?? '' })),
    [dispatch, manualValuationId]
  )

  return disabled ? (
    <Box
      sx={{
        // display like <pre>
        whiteSpace: 'pre',
        maxHeight: '250px',
        borderBottom: '1px solid #ccc',
        pb: 1,
        overflow: 'auto',
      }}
    >
      {renderTextWithLinks(manualValuation?.comment)}
    </Box>
  ) : (
    <Form
      initialValues={{ comment: manualValuation?.comment ?? '' }}
      onSubmit={onSubmit}
      render={({ dirty, valid, handleSubmit, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TextField
              name="comment"
              multiline
              fullWidth
              rows={4}
              label="Comment"
              disabled={disabled}
            />
            <Box display="flex" justifyContent="center" mt={-2}>
              <TextFieldDialogOrButton name="comment" />
            </Box>
            {!disabled && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button type="submit" disabled={!dirty}>
                  {dirty ? (
                    'Save'
                  ) : (
                    <>
                      Saved <Check />
                    </>
                  )}
                </Button>
                <FinalFormAutoSave
                  debounce={900}
                  save={(values) =>
                    !disabled && valid && onSubmit(values, reset)
                  }
                />
              </Box>
            )}
          </Box>
        </form>
      )}
    />
  )
}

const TextFieldDialogOrButton = ({ name }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button onClick={handleClickOpen}>
        <OpenInNew />
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Valuation comment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name={name}
            label="Comment"
            type="email"
            multiline
            rows={20}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
