import { Code } from '@mui/icons-material'
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { debugSelector } from '../features/debug/debugSlice'

export const DebugJSONPopupButton = ({ data, title, buttonText }) => {
  const isDebug = useSelector(debugSelector)
  const [open, setOpen] = useState(false)

  return isDebug ? (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Code /> {buttonText}
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <Box component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
          {JSON.stringify(data, null, 2)}
        </Box>
      </Dialog>
    </>
  ) : null
}
