import { Cancel, Search, Source } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { capitalize } from 'lodash'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'

import { TagsSection } from '../../components/tags/TagsSection'
import { formatDate } from '../../utils/formatUtils'
import { authSelector } from '../auth/authSlice'
import {
  allManualValuationsConnector,
  allManualValuationsSelector,
} from './valuationsSelectors'

export default function ValuationsPage() {
  useFirestoreConnect(allManualValuationsConnector)
  const valuations = useSelector(allManualValuationsSelector)
  const loading = !isLoaded(valuations)
  const auth = useSelector(authSelector)
  const [isFilterOnlyMine, setFilterOnlyMine] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')

  const groupFilters = [
    {
      label: 'Today',
      filter: (v) => moment().startOf('day').isBefore(v),
    },
    {
      label: 'This week',
      filter: (v) =>
        moment().startOf('week').isBefore(v) &&
        moment().startOf('day').isSameOrAfter(v),
    },
    {
      label: 'This month',
      filter: (v) =>
        moment().startOf('month').isBefore(v) &&
        moment().startOf('week').isSameOrAfter(v),
    },
    {
      label: 'Older',
      filter: (v) => moment().startOf('month').isSameOrAfter(v),
    },
  ]

  const matchesSearchQuery = useCallback((textParts, searchQuery) => {
    if (!searchQuery?.length) {
      return true
    }
    return textParts.some(
      (textPart) =>
        textPart && textPart.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [])

  const shouldFilter = useCallback(
    (valuation) =>
      searchQuery?.length > 0
        ? matchesSearchQuery(
            [valuation.title, valuation.committed_by, valuation.created_by],
            searchQuery
          )
        : !auth ||
          !isFilterOnlyMine ||
          valuation.committed_by === auth.uid ||
          valuation.created_by === auth.uid,

    [isFilterOnlyMine, auth, searchQuery, matchesSearchQuery]
  )

  const groups =
    !loading &&
    groupFilters
      .map((group) => {
        return {
          label: group.label,
          valuations: valuations
            .filter((v) => group.filter(moment(v.created_at)))
            .filter(shouldFilter)
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
        }
      })
      .filter((group) => group.valuations.length > 0)

  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          component={Link}
          to="/valuate/new"
        >
          Create
        </Button>
        <TextField
          label="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: '400px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment:
              searchQuery?.length > 0 ? (
                <InputAdornment position="end">
                  <Tooltip title="Clear search query">
                    <IconButton
                      aria-label="clear search query"
                      onClick={() => setSearchQuery('')}
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : null,
          }}
        />

        {!searchQuery?.length && (
          <FormControlLabel
            sx={{ mt: 1 }}
            control={
              <Switch
                checked={!isFilterOnlyMine}
                onChange={() => setFilterOnlyMine(!isFilterOnlyMine)}
              />
            }
            label="Show others' valuations"
          />
        )}
      </Box>
      {groups &&
        groups.map((group, i) => (
          <Box mb={5}>
            <Typography variant="h4" gutterBottom>
              {group.label}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              {group.valuations.map((valuation, i) => (
                <Card key={i} item md={6} sx={{ flex: '0 0 400px' }}>
                  <CardActionArea
                    component={Link}
                    to={`/valuate/${valuation.id}`}
                    sx={{ p: 1, height: '100%' }}
                    style={{ textDecoration: 'none' }} // for Link (not MUI)
                  >
                    <CardContent>
                      <Box sx={{ overflow: 'hidden' }}>
                        <Typography variant="h5" sx={{ width: '500%' }}>
                          {valuation.title || '?'}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle2">
                        {valuation.committed_by || valuation.created_by} <br />
                        {formatDate(valuation.created_at)}
                      </Typography>
                      <Box
                        paragraph
                        sx={{ mt: 2, display: 'flex', gap: 1, width: '99%' }}
                      >
                        <Chip
                          label={
                            <>
                              <Source sx={{ fontSize: '11pt' }} />{' '}
                              {capitalize(valuation.source_type)}
                            </>
                          }
                          color="primary"
                        />
                        {valuation.committed_by && (
                          <Chip label="Committed" color="success" />
                        )}
                        <TagsSection
                          tags={valuation.tags}
                          suggestionsDocKey="manual_valuation"
                          canModify={false}
                        />
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          </Box>
        ))}
    </>
  )
}
